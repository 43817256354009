import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, LinearProgress } from '../../../../components/BillingMDC';
import '../../../../styles/printStyle.css';
import history from '../../../../utils/history';
import { clone } from '../../../../utils/arrayProcessor';
import * as filterUtil from '../../../../utils/filterUtil';
import {
  config,
  getBillFooterSummary,
  getBillSummaryDetails,
  getBillTotalList,
  getTotals,
  MESSAGE,
  TITLE,
} from './config';
import PrintBody from '../../../../components/PrintComponent/PrintBody';
import { ABBREVIATED_INVOICE_BASE } from '../../../../data/enums/Route';
import { RATE_WITH_VAT_FACTOR, CLIENT, GET_BATCH_TH } from '../../../../data/enums/enums';
import { salesInvoiceApi } from '../../../common/base.api';
import { has } from '../../../../utils/hasOwnProperty';
import * as printService from '../../../common/print.service';
import * as snackService from '../../../common/snack.service';
import BillingSnackBar from '../../../../components/BillingMDC/BillingSnackbar';
import PageButtonWrapper from '../../../common/PageButtonWrapper';
import DetailView from '../../../common/detail/DetailView';
import {getPercentage} from "../../../../utils/miscellaneous";
import {addIndexInPrintDataSet} from "../../../common/common";
import InvoiceDetailsStyled from '../../sales/detail/InvoiceDetailsStyled'
import {groupPrintDataSet} from "../../../common/print.service";

const propTypes = {
  postPrintHandler: PropTypes.func,
  batchEnabled: PropTypes.bool,
};

const defaultProps = {
  postPrintHandler: () => window.close(),
  batchEnabled: false,
};

class InvoiceDetails extends Component {
  mapPrintInfo = () => {
    const { firstCopyTaxInvoice, dateTime,printData } = this.state;
    const { user } = this.props;
    if (!firstCopyTaxInvoice && this.client === CLIENT.DBS) {
      this.setState({ printInfo: printData.info });
    } else {
      const printInfo = [
        { title: 'Printed On', value: dateTime.date },
        { title: 'Printed Time', value: dateTime.time },
        { title: 'Printed By', value: user.name  },
      ];
      this.setState({ printInfo });
    }
  };
  getprintDetails (){
    const { printData,printDetails ,invoiceId,dateTime,printTotalInfo} = this.state;
    const { user,match ,location,company} = this.props;
    const {state} =location;
    if(state){
      printData.firstCopy =state.firstCopy;
      printData.id = state.id;
      printData.details =state.details;
      printData.info =   state.info;
      this.setState({printData},()=>{
        this.mapPrintInfo();
      });
    }
    else{
      const printInfo = [
        { title: 'Printed On', value: dateTime.date },
        { title: 'Printed Time', value: dateTime.time },
        { title: 'Printed By', value: user.name },
        { title: 'Printed Count', value: printTotalInfo.printCount },
      ];
    printData.firstCopy = false;
    printData.id = invoiceId;
    printData.details =printDetails;
    printData.info =  printInfo;
    this.setState({printData},()=>{
      this.mapPrintInfo();
    });
    }
  }

  handleCancelClick = () => {
    history.push(`/${ABBREVIATED_INVOICE_BASE}`);
  };

  printInvoice = () => {
    const { printDetails } = this.state;
    const self = this;
    this.groupPrintDataSetNew();
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      // self.props.afterPrint(user.idUsers, printDetails, this.props.afterPrintCallBack);
      printService.postPrint(printDetails, self.handleAfterPrint);
    };
    // todo handle if error while notifying server
  };

  // fetch the detail from the server.
  getDetail = () => {
    const { invoiceNumber, userDetail, printDetails, firstCopyTaxInvoice } = this.state;
    this.setState({ loading: true });
    salesInvoiceApi
      .getPrintDetails({
        invoice_number: invoiceNumber,
        user_id: userDetail.id,
      })
      .then(response => {
        this.setState({ loading: false });
        if ((response.status == '200') & response.success) {
          let { printInfo } = this.state;
          const { invoiceDetail, paymentDetail } = this.state;
          let { salesInvoice = {} } = response.data;

          salesInvoice.salesDetail = salesInvoice.salesDetail.map(saleDetailItem => {
            let rateWithVatFactor = Number(1 + saleDetailItem.vatPercent / 100);
            return {
              ...saleDetailItem,
              promotionDiscount: saleDetailItem.promotionDiscount * rateWithVatFactor,
              amount: saleDetailItem.amount * rateWithVatFactor,
              billDiscount: saleDetailItem.billDiscount * rateWithVatFactor,
              tradeDiscount: saleDetailItem.tradeDiscount * rateWithVatFactor,
              netAmount: saleDetailItem.netAmount * rateWithVatFactor,
            };
          });

          salesInvoice = {
            ...salesInvoice,
            amount: salesInvoice.salesDetail.reduce((acc, item) => acc + item.amount, 0),
            promotionDiscount: salesInvoice.salesDetail.reduce((acc, item) => acc + item.promotionDiscount, 0),
            billDiscount: salesInvoice.salesDetail.reduce((acc, item) => acc + item.billDiscount, 0),
            tradeDiscount: salesInvoice.salesDetail.reduce((acc, item) => acc + item.tradeDiscount, 0),
            subTotal: salesInvoice.salesDetail.reduce((acc, item) => acc + item.netAmount, 0),
          };

          const totalQuantity = response.data.salesInvoice.salesDetail.reduce((acc, curr) => {
            return acc + curr.quantity;
          }, 0);
          const subTotal = salesInvoice.subTotal;
          const billTotalList = getBillTotalList({ ...salesInvoice });
          const dataMiscellaneousList = getBillSummaryDetails(salesInvoice);
          const billSummary = getBillFooterSummary(salesInvoice);

          const invoiceData = {
            ...salesInvoice,
            ...getTotals({ ...salesInvoice, subTotal }),
          };

          const data = {
            list: salesInvoice.salesDetail,
            summary: { ...invoiceData },
          };
          // summary.totalInWords = numberToWords(summary.netAmount || 0);
          if (salesInvoice.paymentMode === 'CASH') {
            paymentDetail.status = true;
            paymentDetail.mode = 'CASH';
          }

          invoiceDetail.value = salesInvoice.userDetail.name;
          const printTotalInfoData =salesInvoice.printDetail;
          if (this.client === CLIENT.ROSIA) {
            if (has.call(response.data, 'actionUserDetail')) {
              userDetail.title = response.data.actionUserDetail.name;
              invoiceDetail.value = response.data.actionUserDetail.name;
            }
            const salesPrintInfo = printService.dataProcessor(salesInvoice, userDetail.id);
            printInfo = !firstCopyTaxInvoice ? salesPrintInfo.printInfo : this.state.printInfo;
            printDetails.invoiceNumber = salesInvoice.invoiceNumber;
            printDetails.printedBy = userDetail.id;
          } else {
            userDetail.title = this.props.user.name;
            userDetail.id = this.props.user.idUsers;
          }
          const callbackFunc= () =>{
            this.getprintDetails();
          }

          this.setState(
            {
              data,
              billSummary,
              totalQuantity: totalQuantity,
              skuList: salesInvoice.salesDetail,
              invoiceId: salesInvoice.invoiceNumber,
              date: moment(salesInvoice.date).format('DD MMM YYYY'),
              miti: salesInvoice.mitiTitle,
              entered_by: salesInvoice.userDetail.name,
              billTotalList,
              dataMiscellaneousList,
              invoiceDetail,
              paymentDetail,
              printInfo,
              userDetail,
              printTotalInfo:printTotalInfoData,
            },callbackFunc
          );
        } else {
          const snack = snackService.generateFailureMessage('Error while loading!');
          this.setState({ loading: false, snack });
        }
      })
      .catch(error => {
        const snack = snackService.generateFailureMessage('Error while loading!');
        this.setState({ loading: false, snack });
      });
  };

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.printInvoice();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  renderPrintContent = () => {
    const {
      date,
      miti,
      print,
      dataList,
      printInfo,
      billSummary,
      invoiceDetail,
      paymentDetail,
      billTotalList,
      firstCopyTaxInvoice,
      dataMiscellaneousList,
      totalQuantity,
    } = this.state;
    const { company } = this.props;
    let printInfoBill = company.printInfo;
    const printBody = [];
    const agentInfo = dataMiscellaneousList.slice(-1)[0];
    const countInfo = printInfo.find((a) => a.title === 'Print Count');
    const printCountExist = !!(countInfo && countInfo.value);
    const printCountInfo = printCountExist ? countInfo : null;
    for (let count = 0; count < printInfoBill.count; count++) {
      const title = !printCountExist ? count === 0 ? TITLE.ORIGINAL : TITLE.ORIGINAL2: TITLE.COPY;
      printBody.push(
        <PrintBody
          date={date}
          miti={miti}
          print={print}
          title={title}
          company={company}
          dataList={dataList}
          printInfo={printInfo}
          billSummary={billSummary}
          message={MESSAGE.NEXT_PAGE}
          invoiceDetail={invoiceDetail}
          paymentDetail={paymentDetail}
          billTotalList={billTotalList}
          dataMiscellaneousList={dataMiscellaneousList}
          agentInfo={agentInfo}
          printInfoBill={printInfoBill}
          pageConfig={this.pageConfig}
          isAbbreviatedInvoice={true}
          totalQuantity={totalQuantity}
          printCountInfo={printCountInfo}
        />,
      );
    }
    return printBody;
  };

  constructor(props) {
    super(props);
    const {match} =this.props;
    const invoiceId=match.params.id.replace(/%2F/g, '/')
    this.state = {
      invoiceId,
      date: '',
      miti: '',
      invoiceNumber: invoiceId,
      entered_by: '',
      firstCopyTaxInvoice: '',
      print: true,
      loading: false,
      printButtonClicked: false,
      paymentDetail: {
        status: true,
        mode: 'CREDIT',
      },
      data: {
        list: [],
        summary: {},
      },
      totalQuantity: 0,
      billSummary: getBillFooterSummary({}),
      dateTime: {
        date: filterUtil.getCurrentDay(),
        time: filterUtil.getCurrentTime(),
      },
      userDetail: {
        id: '',
        title: '',
      },
      company: {
        title: '',
        address: '',
        phone: '',
        panNumber: '',
      },
      invoiceDetail: { title: 'Invoiced By', value: '' },
      skuList: [],
      dataList: [],
      printInfo: [],
      printDetails: {
        transaction: 'SALES',
        invoiceNumber: invoiceId,
        printedBy: '',
      },
      printData:{
        details:{},
        firstCopy:false,
        id:invoiceId,
        info:[],
        start:false,
      },
      printTotalInfo:{},
      billTotalList: getBillTotalList({}),
      dataMiscellaneousList: getBillSummaryDetails({}),
      snack: { ...snackService.snackParameters },
    };
    this.client = CLIENT.DBS;
    this.dataListLength = 0;
    this.pageConfig = this.getPageConfig();
  }

  getPageConfig = () => {
    const {
      company: {
        printInfo: { batchEnabled },
      },
    } = this.props;
    const header = [...config.header];
    config.getFooterContent = this.getFooterContentForABI;
    if (batchEnabled) {
      header.splice(2, 0, GET_BATCH_TH);
      return { ...config, header };
    }
    return { ...config, header };
  };

  componentDidMount() {
    this.setupConfig();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  setupConfig() {
    const { match, user = {}, location = {}, company } = this.props;
    const { userDetail,printData,invoiceId } = this.state;
    this.getprintDetails();
    const invoiceHash =match.params.invoiceHash || '';
    if (!location.state && !!invoiceHash.length) {
      this.client = CLIENT.ROSIA;
      document.getElementsByClassName('scrollbar-container')[0].classList.add('visibility-hidden');
      document.getElementsByClassName('nav-bar')[0].classList.add('visibility-hidden');
      userDetail.id = match.params.userId;
      const firstCopy=match.params.firstCopy || false;
      this.setState(
        {
          invoiceNumber: invoiceId,
          firstCopyTaxInvoice: firstCopy,
          userDetail,
          loading: true,
        },
        () => {
          this.getDetail();
          window.onafterprint = this.handleAfterPrint;
        },
      );
    } else {
      const printDetails = {
        transaction: 'SALES',
        invoiceNumber: invoiceId,
        printedBy: user.idUsers,
      };
      this.setState(
        {
          invoiceNumber: invoiceId,
          firstCopyTaxInvoice: printData.firstCopy,
          company,
          printDetails,
        },
        () => {
          this.getDetail();
          window.onafterprint = this.handleAfterPrint;
        },
      );
    }
  }
  groupPrintDataSetNew() {
    const { skuList } = this.state;
    const { company } = this.props;
    const printInfoBill = company.printInfo || {};
    const orders = clone(skuList);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList }, () => {
      const { firstCopyTaxInvoice } = this.state;
      if (firstCopyTaxInvoice || this.client === CLIENT.ROSIA) {
        setTimeout(() => {
          this.printInvoice();
        }, 500);
      }
    });
  }

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  btnWrapper = () => (
    <>
      <Button
        accent
        className="margin-right-0 cancel-btn modal-btn"
        onClick={() => history.push(`/${ABBREVIATED_INVOICE_BASE}`)}
      >
        Cancel
      </Button>
      <Button
        accent
        className="save-btn modal-btn"
        onClick={() => {
          this.printInvoice();
        }}
      >
        Print
      </Button>
    </>
  );

  handleAfterPrint = () => {
    this.setState({ printButtonClicked: false }, () => {
      if (this.client === CLIENT.ROSIA) window.close();
    });
  };

  render() {
    const { loading, snack, printButtonClicked, data ,      paymentDetail,} = this.state;
    const { company } = this.props;
    const { printInfo: printInfoBill } = company;
    return (
      <InvoiceDetailsStyled>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={`sales-invoice-detail abbreviate-invoice-detail pad-b-24${loading ? 'clickable-false' : ''}`}>
          <div>
            {/*  { !printButtonClicked && ( */}
            <div className={`sales-invoice${loading ? 'clickable-false' : ''}`}>
              <div className={printButtonClicked ? 'no-print' : 'display-block'}>
                <DetailView
                  display={{
                    header: true,
                    footer: true,
                    summary: true,
                  }}
                  serverResponseWaiting={loading}
                  headerConfig={{
                    company,
                    title: config.title,
                    date: moment(data.summary.date || new Date()).format('DD MMM YYYY'),
                    paymentDetail,
                  }}
                  pageConfig={this.pageConfig}
                  data={data}
                  printInfoBill={printInfoBill}
                />
                <PageButtonWrapper renderBtn={this.btnWrapper} />
              </div>
              {printButtonClicked && (
                <div className={printButtonClicked ? 'display-block portrait visibility-shown' : 'no-print'}>
                  {this.renderPrintContent()}
                </div>
              )}
            </div>
            {/* )} */}
          </div>
          <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
        </div>
      </InvoiceDetailsStyled>
    );
  }

  getFooterContentForABI = data => {
    const billPer = getPercentage(data.billDiscount || 0, data.subTotal);
    const tradePer = getPercentage(data.tradeDiscount || 0, data.subTotal - data.billDiscount);
    const subTotal = data.subTotal;
    const netAmount = Number(data.subTotal - data.billDiscount - data.tradeDiscount);
    return [
      {
        title: 'Sub Total',
        value: subTotal || 0,
      },
      {
        title: `Bill Discount${billPer ? ` (${billPer}%)` : ''}`,
        value: data.billDiscount || 0,
      },
      {
        title: `Trade Discount${tradePer ? ` (${tradePer}%)` : ''}`,
        value: data.tradeDiscount || 0,
      },
      {
        title: 'Amount',
        value: netAmount || 0,
      },
    ];
  };
}

InvoiceDetails.contextTypes = {
  router: PropTypes.object,
};

InvoiceDetails.propTypes = propTypes;
InvoiceDetails.defaultProps = defaultProps;

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
});

const AbbreviateInvoiceDetail = connect(mapStateToProps)(InvoiceDetails);

export default AbbreviateInvoiceDetail;
