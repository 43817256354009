import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { menuAction } from '../../../components/Menu/DropDown';
import { EVENT_OPERATION } from '../../../utils/enum';
import { Checkbox } from '../../../components/BillingMDC';
import { VALIDATION_TYPES } from '../../common/validation';

const ServiceInvoiceTableBody = ({ dataList, onRowClick,onIconClick=() =>null ,buRosiaIntegrateIds=[],checkBoxClickSecondary,checkList }) => {
  const buRosiaIntegrateStatus  =(buId) =>{
    return buRosiaIntegrateIds.includes(buId);
  }
  return (
    <tbody>
      {dataList.map((data) => (
        <tr className={`cursor-pointer ${checkList?.includes(data?.idSales) && 'checked'}`} onClick={() => onRowClick(data)} >
      <td  onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={checkList?.includes(data?.idSales)}
          onChange={({ target: { checked } }) => {
            checkBoxClickSecondary(data?.idSales, checked, data);
          }}
        />
        </td>
          <td className='left-align'>{moment(data.date).format('D MMM YYYY')}</td>
          <td className='right-align'>{(data.referenceNumber)}</td>
          <td className='left-align'>{data.invoiceNumber}</td>
          <td className='left-align'>{data?.customerDetail?.title}</td>
          <td className='left-align'>{data?.customerDetail?.email}</td>
          <td className='left-align'>{data.customerDetail.address}</td>
          <td className='right-align'>{fixedFloatAndCommas(data.netAmount)}</td>
          <td className='text-center status'><p className={data.isCancelled && 'red'}>{data?.isCancelled ? 'Cancelled' : 'Invoiced'}</p></td>
          <td  className ='simple-popup-actions' onClick={(event) => {
            event.stopPropagation();
          }}>
            { (buRosiaIntegrateStatus(data?.businessId) || (data?.isCancelled) ) || menuAction(menuConfigList,{ menuIcon: 'ellipsis-v' }, onIconClick, data,{ update: true, delete: true })}</td>
        </tr>
      ))}
    </tbody>
  )};

ServiceInvoiceTableBody.propTypes = {
  dataList: PropTypes.instanceOf(Array).isRequired,
  onRowClick: PropTypes.func,
};

const salesInvoiceHeader = [
  {
    id: 1,
    label: 'date',
    title: 'Date',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 19,
    label: 'reference_number',
    title: 'Trip Id',
    className: 'cursor-pointer right-align',
    sortable: true,
  },
  {
    id: 2,
    label: 'invoice_number',
    title: 'Invoice Number',
    className: 'cursor-pointer left-align',
    sortable: true,
  },
  {
    id: 3,
    label: 'ledgers.title',
    title: 'Customer Name',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 9,
    label: 'ledgers.email',
    title: 'Email',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 4,
    label: 'ledgers.address',
    title: 'Address',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 18,
    label: 'net_amount',
    title: 'Net Amount',
    className: 'cursor-pointer right-align',
    sortable: true,
  },
  {
    id: 5,
    label: 'status',
    title: 'Invoice Status',
    className: 'cursor-pointer text-center',
    sortable: true,
  },
  {
    id: 8,
    label: 'actionHolders',
    title: '',
    className: 'cursor-pointer right-align',
    sortable: true,
  },
];

const formWithEmailValidation = [
  {
    title:'externalEmail',
    type:VALIDATION_TYPES.EMAIL_RULE,
  }
];

const config = {
  header: salesInvoiceHeader,
  getTableBody: ServiceInvoiceTableBody,
  formWithEmailValidation,
};
export default config;

const menuConfigList = [
  {
    title: 'Cancel',
    icon: 'cancel',
    permissionDerivedBy: 'cancel',
    type: EVENT_OPERATION.CANCEL,
  },
];

