import React from 'react';
import PropTypes from 'prop-types';
import { ACTION } from '../../../../data/enums/enums';
import { Button, Icon } from '../../../../components/BillingMDC';
import BillingSelect from '../../../../components/Select/BillingSelect';
import { EMPTY } from '../../../../data/enums/errorMessage';
import HorizontalLabelValueBar from '../../../common/horizontalLabellValueBar';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import PageHeaderStyled from '../../../common/pagination/PageHeaderStyled';
import ServiceDetailStyled from './ServiceDetailStyled';

const propTypes = {
  cancelFlag: PropTypes.bool,
  crudMode: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  updateCrudMode: PropTypes.func.isRequired,
  batchRef: PropTypes.instanceOf(Object),
  catalogDetailRef: PropTypes.instanceOf(Object),
  handleCancelClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
};

const defaultProps = {
  cancelFlag: false,
  crudMode: ACTION.CREATE,
  data: {},
  enableErrorDisplay: false,
  title: '',
  batchRef: {},
  catalogDetailRef: {},
};

const SkuDetailView = ({
  data,
  title,
  crudMode,
  handleInputChange,
  enableErrorDisplay,
  updateCrudMode,
  handleCancelClick,
  handleSaveClick,
  buList,
}) => (
  <ServiceDetailStyled className={`pad-b-24 ${crudMode === ACTION.ADD ? 'sku_create' : 'sku_details'}`}>
    <PageHeaderStyled>
      <div className='card-header-bar'>
        <h2>{title}</h2>
        <div className='header-menu'>
          {crudMode === ACTION.READ && <Icon name='edit' onClick={() => updateCrudMode(ACTION.UPDATE)} />}
        </div>
      </div>
    </PageHeaderStyled>
    <div className='card-body' style={{ pointerEvents: crudMode === ACTION.READ ? 'none' : '' }}>
      <div className='form-wrap'>
        <h6>General Setting</h6>
        <div className='form-inputs'>
          <HorizontalLabelValueBar
            label='Title'
            value={
              <BillingTextField
                value={data.title}
                handleChange={handleInputChange}
                param='title'
                required
                type='text'
                className=''
                emptyField={enableErrorDisplay}
                errorMessage={EMPTY}
              />
            }
          />
        </div>
        <div className='form-inputs'>
          <HorizontalLabelValueBar
            label='Description'
            value={
              <BillingTextField
                value={data.description}
                handleChange={handleInputChange}
                param='description'
                required
                type='text'
                className=''
                emptyField={enableErrorDisplay}
                errorMessage={EMPTY}
              />
            }
          />
        </div>
        <div className='form-inputs'>
          <HorizontalLabelValueBar
            label='Business Unit'
            value={
              <BillingSelect
                className='businessId'
                name='businessId'
                value={buList.filter(
                  (option) => option.businessId === data.businessId
                )}
                clearable={false}
                options={buList}
                onChange={(e) => handleInputChange('businessId', e?.businessId)}
                getOptionLabel={({ businessAlias }) => businessAlias}
                getOptionValue={({ businessId }) => businessId}
              />
            }
          />
        </div>
        <div className='form-inputs'>
          <HorizontalLabelValueBar
            label={<>Rate<small>(Without VAT)</small></>}
            value={
              <BillingTextField
                value={data.rlp}
                handleChange={handleInputChange}
                floatingLabel='RLP'
                param='rlp'
                type='number'
                required
                zeroError={false}
                emptyField={enableErrorDisplay}
                errorMessage={EMPTY}
              />
            }
          />
        </div>
        <div className='form-inputs'>
          <HorizontalLabelValueBar
            label={<>VAT<small>(%)</small></>}
            value={
              <BillingTextField
                value={data.vatPercent}
                handleChange={handleInputChange}
                floatingLabel='VAT'
                param='vatPercent'
                type='number'
                required
                zeroError={false}
                emptyField={enableErrorDisplay}
                errorMessage={EMPTY}
              />
            }
          />
        </div>
      </div>
    </div>
    <>
      {crudMode !== ACTION.READ && (
        <div className='btns'>
          <Button accent className='dialog-cancel cancel-btn modal-btn' onClick={() => handleCancelClick()}>
            Cancel
          </Button>
          <Button
            accent
            className='save-btn modal-btn'
            onClick={() => {
              handleSaveClick();
            }}
          >
            Save
          </Button>
        </div>
      )}
    </>
  </ServiceDetailStyled>
);

SkuDetailView.propTypes = propTypes;
SkuDetailView.defaultProps = defaultProps;

export default SkuDetailView;
