import React, { Component } from 'react';
import moment from 'moment';
import tableConfig from './config';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import ArchivedEmailStyled from '../ArchivedEmailStyled';
import { BillingSnackBar } from '../../../components/BillingMDC';
import { ARCHIVED_EMAIL_CONFIG } from '../../common/domain.config';
import { saveAs } from 'file-saver';
import { base64Encoder } from '../../../utils/conversion';

class ArchivedEmailDetails extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const id = match.params.id.replace(/%2F/g, '/');
    const title = <span className='archiveEmail-title'>Archived Email / <b>{moment(id.substr(4, 6)).format('MMMM')} {id.substring(0, 4)}</b></span> ;
    this.state = {
      id,
      title,
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
      },
      data: {
        list: [],
        total: 0,
      },
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  componentDidMount() {
    this.loadTableData();
  }

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getList } = this.props;
    const { queryParameters } = this.state;
    getList({
      start_date:'',
      end_date: '',
      folderSearchText: queryParameters?.search || '',
    })
      .then(response => {
        const { data } = this.state;
        data.list = response.map(a => {
          return { ...a, id: Number(a?.year?.toString() + a?.month?.toString()) };
        });
        this.setState({ data }, () => {
          this.getData();
        });
      })
      .catch(err => {
        console.log(err);
        this.sendErrorMessage();
      });
  };
  getData = () => {
    const { data, id } = this.state;
    const item = data.list.find(a => a.id === Number(id));
    data.list = item?.inv;
    data.total=item?.inv?.length;
    this.setState({ data });
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  onTableSpanClick = data => {
    const { downloadEmail } = this.props;

    downloadEmail({ invoiceNumber: [data?.invoice_number] })
      .then(response => {
        const convertedBase64 = base64Encoder(response)
        const file = new Blob([convertedBase64], { type: 'application/pdf;base64' });
        saveAs(file, `${data?.invoice_number}`);
      })
      .catch(err => {
        this.sendErrorMessage();
      });
  };

  render() {
    const { queryParameters, data, display, snack, title } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <ArchivedEmailStyled className="archiveEmail-details">
        <PageView
          domainConfig={{ ...ARCHIVED_EMAIL_CONFIG, title }}
          headerConfig={{
            search: true,
            date: false,
            download: false,
            filter: false,
            create: false,
            print: false,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          resetFilter={this.basePaginationService.resetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleFilterChange={this.basePaginationService.handleFilterChange}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          onPageSelect={this.basePaginationService.onPageSelect}
          onTableSpanClick={this.onTableSpanClick}
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </ArchivedEmailStyled>
    );
  }
}

const ArchivedEmailDetailsWithState = withBaseState(ArchivedEmailDetails);

export default ArchivedEmailDetailsWithState;
