import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Cell } from '../BillingMDC';
import { fixedFloatAndCommas } from '../../utils/conversion';


const BillTotalContent = ({
  totalList,totalValue,invoiceDetail,print,printInfo,totalInWords,
  signatureTitle,lineMargin,remarksStatus,remarks,agentInfo ,agentExist,
  paymentMode,totalQuantity,batchEnabled,invoiceData,
}) => {
  const paymentVia = paymentMode ==='CUSTOMER' ? 'Credit' :'Cash';
  const printInfoList =[...printInfo];
  printInfoList.splice(0, 1);
  printInfoList.pop();
  return(
    <tfoot>
      {totalQuantity>0 && (
        <tr className='total_tr'>
          <td colSpan='2' className='total_quantity_td'>
            <span className={`float-left total_quantity ${batchEnabled ? 'batch-enable' :'batch-disable'}`}>
              <p className='invoice_title'>Total Quantity</p>
              <span className='invoice_value font-bold'>{totalQuantity}</span>
            </span>
          </td>
        </tr>
      )}
      <tr className='extra_tr'>
        <td colSpan='2' className='extra_td'>
          <div className={'extra_div'}>
            <p className='billReferenceNumber'>Reference Number:
              <span className='value font-bold'>{invoiceData?.summary?.additionalInfo?.billInformation
          ?.map((bill) => {
            return bill?.referenceNumber;
          })
          .toString() || 'N/A'}</span>
            </p>
            <p className='consignmentValue'>Consignment Value :
              <span className='value font-bold'>{invoiceData?.summary?.additionalInfo?.billInformation?.reduce((previousValue, currentValue) => {
              return Number(previousValue) + Number(currentValue?.consignmentValue);
            },0)
            .toFixed(2) || 'N/A'}</span>
            </p>
          </div>
        </td>
      </tr>
      {totalList.map((item,key) => {
        return (

          <tr key = {key} className='total-info'>
            <td className='total-content-data text-right item_title'>
              {item.title}
            </td>
            <td className='total-content-data text-right item_value pad-r-2 font-bold'>{fixedFloatAndCommas(item.value || 0)}</td>
          </tr> );
      })
      }
      <tr className='tfoot-td total '>
        <td>
          <p className='float-right total_title right-align font-bold'>Total</p>
        </td>
        <td className='total_value right-align pad-r-2 font-bold'>
          {fixedFloatAndCommas(totalValue)}</td>
      </tr>
      <tr className='in-word'>
        <td className='tfoot-td total-word right-align ' colSpan='2'>
          <span className='left'>In Words:</span>
          <span className='right'>{totalInWords}</span>
        </td>
      </tr>
      <tr className='note'>
        <td colSpan='2'>
          <p className='note_p'>"This is a computer generated invoice.
No signature and stamp are required."</p>
        </td>
      </tr>
      <tr className='footer-out'>
        <td className='tfoot-td' colSpan={2}>
          <div className='wrap'>
            <div className='left'>
              <p className='padding-r-8 print-on'>
            Print On:
                <span className='font-bold'>{printInfo[0]?.value}</span>
              </p>
              <p className='padding-r-8 remark'>
                Remarks:
                <span className='font-bold'>{remarks ||  'N/A'}</span>
              </p>
            </div>
            <div className='td-wrap'>
              {
                printInfoList.map((item,key) => {
                  return (
                    <span className='span-wrapper' key={key}>
                      <span className='padding-r-8'>{item.title}:</span>
                      <span className='pad-r-2 font-bold'>{item.value}</span>
                    </span>
                  );
                })
              }
            </div>
            <div className='sign'>
              <p className='float-right right right-align signature-line'/>
              <p className={`authorized float-right font-bold right ${lineMargin}`}>
                {signatureTitle}
              </p>
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  );
};

BillTotalContent.defaultProps = {
  signatureTitle: 'Authorized Signature',
  lineMargin: 'padding-r-8',
  invoiceDetail: { title : '', value:'' },
  agentInfo: null,
};

BillTotalContent.propTypes = {
  totalList: PropTypes.array,
  agentInfo: PropTypes.instanceOf(Object),
};

export default BillTotalContent;
