import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const VatReportTableBody = ({ dataSet = [] ,remainingLines }) => {
  return(
    <>
      {
        dataSet.map((data) => (
          <tr className='cursor-pointer'>
            {data.transaction.purchase ? (
              <td className='left-align'>{data.purchaseMitiTitle}</td>
            ) : (
              <td>{null}</td>
            )}
            {data.transaction.purchase ? (
              <td className='left-align description'>{data.purchaseLedgerName}</td>
            ) : (
              <td>{null}</td>
            )}
            {data.transaction.purchase ? (
              <td className='right-align'>
                {data.transaction.purchase.total_purchase
                  ? fixedFloatAndCommas(data.purchaseTotal)
                  : data.purchaseTotal}
              </td>
            ) : (
              <td>{null}</td>
            )}
            {data.transaction.purchase ? (
              <td className='right-align'>
                {data.purchaseTEA
                  ? fixedFloatAndCommas(data.purchaseTEA)
                  : 0}
              </td>
            ) : (
              <td>{null}</td>
            )}
            {data.transaction.purchase ? (
              <td className='right-align'>
                {data.purchaseTPA
                  ? fixedFloatAndCommas(data.purchaseTPA)
                  : 0}
              </td>
            ) : (
              <td>{null}</td>
            )}
            {data.transaction.purchase ? (
              <td className='right-align'>
                {data.purchasePTR
                  ? fixedFloatAndCommas(data.purchasePTR)
                  : '13%'}
              </td>
            ) : (
              <td>{null}</td>
            )}
            {data.transaction.purchase ? (
              <td className='right-align'>
                {data.transaction.purchase.taxable_purchase_vat
                  ? fixedFloatAndCommas(data.purchasTPV)
                  : data.purchasTPV}
              </td>
            ) : (
              <td>{null}</td>
            )}
            {data.transaction.sales ? <td className='left-align'>{data.salesMitiTitle}</td> : <td>{null}</td>}
            {data.transaction.sales ? <td className='left-align description'>{data.salesLedgerName}</td> : <td>{null}</td>}
            {data.transaction.sales ? (
              <td className='right-align'>
                {data.transaction.sales.total_sales
                  ? fixedFloatAndCommas(data.salesTotal)
                  : data.salesTotal}
              </td>
            ) : (
              <td>{null}</td>
            )}
            {data.transaction.sales ? (
              <td className='right-align'>
                {data.transaction.sales.tax_exempted_amount
                  ? fixedFloatAndCommas(data.salesTEA)
                  : data.salesTEA}
              </td>
            ) : (
              <td>{null}</td>
            )}
            {data.transaction.sales ? (
              <td className='right-align'>
                {data.transaction.sales.taxable_sales_amount
                  ? fixedFloatAndCommas(data.salesTSA)
                  : data.salesTSA}
              </td>
            ) : (
              <td>{null}</td>
            )}
            {data.transaction.sales ? (
              <td className='right-align'>
                {data.transaction.sales.sales_tax_rate ? fixedFloatAndCommas(data.salesSTR) : '13%'}
              </td>
            ) : (
              <td>{null}</td>
            )}
            {data.transaction.sales ? (
              <td className='right-align'>
                {data.transaction.sales.taxable_sales_vat
                  ? fixedFloatAndCommas(data.salesTSV)
                  : data.salesTSV}
              </td>
            ) : (
              <td>{null}</td>
            )}
          </tr>
        ))}
      {remainingLines && remainingLines.length
        ? remainingLines.map((i) => (
          <tr className='blank-tr'>
            <td></td>
            <td colSpan ='14' className=''></td>
          </tr>
        ))
        : null}
    </>
  )
};

VatReportTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const vatReportConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'miti_title',
      title: 'Miti',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    {
      id: 2,
      label: 'ledger_name',
      title: 'Description',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    {
      id: 3,
      label: 'total_purchase',
      title: 'Purchase',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 4,
      label: 'taxable_exempted_amount',
      title: 'Tax Exempted',
      className: 'cursor-pointer right-align',
      sortable: true,
    },

    {
      id: 5,
      label: 'taxable_purchase_amount',
      title: 'Taxable Amount',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 6,
      label: 'purchase_tax_rate',
      title: 'Tax Rate',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 7,
      label: 'taxable_purchase_vat',
      title: 'Tax Amount',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 8,
      label: 'miti_title',
      title: 'Miti',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    {
      id: 9,
      label: 'ledger_name',
      title: 'Description',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    {
      id: 10,
      label: 'total_sales',
      title: 'Sales',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 11,
      label: 'taxable_exempted_amount',
      title: 'Tax Exempted',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 12,
      label: 'taxable_sales_amount',
      title: 'Taxable Amount',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 13,
      label: 'sales_tax_rate',
      title: 'Tax Rate',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 14,
      label: 'taxable_sales_vat',
      title: 'Tax Amount',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
  ],
  upperHeaderDetails: [
    {
      id: 1,
      label: 'purchaseImport',
      title: 'Purchase/Import',
      className: 'table-cell center-align',
      sortable: false,
    },
    {
      id: 2,
      label: 'salesExport',
      title: 'Sales/Export',
      className: 'table-2-cell center-align',
      sortable: false,
    },
  ],
};
const getDataList = (data, label,index) => {
  switch(label) {
  case 'id': return (index+1) || '';
  case 'purchaseMitiTitle': return (data?.transaction?.purchase?.miti_title) || '';
  case 'purchaseLedgerName': return (data?.transaction?.purchase?.ledger_name) || '';
  case 'purchaseTotal': return (data?.transaction?.purchase?.total_purchase) || 0;
  case 'purchaseTEA': return (data?.transaction?.purchase?.tax_exempted_amount) || 0;
  case 'purchaseTPA': return (data?.transaction?.purchase?.taxable_purchase_amount) || 0;
  case 'purchasePTR': return (data?.transaction?.purchase?.purchase_tax_rate) || 0;
  case 'purchasTPV': return (data?.transaction?.purchase?.taxable_purchase_vat) || 0;
  case 'salesMitiTitle': return (data?.transaction?.sales?.miti_title) || '';
  case 'salesLedgerName': return (data?.transaction?.sales?.ledger_name) || '';
  case 'salesTotal': return (data?.transaction?.sales?.total_sales) || 0;
  case 'salesTEA': return (data?.transaction?.sales?.tax_exempted_amount) || 0;
  case 'salesTSA': return (data?.transaction?.sales?.taxable_sales_amount) || 0;
  case 'salesSTR': return (data?.transaction?.sales?.sales_tax_rate) || 0;
  case 'salesTSV': return (data?.transaction?.sales?.taxable_sales_vat) || 0;
  default:
    return null;
  }
}
const lengthyAttributesTd = [
  'id',
  'purchaseMitiTitle',
  'purchaseLedgerName',
  'purchaseTotal',
  'purchaseTEA',
  'purchaseTPA',
  'purchasePTR',
  'purchasTPV',
  'salesMitiTitle',
  'salesLedgerName',
  'salesTotal',
  'salesTEA',
  'salesTSA',
  'salesSTR',
  'salesTSV',
];
const lengthyAttributes = [
  'salesLedgerName',
  'purchaseLedgerName',
]
const printEnums = {
  withoutFooterLimit: 32,
  withFooterLimit: 32,
  salesLedgerName: 26,
  purchaseLedgerName: 26,
  lengthyAttributes,
};

const config = {
  header: vatReportConfig,
  getTableBody: VatReportTableBody,
  uniqueIdGetter: (i) => i.id,
  lengthyAttributes,
  printEnums,
  getDataList,
  lengthyAttributesTd,
};

export {
  vatReportConfig, VatReportTableBody, config
};
