import React from 'react'
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
} from '../../../../components/BillingMDC';
import BillingSelect from '../../../../components/Select/BillingSelect'

const DialogView = ({
  handleModalClose,
  handleModalSubmit,
  modalOpen,
  editModalOpen,
  deleteModalOpen,
  handleCustomerChange,
  customerList,
  createCustomer,
  validation,
}) => {
  return (
    <Dialog
      open={modalOpen || editModalOpen || deleteModalOpen}
      onClose={() => {
        handleModalClose();
      }}
    >
      <DialogHeader>
        {modalOpen && <DialogTitle>Add Customer</DialogTitle>}
        {editModalOpen && <DialogTitle>Edit Customer</DialogTitle>}
        {deleteModalOpen && <DialogTitle> Delete Customer</DialogTitle>}
      </DialogHeader>

      {(modalOpen || editModalOpen) && (
        <DialogBody>
          <div className='dialog-upperpart'>
            <div className='row_one row '>
              <Grid className='grid-padding'>
                <Cell col={12}>
                  <label>Customer</label>
                  <BillingSelect
                    name='form-field-name'
                    param='id'
                    clearable={false}
                    value={createCustomer.id}
                    valueKey='id'
                    labelKey='title'
                    options={customerList}
                    handleChange={handleCustomerChange}
                    multipleParam
                    emptyField={validation}
                    required
                  />
                </Cell>
              </Grid>
            </div>
          </div>
        </DialogBody>
      )}
      {deleteModalOpen && <div className='default-margin-24'>Are you sure you want to Delete ?</div>}
      <DialogFooter>
        <Button
          accent
          className='dialog-cancel modal-btn'
          onClick={() => {
            handleModalClose();
          }}
        >
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            handleModalSubmit();
          }}
        >
          Ok
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default DialogView
