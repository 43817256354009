import styled from 'styled-components';

const EmailFrequencyStyled = styled.div`
&.paginated-list{
    table{
        tbody{
            td{
                text-transform:capitalize;
            }
        }
    }
}
&.create{
    .tab-section{
        background-color: #FFFFFF;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
        .react-tabs__tab-list{
            padding:10px;
            border-bottom: 1px solid #efefef;
            margin:0;
        }
    }
.detail-tab-section {
    h2{
        font-size: 14px;
        color:rgba(0, 0, 0, 0.34);
    }
    .mdc-dialog__surface{
        max-width:352px;
        .mdc-dialog__header__title{
            font-size:20px;
            font-weight:500;
            color:rgba(0, 0, 0, 0.86);
        }
    }
    .frequency-title{
        padding: 24px;
        border-bottom: 1px solid #efefef;
    }
    .bill-issue{
        padding: 24px;
        h4{
            color:rgba(0, 0, 0, 0.379393);
            padding-bottom: 24px;
            font-weight:500;
        }
        .checkbox-list{
            .mdc-checkbox{
                width: 18px;
                height: 18px;
                padding: 8px;
            }
            label{
                text-transform: capitalize; 
                font-size: 14px;
                line-height: 20px;
            }
        }
        .mdc-layout-grid{
            padding:0;
            padding-bottom:24px;
        }
    }
    .time{
        display: flex;
        .time__hours{
            display: flex;
            flex-direction: column;
            width: 100%;
            > label{
                font-size: 10px;
                color: rgb(167, 169, 188);
                margin-left: 16px;
                line-height: 10px;
            }
            .mdc-textfield{
                height: 30px;
                padding-right: 24px;
                input{
                    font-size:13px;
                    font-size: 14px;
                    height: 20px;
                    padding-bottom: 2px;
                    color:rgba(0,0,0,0.87);
                }
            }
        }
    }
    .card-header-bar {
        align-items:center;
        padding: 10px 38px;
        &>h2{
            line-height:14px;
            font-weight:400;
            color:rgba(0, 0, 0, 0.34);
        }
        .display-btn{
            box-shadow: unset;
            padding:0;
            line-height:20px;
            .create-fab-button {
                line-height:unset;
                button{
                position:unset;
                line-height:unset;
            }
            } 
        }
        .create-fab-button{
            .mdc-fab{
                height: 24px;
                width: 24px;
                .mdc-fab__icon{
                    margin: 2px;
                    height: 1px;
                }
            }
        }
    }
}
}
`;

export default EmailFrequencyStyled;
