import React, { Component } from 'react';
import { connect } from 'react-redux';
import InvoiceDetails from './detail';
import history from '../../../utils/history';
import * as queryService from '../../common/query.service';
import * as printService from '../../common/print.service';
import { SERVICE_INVOICE_CONFIG } from '../../common/domain.config';
import { SERVICE_INVOICE_CREATE, SERVICE_INVOICE_DETAILS } from '../../../data/enums/Route';
import PageView from '../../common/pagination/PageView';
import tableConfig from './config';
import {
  buApi,
  checkIfApiCallSuccess,
} from '../../common/base.api';
import * as snackService from '../../common/snack.service';
import { BillingSnackBar } from '../../../components/BillingMDC';
import withBaseState from '../../common/withBaseState';
import { voucherEncoder } from '../../../utils/miscellaneous';
import { extractSingleItemFromList } from '../../../utils/arrayProcessor';
import * as downloadService from '../../common/download.service';
import {storeInLocalStorage ,fetchFromLocalStorage} from "../../common/common";
import { changeBUFilterQueryTitle } from '../../common/common';
import ServiceInvoiceStyled from './ServiceInvoiceStyled'
import {ConfirmationDialog} from './Dialog';
import { EMAIL_RULE } from '../../../data/enums/enums';

class ServiceInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        filter: false,
        menu: false,
        searchBox: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          business_id_thirdParty: [],
        },
      },
      data: {
        list: [],
        total: 1,
      },
      print: {
        id: '',
        start: false,
        info: [
          { title: 'Printed On', value: '' },
          { title: 'Printed Time', value: '' },
          {
            title: 'Print Count',
            value: '',
          },
          { title: 'Printed By', value: '' },
        ],
        details: {
          transaction: '',
          invoiceNumber: '',
          printedBy: '',
        },
      },
      readModal:false,
      cancelInvoiceData:{},
      buRosiaIntegrateIds:[],
      sendmail:false,
      checkList:[],
      externalEmail:'',
      emailValidation: {
        flag: false,
        fieldList: tableConfig.formWithEmailValidation,
      },
      checkDataList:[],
    };
    const { downloadList } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }
  componentDidMount() {
    this.getBUList();
    this.getFilterStateFromStorage();
  }

  getBUList = () => {
    buApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        const filteredBusinessIds = response.data.list.map(a =>a.isRosiaIntegrated ? a.businessId: null);
        this.setState({ buRosiaIntegrateIds:filteredBusinessIds});
      }
    });
  };
  setFilterStateInStorage = () => {
    const { queryParameters,display} = this.state;
    storeInLocalStorage(
      'DISPLAY',
      display,
      'SI'
    );
    storeInLocalStorage(
      'FILTER',
      {
       queryParameters,
      },
      "SI"
    );
  };
  getFilterStateFromStorage = () => {
    const {queryParameters,display} =this.state;
    const localData = fetchFromLocalStorage('FILTER', "SI");
    const localDisplay = fetchFromLocalStorage('DISPLAY', 'SI');
    this.setState(
      { queryParameters:localData?.queryParameters || queryParameters ,
        display:{...localDisplay,filter:false} || display},
      () => {
        this.loadTableData();
      },
    );
  };
  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  loadTableData = () => {
    const { getList } = this.props;
    const { queryParameters } = this.state;
    const extraQuery = '&isAbbreviatedInvoice=false';
    this.setFilterStateInStorage();
    getList(changeBUFilterQueryTitle(queryParameters, extraQuery)).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.totalCount;
      this.setState({ data });
    });
  };
  handleFabButtonClick = () => {
    history.push(`/${SERVICE_INVOICE_CREATE}`);
  };
  handleTableBodyClick = id => {
    history.push(`/${SERVICE_INVOICE_DETAILS}/${id}`, {});
  };

  onServiceInvoiceItemClick = data => {
    const { print } = this.state;
    const { user } = this.props;
    print.firstCopy = false;
    print.id = data.invoiceNumber;
    const modifiedId = voucherEncoder(print.id);
    const printServiceResponse = printService.dataProcessor(data, user.idUsers);
    print.details = printServiceResponse.printDetails;
    print.info = printServiceResponse.printInfo;
    history.push(`/${SERVICE_INVOICE_DETAILS}/${modifiedId}`, { ...print,isCancelled:data?.isCancelled });
    // this.setState({ print });
  };

  handlePostPrint = () => {
    const { print } = this.state;
    print.start = false;
    this.setState(print);
  };
  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };
  handleDownloadClick = (type, label) => {
    const { queryParameters } = this.state;
    const { downloadList, detailDownloadList } = this.props;
    // renaming key ,business_id_thirdParty to business_id
    queryParameters.filter['business_id'] = queryParameters.filter['business_id_thirdParty'];
    delete queryParameters.filter['business_id_thirdParty'];
    if (label === 'psr') {
      detailDownloadList({ type, query: queryParameters }).then(response => downloadService.resolver(response));
    } else {
      downloadList({ type, query: queryParameters }).then(response => downloadService.resolver(response));
    }
  };
  handleResetFilter =()=>{
  this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        business_id_thirdParty: [],
      },
    });
  }

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  onIconClick =(type,data) =>{
    this.setState({readModal:true,cancelInvoiceData:data});
  }
  getConfirmation =(mail=false) =>{
    const {cancelInvoice,sendEmail} =this.props;
    const {cancelInvoiceData,externalEmail,data,checkList,checkDataList} =this.state;
    if(mail){
      let validStatus=true;
      if(externalEmail !== '') {
        validStatus = externalEmail?.match(EMAIL_RULE);
        validStatus ===null && this.setState({emailValidation:{flag:true}})
      }
      const result = checkDataList?.filter((e) => checkList.includes(e.idSales)).map(({invoiceNumber,customerName,customerDetail:{email}}) => ({invoice_number:invoiceNumber, email,customerName}));
      const emailNotExist =result?.every(({email})=>email?.length === 0);
    if (validStatus && (!emailNotExist || externalEmail?.length )) {
      const object ={
        emailObject:result,
        external_email:externalEmail,
      }
      sendEmail(object)
      .then(response => {
        this.setState({ loading: false });
        if (response) {
          const snack = snackService.generateSuccessMessage('Email successfully sent.');
          this.setState({ snack }, () => this.loadTableData());
        }
      })
      .catch(err =>this.getEmailErrorMsg(err));
      this.handleConfirmModalClose();
    }
    else{
      this.getEmailErrorMsg({},emailNotExist);
    }
    }
    else{
    const object ={
      invoice_number:[cancelInvoiceData?.invoiceNumber],
      business_id:cancelInvoiceData?.businessId,
    }
    cancelInvoice(object)
    .then(response => {
      this.setState({ loading: false });
      if (response) {
        const snack = snackService.generateSuccessMessage('Entity Cancelled');
        this.setState({ snack }, () => this.loadTableData());
      }
    })
    .catch(err => {
      const snack = snackService.generateFailureMessage(err?.error?.message);
      this.setState({
        snack,
      });
    });
    this.handleConfirmModalClose();
  }
  }
  getEmailErrorMsg =(err={},emailNotExist=false)=>{
    const errMsg =emailNotExist && 'Email not found'
    const snack = snackService.generateFailureMessage(err?.error?.message || errMsg || 'Error while sending email.');
    this.setState({
      snack,
    });
  }
  handleConfirmModalClose =() =>{
    this.setState({readModal:false,cancelInvoiceData:{},sendmail:false,externalEmail:'',checkList:[],checkDataList:[]});
  }
  checkBoxClickSecondary = (id,checked,data) => {
    const { checkList,checkDataList} = this.state;
    const index = checkList.indexOf(id);
    if (index > -1) {
      checkList.splice(index, 1);
      const indexObj = checkDataList.findIndex(object => object.id === id);
      checkDataList.splice(indexObj, 1);
    } else {
      checkList.push(id);
      checkDataList.push(data);
    }
    this.setState({ checkList });
  };
 
  checkBoxClickPrimary =(checked) =>{
    const {data,checkDataList} =this.state;
    if (checked) {
      const dataList =data?.list;
      checkDataList.push(...dataList)
      const uniqueDataList = [...checkDataList.reduce((map, obj) => map.set(obj.idSales, obj), new Map()).values()];
      this.setState({checkDataList:uniqueDataList},()=>{
        const checkList = extractSingleItemFromList(uniqueDataList,'idSales');
        this.setState({ checkList});
      });
    } else {
      this.setState({ checkList: [] ,checkDataList:[]});
    }
  }
  emailSend =() =>{
    this.setState({sendmail:true});
  }
  onInputChange =(param,value)=>{
    this.setState({externalEmail:value});
  }

  render() {
    const { queryParameters, data, display, snack, print, readModal,cancelInvoiceData,buRosiaIntegrateIds,checkList,sendmail,externalEmail,emailValidation} = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <ServiceInvoiceStyled className='sales-paginated-list'>
        <>
          <PageView
            domainConfig={SERVICE_INVOICE_CONFIG}
            headerConfig={{
              search: true,
              date: true,
              upload: false,
              download: true,
              filter: true,
              create: true,
            }}
            display={display}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.basePaginationService.clearSearchText}
            resetFilter={this.handleResetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            data={data}
            handleDownloadClick={this.handleDownloadClick}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onTableBodyClick={this.onServiceInvoiceItemClick}
            onPageSelect={this.basePaginationService.onPageSelect}
            createHandler={this.handleFabButtonClick}
            onIconClick ={this.onIconClick}
            buRosiaIntegrateIds={buRosiaIntegrateIds}
            checkBoxClickSecondary={this.checkBoxClickSecondary}
            checkBoxClickPrimary={this.checkBoxClickPrimary}
            checkList={checkList}
            emailSend={this.emailSend}
          />
              { (sendmail || readModal) && 
                <ConfirmationDialog
                onConfirmModalClose={this.handleConfirmModalClose}
                getConfirmation={this.getConfirmation}
                readModal ={readModal}
                sendmail={sendmail}
                cancelInvoiceData={cancelInvoiceData}
                onInputChange={this.onInputChange}
                externalEmail={externalEmail}
                checkList={checkList}
                emailValidate={emailValidation?.flag}
                />
          }
          <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
        </>
      </ServiceInvoiceStyled>
    );
  }
}
const ServiceInvoiceWithState = withBaseState(ServiceInvoice);

export default ServiceInvoiceWithState;
