import moment from 'moment';
import * as httpUtils from '../../utils/httpUtils';
import * as filterUtil from '../../utils/filterUtil'
import * as appConfig from '../../config';
import { clone } from '../../utils/arrayProcessor';
import { config } from '../fundamentals/sales/detail/config';
import { addIndexInPrintDataSet } from './common';
import { CLIENT } from '../../data/enums/enums';

const has = Object.prototype.hasOwnProperty;

const dataProcessor = (data, userDetail) => {
  const salesId = data.idSales;
  const firstCopy = false;
  const { printDetail } = data;
  const printInfo = [
    {
      title: 'Printed On',
      value: has.call(printDetail, 'localPrintDate') ? moment(printDetail.localPrintDate).format('ll') : filterUtil.getCurrentDate().format('ll'),
    },
    {
      title: 'Printed Time',
      value: has.call(printDetail, 'localPrintTime') ? printDetail.localPrintTime : filterUtil.getCurrentTime(),
    },
    {
      title: 'Printed By',
      value: userDetail?.title,
    },
    {
      title: 'Print Count',
      value: has.call(printDetail, 'printCount') ? parseInt(printDetail.printCount) : '',
    },
  ];

  const printDetails = {
    transaction: 'SALES',
    invoiceNumber: data.invoiceNumber,
    printedBy: userDetail?.id,
  };

  return { printInfo, printDetails };
};

const postPrint = (printDetails, callBack = () => null) => {
  httpUtils.post(`${appConfig.baseUrl}print`, printDetails)
    .then((response) => {
      if (response.success) {
        callBack();
      }
    })
    .catch((error) => {
      {
        console.log('error', error);
      }
    });
};

const deriveDivideCondition = (item,lengthyAttributes, printEnum) => {
  return lengthyAttributes.every((a) => {
    return (item[a] && item[a].length <= printEnum[a])
  });
}

const deriveMaxLength = (item, lengthyAttributes, printEnum) => {
  return Math.ceil(Math.max.apply(Math, lengthyAttributes.map((a) => ((item[a] && item[a].length)/ printEnum[a]))))
}

const deriveListWithNumberOfLines = (printList, lengthyAttributes, printEnum) => {
  return printList.map((l) => {
    l.requiredLines = deriveMaxLength(l, lengthyAttributes, printEnum);
    return l;
  })
}
const derivePrintDataSet = (
  formattedList,
  initialChunkSize,
  printEnum,
  uniqueIdGetter,
  printOrderLines,
  ordering,
) => {
  const dataList = [];
  let count = 0;
  let tempDataList = [];
  let chunkSize = initialChunkSize;
  let indexChunk = initialChunkSize;
  let remainingLines =0;
  let printList = [...formattedList];
  let printLines = printOrderLines;
  const lengthyAttributes = printEnum.lengthyAttributes
  for (let index = 0, i = formattedList.length; index < i; index = indexChunk) {
    const chunkObj = {
      list: [],
      footer: false,
      page: 0,
    };
    let lengthyListAddedItems = [];
    count += 1;
    const inBetweenFooter = printLines > printEnum.withFooterLimit && printLines < printEnum.withoutFooterLimit
    chunkSize = inBetweenFooter ? printLines - 2 :
      printLines >= printEnum.withoutFooterLimit
        ? printEnum.withoutFooterLimit
        : printEnum.withFooterLimit;
    let availableLines = chunkSize - chunkObj.list.length;
    let lengthySpliceIndex = 0;
    printList.every((o,index) => {
      let requiredLines = 0;
      const uniqueId = uniqueIdGetter(o ,index);
      if (o.requiredLines <= availableLines) {
        chunkObj.list = [...chunkObj.list, o];
        availableLines -= o.requiredLines;
        lengthySpliceIndex += 1;
        lengthyListAddedItems.push(uniqueId);
        printLines = printLines - o.requiredLines;
      }
      if (ordering && o.requiredLines >= availableLines ){
        return false;
      }
      return true;
    });
    printList = printList.filter((l) => {
      const uniqueId = uniqueIdGetter(l);
      return !lengthyListAddedItems.includes(uniqueId)
    });
    remainingLines = availableLines;
    tempDataList = tempDataList.concat(chunkObj.list);
    if (formattedList.length - tempDataList.length === 0) {
      let remaingingLineArrObj = Array.from({ length:remainingLines },()=> ({}));
      chunkObj.footer = true;
      chunkObj.page = count;
      chunkObj.remainingLines=remaingingLineArrObj;
    } else {
      chunkObj.footer = false;
      chunkObj.page = count;
      chunkObj.remainingLines=[];
    }
    indexChunk = tempDataList.length;
    dataList.push(chunkObj);
    //i = i + chunkObj.list.length;
  }
  return dataList;
};

const getFormattedList = (printList, printInfo, config ) => {
  const { printEnums } = config;
  const printEnum = printEnums;
  const lengthyAttributes = printEnum.lengthyAttributes
  const list =  deriveListWithNumberOfLines(printList,lengthyAttributes, printEnum);

  return list;
};

const groupPrintDataSet  = (printList = [], printInfo = {}, config = {},ordering=false) => {
  const list = clone(printList);
  const { printEnums, uniqueIdGetter } = config;
  const printEnum = printEnums;
  const formattedList = getFormattedList(list, printInfo,config);
  const printOrderLines = formattedList.reduce((l,i) => l+i.requiredLines, 0);
  const inBetweenFooter = printOrderLines >= printEnum.withFooterLimit && printOrderLines < printEnum.withoutFooterLimit
  const derivedChunkSize = inBetweenFooter ? printOrderLines - 2 :
    printOrderLines >= printEnum.withoutFooterLimit
      ? printEnum.withoutFooterLimit
      : printEnum.withFooterLimit;
  let dataList = derivePrintDataSet(
    formattedList,
    derivedChunkSize,
    printEnum,
    uniqueIdGetter,
    printOrderLines,
    ordering,
  );
  dataList = addIndexInPrintDataSet(dataList);

  return dataList;
}


const flattenDataPrintAttributeWise = (list, lengthyAttributes, getData) => {
  const formattedList = list.map((d) => {
    lengthyAttributes.forEach((l) => {
      d[l] = getData(d, l)
    })
    return d;
  })

  return formattedList;
}

export {
  postPrint, dataProcessor, groupPrintDataSet, flattenDataPrintAttributeWise
};
