import React, { Component } from 'react';
import tableConfig from './config';
import history from '../../utils/history';
import ArchivedEmailStyled from './ArchivedEmailStyled';
import PageView from '../common/pagination/PageView';
import * as snackService from '../common/snack.service';
import * as queryService from '../common/query.service';
import { BillingSnackBar } from '../../components/BillingMDC';
import { ARCHIVED_EMAIL_DETAILS } from '../../data/enums/Route';
import { ARCHIVED_EMAIL_CONFIG } from '../common/domain.config';
import * as downloadService from '../../../src/views/common/download.service';
import { base64Encoder } from '../../utils/conversion';

class ArchivedEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
      },
      data: {
        list: [],
        total: 0,
      },
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  componentDidMount() {
    this.loadTableData();
  }

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getList } = this.props;
    const { queryParameters } = this.state;
    getList({start_date:'',end_date:'',folderSearchText:queryParameters?.search || ''})
      .then((response) => {
        const { data } = this.state;
        data.list = response.map(a=> {return {...a ,id:Number(a?.year?.toString() + a?.month?.toString())}});
        data.total = response.length;
        this.setState({ data });
      })
      .catch((err) => {
        console.log(err);
        this.sendErrorMessage();
      });
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  onTableBodyClick =(data) =>{
    history.push(`/${ARCHIVED_EMAIL_DETAILS}/${data?.id}`);
  }
  onTableSpanClick =(data) =>{
   const {downloadEmail} = this.props;
    const invoiceNumbers =data?.inv.map((a)=>a.invoice_number);
    downloadEmail({invoiceNumber:invoiceNumbers})
    .then(response => {
      const convertedBase64 = base64Encoder(response)
      const file = new Blob([convertedBase64], { type: 'application/zip;base64' });
      saveAs(file, "invoice.zip");
    })
    .catch((err) => {
      this.sendErrorMessage();
    });
  }

  render() {
    const {
      queryParameters, data, display, snack,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <ArchivedEmailStyled className="data-base">
        <PageView
          domainConfig={ARCHIVED_EMAIL_CONFIG}
          headerConfig={{
            search: true,
            date: false,
            download: false,
            filter: false,
            create: false,
            print: false,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          resetFilter={this.basePaginationService.resetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleFilterChange={this.basePaginationService.handleFilterChange}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          onPageSelect={this.basePaginationService.onPageSelect}
          onTableBodyClick={this.onTableBodyClick}
          onTableSpanClick={this.onTableSpanClick}
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </ArchivedEmailStyled>
    );
  }
}

export default ArchivedEmail;
