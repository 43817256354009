import React, { useEffect, useState } from 'react';
import { Button } from 'react-mdc-web';
import EmailTemplateStyled from './EmailTemplateStyled';
import emailSent from '../../styles/svg/email_sent.svg';
import { useLocation } from "react-router-dom";

const EmailTemplate = () => {
  const location = useLocation();
  const [userdetails,setUserDetails]= useState({
    name:'',
    orderId:''
  })

  useEffect(() => {
    setUserDetails({
      name:location?.state?.name,
      orderId:location?.state?.orderId
    })
 }, [location]);

  const closeTab = () => {
    window.close();
  }
  return (
    <>
      <EmailTemplateStyled>
        <div className="email__container">
          <div className="email__body">
            <img src={emailSent} />
            <h3> Email Sent </h3>
            <span className=''> The copy of Tax invoice of  an order  #{userdetails.orderId ||  'orderId'} has been sent to an email  to </span>
            <span> {userdetails.name || 'user'}.</span>
            <p> Press Ok to close the tab and go back to order page.</p>
            <Button raised accent className="emailTemplate__button" onClick={closeTab}>
              OK
            </Button>
          </div>
        </div>
      </EmailTemplateStyled>
    </>
  );
};

export default EmailTemplate;
