import React, { Component } from 'react';
import * as snackService from '../common/snack.service';
import * as queryService from '../common/query.service';
import history from '../../utils/history';
import { CONTRA_ENTRY_CREATE, CONTRA_ENTRY_DETAILS } from '../../data/enums/Route';
import tableConfig from './config';
import { CE_CONFIG } from '../common/domain.config';
import PageView from '../common/pagination/PageView';
import BillingSnackBar from '../../components/BillingMDC/BillingSnackbar';
import { checkIfApiCallSuccess, jvApi } from '../common/base.api';
import withBaseState from '../common/withBaseState';
import { voucherEncoder } from '../../utils/miscellaneous';
import ContraEntryStyled from './ContraEntryStyled';
import { TRANSACTION_TYPE } from '../../data/enums/enums';
import {storeInLocalStorage ,fetchFromLocalStorage} from "../common/common";

const propTypes = {};
const defaultProps = {};

class ContraEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        filter: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          jv_transaction_type: [TRANSACTION_TYPE.CONTRA_ENTRY],
        },
      },
      data: {
        list: [],
        total: 0,
      },
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        jv_transaction_type: [TRANSACTION_TYPE.CONTRA_ENTRY],
      },
    });
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
  }
  setFilterStateInStorage = () => {
    const { queryParameters} = this.state;
    
    storeInLocalStorage(
      'FILTER',
      {
       queryParameters,
      },
      "CONTRA_ENTRY"
    );
  };
  getFilterStateFromStorage = () => {
    const {queryParameters} =this.state;
    const localData = fetchFromLocalStorage('FILTER', "CONTRA_ENTRY");
    this.setState(
      { queryParameters:localData?.queryParameters || queryParameters ,},
      () => {
        this.loadTableData();
      },
    );
  };


  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { queryParameters } = this.state;
    const extraQuery = '&isContraEntry=true';
    this.setFilterStateInStorage();
    jvApi.getList(queryParameters, extraQuery).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const { data } = this.state;
        data.list = response.list;
        data.total = response.total;
        this.setState({ data });
      }
    });
  };

  handleFabButtonClick = () => {
    history.push(`/${CONTRA_ENTRY_CREATE}`);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  handleTableBodyClick = id => {
    const voucherNumber = voucherEncoder(id) || '';
    history.push(`/${CONTRA_ENTRY_DETAILS}/${voucherNumber}`);
  };

  render() {
    const { display, queryParameters, data, snack } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <ContraEntryStyled className="contra-entry">
        <PageView
          domainConfig={CE_CONFIG}
          headerConfig={{
            search: false,
            date: true,
            upload: false,
            download: true,
            filter: true,
            create: true,
          }}
          resetFilter={() =>
            this.basePaginationService.defaultResetFilter({
              ...queryService.queryParameters,
              filter: {
                jv_transaction_type: [TRANSACTION_TYPE.CONTRA_ENTRY],
              },
            })
          }
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleDownloadClick={this.basePaginationService.handleDownloadClick}
          display={display}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onTableBodyClick={this.handleTableBodyClick}
          onPageSelect={this.basePaginationService.onPageSelect}
          handleFilterChange={this.basePaginationService.handleFilterChange}
          createHandler={this.handleFabButtonClick}
        />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </ContraEntryStyled>
    );
  }
}

ContraEntry.propTypes = propTypes;
ContraEntry.defaultProps = defaultProps;

const ContraEntryWithState = withBaseState(ContraEntry);

export default ContraEntryWithState;
