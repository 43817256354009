import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../components/BillingMDC';

import BillingTextField from '../../../components/TextField/BillingTextField';
import { ACTION, MOBILE_NO_VALID_DIGIT } from '../../../data/enums/enums';
import { EMPTY, MOBILE_NO_DIGITS_ERROR } from '../../../data/enums/errorMessage';

const AgentDialog = ({ agent, actionType, onModalClose, onModalSubmit, onInputChange, validationFlag }) => {
  return (
    <Dialog
      open={actionType !== ACTION.NULL}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader>
        <DialogTitle>{actionType}</DialogTitle>
      </DialogHeader>

      <DialogBody>
        <div className='dialog-upperpart'>
          <Grid className='grid-padding'>
            <Cell col={6} tablet={12}>
              <BillingTextField
                value={agent.name}
                handleChange={onInputChange}
                floatingLabel='Name'
                param='name'
                required
                className='right-side-gap'
                emptyField={validationFlag}
              />
            </Cell>
            <Cell col={6} tablet={12}>
              <BillingTextField
                value={agent.phone}
                handleChange={onInputChange}
                floatingLabel='Phone No.'
                param='phone'
                required
                className='right-side-gap'
                emptyField={validationFlag}
                errorMessage={
                  agent.phone.toString().length !== MOBILE_NO_VALID_DIGIT
                    ? MOBILE_NO_DIGITS_ERROR
                    : 'Should not be empty'
                }
                invalidError={agent.phone.toString().length !== MOBILE_NO_VALID_DIGIT}
              />
            </Cell>
          </Grid>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default AgentDialog;
