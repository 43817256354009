import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const message = {
  success: 'Email Frequency Category created successfully.',
  error: '!Error while creating Email Frequency Category',
  updateSucess: 'Email Frequency Category updated successfully.',
}

const formValidationFields = [{ title: 'title' }];
const validationArr = [{ title: 'id' }];

const generalDataMapper = (element={}) => ({
  customerId: element?.customers?.map((a)=>a.id)||null,
  title: element?.email_frequency_title || '',
  type: element?.config?.type||'',
  day: element?.config?.day||[],
  occurenceDay: element?.config?.occurenceDay||null,
  hours:  element?.config?.time?.split(':')?.[0]||'',
  minutes: (element?.config?.time?.split(':')[1])?.split(' ')?.[0]||'',
  period: (element?.config?.time?.split(':')[1])?.split(' ')?.[1]||'',
});

const periodType = [
  {
    id: 1,
    title: 'AM',
    value: 'AM',
  },
  {
    id: 2,
    title: 'PM',
    value: 'PM',
  },
];

const repeatTimes = [
  {
    id: 1,
    title: 'Daily',
    value: 'daily',
  },
  {
    id: 2,
    title: 'Weekly',
    value: 'weekly',
  },
  {
    id: 3,
    title: 'Fortnightly',
    value: 'fortnightly',
  },
  {
    id: 4,
    title: 'Trimonthly',
    value: 'trimonthly',
  },
  {
    id: 5,
    title: 'Monthly',
    value: 'monthly',
  }
]

const week = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

const weekDays = {
  sunday: 7,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

const Customer = ({ dataList, onIconClick }) => (
  <tbody>
    {dataList?.map((data, index) => (
      <tr className='cursor-pointer' key={'email'- index}>
        <td className='left-align'>{index+1}</td>
        <td className='left-align'>{data?.title}</td>
        <td className='right-align'>
          <Icon name='delete' onClick={() => onIconClick(index)} />
        </td>
      </tr>
    ))}
  </tbody>
);
Customer.propTypes = { data: PropTypes.object.isRequired };

const CustomerConfig = [
  {
    id: 1, label: 'title', title: 'SN', className: 'cursor-pointer left-align', sortable: false,
  },{
    id: 2, label: 'title', title: 'Customer Name', className: 'cursor-pointer left-align', sortable: false,
  },
  {
    id: 3, label: '', title: '', className: 'cursor-pointer left-align', sortable: false,
  },
];

const tableConfig = {
  header: CustomerConfig,
  getTableBody: Customer,
};

export {
  message, generalDataMapper, periodType,repeatTimes, week, weekDays, tableConfig, formValidationFields,validationArr
}
