// TODO: Include Prop Types and Use for Composition Pattern.

import React from 'react';

import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
} from '../../../../components/BillingMDC';
import BillingSelect from '../../../../components/Select/BillingSelect';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import { discountOptions } from './config';
import { fixedFloat } from '../../../../utils/conversion';
import BillingAsyncSelect from '../../../../components/AsyncSelect';


const CreateInvoiceDialog = ({
  handleModalClose,
  modalOpen,
  editModalOpen,
  deleteModalOpen,
  createSKU,
  handleInputChange,
  onModalSubmit,
  skuList,
  formEmptyField,
  editRate,
  editRatePermission,
}) => {
  return (
    <Dialog
      open={modalOpen || editModalOpen || deleteModalOpen}
      onClose={() => {
        handleModalClose();
      }}
    >
      <DialogHeader>
        {modalOpen && <DialogTitle>Select Particular</DialogTitle>}
        {editModalOpen && <DialogTitle>Edit Particular</DialogTitle>}
        {deleteModalOpen && <DialogTitle> Delete Particular</DialogTitle>}
      </DialogHeader>

      {(modalOpen || editModalOpen) && (
        <DialogBody>
          <div className='dialog-upperpart'>
            <div className='row_one row '>
              <Grid className='grid-padding'>
                <Cell col={6} tablet={6}>
                  <label>Services</label>
                  <BillingSelect
                    name='form-field-name'
                    param='sku_id'
                    clearable={false}
                    value={createSKU.sku_id}
                    valueKey='businessSkuId'
                    labelKey='title'
                    options={skuList}
                    handleChange={handleInputChange}
                    required
                    emptyField={formEmptyField}
                    multipleParam
                  />
                </Cell>
                <Cell col={6} tablet={6}>
                  <BillingTextField
                    value={createSKU.particulars || ''}
                    handleChange={handleInputChange}
                    floatingLabel='Description'
                    param='particulars'
                    type='text'
                    className='billing-required right-side-gap'
                  />
                </Cell>
              </Grid>
            </div>
            <div className='row_two row'>
              <Grid className='grid-padding'>
                <Cell col={4} tablet={8}>
                  <BillingTextField
                    value={createSKU.quantity}
                    handleChange={handleInputChange}
                    floatingLabel='Quantity'
                    param='quantity'
                    required
                    zeroError
                    type='number'
                    className='billing-required right-side-gap'
                    emptyField={formEmptyField}
                  />
                </Cell>
                <Cell col={4} tablet={8} className>
                  <BillingTextField
                    value={fixedFloat(createSKU.rate)}
                    handleChange={handleInputChange}
                    floatingLabel='Rate'
                    param='rate'
                    required
                    type='number'
                    disabled={!(editRate && editRatePermission)}
                    zeroError={false}
                    className='billing-required right-side-gap height-50'
                    errorMessage={createSKU.rate === 0 ? 'Should not be 0' : ''}
                    invalidError={formEmptyField && createSKU.rate === 0}
                  />
                </Cell>
              </Grid>
            </div>
            <div className='row_three row'>
              <Grid className='grid-padding three'>
                <Cell col={4}>
                  <label>Net Amount</label>
                  <span className='accent-color net-amount dialog-detail'>{createSKU.net_amount}</span>
                </Cell>
              </Grid>
            </div>
          </div>
        </DialogBody>
      )}

      {deleteModalOpen && <div className='default-margin-24'>Are you sure you want to Delete ?</div>}

      <DialogFooter>
        <Button
          accent
          className='dialog-cancel modal-btn'
          onClick={() => {
            handleModalClose();
          }}
        >
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
          Ok
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default CreateInvoiceDialog;
