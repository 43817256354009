import moment from 'moment';

/**
 * @returns {Array} {title: week 1, id: 1}
 */
const getWeekList = () => {
  const weekArray = [];
  for (let count = 1; count <= 52; count++) {
    weekArray.push({ title: `Week ${count}`, id: count });
  }
  return weekArray;
};

// return the object with assigned attributes
const getObjectWithAssignedAttributes = (object) => {
  Object.keys(object).map((attribute) => {
    if (object[attribute].length === 0) {
      delete object[attribute];
    }
  });
  return object;
};

const getStartOfCurrentMonth = () => moment().startOf('month').format('DD MMM YYYY');

const getEndOfCurrentMonth = () => moment().endOf('month').format('DD MMM YYYY');

const getCurrentDay = () => moment().format('DD MMM YYYY');

const getCurrentTime = () => moment().format('hh:mm:ss a');

const formatToNumericDate = (date) => moment(date).format('YYYY-MM-DD');

const formatToReadableDate = (date) => moment(date).format('ll');

const formatToDateMonthYear = (date) => moment(date).format('DD MMM YYYY');

const formatToMonthDateYear = (date) => moment(date).format('MMM D, YYYY');

const getCurrentDate = () => moment();

const getMomentDate = (date) => moment(date);

export {
  getCurrentDate,
  getWeekList,
  getObjectWithAssignedAttributes,
  getStartOfCurrentMonth,
  getEndOfCurrentMonth,
  getCurrentDay,
  getCurrentTime,
  formatToNumericDate,
  formatToReadableDate,
  formatToDateMonthYear,
  getMomentDate,
  formatToMonthDateYear,
};
