import { fixedFloatAndCommas } from './conversion';
import { persistReducer } from 'redux-persist';
import reducers from '../reducers';
import { createStore } from 'redux';
import storage from 'redux-persist/es/storage';
import AES from 'crypto-js/aes';
import CryptoJs from 'crypto-js';

const voucherEncoder = (voucherNumber, replaceString = /[/]/g, replaceCode = '%2F') =>
  voucherNumber.replace(replaceString, replaceCode);

const voucherDecoder = (voucherNumber, replaceString = /%2F/g, replaceCode = '/') =>
  voucherNumber.replace(replaceString, replaceCode);

const getPercentage = (dividend = 1, divisor) => Number(fixedFloatAndCommas((dividend * 100) / (divisor || 1)));

const encryptText = (text, key) => AES.encrypt(text, key).toString();
const decryptText = (text, key) => CryptoJs.AES.decrypt(text, key).toString(CryptoJs.enc.Utf8);

const persistConfig = {
  key: 'key',
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);

const reduxStore = createStore(persistedReducer);

export {
  voucherEncoder, voucherDecoder, getPercentage, reduxStore, encryptText, decryptText
};
