import styled from 'styled-components';

const ServiceInvoiceStyled = styled.div`
&.sales-paginated-list{
table 
{
  th{
   z-index:1;
  }
  tbody td.status p{
  border: 1px solid rgb(71,196,100);
    color: rgb(40,125,60);
    padding: 2px;
    width: 58px;
    margin: 0;
    height: 12px;
    line-height: 12px;
    font-size: 12px;
    display: inline-block;
    &.red{
      border:1px solid #d93025;
      color:#d93025;
    }
}
}
.confirmation__dialog .mdc-dialog__surface{
  max-width:620px;
}
}
.simple-popup-actions {
  &>div{
    position: relative;
    z-index: 0;
  }
    .filter-item {
      button {
        padding: 4px 7px;
        background: transparent;
        transition: 0.2s ease-out;

        &:hover,
        &:focus {
          background-color: #f7f8f9;
          color: #272833;
        }
      }
    }
    .filter-dropdown-card {
      min-width: 130px;
      padding: 4px 0;
      top: 82%;
      right: 0;
      .filter-content {
        padding: 0;
        font-size: 14px;
        ul{
          padding:0;
          text-decoration:none;
          list-style-type: none;
          margin:0;
        }
        li {
          padding: 0;
          height: 40px;
          line-height: 40px;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            background: #f4f4f4;;
          }
          span {
            color: #272833;
            font-size: 14px;
            line-height: 21px;
            white-space: nowrap;
            i{
              margin-right: 6px;
            }
          }
        }
      }
      li {
        cursor: pointer;
      }
      svg.icon {
        width: 16px;
        height: 16px;
        margin-right: 16px;
        color: #6b6c7e;
      }
    }
  }
.block-overlay-wrapper{
  cursor:not-allowed;
}
  .sales-invoice-create {
    .dialog-upperpart {
      .error-msg {
        padding: 5px 12px;
        border: 1px solid #da1414;
        border-radius: 4px;
        background-color: #feefef;
        margin-bottom: 10px;
        label {
          color: #da1414;
          font-size: 14px;
          line-height: 21px;
        }
      }
      .row_one .select-css .zindex-2__control {
        min-height: 33px;
      }
      .row_two .mdc-textfield{
        height:50px !important;
      }
    }
    .cash-checkbox{
      label{
        position:relative;
        top:3px;
      }
    }
    .mdc-dialog--open .mdc-dialog__footer{
        border-top: 1px solid var(--line-divider-color);
      }
    .total-section-wrapper .sub-total-wrap {
      .default-left-margin-9 {
        .mdc-textfield {
          height: 25px;
        }
      }
    }
  }

  /* bulk email styling */
  .selected-status {
   height:48px;
   padding: 0px 24px;
   padding-left: 32px;
  background-color:#EDF7FF;
  border-bottom:1px solid #2196F3;
}
.selected-status .content-center {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height:48px;
}
.selected-status .content-center>div {
    display: flex;
    align-items: center;
    flex: 1;
    gap:16px;
    .right{
      p{
        margin:0;
        font-size:16px;
      }
    }
}
.mdc-checkbox:after,
.mdc-checkbox:active,
.mdc-checkbox:before,
.mdc-checkbox:hover{
background-color:unset;
}
.mdc-checkbox__native-control:focus,
.mdc-checkbox__native-control{
  background-color:unset;
}
  .mdc-checkbox__background {
  width: 18px;
  height: 18px;
  top:4px;
  border-color:#9B9C9E;
}
.checked{
  background-color:#EDF7FF;
  border-bottom:1px solid #2196F3;
}
.selected-status ~ div .end-list-table{
  max-height: calc(100vh - 308px) !important;
}
.email_dialog.confirmation__dialog .mdc-dialog__surface{
  max-width:420px;
  p{
    font-size:16px;
    line-height:24px;
    color:rgba(0,0,0,0.87);
  }
  .mdc-textfield{
    margin-top: 16px !important;
    max-width:unset;
    label{
      color:rgba(0,0,0,0.54);
      font-size:12px;
    }
    input{
      font-size:16px;
    line-height:24px;
    color:rgba(0,0,0,0.87);
    border-bottom:1px solid rgba(0,0,0,0.42);
    }
  }
}
`;

export default ServiceInvoiceStyled;
