import { DOMAIN, REPORT_TYPE } from '../../data/enums/enums';

// Sample Configuration File for each Domain
const SALES_INVOICE_CONFIG = {
  domain: DOMAIN.SALES,
  title: 'Sales Invoice',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
  url: 'sales-invoice',
  detailDownloadList: [{ title: 'Product Sales Report', label: 'psr', type: 'csv' }],
};
const SERVICE_INVOICE_CONFIG = {
  domain: DOMAIN.SERVICE_INVOICE,
  title: 'Service Invoice',
  downloadList: [REPORT_TYPE.CSV],
  url: 'service-invoice',
  detailDownloadList: [{ title: 'Product Service Report', label: 'psr', type: 'csv' }],
};
const SALES_REPORT_CONFIG = {
  domain: DOMAIN.SALES_REPORT,
  title: 'Sales Report',
  downloadList: [REPORT_TYPE.CSV],
  url: 'sales-report',
};
const PRN_REPORT_CONFIG = {
  domain: DOMAIN.PRN_REPORT,
  title: 'Purchase Return Report',
  downloadList: [REPORT_TYPE.CSV],
  url: 'prn-report',
};
const SRN_REPORT_CONFIG = {
  domain: DOMAIN.SRN_REPORT,
  title: 'Sales Return Report',
  downloadList: [REPORT_TYPE.CSV],
  url: 'srn-report',
};
const GRN_REPORT_CONFIG = {
  domain: DOMAIN.GRN_REPORT,
  title: 'Good Received Report',
  downloadList: [REPORT_TYPE.CSV],
  url: 'grn-report',
};

const ABBREVIATED_INVOICE_CONFIG = {
  domain: DOMAIN.ABBREVIATED_INVOICE,
  title: 'Abbreviated Invoice',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
  url: 'abbreviated-invoice',
  detailDownloadList: [{ title: 'Product Sales Report', label: 'psr', type: 'csv' }],
};

const PICK_LIST_CONFIG = {
  domain: DOMAIN.PICK_LIST,
  title: 'Pick List',
  downloadList: [REPORT_TYPE.CSV],
  url: 'pick-list',
  detailDownloadList: [{ title: 'Sales Summary', label: 'sales-summary', type: 'csv' }],
};
const SALES_SUMMARY_CONFIG = {
  domain: DOMAIN.SALES_SUMMARY,
  title: 'Sales Summary',
  downloadList: [ REPORT_TYPE.CSV ],
  url: 'pick-list'
};

const GRN_CONFIG = {
  domain: DOMAIN.PURCHASE,
  title: 'Goods Received Note',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
};

const MATERIALIZED_VIEW_CONFIG = {
  domain: DOMAIN.MATERIALIZED_VIEW,
  title: 'Sales Materialized View',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
};

const ENTRY_LOG_CONFIG = {
  domain: DOMAIN.ENTRY_LOG,
  title: 'Entry Log',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
};

const CUSTOMER_VENDOR_CONFIG = {
  domain: DOMAIN.CUSTOMER_VENDOR,
  title: 'Ledgers',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
  detailDownloadList: [{ title: 'Customer Ledger', type: 'csv', label: 'customer_ledger' }],
};

const AGEING_REPORT_CONFIG = {
  domain: DOMAIN.AGEING_REPORT,
  title: 'Ageing Report',
  downloadList: [REPORT_TYPE.CSV],
};
const STOCK_AGEING_REPORT_CONFIG = {
  domain: DOMAIN.STOCK_AGEING_REPORT,
  title: 'Stock Ageing Report',
  downloadList: [REPORT_TYPE.CSV],
  url: 'stock_aging_report',
};

const SRN_CONFIG = {
  domain: DOMAIN.SALES_RETURN,
  title: 'Sales Return',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
  url: 'sales-return',
  detailDownloadList: [
    { title: 'Sales Return Details', type: 'csv', label: 'sales_return_details', url: 'sales_return_detail' },
  ],
};

const SKU_CONFIG = {
  domain: DOMAIN.SKU,
  title: 'SKU',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
};

const SERVICE_CONFIG = {
  domain: DOMAIN.SERVICE,
  title: 'Service',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
};
const USER_GROUP_CONFIG = {
  domain: DOMAIN.USER_GROUP,
  title: 'User Group',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
};

const PROMOTION_CONFIG = { title: 'Promotion', };

const OPENING_BALANCE_CONFIG = {
  domain: DOMAIN.OPENING_BALANCE,
  title: 'Opening Balance',
  downloadList: [REPORT_TYPE.CSV],
};
const LEDGER_WISE_CONFIG = {
  domain: DOMAIN.LEDGER_WISE_CONFIG,
  title: 'Ledger Wise Tag Report',
  downloadList: [REPORT_TYPE.CSV],
};
const TAG_WISE_CONFIG = {
  domain: DOMAIN.TAG_WISE_CONFIG,
  title: 'Tag Wise Ledger Report',
  downloadList: [REPORT_TYPE.CSV],
};
const SAMPLE_OPENING_BALANCE_CONFIG = {
  domain: DOMAIN.SAMPLE_OPENING_BALANCE,
  title: 'Sample Opening Balance',
  downloadList: [REPORT_TYPE.CSV],
};

const STOCK_LEDGER_CONFIG = {
  domain: DOMAIN.STOCK_LEDGER,
  title: 'Stock Ledger',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
  detailDownloadList: [{ title: 'Stock Batch Details', label: 'stock_ledger_batch_details', type: 'csv' }],
};

const STOCK_LEDGER_DETAIL = {
  domain: DOMAIN.STOCK_LEDGER_DETAIL,
  title: 'Stock Ledger Details',
};

const STOCK_LEDGER_DETAIL_CONFIG = {
  domain: DOMAIN.STOCK_LEDGER_BATCH_DETAILS,
  title: 'Stock Ledger Details',
};

const STOCK_VALUATION_REPORT_CONFIG = {
  domain: DOMAIN.STOCK_VALUATION_REPORT,
  title: 'Stock Valuation Report',
  downloadList: [],
  detailDownloadList: [
    { title: 'Stock Valuation DLP', type: 'csv', label: 'stock_valuation_dlp', url: 'stock_valuation_report' },
    { title: 'Stock Valuation FIFO', type: 'csv', label: 'stock_valuation_fifo', url: 'stock_valuation_report' },
    { title: 'Stock Valuation Weighted Average', type: 'csv', label: 'stock_valuation_weighted', url: 'stock_valuation_report' },
    { title: 'Stock Valuation FIFO Analysis', type: 'csv', label: 'stock_valuation_analysis', url: 'stock_valuation_report' },
  ],
  url: 'stock_valuation_report',
};
const DAMAGE_EXPIRY_VALUATION_CONFIG = {
  domain: DOMAIN.DAMAGE_EXPIRY_VALUATION_REPORT,
  title: 'Damage Expiry Valuation',
  downloadList: [REPORT_TYPE.CSV],
};

const STOCK_JOURNAL_CONFIG = {
  domain: DOMAIN.STOCK_JOURNAL,
  title: 'Stock Journal',
  downloadList: [REPORT_TYPE.CSV],
};

const PRN_CONFIG = {
  domain: DOMAIN.PURCHASE_RETURN,
  title: 'PRN',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
  url: 'purchase-return',
};

const BUSINESS_GROUPS_CONFIG = {
  domain: DOMAIN.PURCHASE_RETURN,
  title: 'Business Groups',
};

const JV_CONFIG = {
  domain: DOMAIN.JOURNAL_VOUCHER,
  title: 'Journal Voucher',
  downloadList: [REPORT_TYPE.CSV],
  url: 'journal-voucher',
};

const CE_CONFIG = {
  domain: DOMAIN.CONTRA_ENTRY,
  title: 'Contra Entry',
  downloadList: [REPORT_TYPE.CSV],
  url: 'contra-entry',
};

const RECEIPT_CONFIG = {
  domain: DOMAIN.RECEIPT,
  title: 'Receipts',
  url: 'receipt',
  downloadList: [REPORT_TYPE.CSV],
};
const PAYMENT_CONFIG = {
  domain: DOMAIN.PAYMENT,
  title: 'Payment',
  url: 'payment',
  downloadList: [REPORT_TYPE.CSV],
};

const DEBIT_NOTE_CONFIG = {
  domain: DOMAIN.DEBIT_NOTE,
  title: 'Debit Note',
  url: 'debit-note',
  downloadList: [REPORT_TYPE.CSV],
};
const CREDIT_NOTE_CONFIG = {
  domain: DOMAIN.CREDIT_NOTE,
  title: 'Credit Note',
  url: 'credit-note',
  downloadList: [REPORT_TYPE.CSV],
};

const LEDGER_REPORT_CONFIG = {
  domain: DOMAIN.LEDGER_REPORT,
  title: 'Ledger Report',
  downloadList: [REPORT_TYPE.CSV],
  url: 'ledger-report',
};

const NETSALES_REPORT_CONFIG = {
  domain: DOMAIN.NET_SALES,
  title: 'Net Sales Report',
  downloadList: [REPORT_TYPE.CSV],
  url: 'netsales',
};

const LEDGER_DETAILS_REPORT_CONFIG = {
  domain: DOMAIN.LEDGER_DETAILS,
  title: 'Ledger Detail Report',
  downloadList: [REPORT_TYPE.CSV],
  url: 'ledger_details',
};
const TAG_WISE_DETAILS_REPORT_CONFIG = {
  domain: DOMAIN.TAG_WISE_DETAILS,
  title: 'Tag Wise Detail Report',
  downloadList: [REPORT_TYPE.CSV],
  url: 'tag_report_detail',
};
const LEDGER_WISE_DETAILS_REPORT_CONFIG = {
  domain: DOMAIN.LEDGER_WISE_DETAILS,
  title: 'Ledger Wise Detail Report',
  downloadList: [REPORT_TYPE.CSV],
  url: 'ledger_wise_details',
};

const AGGREGATE_LEDGER_REPORT_CONFIG = {
  domain: DOMAIN.AGGREGATED_LEDGER_REPORT,
  title: 'Aggregate Ledger Reports',
  downloadList: [REPORT_TYPE.CSV],
  url: 'aggregated_ledger_reports',
};

const TRIAL_BALANCE_CONFIG = {
  domain: DOMAIN.TRIAL_BALANCE,
  title: 'Trial Balance',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
};

const PROFIT_LOSS_CONFIG = {
  domain: DOMAIN.PROFIT_LOSS,
  title: 'Profit and Loss A/C',
  downloadList: [REPORT_TYPE.CSV],
};

const BALANCE_SHEET_CONFIG = {
  domain: DOMAIN.BALANCE_SHEET,
  title: 'Balance Sheet',
  downloadList: [REPORT_TYPE.CSV],
};

const AGGREGATE_REPORT_JV_DETAILS_CONFIG = {
  domain: DOMAIN.AGGREGATED_LEGER_JV_DETAILS,
  title: 'Aggregate Ledgers Reports',
  downloadList: [REPORT_TYPE.CSV],
  url: 'aggregated_ledger_reports',
};

const DAY_BOOK_SUMMARY_CONFIG = {
  domain: DOMAIN.DAY_BOOK_SUMMARY,
  title: 'Day Book Summary',
  downloadList: [REPORT_TYPE.CSV],
  detailDownloadList: [{ title: 'Day Book Details', label: 'day_book_detail', type: 'csv' }],
  url: 'day_book',
};

const DAY_BOOK_DETAIL_CONFIG = { domain: 'day_book_details', };

const LEDGER_LIST_CONFIG = {
  domain: DOMAIN.LEDGER_LIST,
  title: 'Customer/Vendor',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
  url: 'ledger/list',
};

const USERS_CONFIG = {
  domain: DOMAIN.USERS,
  title: 'Users',
};

const AGENT_CONFIG = {
  domain: DOMAIN.AGENT,
  title: 'Salesperson',
};
const SETTINGS_CONFIG = {
  domain: DOMAIN.SETTINGS,
  title: 'Settings',
};

const ACCOUNT_GROUP_CONFIG = {
  domain: DOMAIN.ACCOUNT_GROUP,
  title: 'Account Group',
};
const FISCAL_YEAR_MIGRATION_CONFIG = {
  domain: DOMAIN.FYM_JV_ENTRY,
  title: 'Fiscal Year Migration',
  downloadList: [REPORT_TYPE.CSV],
  url: 'fym_jv_entry',
};

const DATABASE_CONFIG = {
  domain: DOMAIN.DATABASE,
  title:'Database Log',
};

const TAG_CONFIG = {
  domain: DOMAIN.TAG,
  title: 'Tags',
};

const OUTSTANDING_REPORT_CONFIG = {
  domain: DOMAIN.OUTSTANDING_REPORT,
  title: 'OutStanding Report',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
  url: 'outstanding-report',
};

const SALES_PERSON_OUTSTANDING_REPORT_CONFIG = {
  domain: DOMAIN.SALES_PERSON_OUTSTANDING_REPORT,
  title: 'Sales Person Outstanding Report',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
};

const SALES_VAT_REPORT_CONFIG = {
  domain: DOMAIN.SALES_VAT,
  title: 'Old Sales Book',
  downloadList: [
    REPORT_TYPE.CSV,
    REPORT_TYPE.PDF,
    REPORT_TYPE.XLS,
    REPORT_TYPE.CSV,
  ],
  downloadListTitle: [
    'Download Old Sales Book CSV',
    'Download Old Sales Book PDF',
    'Download Old Sales Book XLS',
    'Download Net Old Sales Vat Report CSV',
  ],
  url: 'sales-vat',
};
const NEW_SALES_VAT_REPORT_CONFIG = {
  domain: DOMAIN.NEW_SALES_VAT,
  title: 'Sales Book',
  downloadList: [
    REPORT_TYPE.CSV,
    REPORT_TYPE.PDF,
    REPORT_TYPE.XLS,
  ],
  downloadListTitle: [
    'Download Sales Book CSV',
    'Download Sales Book PDF',
    'Download Sales Book XLS',
  ],
  url: 'new-sales-vat',
};

const SRN_VAT_REPORT_CONFIG = {
  domain: DOMAIN.SRN_VAT,
  title: 'Old Sales Return Book',
  downloadList: [REPORT_TYPE.XLS],
  downloadListTitle: ['Download Sales Return Book XLS'],
  url: 'srn-vat',
};
const NEW_SRN_VAT_REPORT_CONFIG = {
  domain: DOMAIN.NEW_SRN_VAT_REPORT_CONFIG,
  title: 'Sales Return Book',
  downloadList: [
    REPORT_TYPE.CSV,
    REPORT_TYPE.PDF,
    REPORT_TYPE.XLS,
  ],
  downloadListTitle: [
    'Download Sales Return Book CSV',
    'Download Sales Return Book PDF',
    'Download Sales Return Book XLS',
  ],
  url: 'new-sales-vat',
};

const VAT_REPORT_CONFIG = {
  domain: DOMAIN.VAT_REPORT,
  title: 'VAT Book',
  downloadList: [REPORT_TYPE.PDF, REPORT_TYPE.CSV, REPORT_TYPE.XLS],
  downloadListTitle: [
    'Download VAT Report PDF',
    'Download VAT Report CSV',
    'Download VAT Report XLS',
  ],
  url: 'vat',
};
const PURCHASE_RETURN_VAT_REPORT_CONFIG = {
  domain: DOMAIN.PURCHASE_RETURN_VAT,
  title: 'Old Purchase Return Vat Book',
  downloadList: [REPORT_TYPE.CSV],
  downloadListTitle: ['Download Old Purchase Return Vat Book CSV'],
  url: 'purchase-vat-return',
};

const NEW_PURCHASE_RETURN_VAT_REPORT_CONFIG = {
  domain: DOMAIN.NEW_PURCHASE_RETURN_VAT,
  title: 'Purchase Return Vat Book',
  downloadList: [REPORT_TYPE.CSV],
  downloadListTitle: ['Download Purchase Return Vat Book CSV'],
  url: 'new-purchase-vat-return',
};
const PURCHASE_VAT_REPORT_CONFIG = {
  domain: DOMAIN.PURCHASE_VAT,
  title: 'Old Purchase Book',
  downloadList: [
    REPORT_TYPE.PDF,
    REPORT_TYPE.CSV,
    REPORT_TYPE.XLS,
    REPORT_TYPE.CSV,
  ],
  downloadListTitle: [
    'Download Old Purchase Book PDF',
    'Download Old Purchase Book CSV',
    'Download Old Purchase Book XLS',
    'Download Net Purchase Vat Report CSV',
  ],
  url: 'purchase-vat',
};
const NEW_PURCHASE_VAT_REPORT_CONFIG = {
  domain: DOMAIN.NEW_PURCHASE_VAT,
  title: 'Purchase Book',
  downloadList: [
    REPORT_TYPE.CSV,
    REPORT_TYPE.PDF,
    REPORT_TYPE.XLS,
  ],
  downloadListTitle: [
    'Download Purchase Book CSV',
    'Download Purchase Book PDF',
    'Download Purchase Book XLS',
  ],
  url: 'new-purchase-vat',
};

const PSR_CONFIG = {
  domain: DOMAIN.PSR,
  downloadList: [REPORT_TYPE.CSV],
};
const SRN_DETAILS_CONFIG = {
  domain: DOMAIN.SRN_DETAILS_CONFIG,
  downloadList: [REPORT_TYPE.CSV],
};

const PDC_CONFIG = {
  title: 'PDC Report',
  url: 'pdc',
  domain: DOMAIN.PDC,
  downloadList: [REPORT_TYPE.CSV],
};
const VAT_SUMMARY_REPORT_CONFIG = {
  domain: DOMAIN.VAT_SUMMARY,
  title: 'VAT Summary Report',
  downloadList: [REPORT_TYPE.CSV],
  url: 'vat_summary',
};
const ARCHIVED_EMAIL_CONFIG = {
  domain: DOMAIN.ARCHIVED_EMAIL,
  title:'Archived Email',
};
const EMAIL_FREQUENCY_CONFIG = {
  domain: DOMAIN.EMAIL_FREQUENCY,
  title:'Email Frequency Category',
};

export {
  SALES_INVOICE_CONFIG,
  GRN_CONFIG,
  RECEIPT_CONFIG,
  PAYMENT_CONFIG,
  MATERIALIZED_VIEW_CONFIG,
  ENTRY_LOG_CONFIG,
  SRN_CONFIG,
  CUSTOMER_VENDOR_CONFIG,
  SKU_CONFIG,
  SERVICE_CONFIG,
  STOCK_LEDGER_CONFIG,
  JV_CONFIG,
  STOCK_JOURNAL_CONFIG,
  PRN_CONFIG,
  BUSINESS_GROUPS_CONFIG,
  AGEING_REPORT_CONFIG,
  STOCK_AGEING_REPORT_CONFIG,
  OPENING_BALANCE_CONFIG,
  LEDGER_REPORT_CONFIG,
  AGGREGATE_LEDGER_REPORT_CONFIG,
  USERS_CONFIG,
  AGENT_CONFIG,
  DATABASE_CONFIG,
  LEDGER_LIST_CONFIG,
  OUTSTANDING_REPORT_CONFIG,
  SALES_PERSON_OUTSTANDING_REPORT_CONFIG,
  SALES_VAT_REPORT_CONFIG,
  NEW_SALES_VAT_REPORT_CONFIG,
  STOCK_VALUATION_REPORT_CONFIG,
  SRN_VAT_REPORT_CONFIG,
  NEW_SRN_VAT_REPORT_CONFIG,
  PURCHASE_RETURN_VAT_REPORT_CONFIG,
  PURCHASE_VAT_REPORT_CONFIG,
  VAT_REPORT_CONFIG,
  DAY_BOOK_SUMMARY_CONFIG,
  DAY_BOOK_DETAIL_CONFIG,
  AGGREGATE_REPORT_JV_DETAILS_CONFIG,
  STOCK_LEDGER_DETAIL_CONFIG,
  PICK_LIST_CONFIG,
  ACCOUNT_GROUP_CONFIG,
  FISCAL_YEAR_MIGRATION_CONFIG,
  TRIAL_BALANCE_CONFIG,
  PROFIT_LOSS_CONFIG,
  BALANCE_SHEET_CONFIG,
  ABBREVIATED_INVOICE_CONFIG,
  LEDGER_DETAILS_REPORT_CONFIG,
  CE_CONFIG,
  TAG_CONFIG,
  SAMPLE_OPENING_BALANCE_CONFIG,
  SETTINGS_CONFIG,
  DEBIT_NOTE_CONFIG,
  PSR_CONFIG,
  PDC_CONFIG,
  CREDIT_NOTE_CONFIG,
  SRN_DETAILS_CONFIG,
  PROMOTION_CONFIG,
  USER_GROUP_CONFIG,
  NEW_PURCHASE_VAT_REPORT_CONFIG,
  NEW_PURCHASE_RETURN_VAT_REPORT_CONFIG,
  SALES_REPORT_CONFIG,
  GRN_REPORT_CONFIG,
  SRN_REPORT_CONFIG,
  PRN_REPORT_CONFIG,
  VAT_SUMMARY_REPORT_CONFIG,
  DAMAGE_EXPIRY_VALUATION_CONFIG,
  STOCK_LEDGER_DETAIL,
  LEDGER_WISE_CONFIG,
  TAG_WISE_CONFIG,
  TAG_WISE_DETAILS_REPORT_CONFIG,
  LEDGER_WISE_DETAILS_REPORT_CONFIG,
  NETSALES_REPORT_CONFIG,
  SALES_SUMMARY_CONFIG,
  SERVICE_INVOICE_CONFIG,
  ARCHIVED_EMAIL_CONFIG,
  EMAIL_FREQUENCY_CONFIG,
};
