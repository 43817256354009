import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-mdc-web/lib/Icon/Icon';

const ArchivedEmailTableBody = ({ dataList ,onRowClick,onSpanClick}) => (
  <tbody>
    {dataList?.map((data, index) => (
      <tr onClick={()=>onRowClick(data)} className='cursor-pointer'>
        <td className="left-align">{ moment(data?.month, 'M').format('MMMM')}  {data?.year}</td>
        <td className="right-align" onClick={(e) => e.stopPropagation()} className='download-icon'>
        <Icon name='download' onClick={() => onSpanClick(data)} />
        </td>
      </tr>
    ))}
  </tbody>
);
ArchivedEmailTableBody.propTypes = { data: PropTypes.object.isRequired };

const ArchivedEmailConfig = [
  {
    id: 1, label: 'month', title: 'Month', className: 'cursor-pointer left-align', sortable: false,
  },
  {
    id: 1, label: 'options', title: '', className: 'cursor-pointer right-align download-icon', sortable: false,
  },
];

const config = {
  header: ArchivedEmailConfig,
  getTableBody: ArchivedEmailTableBody,
};
export default config;
