import React, { Fragment } from 'react';
import { Cell, Grid } from '../../../components/BillingMDC';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { VAT_PERCENTAGE } from '../../../data/enums/enums';
import { getPercentage } from '../../../utils/miscellaneous';

const DiscountPresenter = ({ billSummary }) => {
  const billDiscountPer = getPercentage(billSummary.bill_discount_value, billSummary.net_amount);
  const tradeDiscountPer = getPercentage(
    billSummary.trade_discount_value,
    billSummary.net_amount - billSummary.bill_discount_value || 0,
  );
  return (
    <Fragment>
      <Grid>
        <Cell col={8} />
        <Cell col={3} className='right-align active-opacity-text'>
          Sub Total
        </Cell>
        <Cell col={1} className='right-align'>
          {fixedFloatAndCommas(billSummary.net_amount)}
        </Cell>
      </Grid>
      <Grid>
        <Cell col={8} />
        <Cell col={3} className='right-align active-opacity-text'>
          Bill Discount {billDiscountPer ? `(${billDiscountPer}%)` : ''}
        </Cell>
        <Cell col={1} className='right-align'>
          {fixedFloatAndCommas(billSummary.bill_discount_value)}
        </Cell>
      </Grid>
      <Grid>
        <Cell col={8} />
        <Cell col={3} className='right-align active-opacity-text'>
          Trade Discount {tradeDiscountPer ? `(${tradeDiscountPer}%)` : ''}
        </Cell>
        <Cell col={1} className='right-align'>
          {fixedFloatAndCommas(billSummary.trade_discount_value)}
        </Cell>
      </Grid>
      <Grid>
        <Cell col={8} />
        <Cell col={3} className='right-align active-opacity-text'>
          Taxable Amount
        </Cell>
        <Cell col={1} className='right-align'>
          {fixedFloatAndCommas(billSummary.actualTaxableAmount || 0)}
        </Cell>
      </Grid>
      <Grid>
        <Cell col={8} />
        <Cell col={3} className='right-align active-opacity-text'>
          VAT
        </Cell>
        <Cell col={1} className='right-align'>
          {fixedFloatAndCommas(billSummary.vat)}
        </Cell>
      </Grid>
      <div className='hr' />
      <Grid>
        <Cell col={8} />
        <Cell col={3} className='right-align active-opacity-text'>
          Total
        </Cell>
        <Cell col={1} className='right-align font-bold'>
          {fixedFloatAndCommas(billSummary.gross_amount || 0)}
        </Cell>
      </Grid>
    </Fragment>
  );
};

export default DiscountPresenter;
