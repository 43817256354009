import styled from 'styled-components';
const VatReportStyle = styled.div`
  .srn-vat-report {
    .svr-table {
      tfoot {
        td:last-of-type {
          padding-right: 16px;
        }
      }
    }
  }

  /* ###====================== print stying for srnVat ,new srnVat,srnVat Return,new srnVat Return ,purchase Vat ,newPurchase vat,purchase Vat Return ,new purchase Vat Return and SMV ====================== ### */
  @media print {
    .vat .print-body,
    .srn-vat .print-body,
    .sales-vat .print-body,
    .srn-vat-report .print-body,
    .purchase-vat .print-body,
    .purchase-return-vat .print-body,
    .new-srn-vat .print-body,
    .new-sales-vat .print-body,
    .new-purchase-vat .print-body,
    .new-purchase-return-vat .print-body,
    .sales-materialized .landscape .print-body{
         height:100% !important;
        page-break-after: always;
        padding:0 !important;
        margin:24px 29.6px !important;
    }
    .vat .landscape .print-body table,
    .sales-materialized .landscape .print-body table,
    .new-purchase-return-vat .print-body table,
    .purchase-return-vat .print-body table,
    .new-purchase-vat .print-body table,
    .purchase-vat .print-body table,
    .srn-vat .print-body table,
    .new-srn-vat .print-body table,
    .new-sales-vat .print-body table,
    .sales-vat .print-body table{
        height:100%;
    }
    .vat .print-body table .blank-tr,
    .new-purchase-return-vat .print-body table .blank-tr,
    .purchase-return-vat .print-body table .blank-tr,
    .new-purchase-vat .print-body table .blank-tr,
    .purchase-vat .print-body table .blank-tr,
    .srn-vat .print-body table .blank-tr,
    .new-srn-vat .print-body table .blank-tr,
    .new-sales-vat .print-body table .blank-tr,
    .sales-vat .print-body table .blank-tr{
        border-bottom:0 !important;
    }
    .vat .print-body table .blank-tr td,
    .sales-materialized .landscape .print-body table .blank-tr td,
    .new-purchase-return-vat .print-body table .blank-tr td,
    .purchase-return-vat .print-body table .blank-tr td,
    .new-purchase-vat .print-body table .blank-tr td,
    .purchase-vat .print-body table .blank-tr td,
    .srn-vat .print-body table .blank-tr td,
    .new-srn-vat .print-body table .blank-tr td,
    .new-sales-vat .print-body table .blank-tr td,
    .sales-vat .print-body table .blank-tr td{
        height:25px;
        border-bottom:0 !important;
    }
    .vat .print-body  .print-body table .total td,
    .new-purchase-return-vat .print-body table .total td,
    .purchase-return-vat .print-body table .total td,
    .new-purchase-vat .print-body table .total td,
    .purchase-vat .print-body table .total td,
    .srn-vat .print-body table .total td,
    .new-srn-vat .print-body table .total td,
    .new-sales-vat .print-body table .total td,
    .sales-vat .print-body table .total td{
        font-weight: 600;
        vertical-align: middle !important;
    }
    .vat .print-body .fixed-table-wrapper,
    .purchase-vat .print-body .fixed-table-wrapper,
    .srn-vat .print-body .fixed-table-wrapper,
    .srn-vat-report .print-body .fixed-table-wrapper,
    .purchase-return-vat .print-body .fixed-table-wrapper,
    .sales-vat .print-body  .fixed-table-wrapper,
    .new-purchase-vat .print-body .fixed-table-wrapper,
    .new-srn-vat .print-body .fixed-table-wrapper,
    .new-purchase-return-vat .print-body .fixed-table-wrapper,
    .new-sales-vat .print-body  .fixed-table-wrapper{
      height:950px !important;
        white-space: normal !important;
        line-height: 16px !important;
    }
    .landscape .footer-block{
        position: unset;
    }
    .landscape .print-body table td{
      vertical-align:middle !important;
    }
    .new-purchase-vat .print-body .fixed-table-wrapper thead th,
    .new-srn-vat .print-body .fixed-table-wrapper thead th,
    .new-purchase-return-vat .print-body .fixed-table-wrapper thead th,
    .new-sales-vat .print-body  .fixed-table-wrapper thead th {
        white-space: normal !important;
        line-height: 16px !important;
    }
    .srn-vat-report .print-body  table td:last-child{
    padding-right:12px !important;
}
   /* sales vat A4 landscape print */
   .sales-vat,.srn-vat{
      .print-body .fixed-table-wrapper {
     td,th{
     .ledger-name{
        width:300px !important;
        min-width:300px !important;
        max-width:300px !important;
        white-space:normal !important;
   }
  }
    }
  }
    .purchase-vat,.purchase-return-vat,.new-purchase-vat{
      .print-body .fixed-table-wrapper{ 
      th.sku,td.sku{
        width:70px !important;
        min-width: 70px !important;
        max-width:70px !important;
        white-space:normal !important;
    }
    }
  }
.purchase-vat,.purchase-return-vat,.new-purchase-vat{
.print-body .fixed-table-wrapper {
  th.ledger-name,td.ledger-name{
        width:160px !important;
        min-width: 160 !important;
        max-width:160px !important;
        white-space:normal !important;
    }
  }
  }
   .purchase-vat,.purchase-return-vat,.sales-vat,.srn-vat-report,.new-sales-vat,.srn-vat,.new-purchase-vat,.new-purchase-return-vat{
      .print-body .fixed-table-wrapper tfoot td{
        white-space: normal !important;
        word-break: break-word;
        vertical-align: top;
    }
  }
    
  }

  @media (orientation: landscape) {
       .new-purchase-vat,.new-sales-vat,.sales-materialized{ 
         .print-body .fixed-table-wrapper {
         th,td{
            box-sizing:border-box;
            min-width: 40px !important;
            max-width: 40px !important;width: 40px !important;
            line-height:16px !important;
            padding:0 !important;
            padding:6px 10px 2px 0 !important;
            overflow:hidden !important;
            white-space:nowrap !important;
            color:rgba(0,0,0,0.7);
            vertical-align: middle;
        }
      }
      }
       .new-purchase-vat,.new-sales-vat,.sales-materialized{ .print-body .fixed-table-wrapper th{
            white-space:normal !important;
            padding:0 !important;
            padding-right:10px !important;
        }
      }
      .new-purchase-vat,.new-sales-vat{ table.pdf-table tr {
            border-bottom: 0;
        }
      }
        .new-purchase-vat,.new-sales-vat{ .print-body .fixed-table-wrapper tr:not(:last-child) td{
            border-bottom: 1px solid #c5c5c5 ;
        }
      }
        .new-purchase-vat,.new-sales-vat{ .print-body .fixed-table-wrapper tfoot td{
            border:0 !important;
            color:#000000;
        }
      }
        .new-purchase-vat,.new-sales-vat{ .print-body .fixed-table-wrapper {
          th:last-child,td:last-child{
            padding-right:0 !important;
        }
      }
      }
        .new-purchase-vat .print-body .fixed-table-wrapper {
          th.invoice-number,td.invoice-number{
            min-width: 75px !important;
            max-width: 75px !important;
            width: 75px !important;
        }
        }
        .new-purchase-vat .print-body .fixed-table-wrapper {
          th.miti,td.miti{
            min-width: 58px !important;
            max-width: 58px !important;
            width: 58px !important;
        }
      }
   
        .new-purchase-vat .print-body .fixed-table-wrapper{
          th.discount,td.discount,
          td.quantity,th.quantity{
            min-width: 30px !important;
            max-width: 30px !important;
            width: 30px !important;
        }
      }
        .new-purchase-vat .print-body .fixed-table-wrapper {
          th.bill-no,td.bill-no{
            min-width: 56px !important;
            max-width: 56px !important;
            width: 56px !important;
        }
      }
        .new-purchase-vat .print-body .fixed-table-wrapper {
          th.pan-no,td.pan-no{
            min-width: 40px !important;
            max-width: 40px !important;
            width:40px !important;
        }
        }
        .new-purchase-vat .print-body .fixed-table-wrapper {
          th.ledger-name,td.ledger-name{
            min-width: 80px !important;
            max-width: 80px !important;
            white-space:normal !important;
        }
        }
        .new-purchase-vat .print-body .fixed-table-wrapper {
          th.sku,td.sku{
            min-width: 110px !important;
            max-width: 110px !important;
            white-space:normal !important;
        }
    }
    /* vat book styling */
    .vat .print-body .fixed-table-wrapper {
      td.description,th.description {
        min-width:200px !important;
        max-width:200px !important;
        width:200px !important;
        white-space:normal !important;
      }
    }
    /* new-purhcase return Vat styling */
    .new-purchase-return-vat .print-body .fixed-table-wrapper {
      th.bill-no,td.bill-no{
        min-width: 88px !important;
    }
    }
.new-purchase-return-vat .print-body .fixed-table-wrapper {
  td.miti,th.miti {
        min-width: 60px !important;
}
    }
    .new-purchase-return-vat .print-body .fixed-table-wrapper {
      td.invoice-number,th.invoice-number {
        min-width:85px !important;
      }
    }
.new-purchase-return-vat .print-body .fixed-table-wrapper {
  td.pan-no,th.pan-no{
        min-width:44px !important
}
    }
       /* new-purhcase return Vat styling end */
       .new-sales-vat .print-body .fixed-table-wrapper{
         td.miti,th.miti{
        min-width: 56px !important;
      }
       }
       .new-sales-vat .print-body .fixed-table-wrapper {th.bill-no,td.bill-no{
        min-width: 80px !important;
        max-width: 80px !important;
       }
       }
       .new-sales-vat .print-body .fixed-table-wrapper{td.ledger-name,th.ledger-name{
        min-width: 90px !important;
        max-width: 90px !important;
        white-space:normal !important;
       }
       }
       .new-sales-vat .print-body .fixed-table-wrapper {td.sku,th.sku{
        min-width: 140px !important;
        max-width: 140px !important;
        white-space:normal !important;
       }
       }
         /* .new-sales-vat styling end */
         /* .sales-materialized styling  for landscape A3*/
         .sales-materialized .print-body{
            /* min-height:1560px !important;
            height:1560px;
            max-height:1560px; */
         }
         .sales-materialized .print-body .fixed-table-wrapper{
            min-height:1420px !important;
            height:1420px;
            max-height:1420px;
         }
         .sales-materialized .print-body .fixed-table-wrapper {th,td {
            min-width: 30px !important;
            max-width: 30px !important;width: 30px ;
         }
         }
         .sales-materialized .print-body .fixed-table-wrapper {
           .sync,.bill-print,.bill-active,.real-time{
            min-width: 20px !important;
            max-width: 20px !important;width: 20px ;
         }
         }

         .sales-materialized .print-body .fixed-table-wrapper {.fiscal-year,.customer-pan,.bill-date{
            min-width: 32px !important;
            max-width: 32px !important;width: 32px ;
         }
         }
         .sales-materialized .print-body .fixed-table-wrapper .miti{
            min-width: 50px !important;
            max-width: 50px !important;width: 50px ;
         }
         .sales-materialized .print-body .fixed-table-wrapper .print-time{
            min-width: 53px !important;
            max-width: 53px !important;width: 53px ;
         }
         .sales-materialized .print-body .fixed-table-wrapper .bill-no{
            min-width: 60px !important;
            max-width: 60px !important;
            width: 60px ;
         }
          .sales-materialized .print-body .fixed-table-wrapper {.customer-name,.entered-by,.print-by{
            min-width: 70px !important;
            max-width: 70px !important;width: 70px ;
            white-space: normal !important;
          }
          }  
}
  /* ###====================== print stying for srnVat ,new srnVat,srnVat Return,new srnVat Return ,purchase Vat ,newPurchase vat,purchase Vat Return ,new purchase Vat Return and SMV ====================== ### */
`;
export default VatReportStyle;
