// TODO: Include Prop Types and Use for Composition Pattern.

import React from 'react';

import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell, Fab, Icon,
} from '../../../../components/BillingMDC';
import { fixedFloat } from '../../../../utils/conversion';
import BillingSelect from '../../../../components/Select/BillingSelect';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import BillingAsyncSelect from '../../../../components/AsyncSelect/AsyncSelect';
import { discountOptions } from '../../sales/create/config';
import BatchDialog from '../../grn/create/BatchDialog';

const CreateSrnDialog = ({
  onModalClose,
  modalOpen,
  editModalOpen,
  deleteModalOpen,
  createSKU,
  handleInputChange,
  onModalSubmit,
  skuList,
  brandList,
  batchList,
  brandSKUList,
  formEmptyField,
  invoiceIdStatus,
  qtyValidation,
  loadOptions,
  batchDetails,
  onBatchInputChange,
  onBatchFormSubmit,
  onBatchFormCancel,
  batchFormOpen,
  onBatchFabClick,
  batchDialogValidation,
}) => (
  <Dialog
    open={modalOpen || editModalOpen || deleteModalOpen}
    onClose={() => {
      onModalClose();
    }}
  >
    <DialogHeader>
      {modalOpen && <DialogTitle>Select SKU</DialogTitle>}
      {editModalOpen && <DialogTitle>Edit SKU</DialogTitle>}
      {deleteModalOpen && <DialogTitle> Delete SKU</DialogTitle>}
    </DialogHeader>

    {(modalOpen || editModalOpen) && (
      <DialogBody>
        <div className='dialog-upperpart'>
          {!(editModalOpen && invoiceIdStatus) && (
            <div className='row_one row '>
              <Grid className='grid-padding '>
                {/* <Cell col={6} tablet={8}>
                  <label>Brand</label>
                  <BillingSelect
                    name='form-field-name'
                    param='brand'
                    clearable={true}
                    value={createSKU.brand}
                    valueKey='businessCatalogDetailId'
                    valueKey1='businessId'
                    keyValue={createSKU.business_id}
                    extraValueKey
                    labelKey='title'
                    options={brandList}
                    onChange={(e) => handleInputChange('brand', e.businessCatalogDetailId, e)}
                    required={true}
                    emptyField={formEmptyField}
                    multipleParam
                  />
                </Cell> */}
                <Cell col={6} tablet={8}>
                  <label>Service</label>
                  <BillingAsyncSelect
                    param='sku_id'
                    placeholder='Search/Select...'
                    loadOptions={loadOptions}
                    handleChange={handleInputChange}
                    options={skuList}
                    defaultOptions={skuList}
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ businessSkuId }) => businessSkuId}
                    multipleParam
                    required
                    emptyField={formEmptyField}
                    value={skuList.filter(({ businessSkuId }) => businessSkuId === createSKU.sku_id)}
                    valueKey='businessSkuId'
                  />
                </Cell>
                <Cell col={6} tablet={6}>
                  <BillingTextField
                    value={createSKU.particulars}
                    handleChange={handleInputChange}
                    floatingLabel='Description'
                    param='particulars'
                    required={true}
                    type='text'
                    emptyField={formEmptyField}
                    errorMessage='Should not be empty'
                  />
                </Cell>

                {
                  !batchFormOpen && (
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                        value={fixedFloat(createSKU.rate)}
                        handleChange={handleInputChange}
                        floatingLabel='Rate'
                        param='rate'
                        required={true}
                        zeroError={false}
                        type='number'
                        className={`billing-required ${invoiceIdStatus ? 'inp-disabled' : ''}`}
                        emptyField={formEmptyField}
                        errorMessage={createSKU.rate === 0 ? 'Should not be 0' : 'Should not be empty'}
                      />
                    </Cell>
                  )
                }
              </Grid>
            </div>
          )}
          {!batchFormOpen ? (
            <>
              <div className='row_two row'>
                <Grid className='grid-padding row'>
                  <Cell col={4} tablet={8}>
                    <BillingTextField
                      value={createSKU.normal}
                      handleChange={handleInputChange}
                      floatingLabel='Quantity'
                      param='normal'
                      required={false}
                      zeroError={true}
                      type='number'
                      max={createSKU.hasOwnProperty('maxValue') ? createSKU.maxValue : '0'}
                    />
                  </Cell>
                </Grid>
              </div>
              <div className='row_three row'>
                <Grid className='grid-padding'>
                  <Cell col={6} tablet={8}>
                    <BillingTextField
                      value={createSKU.net_amount}
                      handleChange={handleInputChange}
                      floatingLabel='Net Amount'
                      param='net_amount'
                      required={true}
                      zeroError={true}
                      type='number'
                      className='billing-required inp-disabled'
                      emptyField={formEmptyField}
                      errorMessage={createSKU.net_amount === 0 ? 'Should not be 0' : 'Should not be empty'}
                    />
                  </Cell>
                </Grid>
              </div>
            </>
          ) :
            null
          }
          {batchFormOpen ? (
            <div className='row row_one'>
              <Grid className='grid-padding width-100'>
                <Cell col={12} tablet={8}>
                  <BatchDialog
                    batch={batchDetails}
                    batchDialogValidation={!batchDialogValidation}
                    onInputChange={onBatchInputChange}
                    onFormCancel={onBatchFormCancel}
                    onFormSubmit={onBatchFormSubmit}
                    batchFormOpen={batchFormOpen}
                  />
                </Cell>
              </Grid>
            </div>
          ) : null}
        </div>
      </DialogBody>
    )}

    {deleteModalOpen && <div className='default-margin-24'>Are you sure you want to Delete ?</div>}

    <DialogFooter>
      <Button
        accent
        className='dialog-cancel modal-btn'
        onClick={() => {
          if (batchFormOpen) {
            onBatchFormCancel();
          } else onModalClose();
        }}
      >
        Cancel
      </Button>
      <Button
        accent
        className='dialog-ok modal-btn'
        onClick={() => {
          if (batchFormOpen) {
            onBatchFormSubmit();
          } else onModalSubmit();
        }}
      >
        Ok
      </Button>
    </DialogFooter>
  </Dialog>
);

export default CreateSrnDialog;
