import React, { Component } from 'react';
import View from './View';
import tableConfig from './config';
import ProfitLossStyle from './ProfitLossStyle';
import withBaseState from '../../common/withBaseState';
import * as queryService from '../../common/query.service';
import PageHeader from '../../common/pagination/PageHeader';
import { PROFIT_LOSS_CONFIG } from '../../common/domain.config';
import Loading from '../../../common/Loading';
import * as filterUtil from '../../../utils/filterUtil';
import { accountReportViewOptions } from '../../common/DrawerFilter/config';
import { PAGINATION } from '../../../data/enums/enums';
import * as downloadService from '../../common/download.service';

class ProfitLoss extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        drawer: true,
      },
      queryParameters: {
        singleDate: filterUtil.getCurrentDay(),
        flag: { stock: tableConfig.disableStockFilter },
        viewType: accountReportViewOptions[0].label,
      },
      data: {
        expenseLedgers: [],
        incomeLedgers: [],
        totalBalance: {},
        total: 0,
      },
      activeIncomes: [],
      activeExpenses: [],
      activeRow: {
        activeIncomes: 0,
        activeExpenses: 0,
      },
      activeView: accountReportViewOptions[0].label,
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
  }
  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  loadTableData = () => {
    const { getProfitLossList } = this.props;
    const { queryParameters, activeRow } = this.state;
    getProfitLossList(queryParameters).then(response => {
      const { data } = this.state;
      data.expenseLedgers = response.data.groupedExpense || [];
      data.incomeLedgers = response.data.groupedIncome || [];
      data.totalBalance = response.data.totalBalance || {};
      data.total = response.total ? response.total : response.data.length;
      let activeDetail = { activeIncomes: [], activeExpenses: [] };
      const incomeLedgers =
        (data.incomeLedgers.length &&
          data.incomeLedgers[activeRow.activeIncomes] &&
          data.incomeLedgers[activeRow.activeIncomes].list &&
          data.incomeLedgers[activeRow.activeIncomes].list) ||
        [];
      const expenseLedgers =
        (data.expenseLedgers.length &&
          data.expenseLedgers[activeRow.activeExpenses] &&
          data.expenseLedgers[activeRow.activeExpenses].list &&
          data.expenseLedgers[activeRow.activeExpenses].list) ||
        [];
      activeDetail.activeIncomes = incomeLedgers;
      activeDetail.activeExpenses = expenseLedgers;

      this.setState({
        data,
        activeIncomes: activeDetail.activeIncomes,
        activeExpenses: activeDetail.activeExpenses,
      });
    });
  };
  resetFilter = () => {
    this.basePaginationService.defaultResetFilter({
      singleDate: filterUtil.getCurrentDay(),
      flag: { stock: tableConfig.includeStockFilter },
      viewType: accountReportViewOptions[0].label,
    });
    this.setState(
      {
        queryParameters: {
          singleDate: filterUtil.getCurrentDay(),
            flag: { stock: {...tableConfig.includeStockFilter,status:true} },
        },
        activeView: accountReportViewOptions[0].label,
      },
      () => {
        this.loadTableData();
      },
    );
  };
  
  handleRowClick = (label, data, index) => {
    const { activeRow } = this.state;
    const activeDetail = { ...activeRow };
    this.setState({
      [label]: data,
      activeRow: { ...activeDetail, [label]: index },
    });
  };

  handleViewChange = activeView => {
    this.setState({ activeView });
  };

  handleDownloadClick = reportType => {
    const { downloadList } = this.props;
    const { queryParameters, activeView } = this.state;
    const extraQueryString = `&view_type=${activeView}`;
    downloadList({
      type: reportType,
      query: queryParameters,
      extraQueryString,
    }).then(response => downloadService.resolver(response));
  };

  render() {
    const { queryParameters, data, display, activeView, activeIncomes, activeExpenses } = this.state;

    const { serverResponseWaiting, company } = this.props;
    return (
      <ProfitLossStyle>
        <div className="profit-loss">
          <PageHeader
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            handleDownloadClick={this.handleDownloadClick}
            controlDisplay={this.controlDisplay}
            clearSearchText={this.basePaginationService.clearSearchText}
            queryParameters={queryParameters}
            domainConfig={PROFIT_LOSS_CONFIG}
            display={display}
            config={{
              search: false,
              filter: true,
              date: false,
              create: false,
              download: true,
              flag: false,
              singleDate: true,
            }}
            handleUploadClick={this.basePaginationService.handleUploadClick}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handlePrintClick={this.basePaginationService.handlePrintClick}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            handleDateChange={this.basePaginationService.handleDateChange}
            resetFilter={this.resetFilter}
            disableStartDate={false}
            viewOptions={{
              list: accountReportViewOptions,
              handler: this.handleViewChange,
              selectedValue: activeView,
            }}
            handleFlagChange={this.basePaginationService.handleFlagChange}
            serverResponseWaiting={serverResponseWaiting}
          />
          <View
            data={data}
            company={company}
            config={tableConfig}
            queryParameters={queryParameters}
            activeView={activeView}
            activeIncomes={activeIncomes}
            activeExpenses={activeExpenses}
            onRowClick={this.handleRowClick}
          />
        </div>
      </ProfitLossStyle>
    );
  }
}
const ProfitLossWithState = withBaseState(ProfitLoss);

export default ProfitLossWithState;
