import  Menu from './Menu';
import { Icon } from 'react-mdc-web';

export const menuAction = (menuConfigList, headerConfig, onMenuItemClick, data,permission,withOutIcon) => {
  const menuHeader =<Icon name='more_vert' />;
  return (
    <>
      <Menu label='' header={menuHeader}>
        <div className='filter-content text-left'>
          <ul>
            {menuConfigList.reduce((acc, item) => {
              acc.push(
                <li
                  key={item.title}
                  onClick={(e) => {
                    e.preventDefault();
                    onMenuItemClick(item.type, data, () => null);
                  }}
                >
                  <span>
                    {withOutIcon ? null : <Icon name={item.icon} />}
                    {item.title}
                  </span>
                </li>,
              );
              return acc;
            }, [])}
          </ul>
        </div>
      </Menu>
    </>
  );
};
