import React from 'react';
import { Cell, Grid } from '../BillingMDC';
import DateToRender from '../DateToRender';

const IrdReportHeader = ({
  divClassName,
  company,
  domainConfig,
  date,
  nepaliDate,
  nepaliDateExist,
  singleDate,
  singleDateExist,
}) => (
  <div className={divClassName}>
    <Grid>
      <Cell col={4} tablet={8}>
        <div className='header-title'>{company.title}</div>
        <span className='header-title-content'>
          <span className='active-opacity-text'>Address: </span>
          <span>{company.address}</span>
        </span>
        <span className='header-title-content'>
          <span className='active-opacity-text'>Phone: </span>
          <span>{company.phone}</span>
        </span>
        <span className='no-margin-bottom header-title-content'>
          <span className='active-opacity-text'>PAN No: </span>
          <span>{company.panNumber}</span>
        </span>
      </Cell>
      <Cell col={4}>
        <div className='center-align'>
          <h2>{domainConfig.title}</h2>
          <div className='default-margin-top-6'>
            <span className='header-title-content no-margin-bottom'>
              <span className='active-opacity-text'>
                {singleDateExist ? 'As of date:' : 'Report from'}
                <span className='date-rendered'>
                  <DateToRender date={date ? date : singleDate} singleDateExist />
                </span>
              </span>
            </span>
            {nepaliDateExist && (
              <span className='header-title-content'>
                <span className='active-opacity-text'>
                  Report from
                  <span className='date-rendered'>
                    <span> {nepaliDate.start}</span>
                    <span> - {nepaliDate.end}</span>
                  </span>
                </span>
              </span>
            )}
          </div>
        </div>
      </Cell>
      <Cell col={4}>
        <div className='header-menu-right'>
          <span>
            {' '}
            <span className=''>Date: </span>
            <span>{date ? date.end : singleDate}</span>
          </span>
        </div>
      </Cell>
    </Grid>
  </div>
);

export default IrdReportHeader;
