import * as apiCall from '../../api/client';
import * as appConstants from '../../config';
import { queryGenerator } from './query.service';

const postApiWithTransformer = (url, body) =>  apiCall.post(`${appConstants.baseUrl}${url}`, body);
const postApiReqWithTransformer = (url, body) => apiCall.postReq(`${appConstants.baseUrl}${url}`, body);

const putApiWithTransformer = (url, id, body) => apiCall.put(`${appConstants.baseUrl}${url}`, id, body);
const putApiWithoutIDTransformer = (url, body) => apiCall.putWithoutID(`${appConstants.baseUrl}${url}`, body);

const multipartPostWithTransformer = (url, body) => apiCall.multipartPost(`${appConstants.baseUrl}${url}`, body);

const getPaginatedApiWithTransformer = (url, query, queryString) => {
  const processedQuery = queryGenerator(query, queryString);
  return apiCall.get(`${appConstants.baseUrl}${url}${processedQuery}`);
};

const deleteApiWithTransformer = (url, id) => apiCall.deleteapi(`${appConstants.baseUrl}${url}`, id);

/**
 * @param url
 * @param query: { brand_id: [2, 3, 4], catalog_id: [2, 4, 5], date: '2010-01-01' }
 * @returns {Promise<*>}
 */
const getApiWithTransformer = (url, query = {}) => {
  // eslint-disable-next-line no-return-assign
  const stringQuery = Object.keys(query).reduce((acc, value) => (acc += `${value}=${query[value]}&`), '');

  return apiCall.get(`${appConstants.baseUrl}${url}${stringQuery}`);
};

const downloadApiWithTransformer = (domain, type, query, extraQueryString) => {
  const downloadMode = true;

  const processedQuery = queryGenerator(query, extraQueryString, downloadMode);

  return apiCall.get(`${appConstants.baseUrl}downloads${processedQuery}&report_type=${type}&domain=${domain}`);
};

const downloadApi = (domain, type, query) => {
  return apiCall.get(`${appConstants.baseUrl}downloads?report_type=${type}&domain=${domain}&${query}`);
};

export {
  postApiWithTransformer,
  getPaginatedApiWithTransformer,
  getApiWithTransformer,
  multipartPostWithTransformer,
  putApiWithTransformer,
  downloadApiWithTransformer,
  downloadApi,
  deleteApiWithTransformer,
  putApiWithoutIDTransformer,
  postApiReqWithTransformer,
};
