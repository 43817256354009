// USE the bread crumb function over here.

const LOG_BASE = 'log';
const INVENTORY_BASE = 'grn';
const REPORT_BASE = 'report';
const STOCK_JOURNAL_BASE = 'stock-journal';
const CONFIGURATION_BASE = 'configuration';
const SALES_INVOICE_BASE = 'sales-invoice';
const SERVICE_INVOICE_BASE = 'service-invoice';
const ABBREVIATED_INVOICE_BASE = 'abbreviated-invoice';
const SALES_RETURN_BASE = 'sales-return';
const PURCHASE_RETURN_BASE = 'purchase-return';
const JOURNAL_VOUCHER_BASE = 'journal-voucher';
const CUSTOMER_LEDGER_BASE = 'customer-ledger';
const ARCHIVE_REPORT_BASE = 'archive-report';
const AGGREGATE_CUSTOMER_LEDGER_BASE = 'aggregated-ledger-reports';
const PAYMENT_BASE = 'payment';
const STOCK_LEDGER_BASE = 'stock-ledger';
const STOCK_VALUATION_REPORT = 'stock-valuation-report';
const DAMAGE_EXPIRY_VALUATION = 'damage-expiry-valuation';
const DAY_BOOK_SUMMARY = 'day-book-summary';
const TAG_REPORT = 'tag-report';

const SALES_PRINT = 'print-sales-invoice';
const SALES_CREATE = `${SALES_INVOICE_BASE}/create`;
const SALES_DETAILS = `${SALES_INVOICE_BASE}/details`;
const SERVICE_INVOICE_CREATE = `${SERVICE_INVOICE_BASE}/create`;
const SERVICE_INVOICE_DETAILS = `${SERVICE_INVOICE_BASE}/details`;
const ROSIA_SALES_PRINT = 'sales-invoice-print-details';
const ROSIA_ABBREVIATED_INVOICE_PRINT = 'abbreviated-invoice-print-details';
const ROSIA_PURCHASE_RETURN_PRINT = 'purchase-return-print-details';

const SRN_CREATE = `${SALES_RETURN_BASE}/create`;
const SRN_DETAILS = `${SALES_RETURN_BASE}/details`;
const ROSIA_SRN_PRINT = 'sales-return-print-details';

const ABBREVIATED_INVOICE_CREATE = `${ABBREVIATED_INVOICE_BASE}/create`;
const ABBREVIATED_INVOICE_DETAILS = `${ABBREVIATED_INVOICE_BASE}/details`;

const GRN_CREATE = `${INVENTORY_BASE}/create`;
const GRN_DETAILS = `${INVENTORY_BASE}/details`;

const PRN_CREATE = `${PURCHASE_RETURN_BASE}/create`;
const PRN_DETAILS = `${PURCHASE_RETURN_BASE}/details`;

const STOCK_JOURNAL_CREATE = `${STOCK_JOURNAL_BASE}/create`;
const STOCK_JOURNAL_DETAILS = `${STOCK_JOURNAL_BASE}/details`;

const JOURNAL_VOUCHER_PRINT = `${JOURNAL_VOUCHER_BASE}/print`;
const JOURNAL_VOUCHER_CREATE = `${JOURNAL_VOUCHER_BASE}/create`;
const ROSIA_JOURNAL_VOUCHER_PRINT = 'journal-voucher-print/:id/:userId';
const JOURNAL_VOUCHER_DETAILS = `${JOURNAL_VOUCHER_BASE}/details`;

const SALES_REPORT= `${ARCHIVE_REPORT_BASE}/sales-report`;
const SRN_REPORT= `${ARCHIVE_REPORT_BASE}/srn-report`;
const PRN_REPORT= `${ARCHIVE_REPORT_BASE}/prn-report`;
const GRN_REPORT= `${ARCHIVE_REPORT_BASE}/grn-report`;
const LEDGER_WISE = `${TAG_REPORT}/ledger-wise`;
const TAG_WISE = `${TAG_REPORT}/tag-wise`;
const TAG_WISE_DETAILS = `${TAG_REPORT}/tag-wise/details`;
const VAT = `${REPORT_BASE}/vat`;
const AGEING = `${REPORT_BASE}/ageing`;
const STOCK_AGEING = `${REPORT_BASE}/stock-aging`;
const SALES_VAT = `${REPORT_BASE}/sales-vat`;
const NEW_SALES_VAT = `${REPORT_BASE}/new-sales-vat`;
const OUTSTANDING = `${REPORT_BASE}/outstanding`;
const NETSALES = `${REPORT_BASE}/netsales`;
const SALES_PERSON_OUTSTANDING = `${REPORT_BASE}/salesperson-outstanding`;
const PROFIT_LOSS = `${REPORT_BASE}/profit-loss`;
const PURCHASE_VAT = `${REPORT_BASE}/purchase-vat`;
const NEW_PURCHASE_VAT = `${REPORT_BASE}/new-purchase-vat`;
const TRIAL_BALANCE = `${REPORT_BASE}/trial-balance`;
const BALANCE_SHEET = `${REPORT_BASE}/balance-sheet`;
const SALES_RETURN_VAT = `${REPORT_BASE}/sales-return-vat`;
const NEW_SALES_RETURN_VAT = `${REPORT_BASE}/new-sales-return-vat`;
const PURCHASE_RETURN_VAT = `${REPORT_BASE}/purchase-return-vat`;
const NEW_PURCHASE_RETURN_VAT = `${REPORT_BASE}/new-purchase-return-vat`;
const VAT_SUMMARY_REPORT = `${REPORT_BASE}/vat-summary`;

const ENTRY_LOG = `${LOG_BASE}/entry`;
const DATABASE_LOG = `${LOG_BASE}/database`;

const SKU = `${CONFIGURATION_BASE}/sku`;
const SERVICE = `${CONFIGURATION_BASE}/service`;
const SERVICE_CREATE = `${CONFIGURATION_BASE}/service/create`;
const SERVICE_DETAIL = `${CONFIGURATION_BASE}/service/detail`;
const SKU_CREATE = `${CONFIGURATION_BASE}/sku/create`;
const SKU_DETAILS = `${CONFIGURATION_BASE}/sku/details`;

const SETTINGS = `${CONFIGURATION_BASE}/settings`;

const LEDGERS = `${CONFIGURATION_BASE}/ledgers`;
const SKU_PRICE = `${CONFIGURATION_BASE}/sku-price`;
const SKU_PRICE_HISTORY = `${SKU_PRICE}/history`;
const PROMOTION = `${CONFIGURATION_BASE}/promotion`;
const PROMOTION_CREATE = `${PROMOTION}/create`;
const PROMOTION_DETAILS = `${PROMOTION}/details`;
const OPENING_BALANCE = `${CONFIGURATION_BASE}/opening-balance`;

const SALES_MATERIALIZED_VIEW = 'sales-materialized-view';
const CUSTOMER_LEDGER_DETAILS = `${CUSTOMER_LEDGER_BASE}/details`;

const BUSINESS_GROUPS = `${CONFIGURATION_BASE}/business-groups`;

const COMPANY = 'company';

const USERS = `${CONFIGURATION_BASE}/users`;
const AGENT = `${CONFIGURATION_BASE}/agent`;
const ACCOUNT_GROUP = `${CONFIGURATION_BASE}/account-group`;
const FISCAL_YEAR_MIGRATION = `${CONFIGURATION_BASE}/fiscal-year-migration`;

const RECEIPT = 'receipt';
const RECONCILE = `${PAYMENT_BASE}/details`;
const PAYMENT = `${PAYMENT_BASE}`;
const PDC = 'post-dated-cheque';
const CONTRA_ENTRY_BASE = 'contra-entry';
const CONTRA_ENTRY_CREATE = `${CONTRA_ENTRY_BASE}/create`;
const CONTRA_ENTRY_DETAILS = `${CONTRA_ENTRY_BASE}/details`;
const AGGREGATE_CUSTOMER_LEDGER_DETAILS = `${AGGREGATE_CUSTOMER_LEDGER_BASE}/details`;
const DEBIT_NOTE = 'debit-note';
const DEBIT_NOTE_CREATE = `${DEBIT_NOTE}/create`;
const DEBIT_NOTE_DETAILS = `${DEBIT_NOTE}/details`;
const CREDIT_NOTE = 'credit-note';
const CREDIT_NOTE_CREATE = `${CREDIT_NOTE}/create`;
const CREDIT_NOTE_DETAILS = `${CREDIT_NOTE}/details`;

const STOCK_LEDGER_DETAILS = `${STOCK_LEDGER_BASE}/details`;

const PICK_LIST = 'pick-list';
const ARCHIVED_EMAIL='archived-email'
const ARCHIVED_EMAIL_DETAILS = `${ARCHIVED_EMAIL}/details`;

const TAG = `${CONFIGURATION_BASE}/tag`;
const USER_GROUP = `${CONFIGURATION_BASE}/user-group`;
const EMAIL_FREQUENCY = 'email-frequency-category';
const EMAIL_FREQUENCY_CREATE = `${EMAIL_FREQUENCY}/create`;

const ERR_BASE = 'error';
const ERR_404 = 404;

export {
  TAG,
  SKU,
  VAT,
  USERS,
  AGENT,
  AGEING,
  STOCK_AGEING,
  RECEIPT,
  PAYMENT_BASE,
  PAYMENT,
  COMPANY,
  LEDGERS,
  RECONCILE,
  ENTRY_LOG,
  PROMOTION,
  PROMOTION_CREATE,
  PROMOTION_DETAILS,
  SALES_VAT,
  NEW_SALES_VAT,
  SKU_PRICE,
  GRN_CREATE,
  PRN_CREATE,
  SRN_CREATE,
  SKU_CREATE,
  PRN_DETAILS,
  GRN_DETAILS,
  SRN_DETAILS,
  SKU_DETAILS,
  OUTSTANDING,
  SALES_PERSON_OUTSTANDING,
  SALES_PRINT,
  DATABASE_LOG,
  PURCHASE_VAT,
  NEW_PURCHASE_VAT,
  SALES_CREATE,
  SALES_DETAILS,
  SERVICE_INVOICE_DETAILS,
  SERVICE_INVOICE_CREATE,
  INVENTORY_BASE,
  BUSINESS_GROUPS,
  OPENING_BALANCE,
  ROSIA_SRN_PRINT,
  SALES_RETURN_VAT,
  NEW_SALES_RETURN_VAT,
  STOCK_LEDGER_BASE,
  ROSIA_SALES_PRINT,
  SKU_PRICE_HISTORY,
  SALES_RETURN_BASE,
  STOCK_JOURNAL_BASE,
  SALES_INVOICE_BASE,
  PURCHASE_RETURN_VAT,
  NEW_PURCHASE_RETURN_VAT,
  CUSTOMER_LEDGER_BASE,
  AGGREGATE_CUSTOMER_LEDGER_BASE,
  AGGREGATE_CUSTOMER_LEDGER_DETAILS,
  JOURNAL_VOUCHER_BASE,
  PURCHASE_RETURN_BASE,
  STOCK_JOURNAL_CREATE,
  STOCK_LEDGER_DETAILS,
  STOCK_JOURNAL_DETAILS,
  JOURNAL_VOUCHER_PRINT,
  JOURNAL_VOUCHER_CREATE,
  CUSTOMER_LEDGER_DETAILS,
  SALES_MATERIALIZED_VIEW,
  JOURNAL_VOUCHER_DETAILS,
  STOCK_VALUATION_REPORT,
  ROSIA_JOURNAL_VOUCHER_PRINT,
  DAY_BOOK_SUMMARY,
  ERR_BASE,
  ERR_404,
  ACCOUNT_GROUP,
  TRIAL_BALANCE,
  PROFIT_LOSS,
  BALANCE_SHEET,
  FISCAL_YEAR_MIGRATION,
  ABBREVIATED_INVOICE_BASE,
  ABBREVIATED_INVOICE_DETAILS,
  ABBREVIATED_INVOICE_CREATE,
  CONTRA_ENTRY_BASE,
  CONTRA_ENTRY_CREATE,
  ROSIA_ABBREVIATED_INVOICE_PRINT,
  ROSIA_PURCHASE_RETURN_PRINT,
  CONTRA_ENTRY_DETAILS,
  SETTINGS,
  DEBIT_NOTE,
  DEBIT_NOTE_CREATE,
  DEBIT_NOTE_DETAILS,
  PICK_LIST,
  PDC,
  CREDIT_NOTE,
  CREDIT_NOTE_CREATE,
  CREDIT_NOTE_DETAILS,
  USER_GROUP,
  SALES_REPORT,
  SRN_REPORT,
  PRN_REPORT,
  GRN_REPORT,
  VAT_SUMMARY_REPORT,
  DAMAGE_EXPIRY_VALUATION,
  LEDGER_WISE,
  TAG_WISE,
  SERVICE,
  SERVICE_DETAIL,
  SERVICE_CREATE,
  TAG_WISE_DETAILS,
  NETSALES,
  SERVICE_INVOICE_BASE,
  ARCHIVED_EMAIL,
  ARCHIVED_EMAIL_DETAILS,
  EMAIL_FREQUENCY,
  EMAIL_FREQUENCY_CREATE
};
