import { compose } from 'recompose';

import apiInterceptor from '../../../api/interceptor';

import EmailFrequency from './emailFrequency';
import { emailFrequencyCustomerList } from '../../common/base.api';

const composedEmailFrequency = compose(apiInterceptor({ ...emailFrequencyCustomerList }))(EmailFrequency);

export default composedEmailFrequency;
