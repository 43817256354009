import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appConfig from '../../config';

import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
  Snackbar,
} from 'react-mdc-web';
import Select from 'react-select';
import history from '../../../utils/history';
import RadioButtonList from '../../../components/RadioButtonList/RadioButtonList';
import { CreateSalesReturnTableBody, createSalesReturnConfig, ZERO_FLAG_ERROR_ITEM } from './CreateSRNConfig';
import BillingTextField from '../../../components/TextField/BillingTextField';
import BillingSelect from '../../../components/Select/BillingSelect';
import * as httpUtils from '../../../utils/httpUtils';
import PropTypes from 'prop-types';
import * as filterUtil from '../../../utils/filterUtil';
import _ from 'lodash';
import { isObjectEmpty } from '../../../utils/objectProcessor';
import { clone } from '../../../utils/arrayProcessor';
import { debouncer } from '../../../utils/handlers';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { SALES_INVOICE_BASE, SALES_RETURN_BASE } from '../../../data/enums/Route';
// const businessUnitID = JSON.parse(localStorage.getItem('business_id'));
// const serverInvoiceList = [];

const quantityTypeOptions = [
  { value: 'pcs', label: 'Pcs' },
  { value: 'case', label: 'Case' },
];

const message = {
  serverError: '!Server Error',
  success: 'Sales Return created successfully.',
  error: '!Error while creating Sales Return',
  invoiceNumberNotFound: '!Invoice Number not found',
  invoiceListNotSelected: 'Sales Invoice not selected.',
  misMatchBusinessId: '!Invoice number belong to the other Business Unit.',
};

// invoice id is present in the list.
class CreateSalesReturn extends Component {
  constructor(props) {
    /** todo: consistent parameter naming **/
    super(props);
    const { user, bu_id } = props;
    this.state = {
      loading: false,
      editModalOpen: false,
      formEmptyField: false,
      pageEmptyField: false,
      createModalOpen: false,
      deleteModalOpen: false,
      qtyValidation: false,
      indexInInvoiceList: 0,
      showMessage: false,
      success: true,
      message: message.success,
      invoiceIdPresent: false,
      invoiceIdTaken: false,
      businessUnitId: '',
      customerDetails: {
        id: '',
        customerId: '',
        name: '',
        address: '',
        panNo: '',
      },
      createSKU: {
        vat: 0,
        rate: 0,
        price: 0,
        batch: '',
        brand: '',
        amount: 0,
        sku_id: '',
        quantity: 0,
        skuTitle: '',
        net_amount: 0,
        brandTitle: '',
        business_id: 0,
        bill_discount: 0,
        trade_discount: 0,
        return_order_id: 0,
        sales_detail_id: 0,
        invoice_number: '0',
        promotion_discount: 0,
      },
      billSummary: {
        net_amount: 0,
        entered_by: user.idUsers,
        amount: 0,
        // entered_by: userInfo.name,
        vat: 0,
        sub_total: 0,
        bill_discount: 0,
        trade_discount: 0,
        gross_amount: 0,
        remarks: '',
      },
      date: {
        start: filterUtil.getStartOfCurrentMonth(),
        end: filterUtil.getCurrentDay(),
      },
      invoiceList: [],
      headerTitleToView: createSalesReturnConfig.headerTitleToView,
      checkBoxForSalesReturn: {
        primary: false,
        secondary: [],
      },
      formValidateFieldArray: ['brand', 'sku_id', 'quantity', 'promotion_discount', 'net_amount', 'rate'],
      editFormValidateFieldArray: ['quantity', 'promotion_discount', 'net_amount'],
      pageValidateFieldArray: ['invoiceId', 'name', 'address', 'panNo', 'remarks'],
      customerList: [],
      brandList: [],
      skuList: [],
      buList: [],
      invoiceId: '',
      backUpCreateSku: {},
    };
    this.promotionError = '!Violates Promotion';
    //this.interval;
    this.discountCalculationReferenceField = {
      subTotal: 1,
      billDiscount: 0,
      tradeDiscount: 0,
    };
  }

  // after mounted get the customer list.
  componentDidMount() {
    // this.getDetail();
    this.getBUList();
    this.getCustomerList();
  }

  // handle input change of customer Details. Fetch the list if invoice id is valid.
  onInputFieldChange = (field, value) => {
    const { customerDetails } = this.state;
    customerDetails[field] = value;
    this.setState({ createSKU: customerDetails });
  };

  onCustomerDropDownChange = (field, id, customer) => {
    const customerDetails = {
      id: customer.idLedger,
      customerId: customer.customerId,
      name: customer.name,
      address: customer.address,
      panNo: customer.panNo,
    };
    this.setState({ customerDetails: customerDetails });
  };

  // handle change of create sku events.
  onChange = (field, value, all = {}) => {
    const { createSKU, invoiceIdPresent } = this.state;
    createSKU[field] = value;
    createSKU.amount = Number(createSKU.quantity * createSKU.rate);
    if (createSKU.promotion_discount < 0 || createSKU.promotion_discount > createSKU.quantity * createSKU.rate) {
      createSKU.promotion_discount = invoiceIdPresent ? createSKU.promotion_discount : 0;
    }
    createSKU.net_amount = Number((createSKU.quantity * createSKU.rate - createSKU.promotion_discount).toFixed(2));
    createSKU.vat = ((13 / 100) * createSKU.net_amount).toFixed(2);
    // createSKU.net_amount = (Number(createSKU.net_amount) +  Number(createSKU.vat)).toFixed(2);
    if (field === 'sku_id') {
      createSKU.skuTitle = all['title'] || 'title';
      createSKU.rate = all['rate'] || 0;
    }
    this.setState({ createSKU: createSKU });
    // update the gross amount upon the changes.
    // in case field is brand , set the sku list
    if (field === 'brand') {
      this.getSKUListUnderBrand(value);
    }
  };

  validateQty = paramsObj => {
    const { backUpCreateSku } = this.state;
    const self = this;
    this.setState({ loading: true });

    return httpUtils
      .get(
        appConfig.baseUrl +
          `sales-return/editSKU?invoice_number=${paramsObj.invoice_number}&quantity=${paramsObj.quantity}
          &actual_quantity=${paramsObj.actual_quantity}&rate=${paramsObj.rate}&sales_detail_id=${paramsObj.sales_detail_id}`,
      )
      .then(response => {
        this.setState({ loading: false, qtyValidation: false });
        if (!isObjectEmpty(response.data.edited || {})) {
          this.updateCreateSku(this.skuObjMapper(response.data.edited));

          return true;
        } else {
          self.promotionError = response.data.message;
          this.updateCreateSku(backUpCreateSku);

          return false;
          // this.setState({ invoiceIdPresent: false, invoiceList: [], invoiceIdTaken: false });
        }
      })
      .catch(error => {
        self.promotionError = '!Server Error';
        this.setState({ loading: false, qtyValidation: false });
        this.updateCreateSku(backUpCreateSku);

        return false;
        //  this.setState({ invoiceIdPresent: false, invoiceList: [], invoiceIdTaken: false });
      });
  };

  updateCreateSku = updatedObj => {
    const { createSKU } = this.state;
    this.setState({ createSKU: updatedObj });
  };
  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (data, index) => {
    const { invoiceIdPresent } = this.state;
    this.setState({ editModalOpen: true, indexInInvoiceList: index });
    if (!invoiceIdPresent) this.getSKUListUnderBrand(data.brand);
    this.setState({ createSKU: clone(data), backUpCreateSku: clone(data) });
  };

  // create the sales Return, update the discount value
  createSalesReturn = () => {
    const { invoiceList, createSKU, businessUnitId } = this.state;
    createSKU.business_id = businessUnitId;
    invoiceList.push(createSKU);
    this.setState({ invoiceList: invoiceList }, () => {
      this.closeModal();
      // handle SRN item update
      this.handleSRNItemUpdate(invoiceList);
    });
  };

  // editSalesInvoice
  editSalesInvoice = (data, index) => {
    const { invoiceList, createSKU, invoiceIdPresent } = this.state;
    // update the discount value
    // createSKU.discount = createSKU.quantity * createSKU.rate - createSKU.price;
    if (invoiceIdPresent) {
      createSKU.bill_discount = this.calculateRelativeDiscount('billDiscount', createSKU.net_amount) || 0;
      createSKU.trade_discount = this.calculateRelativeDiscount('tradeDiscount', createSKU.net_amount) || 0;
    }
    /** todo: if we have to calculate promotion discount for editted qty we need to have original (promotion discount & qty / amount) for this sku applied while invoicing */
    invoiceList[index] = createSKU;
    this.setState({ invoiceList }, () => {
      this.closeModal();
    });
    if (invoiceIdPresent) {
      this.handleSecondaryCheckBoxClick(data.sales_detail_id, true, createSKU);
    } else {
      this.handleSRNItemUpdate(invoiceList);
    }
  };

  // delete the sales Return for particular sku, and update the total value.
  deleteSalesReturnItem = index => {
    const { invoiceList, indexInInvoiceList } = this.state;
    if (invoiceList.length >= indexInInvoiceList) {
      invoiceList.splice(indexInInvoiceList, 1);
      this.setState({ invoiceList: invoiceList }, () => {
        this.closeModal();
        this.handleSRNItemUpdate(invoiceList);
      });
    }
  };

  calculateRelativeDiscount = (type, subTotal) =>
    (subTotal / this.discountCalculationReferenceField.subTotal) * this.discountCalculationReferenceField[type];

  // when SRN item number changes, update the bill total value
  handleSRNItemUpdate = invoiceList => {
    // set the invoice total.
    const { billSummary } = this.state;

    let amountInfo = {
      salesValue: 0,
      billDiscount: 0,
      tradeDiscount: 0,
      promotionDiscount: 0,
      amount: 0,
    };
    invoiceList.map(element => {
      amountInfo = {
        amount: amountInfo.amount + Number(element.quantity || 0) * Number(element.rate || 0),
        salesValue: amountInfo.salesValue + Number(element.net_amount || 0),
        billDiscount: amountInfo.billDiscount + Number(element.bill_discount || 0),
        tradeDiscount: amountInfo.tradeDiscount + Number(element.trade_discount || 0),
        promotionDiscount: amountInfo.promotionDiscount + Number(element.promotion_discount || 0),
      };

      return amountInfo;
    });
    const taxableAmount = Number(amountInfo.salesValue - amountInfo.billDiscount - amountInfo.tradeDiscount);
    const vat = Number(((13 / 100) * Number(taxableAmount)).toFixed(2));
    billSummary.vat = vat;
    billSummary.bill_discount = amountInfo.billDiscount;
    billSummary.trade_discount = amountInfo.tradeDiscount;
    billSummary.sub_total = Number(amountInfo.salesValue.toFixed(2));
    billSummary.net_amount = Number(taxableAmount.toFixed(2));
    billSummary.gross_amount = Number((taxableAmount + vat).toFixed(2));
    billSummary.amount = amountInfo.amount;
    billSummary.promotion_discount = amountInfo.promotionDiscount;
    this.setState({ billSummary });
  };

  // close the modal resetting  all the value
  closeModal = () => {
    this.setState({ createModalOpen: false, editModalOpen: false, deleteModalOpen: false });
    this.resetSKUDialog();
  };

  // reset SKU Dialog
  resetSKUDialog = () => {
    const createSKU = {
      vat: 0,
      rate: 0,
      price: 0,
      batch: '',
      brand: '',
      amount: 0,
      sku_id: '',
      quantity: 0,
      skuTitle: '',
      net_amount: 0,
      brandTitle: '',
      business_id: 0,
      bill_discount: 0,
      trade_discount: 0,
      return_order_id: 0,
      sales_detail_id: 0,
      invoice_number: '0',
      promotion_discount: 0,
    };
    const skuList = [];
    this.setState({ createSKU: createSKU, formEmptyField: false, skuList: skuList });
  };

  resetCustomerDetails = () => {
    const customerDetails = {
      id: '',
      customerId: '',
      name: '',
      address: '',
      panNo: '',
    };
    this.setState({ customerDetails });
  };

  resetBillSummaryAmounts = () => {
    const { billSummary } = this.state;
    billSummary.net_amount = 0;
    billSummary.vat = 0;
    billSummary.bill_discount = 0;
    billSummary.trade_discount = 0;
    billSummary.gross_amount = 0;

    this.setState({ billSummary });
  };

  resetDiscountCalculationReferenceField = () => {
    this.discountCalculationReferenceField.subTotal = 1;
    this.discountCalculationReferenceField.billDiscount = 0;
    this.discountCalculationReferenceField.tradeDiscount = 0;
  };

  // on okay click for the modal
  handleModalOkClick = () => {
    const { createModalOpen, editModalOpen } = this.state;
    if (editModalOpen || createModalOpen) {
      const valid = this.validateModal();
      if (valid) {
        if (createModalOpen) {
          this.createSalesReturn();
        } else {
          this.editSalesInvoice(this.state.createSKU, this.state.indexInInvoiceList);
        }
      }
    } else {
      this.deleteSalesReturnItem();
    }
  };

  validateModal = () => {
    const { createSKU, invoiceIdPresent, editFormValidateFieldArray, formValidateFieldArray } = this.state;
    const flag = !Object.values(ZERO_FLAG_ERROR_ITEM).some(field => createSKU[field] === 0);
    const validateObject = invoiceIdPresent ? editFormValidateFieldArray : formValidateFieldArray;
    const valid = this.validateForm(createSKU, validateObject, 'formEmptyField', flag, false);

    return valid;
  };

  checkQtyValidation = async () => {
    const { createSKU } = this.state;
    const { invoice_number, quantity, sales_detail_id, actual_quantity, rate } = createSKU;
    const validated = await this.validateQty({ invoice_number, quantity, actual_quantity, rate, sales_detail_id }).then(
      response => response,
    );
    if (!validated) {
      this.setState({
        showMessage: true,
        success: false,
        message: this.promotionError,
      });
    }
  };

  validateForm = (formObject, config, flag, zeroFlag, invalidIdFlag) => {
    const data = Object.keys(formObject).some(k => {
      if (config.indexOf(k) > -1) {
        let value = formObject[k];
        const conditionParameter = zeroFlag ? value === '' : !value;
        if (conditionParameter) {
          this.setState({ [flag]: true });

          return true;
        }
      }
    });
    if (this.state.invoiceIdTaken && invalidIdFlag) {
      return false;
    } else {
      return !data;
    }
  };

  getSelectedInvoiceList = checkedList => {
    const { invoiceList } = this.state;
    const selectedInvoiceList = invoiceList.filter(item => checkedList.indexOf(item.sales_detail_id) > -1);

    return selectedInvoiceList;
  };

  /** handle the primary check box click. */
  handlePrimaryCheckBoxClick = flag => {
    const { checkBoxForSalesReturn, invoiceList } = this.state;
    checkBoxForSalesReturn.primary = flag;
    if (!flag) {
      checkBoxForSalesReturn.secondary = [];
      this.resetBillSummaryAmounts();
      this.setState({ checkBoxForSalesReturn: checkBoxForSalesReturn });
    } else {
      /** run through all the loop within the invoice list */
      const allCheckBox = invoiceList.map((element, key) => {
        return element.sales_detail_id;
      });
      checkBoxForSalesReturn.secondary = allCheckBox;
      const selectedInvoiceList = this.getSelectedInvoiceList(checkBoxForSalesReturn.secondary);
      this.setState({ checkBoxForSalesReturn }, () => this.handleSRNItemUpdate(selectedInvoiceList));
    }
  };

  /** handle the secondary checkBox click */
  handleSecondaryCheckBoxClick = (id, flag, item) => {
    const { checkBoxForSalesReturn, invoiceList } = this.state;
    let selectedInvoiceList = [];
    if (!flag) {
      // find the index in the list, and clear it.
      // reset the primary checkbox
      let indexInList = checkBoxForSalesReturn.secondary.indexOf(id);
      checkBoxForSalesReturn.secondary.splice(indexInList, 1);
      checkBoxForSalesReturn.primary = false;
      selectedInvoiceList = this.getSelectedInvoiceList(checkBoxForSalesReturn.secondary);
      this.setState({ checkBoxForSalesReturn }, () => this.handleSRNItemUpdate(selectedInvoiceList));
    } else {
      // check the condition if primary is to be set.
      let indexInList = checkBoxForSalesReturn.secondary.indexOf(id);
      if (indexInList === -1) checkBoxForSalesReturn.secondary.push(id);
      if (checkBoxForSalesReturn.secondary.length === invoiceList.length) {
        checkBoxForSalesReturn.primary = true;
      }
      selectedInvoiceList = this.getSelectedInvoiceList(checkBoxForSalesReturn.secondary);
      this.setState({ checkBoxForSalesReturn }, () => this.handleSRNItemUpdate(selectedInvoiceList));
    }
  };

  // get the customer list for the selection.
  getCustomerList = () => {
    this.setState({ loading: true });
    const type = 'cash';
    httpUtils.get(appConfig.baseUrl + `ledger/customer-list?type=${type}`).then(response => {
      this.setState({ loading: false });
      if (response.status == 200 && response.success) {
        let responseDataList = response.data;
        responseDataList.map(customer => {
          customer['label'] = customer.idLedger + '. ' + customer.name;
        });
        this.setState({ customerList: responseDataList });
      }
    });
  };

  // get the brand list
  getBrandList = () => {
    const { businessUnitId } = this.state;
    httpUtils.get(appConfig.baseUrl + 'brand/list?bu_id=' + businessUnitId).then(response => {
      if (response.success) {
        this.setState({ brandList: response.data.brandList });
      }
    });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = brandId => {
    const { businessUnitId } = this.state;
    httpUtils.get(appConfig.baseUrl + 'sku-list?bu_id=' + businessUnitId + '&bu_brand_id=' + brandId).then(response => {
      if (response.success) {
        this.setState({ skuList: response.data });
      }
    });
  };

  // get BU list
  getBUList = () => {
    httpUtils.get(appConfig.baseUrl + 'upload/business-company').then(response => {
      if (response.success) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  handleBUClick = id => {
    this.setState({ businessUnitId: id }, () => {
      this.getBrandList();
    });
  };

  directToMainPage = () => {
    const { showMessage, success } = this.state;
    if (!showMessage && success) {
      history.push(`/${SALES_RETURN_BASE}`);
    }
  };

  handleSaveClick = () => {
    // generate the object to send to the server
    const {
      customerDetails,
      billSummary,
      invoiceList,
      businessUnitId,
      pageValidateFieldArray,
      invoiceId,
      checkBoxForSalesReturn,
      invoiceIdPresent,
    } = this.state;
    const toValidObject = Object.assign({}, customerDetails);
    toValidObject['invoiceId'] = invoiceId;
    toValidObject['remarks'] = billSummary.remarks;
    const invalidIdFLag = this.state.invoiceIdTaken;
    const valid = this.validateForm(toValidObject, pageValidateFieldArray, 'pageEmptyField', false, invalidIdFLag);
    const invoiceListCheck = invoiceIdPresent ? checkBoxForSalesReturn.secondary.length > 0 : invoiceList.length > 0;
    if (!invoiceListCheck) {
      this.setState({ showMessage: true, success: false, message: message.invoiceListNotSelected });

      return false;
    }
    if (valid && invoiceListCheck) {
      let invoiceArray = [];
      if (checkBoxForSalesReturn.secondary.length > 0 && checkBoxForSalesReturn.primary === false) {
        invoiceArray = invoiceList.filter((item, key) => {
          return checkBoxForSalesReturn.secondary.indexOf(item.sales_detail_id) > -1;
        });
      } else {
        invoiceArray = invoiceList;
      }
      const object = {
        customer_id: customerDetails.customerId,
        business_id: businessUnitId,
        entered_by: billSummary.entered_by,
        net_amount: billSummary.gross_amount,
        vat: billSummary.vat,
        amount: billSummary.amount,
        skuList: invoiceArray,
        invoice_number: invoiceId,
        promotion_discount: billSummary.promotion_discount || 0,
        bill_discount: billSummary.bill_discount,
        trade_discount: billSummary.trade_discount,
        taxable_amount: billSummary.taxable_amount,
        remarks: billSummary.remarks,
      };
      this.setState({ loading: true });
      httpUtils
        .post(appConfig.baseUrl + 'sales-return', object)
        .then(response => {
          this.setState({ loading: false });
          if (response.success) {
            // alert('Sales Return Created');
            this.setState({ showMessage: true, success: true, message: message.success });
            this.setState({ invoiceList: [] });
          }
        })
        .catch(error => {
          {
            this.setState({
              loading: false,
              showMessage: true,
              success: false,
              message: message.error,
            });
          }
        });
    }
  };

  handleCancelClick = () => {
    history.push(`/${SALES_RETURN_BASE}`);
  };

  handleDeleteIconClick = index => {
    this.setState({ deleteModalOpen: true, indexInInvoiceList: index });
  };

  onInvoiceIdChange = (field, value) => {
    this.setState({ invoiceId: value }, () => {
      debouncer(this.getInvoiceIdDetails, 1000)(this.state.invoiceId);
    });
  };

  onQtyChange = (field, value) => {
    this.onChange(field, value);
    const { createSKU, invoiceIdPresent, editModalOpen, qtyValidation } = this.state;
    const validateQty =
      createSKU.quantity !== createSKU.actual_quantity &&
      invoiceIdPresent &&
      createSKU.promotion_discount &&
      editModalOpen;
    if (validateQty) {
      if (!qtyValidation) this.setState({ qtyValidation: true });
      debouncer(this.checkQtyValidation, 2000)();
    }
  };

  onPageInputFieldChange = (field, value) => {
    const { billSummary } = this.state;
    billSummary[field] = value;
    this.setState({ billSummary: billSummary });
  };

  getInvoiceIdDetails = id => {
    this.resetBillSummaryAmounts();
    this.resetCustomerDetails();
    this.resetDiscountCalculationReferenceField();
    this.setState({ loading: true });
    httpUtils
      .get(appConfig.baseUrl + `sales-return/available-list?invoice_number=${id}`)
      .then(response => {
        this.setState({ loading: false });
        if (response.success && !isObjectEmpty(response.data)) {
          if (response.data.businessId === this.state.businessUnitId) {
            const customerDetails = response.data.customerDetail;
            this.setState({ invoiceIdPresent: true, invoiceIdTaken: false });
            this.onCustomerDropDownChange('customerId', customerDetails.customerId, customerDetails);
            this.createListObject(response.data.salesInvoice);
            this.discountCalculationReferenceField.subTotal = response.data.subTotalAmount || 1;
            this.discountCalculationReferenceField.billDiscount = response.data.cashDiscount || 0;
            this.discountCalculationReferenceField.tradeDiscount = response.data.tradeDiscount || 0;
          } else {
            this.setState({
              showMessage: true,
              success: false,
              message: message.misMatchBusinessId,
              invoiceIdPresent: false,
              invoiceList: [],
              invoiceIdTaken: true,
            });
          }
        } else {
          const userMessage = response.message ? `!${response.message}` : '!SRN already done.';
          this.setState({
            showMessage: true,
            success: false,
            message: userMessage,
            invoiceIdPresent: false,
            invoiceList: [],
            invoiceIdTaken: false,
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          showMessage: true,
          success: false,
          message: message.invoiceNumberNotFound,
          invoiceIdPresent: false,
          invoiceList: [],
          invoiceIdTaken: false,
        });
      });
  };

  createListObject = salesArray => {
    const { invoiceIdPresent } = this.state;
    const invoiceList = salesArray.map((data, key) => {
      const createSkuObj = this.skuObjMapper(data);
      if (createSkuObj.quantity === 0) {
        createSkuObj.amount = 0;
        createSkuObj.net_amount = 0;
        createSkuObj.vat = 0;
      }
      /* else
       {
         createSkuObj.amount = Number(createSkuObj.quantity * createSkuObj.rate).toFixed(2);
         createSkuObj.net_amount = Number(createSkuObj.amount - ( createSkuObj.promotion_discount + createSkuObj.bill_discount + createSkuObj.trade_discount )).toFixed(2);
         createSkuObj.vat = Number(createSkuObj.net_amount * 0.13).toFixed(2);
       }*/
      if (invoiceIdPresent) {
        createSkuObj['maxValue'] = data.quantity;
        createSkuObj['disabled'] = true;
      }

      return createSkuObj;
    });

    this.setState({ invoiceList });
  };

  skuObjMapper = data => {
    const { businessUnitId } = this.state;

    return {
      sales_detail_id: data.idSalesDetail,
      batch: data.batch,
      brand: '',
      brandTitle: '',
      sku_id: data.skuId,
      skuTitle: data.title,
      price: 0,
      vat: data.vat || 0,
      return_order_id: 0,
      invoice_number: data.invoiceNumber,
      quantity: data.quantity,
      rate: data.rate,
      business_id: data.businessId || businessUnitId,
      promotion_discount: data.promotionDiscount,
      bill_discount: data.billDiscount || 0,
      trade_discount: data.tradeDiscount || 0,
      net_amount: data.netAmount || 0,
      amount: data.amount || data.quantity * data.rate,
      actual_quantity: data.actual_quantity ? data.actual_quantity : data.quantity || 0,
    };
  };

  render() {
    const {
      loading,
      invoiceList,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      invoiceIdPresent,
      customerDetails,
      businessUnitId,
      buList,
      showMessage,
      qtyValidation,
    } = this.state;

    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="message-snackbar">
            {
              <Snackbar
                className={this.state.success ? 'success-message' : ''}
                timeout={this.state.success ? 1000 : 6000}
                onTimeout={() => {
                  this.setState({ showMessage: false }, () => {
                    this.directToMainPage();
                  });
                }}
                open={showMessage}
              >
                {this.state.message}
              </Snackbar>
            }
          </div>
          <div className="card-header-bar clearfix" ref="tableReference">
            <div className="header-left">
              <h2>New SRN</h2>
            </div>
            <div className="header-right">{this.state.date.end}</div>
          </div>
          <div className="card-body">
            <div className="radio-list-wrapper">
              {buList.length && <RadioButtonList data={buList} handleBuClick={this.handleBUClick} />}
            </div>
            <div className={this.state.businessUnitId ? '' : 'block-overlay'}>
              <div className="three-input-wrapper">
                <Grid>
                  <Cell col={2} className="input-field">
                    <BillingTextField
                      value={this.state.invoiceId}
                      floatingLabel="Sales Invoice Number"
                      required={true}
                      className="billing-required"
                      emptyField={this.state.pageEmptyField}
                      errorMessage={
                        this.state.invoiceIdTaken ? 'Invoice No. belongs to other Business Unit' : 'Should not be empty'
                      }
                      handleChange={this.onInvoiceIdChange}
                      invalidError={this.state.invoiceIdTaken}
                    />
                  </Cell>
                  <Cell col={3} className="input-select">
                    <label>Customer Name</label>
                    <BillingSelect
                      name="form-field-name"
                      param="name"
                      clearable={false}
                      value={this.state.customerDetails.customerId}
                      valueKey="customerId"
                      options={this.state.customerList}
                      handleChange={this.onCustomerDropDownChange}
                      required={true}
                      className={invoiceIdPresent ? 'no-pointer-events' : ''}
                      emptyField={this.state.pageEmptyField}
                      multipleParam={true}
                    />
                  </Cell>
                  <Cell col={2} className="input-field">
                    <BillingTextField
                      value={this.state.customerDetails.address}
                      floatingLabel="Address"
                      required={true}
                      className="billing-required no-pointer-events"
                      emptyField={this.state.pageEmptyField}
                    />
                  </Cell>
                  <Cell col={2} className="input-field">
                    <BillingTextField
                      value={this.state.customerDetails.panNo}
                      floatingLabel="PAN Number"
                      required={true}
                      type="number"
                      rule="isInt"
                      className="billing-required no-pointer-events"
                      emptyField={this.state.pageEmptyField}
                    />
                  </Cell>
                  <Cell col={3} className="input-field">
                    <BillingTextField
                      value={this.state.billSummary.remarks}
                      param="remarks"
                      floatingLabel="Remarks"
                      required={true}
                      className="billing-required"
                      emptyField={this.state.pageEmptyField}
                      handleChange={this.onPageInputFieldChange}
                    />
                  </Cell>
                </Grid>
              </div>
              <div className="table-wrapper">
                <div ref="fixedTableBody" className="fixed-table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          {invoiceIdPresent && (
                            <Checkbox
                              checked={this.state.checkBoxForSalesReturn.primary}
                              onChange={({ target: { checked } }) => {
                                this.handlePrimaryCheckBoxClick(checked);
                              }}
                            />
                          )}
                          {!invoiceIdPresent && <span>SN</span>}
                        </th>
                        <th>SKU</th>
                        <th className="right-align">Rate</th>
                        <th className="right-align">Quantity (pcs)</th>
                        <th className="right-align">Discount</th>
                        <th className="right-align">Net Amount</th>
                        <th />
                      </tr>
                    </thead>
                    {invoiceList.map((data, key) => (
                      <tbody className="common-checkbox">
                        <CreateSalesReturnTableBody
                          data={data}
                          index={key}
                          deleteSalesInvoice={this.deleteSalesInvoice}
                          handleEditIconClick={this.handleEditIconClick}
                          handleDeleteIconClick={this.handleDeleteIconClick}
                          handleCheckBoxClick={this.handleSecondaryCheckBoxClick}
                          selectedCheckBox={this.state.checkBoxForSalesReturn.secondary}
                          showCheckBox={this.state.invoiceIdPresent}
                        />
                      </tbody>
                    ))}
                  </table>
                </div>

                {/* Fab Icon*/}
                {!invoiceIdPresent && (
                  <div className="mini-fab-button">
                    <Fab mini onClick={() => this.setState({ createModalOpen: true })}>
                      <Icon name="add" />
                    </Fab>
                  </div>
                )}
              </div>
              {/* Sales invoice table summary goes over here.*/}
              <div className="total-section-wrapper">
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Sub Total
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(this.state.billSummary.sub_total)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Cash Discount
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(this.state.billSummary.bill_discount)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Trade Discount
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(this.state.billSummary.trade_discount)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Taxable Amount
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(this.state.billSummary.net_amount)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    VAT:{' '}
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(this.state.billSummary.vat)}
                  </Cell>
                </Grid>

                <div className="hr" />
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Total
                  </Cell>
                  <Cell col={1} className="right-align font-bold">
                    {fixedFloatAndCommas(this.state.billSummary.gross_amount)}
                  </Cell>
                </Grid>
              </div>
            </div>
          </div>
          <div className="newinvoice-btn-wrapper">
            <Grid>
              <Cell col={8} />
              <Cell col={4} className="right-align">
                <Button
                  accent
                  className="cancel-btn modal-btn"
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  accent
                  className="save-btn modal-btn"
                  onClick={() => {
                    this.handleSaveClick();
                  }}
                >
                  Save
                </Button>
              </Cell>
            </Grid>
          </div>

          {/* Begin of the dialog Body*/}
          <Dialog
            open={createModalOpen || editModalOpen || deleteModalOpen}
            onClose={() => {
              this.closeModal();
            }}
          >
            <DialogHeader>
              {createModalOpen && <DialogTitle>Create SKU</DialogTitle>}
              {editModalOpen && <DialogTitle>Edit SKU</DialogTitle>}
              {deleteModalOpen && <DialogTitle>Delete SKU</DialogTitle>}
            </DialogHeader>
            {!deleteModalOpen && (
              <DialogBody>
                <div className="dialog-upperpart">
                  {!(editModalOpen && invoiceIdPresent) && (
                    <Grid className="grid-padding">
                      <Cell col={6} tablet={8}>
                        <label>Brand</label>
                        <BillingSelect
                          name="form-field-name"
                          param="brand"
                          clearable={false}
                          value={this.state.createSKU.brand}
                          valueKey="businessCatalogDetailId"
                          labelKey="title"
                          options={this.state.brandList}
                          handleChange={this.onChange}
                          required={true}
                          emptyField={this.state.formEmptyField}
                        />
                      </Cell>

                      <Cell col={6} tablet={8}>
                        <label>SKU</label>
                        <BillingSelect
                          name="form-field-name"
                          param="sku_id"
                          clearable={false}
                          value={this.state.createSKU.sku_id}
                          valueKey="business_sku_id"
                          labelKey="title"
                          options={this.state.skuList}
                          handleChange={this.onChange}
                          required={true}
                          emptyField={this.state.formEmptyField}
                          multipleParam={true}
                        />
                      </Cell>
                    </Grid>
                  )}

                  <Grid className="grid-padding">
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                        value={this.state.createSKU.batch}
                        handleChange={this.onChange}
                        floatingLabel="Batch"
                        param="batch"
                        required={false}
                        className={`billing-required ${invoiceIdPresent ? 'no-pointer-events' : ''}`}
                        className={`billing-required ${
                          this.state.createSKU.hasOwnProperty('disabled')
                            ? this.state.createSKU.disabled
                              ? 'no-pointer-events'
                              : ''
                            : ''
                        }`}
                        /*  emptyField={this.state.formEmptyField}*/
                      />
                    </Cell>

                    <Cell col={4} tablet={8}>
                      <BillingTextField
                        value={this.state.createSKU.rate}
                        handleChange={this.onChange}
                        floatingLabel="Rate"
                        param="rate"
                        required={true}
                        zeroError={true}
                        type="number"
                        className={`billing-required ${invoiceIdPresent ? 'no-pointer-events' : ''}`}
                        emptyField={this.state.formEmptyField}
                        errorMessage={this.state.createSKU.quantity === 0 ? 'Should not be 0' : 'Should not be empty'}
                      />
                    </Cell>
                    <Cell col={4} tablet={8} className="relative-container">
                      <BillingTextField
                        value={this.state.createSKU.quantity}
                        handleChange={this.onQtyChange}
                        floatingLabel="Quantity (pcs)"
                        param="quantity"
                        required={true}
                        zeroError={true}
                        type="number"
                        rule="isInt"
                        max={this.state.createSKU.hasOwnProperty('maxValue') ? this.state.createSKU.maxValue : '0'}
                        className="billing-required right-side-gap"
                        emptyField={this.state.formEmptyField}
                        errorMessage={this.state.createSKU.quantity === 0 ? 'Should not be 0' : 'Should not be empty'}
                      />
                    </Cell>
                  </Grid>

                  <Grid className="grid-padding">
                    <Cell col={6} tablet={8}>
                      <BillingTextField
                        value={this.state.createSKU.promotion_discount}
                        handleChange={this.onChange}
                        floatingLabel="Discount"
                        param="promotion_discount"
                        required={true}
                        zeroError={false}
                        type="number"
                        max={Number((this.state.createSKU.quantity * this.state.createSKU.rate).toFixed(2)).toString()}
                        className={`billing-required ${invoiceIdPresent ? 'no-pointer-events' : ''}`}
                        emptyField={this.state.formEmptyField}
                        errorMessage="Should not be empty"
                      />
                    </Cell>
                    <Cell col={6} tablet={8}>
                      <BillingTextField
                        value={this.state.createSKU.net_amount}
                        handleChange={this.onChange}
                        floatingLabel="Net Amount"
                        param="net_amount"
                        required={true}
                        zeroError={true}
                        type="number"
                        className="billing-required no-pointer-events"
                        emptyField={this.state.formEmptyField}
                        errorMessage={this.state.createSKU.net_amount === 0 ? 'Should not be 0' : 'Should not be empty'}
                      />
                    </Cell>
                  </Grid>
                </div>
              </DialogBody>
            )}

            {deleteModalOpen && <div className="default-margin-24">Are you sure you want to delete ?</div>}

            <DialogFooter>
              <Button
                accent
                className="dialog-cancel modal-btn"
                onClick={() => {
                  this.closeModal();
                }}
              >
                Cancel
              </Button>
              <Button
                accent
                className={`dialog-ok modal-btn ${qtyValidation ? 'btn-disabled' : ''}`}
                onClick={() => {
                  this.handleModalOkClick();
                }}
              >
                Ok
              </Button>
            </DialogFooter>
          </Dialog>
        </div>
      </div>
    );
  }
}

CreateSalesReturn.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
    bu_id: state.billing.bu_id || null,
  };
};

const CreateSRN = connect(mapStateToProps)(CreateSalesReturn);

export default CreateSRN;
