import React, { Component } from 'react'
import { EMAIL_FREQUENCY, EMAIL_FREQUENCY_CREATE } from '../../../data/enums/Route';
import * as queryService from '../../common/query.service';
import * as snackService from '../../common/snack.service';
import PageView from '../../common/pagination/PageView';
import { BillingSnackBar } from '../../../components/BillingMDC';
import tableConfig from './config';
import * as filterUtil from '../../../utils/filterUtil';
import { EMAIL_FREQUENCY_CONFIG } from '../../common/domain.config';
import history from '../../../utils/history';
import EmailFrequencyStyled from './EmailFrequencyStyled';

class EmailFrequency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        singleDate: filterUtil.getCurrentDay(),
      },
      data: {
        list: [],
        total: 1,
      },
    };

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
    );
  }

  componentDidMount(){
    this.loadTableData()
  }

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  loadTableData = () => {
    const { getList } = this.props;
    const { queryParameters } = this.state;
    getList({search_text:queryParameters?.search || ''})
      .then((response) => {
        const { data } = this.state;
        data.list = response;
        data.total = response.length;
        this.setState({ data });
      })
      .catch((err) => {
        console.log(err);
        this.sendErrorMessage();
      });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  handleFabButtonClick = () => {
    history.push(`/${EMAIL_FREQUENCY_CREATE}`);
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  handleTableBodyClick = id => {
    history.push(`/${EMAIL_FREQUENCY}/${id}`);
  };

  render() {
    const { queryParameters, data, snack, display } =this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <EmailFrequencyStyled className="paginated-list">
      <PageView
            domainConfig={EMAIL_FREQUENCY_CONFIG}
            headerConfig={{
              search: true,
              date: false,
              create: true,
              filter: false,
              singleDate: true,
            }}
            display={display}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.basePaginationService.clearSearchText}
            // handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            onTableBodyClick={this.handleTableBodyClick}
            data={data}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            onPageSelect={this.basePaginationService.onPageSelect}
            createHandler={this.handleFabButtonClick}
          />
          <BillingSnackBar closeSnack={this.closeSnack} config={snack} />

      </EmailFrequencyStyled>
      
    )
  }
}

export default EmailFrequency;
