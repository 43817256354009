import React, { Component } from 'react';
import View from './ View';
import { ACTION } from '../../../../data/enums/enums';
import { formValidationFields, getApiTransformedData, skuDetailsMapper, message } from './config';
import { SERVICE_CONFIG } from '../../../common/domain.config';
import {
  buApi,
  checkIfApiCallSuccess,
} from '../../../common/base.api';
import { validateForm } from '../../../common/validation';
import history from '../../../../utils/history';
import { SERVICE_DETAIL, SERVICE } from '../../../../data/enums/Route';
import * as snackService from '../../../common/snack.service';
import BillingSnackBar from '../../../../components/BillingMDC/BillingSnackbar';
import Loading from '../../../../common/Loading';
import {getBusinessId} from "../../../../utils/handlers";

const propTypes = {};

const defaultProps = {};

class ServiceDetail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const id = props.match.params.id ? parseInt(props.match.params.id) : 0;
    this.state = {
      data: skuDetailsMapper({}),
      serverData: skuDetailsMapper({}),
      id,
      crudMode: id ? ACTION.READ : ACTION.ADD,
      actionType: ACTION.NULL,
      validation: {
        flag: false,
        fieldList: formValidationFields,
      },
      snack: { ...snackService.snackParameters },
      cancelClicked: false,
      buList:[],
    };
  }

  componentDidMount() {
    const { id } = this.state;
    if (id) this.getServiceDetails(id);
    this.getBUList();
  }

  getBUList = () => {    
    buApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        const filteredResponse = response.data.list.filter(el => !el.isRosiaIntegrated);
        this.setState({ buList: filteredResponse });
      }
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.cancelClicked) {
      this.setState({ cancelClicked: false });
    }
  }

  getServiceDetails = id => {
    const { getSkuDetails } = this.props;
    getSkuDetails({ idSku: id }).then(response => {
      const data = skuDetailsMapper(response.details || {});
      this.setState({ businessId:response?.details?.businessId,  data, crudMode: ACTION.READ, serverData: { ...data } });
    });
  };

  handleInputChange = (param, value) => {
    const { data } = this.state;
    data[param] = value;
    this.setState({ data });
  };

  getValidation = () => {
    const { data, validation } = this.state;
    const getDetailsValidation = validateForm(data, validation, valid => this.setState({ validation: valid }));
    if (getDetailsValidation) return true;
    return  false;
  };

  handleSaveClick = () => {
    const { data, crudMode } = this.state;
    const isValid = this.getValidation();
    if (isValid) {
      if (crudMode === ACTION.ADD) {
        this.getCreateService(data);
      } else {
        this.getUpdateService(data);
      }
    }
  };
  getCreateService = (data) => {
    this.setState({ actionType: ACTION.DELETE });
      this.createSku(data);
      this.historyPush();
  };
  historyPush = () => {
    setTimeout(() => {
      history.push(`/${SERVICE}`);
    }, 1000);
  };
  getUpdateService = (data) => {
    this.setState({actionType: ACTION.DELETE });
      this.updateSku(data);
      this.historyPush();
  };
  createSku = accumulatedData => {
    const { crudMode } = this.state;
    const apiTransformedData = getApiTransformedData(accumulatedData, crudMode);
    const { createSKUWithBatchList } = this.props;

    createSKUWithBatchList(apiTransformedData)
      .then(response => {
        if (response) {
          const id = response.length > 0 ? response[0].skuInsertion.idSku || null : null;
          this.setState({ snack: { ...snackService.generateSuccessMessage(message.ADD) } }, () =>
            history.push(`/${SERVICE_DETAIL}/${id}`),
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          snack: {
            ...snackService.generateFailureMessage(message.CREATE_ERROR),
          },
        });
      });
  };

  updateSku = accumulatedData => {
    const { crudMode, id } = this.state;
    const { updateSKUWithBatchList } = this.props;
    const apiTransformedData = getApiTransformedData(accumulatedData, crudMode);
    updateSKUWithBatchList(apiTransformedData)
      .then(response => {
        if (response) {
          this.getServiceDetails(id);
          this.setState({
            snack: { ...snackService.generateSuccessMessage(message.UPDATE) },
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          snack: {
            ...snackService.generateFailureMessage(message.UPDATE_ERROR),
          },
        });
      });
  };

  handleCancelClick = () => {
    const { id, serverData } = this.state;
    if (id) {
      this.setState({
        data: { ...serverData },
        cancelClicked: true,
        crudMode: ACTION.READ,
      });
    } else {
      this.setState({
        data: skuDetailsMapper({}),
        cancelClicked: true,
      });
    }
  };
  onCancel = () => {
    const { data } = this.state;
    this.setState({
      data: { ...data },
      actionType: ACTION.NULL,
    });
  };

  updateCrudMode = crudMode => {
    this.setState({ crudMode });
  };

  getTitle = () => {
    const { id, crudMode } = this.state;
    if (id) {
      if (crudMode !== ACTION.READ) return `${crudMode} ${SERVICE_CONFIG.title}`;
      return `${SERVICE_CONFIG.title} `;
    }
    return `${crudMode} ${SERVICE_CONFIG.title}`;
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { crudMode, validation, data, snack, cancelClicked, actionType ,id,buList} = this.state;
    const { serverResponseWaiting } = this.props;
    const title = this.getTitle();
    return (
      <>
        <Loading display={serverResponseWaiting} />
        <View
          handleInputChange={this.handleInputChange}
          crudMode={crudMode}
          enableErrorDisplay={validation.flag}
          title={title}
          data={data}
          updateCrudMode={this.updateCrudMode}
          handleCancelClick={this.handleCancelClick}
          handleSaveClick={this.handleSaveClick}
          cancelFlag={cancelClicked}
          onCancel={this.onCancel}
          actionType={actionType}
          buList={buList}
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </>
    );
  }
}

ServiceDetail.propTypes = propTypes;

ServiceDetail.defaultProps = defaultProps;

export default ServiceDetail;
