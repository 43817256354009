import React, { Component, Fragment } from 'react';
import moment from 'moment/moment';
import DetailView from '../../../common/detail/DetailView';
import PageButtonWrapper from '../../../common/PageButtonWrapper';
import config from './config';
import { checkIfApiCallSuccess, prnApi } from '../../../common/base.api';
import withBaseState from '../../../common/withBaseState';
import { PURCHASE_RETURN_BASE } from '../../../../data/enums/Route';
import { Button, LinearProgress } from '../../../../components/BillingMDC';
import history from '../../../../utils/history';
import numberToWords from '../../../../utils/numberToTextConverter';
import { getPercentage,voucherDecoder } from '../../../../utils/miscellaneous';
import {CLIENT, VAT_PERCENTAGE, GET_BATCH_TH } from '../../../../data/enums/enums';
import { has } from '../../../../utils/hasOwnProperty';
import Header from '../../../../components/PrintComponent/Header';
import TableHeader from '../../../../components/TableHeader/TableHeader';
import BillTotalContent from '../../../../components/PrintComponent/BillTotalContent';
import PageNumber from '../../../../components/PrintComponent/PageNumber';
import { clone } from '../../../../utils/arrayProcessor';
import ReportView from '../../../../components/PrintComponent/ReportView';
import {fixedFloat} from "../../../../utils/conversion";
import InvoiceDetailsStyled from '../../sales/detail/InvoiceDetailsStyled';
import {groupPrintDataSet} from "../../../common/print.service";

const propTypes = {};
const defaultProps = {};

class PRNDetail extends Component {
  getDetail = () => {
    const { match, company ,location} = this.props;
    const { invoiceDetail ,client} = this.state;
    const prnNumber = voucherDecoder(match.params.id);
    const userId = match.params.userId || null;
    this.setState({ loading: true, client: userId ? CLIENT.ROSIA : CLIENT.DBS });
    prnApi.getPrintDetails(prnNumber).then(response => {
      this.setState({ loading: false });
      const { details, ...summary } = { ...response.data };
      let companyInfo = { ...company };
      summary.subTotal = summary.amount - (summary.promotionDiscount || 0);
      summary.taxableAmount = summary.subTotal - (summary.billDiscount || 0) - (summary.tradeDiscount || 0);
      summary.actualTaxableAmount = fixedFloat(summary.taxableAmount - (summary.taxExemptedAmount || 0));
      const data = {
        list: details,
        summary,
      };

      summary.totalInWords = numberToWords(summary.netAmount || 0);
      const billPercent = getPercentage(summary.billDiscount, summary.subTotal);
      const tradePercent = getPercentage(summary.tradeDiscount, summary.subTotal - summary.billDiscount);

      const billSummary = config.billSummaryMapper({
        ...summary,
        billPercent,
        tradePercent,
      });
      const customerDetails = config.customerDetailMapper(summary);
      const dataMiscellaneousList = [
        {
          title: 'PRN Invoice Number',
          value: summary.purchaseReturnInvoiceNumber,
        },
        {
          title: 'GRN Number',
          value: summary.refNumber,
        },
        { title: 'Vendor', value: customerDetails.customerName },
        {
          title: 'Address',
          value: summary.address,
        },
        { title: 'PAN No', value: summary.customer.panNo },
      ];
      const billTotalList = [
        { title: 'Sub Total', value: summary.subTotal },
        {
          title: `Bill Discount${billPercent ? ` (${billPercent}%)` : ''}`,
          value: summary.billDiscount,
        },
        {
          title: `Trade Discount${tradePercent ? ` (${tradePercent}%)` : ''}`,
          value: summary.tradeDiscount,
        },
        { title: 'Taxable Amount', value: summary.actualTaxableAmount },
        { title: 'VAT', value: summary.vat },
      ];
      if (has.call(summary.user || {}, 'name')) {
        invoiceDetail.value = summary.user.name;
      }
      const totalQuantity = details.reduce((acc, curr) => {
        return acc + curr.quantity;
      }, 0);
      if (client === CLIENT.ROSIA) {
        companyInfo = response.data.company || company;
      }
      this.setState(
        {
          date: moment(summary.date).format('DD MMM YYYY'),
          miti: summary.miti,
          billSummary,
          invoiceDetail,
          billTotalList,
          dataMiscellaneousList,
          data,
          totalQuantity,
          company: companyInfo,
        }, () =>{
          location && location.state &&  location.state.printEnable && this.handlePrintClick();
        }
      );
    })  .catch(err => this.setState({ loading: false }));
  };
  setupConfig() {
    const { client } = this.state;
    if (client === CLIENT.ROSIA) {
      document.getElementsByClassName('scrollbar-container')[0].classList.add('visibility-hidden');
      document.getElementsByClassName('nav-bar')[0].classList.add('visibility-hidden');
    }
  }
  groupPrintDataSetNew() {
    const { data} = this.state;
    const { company  } = this.props;
    const printInfoBill = company.printInfo || {};
    const orders = clone(data.list);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  }
  calculateTotal = (detailList, key) => {
    let totalValue = 0;
    detailList.map(item => {
      totalValue += item[key];
    });
    return Number(Number(totalValue).toFixed(2));
  };
  handlePrintClick = () => {
    const { client } = this.state;
    const self = this;
    this.groupPrintDataSetNew();
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.setState({ printButtonClicked: false }, () => {
        if (client === CLIENT.ROSIA) window.close();
      });
    };
  };

  btnWrapper = () => (
    <Fragment>
      <Button
        accent
        className="margin-right-0 cancel-btn modal-btn"
        onClick={() => history.push(`/${PURCHASE_RETURN_BASE}`)}
      >
        Cancel
      </Button>
      <Button
        accent
        className="save-btn modal-btn"
        onClick={() => {
          this.handlePrintClick();
        }}
      >
        Print
      </Button>
    </Fragment>
  );

  constructor(props) {
    super(props);
    this.state = {
      client: CLIENT.DBS,
      id: props.match.params.id || '',
      data: {
        list: [],
        summary: {},
      },
      loading: false,
      billSummary: config.billSummaryMapper({}),
      date: '',
      miti: '',
      // invoiceList: [],
      printButtonClicked: false,
      billTotalList: [
        { title: 'Sub Total', value: 0 },
        { title: 'Bill Discount', value: 0 },
        {
          title: 'Trade Discount',
          value: 0,
        },
        { title: 'VAT', value: 0 },
      ],
      dataMiscellaneousList: [
        { title: 'PRN Invoice Number', value: '' },
        {
          title: 'GRN Number',
          value: '',
        },
        { title: 'Vendor Name', value: '' },
        { title: 'Address', value: '' },
        { title: 'PAN No', value: '' },
      ],
      dataList: [],
      totalQuantity: 0,
      invoiceDetail: { title: 'Prepared By', value: '' },
    };
    this.pageConfig = this.getPageConfig();
  }

  getPageConfig = () => {
    const {
      company: {
        printInfo: { batchEnabled },
      },
    } = this.props;
    const header = [...config.header];
    if (batchEnabled) {
      header.splice(2, 0, GET_BATCH_TH);
      return { ...config, header };
    }
    return config;
  };

  componentDidMount() {
    this.getDetail();
    window.addEventListener('keydown', this.handleKeyDown);
    this.setupConfig();
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }
  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  
  render() {
    const { serverResponseWaiting, company } = this.props;
    const {
      data,
      loading,
      dataList,
      billSummary,
      billTotalList,
      invoiceDetail,
      date,
      miti,
      totalQuantity,
      printButtonClicked,
      dataMiscellaneousList,
    } = this.state;
    return (
      <InvoiceDetailsStyled>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={`sales-invoice srn-detail prn-detail${loading ? 'clickable-false' : ''}`}>
          <div className={printButtonClicked ? 'no-print' : 'display-block'}>
            <DetailView
              display={{
                header: true,
                footer: true,
                summary: true,
              }}
              serverResponseWaiting={serverResponseWaiting}
              headerConfig={{
                company,
                title: config.title.original,
                date: moment(data.summary.documentDate || new Date()).format('DD MMM YYYY'),
              }}
              printInfoBill={company.printInfo}
              pageConfig={this.pageConfig}
              data={data}
            />
            <PageButtonWrapper renderBtn={this.btnWrapper} />
          </div>
          <div className={printButtonClicked ? 'display-block portrait' : 'no-print'}>
            <ReportView
              billTotalList={billTotalList}
              billSummary={billSummary}
              config={config}
              dataList={dataList}
              invoiceDetail={invoiceDetail}
              dataMiscellaneousList={dataMiscellaneousList}
              date={date}
              company={company}
              miti={miti}
              config={config}
              pageConfig={this.pageConfig}
              batchControl
              totalQuantity={totalQuantity}
            />
          </div>
        </div>
      </InvoiceDetailsStyled>
    );
  }
}

PRNDetail.propTypes = propTypes;
PRNDetail.defaultProps = defaultProps;

const PurchaseReturnDetailWithState = withBaseState(PRNDetail);

export default PurchaseReturnDetailWithState;
