import { clone } from '../../../utils/arrayProcessor';
import history from "../../../utils/history";
import {
  ABBREVIATED_INVOICE_DETAILS,
  CONTRA_ENTRY_DETAILS,
  CREDIT_NOTE_DETAILS,
  DEBIT_NOTE_DETAILS,
  GRN_DETAILS, JOURNAL_VOUCHER_DETAILS,
  PRN_DETAILS,
  SERVICE_INVOICE_DETAILS,
  SRN_DETAILS
} from "../../../data/enums/Route";
import {voucherEncoder} from "../../../utils/miscellaneous";
import { JWT } from '../../../environment';
import {TRANSACTION_TYPE,LEDGERS} from "../../../data/enums/enums";
import {checkIfApiCallSuccess, grnApi,ledgerApi} from "../../common/base.api";

const calculateRelativeDiscount = (referenceAmount = 1, referenceDiscount, amount) =>
  (amount * referenceDiscount) / referenceAmount;

const updateDiscountValueOfInvoiceList = (invoiceList, cashDiscountValue, tradeDiscountValue) => {
  const grossInvoiceNetAmount = invoiceList.reduce((acc, item) => acc + item.net_amount, 0);
  return invoiceList.map((invoice) => {
    const bill_discount = calculateRelativeDiscount(grossInvoiceNetAmount, cashDiscountValue, invoice.net_amount);
    const trade_discount = calculateRelativeDiscount(grossInvoiceNetAmount, tradeDiscountValue, invoice.net_amount);
    let taxable_amount = invoice.net_amount - (bill_discount||0) - (trade_discount||0);
    if(!invoice.excisePercent){
      invoice.excisePercent = Number(invoice.excise) * 100/ Number(taxable_amount)
    }
    if(!invoice.excisePercent){
      invoice.excisePercent = 0;
    }
    invoice.excisePercent = JSON.parse(localStorage.getItem('rosiaCompany'))?.enableExcise ? invoice.excisePercent : 0;
    taxable_amount += Number(Number(invoice.net_amount) - Number(bill_discount||0)  - Number(trade_discount||0)) * (invoice.excisePercent||0) /100;

    const vat = Number(Number((invoice.vat_percent / 100) * taxable_amount).toFixed(2)) || 0;
    const tax_exempted_amount = vat === 0 ? taxable_amount - vat : 0;
    const excise =invoice.excisePercent === 0 ? 0:invoice.excise;

    return {
      ...invoice,
      excise,
      bill_discount: bill_discount || 0,
      trade_discount: trade_discount || 0,
      taxable_amount: taxable_amount || 0,
      vat: Number(Number((invoice.vat_percent / 100) * taxable_amount).toFixed(2)) || 0,
      tax_exempted_amount,
    };
  });
};

const route = async (id, transactionType, isVoucherEncoded = false) => {
  id = isVoucherEncoded ? id : voucherEncoder(id)

  switch (parseInt(transactionType)) {

  case TRANSACTION_TYPE.SI:
    if (id.toString().toLowerCase().includes('abi'))
      return window.open(`/${ABBREVIATED_INVOICE_DETAILS}/${id}`, '_blank');
    return window.open(`/${SERVICE_INVOICE_DETAILS}/${id}`, '_blank');

  case TRANSACTION_TYPE.SRN:
    return window.open(`/${SRN_DETAILS}/${id}`, '_blank');

  case TRANSACTION_TYPE.GRN:
    const purchaseId = await fetchPurchaseId(id);
    if (!purchaseId) {
      return false;
    }
    return window.open(`/${GRN_DETAILS}/${purchaseId}`, '_blank');

  case TRANSACTION_TYPE.PR:
    return window.open(`/${PRN_DETAILS}/${id}`, '_blank');

  case TRANSACTION_TYPE.JV:
  case TRANSACTION_TYPE.JV_INWARD:
  case TRANSACTION_TYPE.JV_OUTWARD:
    return window.open(`/${JOURNAL_VOUCHER_DETAILS}/${id}`, '_blank');

  case TRANSACTION_TYPE.CREDIT_NOTE:
    return window.open(`/${CREDIT_NOTE_DETAILS}/${id}`, '_blank');

  case TRANSACTION_TYPE.DEBIT_NOTE:
    return window.open(`/${DEBIT_NOTE_DETAILS}/${id}`, '_blank');

  case TRANSACTION_TYPE.CONTRA_ENTRY:
    return window.open(`/${CONTRA_ENTRY_DETAILS}/${id}`, '_blank');
  }
  return false;
}

const fetchPurchaseId = async (id) => {
  return await grnApi.find(id).then((response) => {
    if (checkIfApiCallSuccess(response)) {
      return response.data.idPurchase;
    }
  }).catch((e) => {
    return false;
  });

}


const onRowItemClick = (data) => {
  if (parseInt(data.transaction_type_id) === TRANSACTION_TYPE.GRN) {
    return route(data.invoice_number || data.bill_no, data.transaction_type_id, true)
  }
  return route(data.bill_no, data.transaction_type_id)
}

const getMinimumDateForReturns = (fiscalYearRange, invoicedDate, invoiceIdPresent) => {
  const fyCloseStatus = fiscalYearRange.previous_fiscal_year_closed;

  //SRN and PRN should after the sales and purcahse date
  if (invoiceIdPresent) {
    return invoicedDate;
  }
  if (!fyCloseStatus) {
    return fiscalYearRange.previous_fiscal_year_start;
  }
  return fiscalYearRange.start_date;
}
const getTypes =(type) =>{
  switch (type) {
    case 'customerVendor':
      return `type=${LEDGERS.CUSTOMER.type},${LEDGERS.VENDOR.type}`;
    case 'vendor':
      return `type=${LEDGERS.VENDOR.type}`;
    case 'isCashBankLedgerTrue':
      return `isCashBankLedger=true`;
    case 'isCashBankLedgerFalse':
      return `isCashBankLedger=false`;
    default:
      return  ``;
  }
}

const getCustomerList =  async(type) => {
  const types =getTypes(type);
  const result = await ledgerApi.getList({},`${types}`);
  return result?.data?.list;
}

const isInt=(n)=>{
  if( Number(n) === n && n % 1 === 0){
    return true
  }
}
const customerfilterOption = async (option, inputValue) => {
  if(inputValue.length > 0) {
    const testCondition=    option?.data?.panNo?.includes(inputValue) || option?.data?.title?.toLowerCase()?.includes(inputValue.toLowerCase());
    return  !!testCondition;
  }

}

const getBackDatedStatus =() =>{
const settings = JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.SETTINGS.NAME));
return settings?.backDated;
}

const getValidationErrorMsg = (err) =>{
switch(err?.error?.code){
case 422:
  return err?.error?.details?.map(a=>a?.message)?.join(', ') || err?.error?.message;
case 500:
  return err?.error?.message || err?.message;
default:
  return err?.error?.message ||  err?.message;
}
}



export { calculateRelativeDiscount, updateDiscountValueOfInvoiceList, route, onRowItemClick ,getMinimumDateForReturns,getCustomerList ,getTypes,customerfilterOption,getBackDatedStatus,getValidationErrorMsg,
   isInt
};
