import { TRANSACTION_NATURE } from '../data/enums/enums';

const clone = (list) => JSON.parse(JSON.stringify(list));

const compareList = (list1, list2) => JSON.stringify(list1) === JSON.stringify(list2);

const getTotal = (list = [], field = '') => {
  let total = 0;
  list.map((item) => (total += item[field]));

  return total;
};

const addNewFieldsInList = (list = [], fieldsObj = { reconcileAmount: 0, type: TRANSACTION_NATURE.PARTIAL }) => {
  return list.map((item) => {
    return { ...fieldsObj, ...item };
  });
};
const extractSingleItemFromList = (list = [], attribute = 'id') => list.map((item) => item[attribute]);

export {
  clone, compareList, addNewFieldsInList, getTotal,extractSingleItemFromList
};
