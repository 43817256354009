import { compose } from 'recompose';

import Receipt from './Receipt';

import apiInterceptor from '../../../api/interceptor';

import { paymentApi } from '../../common/base.api';

const composedReceipt = compose(apiInterceptor({ ...paymentApi }))(Receipt);

export default composedReceipt;
