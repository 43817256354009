import React from 'react';
import {
  Redirect, Route, Switch
} from 'react-router-dom';
import ErrorPage from './components/Error';
import {
  AGEING,
  // STOCK_AGEING,
  BUSINESS_GROUPS,
  COMPANY,
  CUSTOMER_LEDGER_BASE,
  CUSTOMER_LEDGER_DETAILS,
  AGGREGATE_CUSTOMER_LEDGER_BASE,
  AGGREGATE_CUSTOMER_LEDGER_DETAILS,
  DATABASE_LOG,
  ENTRY_LOG,
  ERR_404,
  ERR_BASE,
  GRN_CREATE,
  GRN_DETAILS,
  INVENTORY_BASE,
  JOURNAL_VOUCHER_BASE,
  JOURNAL_VOUCHER_CREATE,
  JOURNAL_VOUCHER_DETAILS,
  LEDGERS,
  OPENING_BALANCE,
  OUTSTANDING,
  SALES_PERSON_OUTSTANDING,
  PRN_CREATE,
  PRN_DETAILS,
  PURCHASE_RETURN_BASE,
  PURCHASE_RETURN_VAT,
  PURCHASE_VAT,
  RECEIPT,
  RECONCILE,
  PAYMENT,
  ROSIA_SALES_PRINT,
  ROSIA_SRN_PRINT,
  SALES_CREATE,
  SALES_INVOICE_BASE,
  SALES_MATERIALIZED_VIEW,
  SALES_DETAILS,
  SERVICE_INVOICE_BASE,
  SERVICE_INVOICE_CREATE,
  SERVICE_INVOICE_DETAILS,
  SALES_PRINT,
  SALES_RETURN_BASE,
  SALES_RETURN_VAT,
  SALES_VAT,
  // SKU,
  // SKU_CREATE,
  // SKU_DETAILS,
  SRN_CREATE,
  SRN_DETAILS,
  STOCK_JOURNAL_BASE,
  STOCK_JOURNAL_CREATE,
  STOCK_JOURNAL_DETAILS,
  STOCK_LEDGER_BASE,
  STOCK_LEDGER_DETAILS,
  STOCK_VALUATION_REPORT,
  USERS,
  VAT,
  DAY_BOOK_SUMMARY,
  AGENT,
  ACCOUNT_GROUP,
  FISCAL_YEAR_MIGRATION,
  TRIAL_BALANCE,
  PROFIT_LOSS,
  BALANCE_SHEET,
  ABBREVIATED_INVOICE_BASE,
  ABBREVIATED_INVOICE_DETAILS,
  ABBREVIATED_INVOICE_CREATE,
  CONTRA_ENTRY_BASE,
  CONTRA_ENTRY_DETAILS,
  CONTRA_ENTRY_CREATE,
  TAG,
  ROSIA_ABBREVIATED_INVOICE_PRINT,
  ROSIA_PURCHASE_RETURN_PRINT,
  DEBIT_NOTE,
  DEBIT_NOTE_CREATE,
  DEBIT_NOTE_DETAILS,
  CREDIT_NOTE,
  CREDIT_NOTE_CREATE,
  CREDIT_NOTE_DETAILS,
  SETTINGS,
  // PICK_LIST,
  PDC,
  PROMOTION,
  PROMOTION_CREATE,
  PROMOTION_DETAILS,
  USER_GROUP,
  NEW_SALES_VAT,
  NEW_PURCHASE_VAT,
  NEW_PURCHASE_RETURN_VAT,
  NEW_SALES_RETURN_VAT,
  SALES_REPORT,
  SRN_REPORT,
  PRN_REPORT,
  GRN_REPORT,
  VAT_SUMMARY_REPORT,
  DAMAGE_EXPIRY_VALUATION,
  LEDGER_WISE,
  TAG_WISE,
  SERVICE,
  SERVICE_CREATE,
  SERVICE_DETAIL,
  TAG_WISE_DETAILS,
  NETSALES,
  ARCHIVED_EMAIL,
  ARCHIVED_EMAIL_DETAILS,
  EMAIL_FREQUENCY,
  EMAIL_FREQUENCY_CREATE,
} from './data/enums/Route';

import {
  // DatabaseLog,
  PrintSalesInvoice,
  // StockJournalDetail,
  // VatReport,
} from './v1/views/index';
// import SalesInvoiceDetail from './v1/views/SalesInvoice/SalesInvoiceDetail';

import {
  AgeingReport,
  StockAgeingReport,
  BusinessGroups,
  Company,
  CreateGRN,
  CreateJournalVoucher,
  CreatePurchaseReturn,
  CreateSalesInvoice,
  CreateSRN,
  // CreateStockJournal,
  Customer as CustomerLedger,
  EntryLog,
  GRN,
  GRNDetails,
  JournalVoucherDetail,
  JournalVoucherReport,
  LedgerReport,
  AggregateLedgerReport,
  LedgerReportDetail,
  // OpeningBalance,
  OutstandingReport,
  NetSalesReport,
  SalesPersonOutstandingReport,
  PurchaseReturn,
  PurchaseReturnDetails,
  PurchaseVatReport,
  Receipt,
  Reconcile,
  Payment,
  // SalesInvoice,
  SalesInvoiceDetail,
  SalesMaterializedView,
  SalesReturn,
  SalesVatReport,
  NewSalesVatReport,
  SKU as SkuView,
  SkuDetail,
  SRNDetails,
  SRNVatReport,
  // StockJournal,
  // StockLedger,
  // StockLedgerDetail,
  // StockValuationReport,
  User,
  PurchaseReturnVatReport,
  VatReport,
  AggregateDetails,
  SalesWithoutPrintCount,
  DayBookSummary,
  Agent,
  AccountGroup,
  TrialBalance,
  ProfitLoss,
  BalanceSheet,
  FiscalYearMigration,
  AbbreviatedInvoice,
  CreateAbbreviatedInvoice,
  AbbreviateInvoiceDetail,
  CreateContraEntry,
  ContraEntryDetail,
  ContraEntry,
  Tag,
  CompanySettings,
  DebitNote,
  CreateDebitNote,
  DebitNoteDetail,
  CreditNote,
  CreateCreditNote,
  CreditNoteDetail,
  PickList,
  PostDatedCheque,
  Promotion,
  PromotionDetail,
  UserGroup,
  UserGroupDetails,
  NewPurchaseVatReport,
  NewPurchaseReturnVatReport,
  NewSRNVatReport,
  SalesReport,
  SRNReport,
  PRNReport,
  GRNReport,
  VATSummary,
  DamageExpiryValuation,
  LedgerWise,
  TagWise,
  DataBaseLog,
  // StockJournalDetails,
  Service,
  ServiceDetail,
  ServiceInvoice,
  CreateServiceInvoice,
  ServiceInvoiceDetail,
  TagWiseDetail,
  LedgerWiseDetail,
  ArchivedEmail,
  ArchivedEmailDetails,
  EmailFrequency,
  EmailFrequencyCreate,
} from './views';

const routes = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}${COMPANY}`} component={Company} />
    <Route exact path={`${match.url}${SERVICE_INVOICE_BASE}`} component={ServiceInvoice} />


    <Route exact path={`${match.url}${SALES_PRINT}`} component={PrintSalesInvoice} />
    <Route exact path={`${match.url}${SERVICE_INVOICE_CREATE}`} component={CreateServiceInvoice} />
    <Route exact path={`${match.url}${SERVICE_INVOICE_DETAILS}/:id`} component={ServiceInvoiceDetail} />

    <Route exact path={`${match.url}${SALES_RETURN_BASE}`} component={SalesReturn} />


    <Route exact path={`${match.url}${SRN_CREATE}`} component={CreateSRN} />
    <Route exact path={`${match.url}${SRN_DETAILS}/:id`} component={SRNDetails} />
    <Route
      exact
      path={`${match.url}${ROSIA_ABBREVIATED_INVOICE_PRINT}/:invoiceHash/:id/:userId/:firstCopy`}
      component={AbbreviateInvoiceDetail}
    />
    <Route
      exact
      path={`${match.url}${ ROSIA_PURCHASE_RETURN_PRINT}/:invoiceHash/:id/:userId`}
      component={PurchaseReturnDetails}
    />
    <Route
      exact
      path={`${match.url}${ ROSIA_SALES_PRINT}/:invoiceHash/:id/:userId/:firstCopy`}
      component={ServiceInvoiceDetail}
    />

    <Route exact path={`${match.url}${SALES_MATERIALIZED_VIEW}`} component={SalesMaterializedView} />
    <Route exact path={`${match.url}${ENTRY_LOG}`} component={EntryLog} />

    <Route exact path={`${match.url}${LEDGERS}`} component={CustomerLedger} />

    <Route exact path={`${match.url}${SETTINGS}`} component={CompanySettings} />
    <Route exact path={`${match.url}${PROMOTION_CREATE}`} component={PromotionDetail} />
    <Route exact path={`${match.url}${SERVICE}`} component={Service} />
    <Route exact path={`${match.url}${SERVICE_CREATE}`} component={ServiceDetail} />
    <Route exact path={`${match.url}${SERVICE_DETAIL}/:id`} component={ServiceDetail} />

    <Route
      exact
      path={`${match.url}${PROMOTION_DETAILS}/:id`}
      component={PromotionDetail}
    />
    <Route exact path={`${match.url}${USERS}`} component={User} />
    <Route exact path={`${match.url}${AGENT}`} component={Agent} />
    <Route exact path={`${match.url}${LEDGER_WISE}`} component={LedgerWise} />
    <Route exact path={`${match.url}${TAG_WISE}`} component={TagWise} />
    <Route exact path={`${match.url}${TAG_WISE_DETAILS}/:id`} component={TagWiseDetail} />

    <Route exact path={`${match.url}${VAT}`} component={VatReport} />
    <Route exact path={`${match.url}${SALES_VAT}`} component={SalesVatReport} />
    <Route exact path={`${match.url}${NEW_SALES_VAT}`} component={NewSalesVatReport} />
    <Route
      exact
      path={`${match.url}${NEW_PURCHASE_VAT}`}
      component={NewPurchaseVatReport}
    />
    <Route
      exact
      path={`${match.url}${NEW_SALES_RETURN_VAT}`}
      component={NewSRNVatReport}
    />
    <Route
      exact
      path={`${match.url}${NEW_PURCHASE_RETURN_VAT}`}
      component={NewPurchaseReturnVatReport}
    />

    <Route exact path={`${match.url}${DATABASE_LOG}`} component={DataBaseLog} />

    <Route
      exact
      path={`${match.url}${JOURNAL_VOUCHER_BASE}`}
      component={JournalVoucherReport}
    />
    <Route
      exact
      path={`${match.url}${JOURNAL_VOUCHER_CREATE}`}
      component={CreateJournalVoucher}
    />
    <Route
      exact
      path={`${match.url}${JOURNAL_VOUCHER_DETAILS}/:id`}
      component={JournalVoucherDetail}
    />
    <Route exact path={`${match.url}${AGEING}`} component={AgeingReport} />

    <Route
      exact
      path={`${match.url}${OUTSTANDING}`}
      component={OutstandingReport}
    />
    <Route
      exact
      path={`${match.url}${NETSALES}`}
      component={NetSalesReport}
    />
    <Route
      exact
      path={`${match.url}${SALES_PERSON_OUTSTANDING}`}
      component={SalesPersonOutstandingReport}
    />
    <Route
      exact
      path={`${match.url}${CUSTOMER_LEDGER_BASE}`}
      component={LedgerReport}
    />
    <Route
      exact
      path={`${match.url}${CUSTOMER_LEDGER_DETAILS}/:id`}
      component={LedgerReportDetail}
    />
    <Route
      exact
      path={`${match.url}${AGGREGATE_CUSTOMER_LEDGER_BASE}`}
      component={AggregateLedgerReport}
    />
    <Route
      exact
      path={`${match.url}${AGGREGATE_CUSTOMER_LEDGER_DETAILS}/:id`}
      component={AggregateDetails}
    />
    <Route
      exact
      path={`${match.url}${DAY_BOOK_SUMMARY}`}
      component={DayBookSummary}
    />

    <Route exact path={`${match.url}${RECEIPT}`} component={Receipt} />
    <Route exact path={`${match.url}${RECONCILE}/:id`} component={Reconcile} />
    <Route exact path={`${match.url}${PAYMENT}`} component={Payment} />
    <Route exact path={`${match.url}${PDC}`} component={PostDatedCheque} />
    <Route exact path={`${match.url}${ACCOUNT_GROUP}`} component={AccountGroup} />
    <Route exact path={`${match.url}${FISCAL_YEAR_MIGRATION}`} component={FiscalYearMigration} />
    <Route exact path={`${match.url}${TRIAL_BALANCE}`} component={TrialBalance} />
    <Route exact path={`${match.url}${PROFIT_LOSS}`} component={ProfitLoss} />
    <Route exact path={`${match.url}${BALANCE_SHEET}`} component={BalanceSheet} />
    <Route exact path={`${match.url}${CONTRA_ENTRY_BASE}`} component={ContraEntry} />
    <Route exact path={`${match.url}${CONTRA_ENTRY_CREATE}`} component={CreateContraEntry} />
    <Route exact path={`${match.url}${CONTRA_ENTRY_DETAILS}/:id`} component={ContraEntryDetail} />

    <Route exact path={`${match.url}${TAG}`} component={Tag} />

    <Route exact path={`${match.url}${DEBIT_NOTE}`} component={DebitNote} />

    <Route exact path={`${match.url}${DEBIT_NOTE_CREATE}`} component={CreateDebitNote} />

    <Route
      exact
      path={`${match.url}${DEBIT_NOTE_DETAILS}/:id`}
      component={DebitNoteDetail}
    />
    <Route
      exact
      path={`${match.url}${CREDIT_NOTE}`}
      component={CreditNote}
    />

    <Route
      exact
      path={`${match.url}${CREDIT_NOTE_CREATE}`}
      component={CreateCreditNote}
    />

    <Route
      exact
      path={`${match.url}${CREDIT_NOTE_DETAILS}/:id`}
      component={CreditNoteDetail}
    />

    <Route
      exact
      path={`${match.url}${USER_GROUP}`}
      component={UserGroup}
    />
    <Route
      exact
      path={`${match.url}${USER_GROUP}/:id`}
      component={UserGroupDetails}
    />

    <Route
      exact
      path={`${match.url}${PROMOTION}`}
      component={Promotion}
    />

    {/* <Route exact path={`${match.url}${PICK_LIST}`} component={PickList} /> */}
    <Route exact path={`${match.url}${SALES_REPORT}`} component={SalesReport} />
    <Route exact path={`${match.url}${PRN_REPORT}`} component={PRNReport} />
    <Route exact path={`${match.url}${SRN_REPORT}`} component={SRNReport} />
    <Route exact path={`${match.url}${GRN_REPORT}`} component={GRNReport} />
    <Route exact path={`${match.url}${VAT_SUMMARY_REPORT}`} component={VATSummary} />
    {/* <Route
      exact
      path={`${match.url}${PICK_LIST}`}
      component={PickList}
    /> */}
    <Route
      exact
      path={`${match.url}${SALES_REPORT}`}
      component={SalesReport}
    />
    <Route
      exact
      path={`${match.url}${PRN_REPORT}`}
      component={PRNReport}
    />
    <Route
      exact
      path={`${match.url}${SRN_REPORT}`}
      component={SRNReport}
    />
    <Route
      exact
      path={`${match.url}${GRN_REPORT}`}
      component={GRNReport}
    />
    <Route
      exact
      path={`${match.url}${VAT_SUMMARY_REPORT}`}
      component={VATSummary}
    />

    <Route
      exact
      path={`${match.url}${DAMAGE_EXPIRY_VALUATION}`}
      component={DamageExpiryValuation}
    />
    <Route exact path={`${match.url}${ARCHIVED_EMAIL}`} component={ArchivedEmail} />
    <Route exact path={`${match.url}${ARCHIVED_EMAIL_DETAILS}/:id`} component={ArchivedEmailDetails} />
    <Route exact path={`${match.url}${EMAIL_FREQUENCY}`} component={EmailFrequency} />
    <Route exact path={`${match.url}${EMAIL_FREQUENCY}/:id`} component={EmailFrequencyCreate} />
    <Route exact path={`${match.url}${EMAIL_FREQUENCY_CREATE}`} component={EmailFrequencyCreate} />


    <Route exact path={`${match.url}${ERR_BASE}/:id`} component={ErrorPage} />
    <Route
      render={() => <Redirect to={`${match.url}${ERR_BASE}/${ERR_404}`} />}
    />
  </Switch>
);

export default routes;
