import React from 'react';
import { fixedFloatAndCommas, roundAndCommas } from '../../../../utils/conversion';
import { formatToReadableDate } from '../../../../utils/filterUtil';

const LedgerDetailBody = ({ dataList,remainingLines=[], onRowClick }) => {
  return (
    <tbody>
    {dataList.map((data, index) => {
      if (index === dataList.length - 1) {
        return (
          <tr className='pagination-wrapper'>
            <td className='left-align font-bold document-date'>
              {data.documentDate ? formatToReadableDate(data.documentDate) : null}
            </td>
            <td
              className='left-align created-date print_display_none'>{data.date ? formatToReadableDate(data.date) : null}</td>
            <td className='left-align font-bold miti'>{data.mitiTitle}</td>
            <td className='left-align font-bold voucher-num link-voucher'
                onClick={() => onRowClick(data)}>{data.voucherNumber}</td>
            <td className='left-align font-bold narration'>{data.narration}</td>
            <td className='right-align font-bold'>{data.debit ? fixedFloatAndCommas(data.debit) : 0}</td>
            <td className='right-align font-bold'>{data.credit ? fixedFloatAndCommas(data.credit) : 0}</td>
            <td className='right-align font-bold'>{data.balance ? roundAndCommas(Math.abs(data.balance)) : 0}</td>
            <td className='right-align font-bold'>{data.balance > 0 ? ' Dr' : ' Cr'}</td>
          </tr>
        );
      }

      return (
        <tr>
          <td className='left-align document-date'>
            {data.documentDate ? formatToReadableDate(data.documentDate) : null}
          </td>
          <td
            className='left-align created-date print_display_none'>{data.date ? formatToReadableDate(data.date) : null}</td>
          <td className='left-align miti'>{data.mitiTitle}</td>
          <td className='left-align voucher-num link-voucher' onClick={() => onRowClick(data)}>{data.voucherNumber}</td>
          <td className='left-align narration'>{data.narration}</td>
          <td className='right-align'>{data.debit ? fixedFloatAndCommas(data.debit) : 0}</td>
          <td className='right-align'>{data.credit ? fixedFloatAndCommas(data.credit) : 0}</td>
          <td className='right-align'>{data.balance ? roundAndCommas(Math.abs(data.balance)) : 0}</td>
          <td className='right-align'>{data.balance > 0 ? ' Dr' : ' Cr'}</td>
        </tr>
      );
    })}
    {remainingLines && remainingLines.length
      ? remainingLines.map((i) => (
        <tr className='blank-tr'>
          <td></td>
          <td colSpan='14' className=''></td>
        </tr>
      ))
      : null}
    </tbody>
  );
};

const ledgerReportDetailConfig = [
  {
    id: 1,
    label: 'date',
    title: 'Date',
    className: 'cursor-pointer document-date',
    sortable: false,
  },
  {
    id: 2,
    label: 'createdAt',
    title: 'Created Date',
    className: 'cursor-pointer created-date print_display_none',
    sortable: false,
  },
  {
    id: 3,
    label: 'mitiTitle',
    title: 'Miti',
    className: 'cursor-pointer miti',
    sortable: false,
  },
  {
    id: 4,
    label: 'voucherNumber',
    title: 'Voucher Number',
    className: 'left-align voucher-no',
    sortable: false,
  },
  {
    id: 5,
    label: 'narration',
    title: 'Narration',
    className: 'left-align narration',
    sortable: false,
  },
  {
    id: 6,
    label: 'debit',
    title: 'Debit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 7,
    label: 'credit',
    title: 'Credit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 8,
    label: 'balance',
    title: 'Balance',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 9,
    label: 'dr',
    title: '',
    className: 'right-align',
    sortable: false,
  },
];
const lengthyAttributes = ['narration',];
const printEnums = {
  withoutFooterLimit: 37,
  withFooterLimit: 36,
  narration: 25,
  lengthyAttributes,
};

const config = {
  header: ledgerReportDetailConfig,
  getTableBody: LedgerDetailBody,
  printEnums,
  uniqueIdGetter: (i) => i.voucherNumber
};

export default config;
