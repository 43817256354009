import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const SalesVatReportTableBody = ({ data }) => {
  return (
    <tr className='cursor-pointer'>
      {/* <td className="left-align">{moment(data.date).format('ll')}</td>*/}
      <td className='left-align'>{data.miti_title}</td>
      <td className='left-align'>{data.bill_no}</td>
      <td className='left-align'>{data.ledger_name}</td>
      <td className='left-align'>{data.pan_no}</td>
      <td className='right-align'>{data.total_sales ? fixedFloatAndCommas(data.total_sales) : data.total_sales}</td>
      <td className='right-align'>
        {data.tax_exempted_sales ? fixedFloatAndCommas(data.tax_exempted_sales) : data.tax_exempted_sales}
      </td>
      <td className='right-align'>{data.export_sales ? fixedFloatAndCommas(data.export_sales) : data.export_sales}</td>
      <td className='right-align'>{data.discount ? fixedFloatAndCommas(data.discount) : data.discount}</td>
      <td className='right-align'>
        {data.taxable_sales_amount ? fixedFloatAndCommas(data.taxable_sales_amount) : data.taxable_sales_amount}
      </td>
      <td className='right-align'>
        {data.taxable_sales_vat ? fixedFloatAndCommas(data.taxable_sales_vat) : data.taxable_sales_vat}
      </td>
    </tr>
  );
};

SalesVatReportTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const salesVatReportConfig = {
  headerDetails: [
    /* {id: 1, label: 'date', title: 'Date', className: 'cursor-pointer left-align', sortable: true},*/
    { id: 2, label: 'miti_title', title: 'Miti', className: 'cursor-pointer left-align', sortable: true },
    { id: 3, label: 'bill_no', title: 'Bill Number', className: 'cursor-pointer left-align', sortable: true },
    { id: 4, label: 'ledger_name', title: 'Buyers Name', className: 'cursor-pointer left-align', sortable: true },
    { id: 5, label: 'pan_no', title: 'Buyers PAN No.', className: 'cursor-pointer left-align', sortable: true },
    { id: 6, label: 'total_sales', title: 'Total Sales', className: 'cursor-pointer right-align', sortable: true },
    {
      id: 7,
      label: 'tax_exempted_sales',
      title: 'Tax Exempted Sale',
      className: 'cursor-pointer right-align',
      sortable: false,
    },
    { id: 8, label: 'export_sales', title: 'Export Sales', className: 'cursor-pointer right-align', sortable: true },
    { id: 9, label: 'discount', title: 'Discount', className: 'cursor-pointer right-align', sortable: true },
    { id: 10, label: 'taxable_sales_amount', title: 'Amount', className: 'cursor-pointer right-align', sortable: true },
    { id: 11, label: 'taxable_sales_vat', title: 'VAT', className: 'cursor-pointer right-align', sortable: true },
  ],
  upperHeaderDetails: [
    { id: 1, label: 'invoice', title: 'Invoice', className: 'table-cell center-align', sortable: false },
    { id: 2, label: 'taxableSales', title: 'Taxable Sales', className: 'table-2-cell center-align', sortable: false },
  ],
};

const downloadFormat = [
  { id: 1, value: 'Download Sales Book PDF' },
  { id: 2, value: 'Download Sales Book CSV' },
  { id: 3, value: 'Download Sales Book XLS' },
  { id: 4, value: 'Download Sales Book XML' },
  { id: 5, value: 'Download Net Sales Vat Report CSV' },
];

export {
  downloadFormat, salesVatReportConfig, SalesVatReportTableBody
};
