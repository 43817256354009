import React from 'react';
import Icon from 'react-mdc-web/lib/Icon/Icon';
import { paymentTypes } from '../../../data/enums/enums';
import { formatToReadableDate } from '../../../utils/filterUtil';
import { isObjectEmpty } from '../../../utils/objectProcessor';
import { VALIDATION_TYPES } from '../../common/validation';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const title = 'Receipt';
const getReceipt = (data = {}) => {
  const receipt = {
    amount: data.amount || 0,
    from_ledger: data.from_ledger || '',
    document_date: isObjectEmpty(data) ? new Date() : data.document_date,
    narration: data.narration || '',
    reference_date: isObjectEmpty(data) ? new Date() : data.reference_date,
    reference_number: data.reference_number || '',
    payment_type: data.payment_type || '',
    reconciled: false,
    to_ledger: data.to_ledger || '',
    tagid: data.tagid || '',
    period_id: data.period_id,
    id: data.id,
    invoice_number: data.invoice_number,
  };
  return receipt;

};
const RECEIPT_TAB ={
  PENDING:0,
  RECONCILE:1,
}

const getApiTransformedData = (data) => {
  const transformedReceipt = {
    amount: data.amount,
    from_ledger: data.from_ledger,
    document_date: data.document_date,
    narration: data.narration,
    reference_date: data.reference_date,
    reference_number: data.reference_number,
    transaction_type: 0, // for receipt transaction_type is 0
    payment_type: data.payment_type,
    to_ledger: data.to_ledger,
    tagid: data.tagid,
    invoice_number: data.invoice_number,
    reconciled: data.reconciled === false ? 0 : 1,
    period_id: data.period_id,
  };
  return transformedReceipt;
};
const getApiTransformedDataReconcile =(data) => {
  const transformedPaymentReconcile ={
    amount: data.amount || 0,
    from_ledger:data.from_ledger || 0,
    to_ledger:data.to_ledger || 0,
    narration:data.narration || '',
    tagid:data.tagid || 0,
    document_date:data.document_date || '',
  }
  return transformedPaymentReconcile ;
}

const labelMappings = {
  id: 'id',
  invoice_number: 'invoice_number',
  document_date: 'document_date',
  mitiTitle: 'mitiTitle',
  tagid: 'tagid',
  reference_number: 'reference_number',
  customer: 'customer',
  payment_type: 'payment_type',
  title: 'title',
  amount: 'amount',
  narration: 'narration',
  date: 'date',
  deleteIcon: 'deleteIcon',
};

const updateApiTransformedData = (data) => {
  const transformedReceipt = {
    ...getApiTransformedData(data),
  };
  return transformedReceipt;
};
const updateApiTransformedDataReconcile = (data) => {
  const transformedReceipt = {
    ...getApiTransformedDataReconcile(data),
  };
  return transformedReceipt;
};

const formValidationFieldList = [
  { title: 'narration' },
  { title: 'to_ledger' },
  { title: 'from_ledger' },
  { title: 'amount', type: VALIDATION_TYPES.ZERO_ERROR },
  { title: 'payment_type' },
];

const chequeValidationFieldList = [...formValidationFieldList, { title: 'reference_number' }];

const receiptConfig = [
  { id: 2, label: labelMappings.document_date, title: 'Document Date', className: 'document-date' },
  { id: 3, label: labelMappings.mitiTitle, title: 'Received Miti', className: 'miti' },
  { id: 4, label: labelMappings.reference_number, title: 'Ref. Number', className: 'reference-num' },
  { id: 5, label: labelMappings.customer, title: 'Customer Ledger', className: 'customer' },
  { id: 6, label: labelMappings.payment_type, title: 'Payment Type', className: 'payment-type' },
  {
    id: 7,
    label: 'tagid',
    title: 'Tag',
    className: 'cursor-pointer tag print_display_none',
    sortable: true,
  },
  { id: 9, label: labelMappings.amount, title: 'Amount Received', className: 'right-align amount' },
  { id: 10, label: labelMappings.narration, title: 'Narration', className: 'narration' },
  { id: 11, label: labelMappings.date, className: 'print_display_none', title: 'Created Date' },
];

const tabBasedHeaderConfig = {
  [0]: [
    { id: 1, label: labelMappings.id, title: 'Id', className: 'id' },
    ...receiptConfig,
    { id: 11, label: labelMappings.deleteIcon, title: '', className: 'print_display_none' },
  ],
  [1]: [
    { id: 1, label: labelMappings.invoice_number, title: 'Voucher Number', className: 'invoice-num' },
    ...receiptConfig,
  ],
};

const getTableTd = (data, labelConfig, deleteHandler,tagList) => ({
  id: (
    <td key={`${labelConfig.id}-name`} className='left-align'>
      {data[labelConfig.label]}
    </td>
  ),
  invoice_number: (
    <td key={`${labelConfig.id}-name`} className='left-align invoice-num'>
      {data[labelConfig.label]}
    </td>
  ),
  document_date: (
    <td key={`${labelConfig.id}-parent-name`} className='left-align document-date'>
      {formatToReadableDate(data.document_date)}
    </td>
  ),
  mitiTitle: (
    <td key={`${labelConfig.id}-coverage`} className='left-align miti'>
      {data[labelConfig.label]}
    </td>
  ),
  reference_number: (
    <td key={`${labelConfig.id}-active-coverage`} className='left-align reference-num'>
      {data[labelConfig.label]}
    </td>
  ),
  customer: (
    <td key={`${labelConfig.id}-active-coverage`} className='left-align customer'>
      {data[labelConfig.label]}
    </td>
  ),
  payment_type: (
    <td key={`${labelConfig.id}-brand-coverage`} className='left-align payment-type'>
      {data.payment_type ? paymentTypes.filter((list) => list.id === data.payment_type)[0].title : ''}
    </td>
  ),
  tagid: (
    <td key={`${labelConfig.id}-tagid`} className='left-align payment-type'>
      {data?.tagid ? tagList?.filter((list) => list?.idTags === data?.tagid)[0]?.title : ''}
    </td>
  ),
  title: (
    <td key={`${labelConfig.id}-brand-coverage`} className='left-align print_display_none tag'>
      {data[labelConfig.label]}
    </td>
  ),
  amount: (
    <td key={`${labelConfig.id}-brand-coverage`} className='right-align amount'>
      {fixedFloatAndCommas(data[labelConfig.label])}
    </td>
  ),
  narration: (
    <td key={`${labelConfig.id}-brand-coverage`} className='left-align narration'>
      {data[labelConfig.label]}
    </td>
  ),
  date: (
    <td key={`${labelConfig.id}-brand-coverage`} className='left-align print_display_none'>
      {formatToReadableDate(data.date)}
    </td>
  ),
  deleteIcon: (
    <td className='right-align print_display_none' onClick={(e) => e.stopPropagation()}>
      <Icon name='delete' onClick={() => deleteHandler(data)} />
    </td>
  ),
});

const returnTableBody = (data, labelConfig, deleteHandler = [],tagList) =>
  labelConfig.map((item) => {
    const tableRow = getTableTd(data, item, deleteHandler,tagList);
    return tableRow[item.label];
  });

const ReceiptBody = ({ dataList, onRowClick, viewType, deleteHandler ,remainingLines=[] ,tagList }) => {
  return (
    <tbody>
      {dataList.map((data, index) => (
        <tr key={data.id} onClick={() => onRowClick(data, index)} className={`cursor-pointer ${index === dataList.length - 1 && 'last-tr'}`}>
          {returnTableBody(data, tabBasedHeaderConfig[viewType], deleteHandler,tagList)}
        </tr>
      ))}
      {remainingLines && remainingLines.length
        ? remainingLines.map((i) => (
          <tr className='blank-tr'>
            <td></td>
            <td colSpan ='14' className=''></td>
          </tr>
        ))
        : null}
    </tbody>
  )
};

const PAYMENT_TABS = {
  PENDING: 'pending',
  RECONCILED: 'reconciled',
};

const tabMapper = {
  0: PAYMENT_TABS.PENDING,
  1: PAYMENT_TABS.RECONCILED,
};
const lengthyAttributes = ['customer','narration'];
const printEnums = {
  withoutFooterLimit: 40,
  withFooterLimit: 40,
  customer: 25,
  narration: 25,
  lengthyAttributes,
};

const config = {
  tabBasedHeaderConfig,
  getTableBody: ReceiptBody,
  getDetail: getReceipt,
  getApiTransformedData,
  formValidationFieldList,
  PAYMENT_TABS,
  tabMapper,
  title,
  updateApiTransformedData,
  updateApiTransformedDataReconcile,
  chequeValidationFieldList,
  printEnums,
  RECEIPT_TAB,
  uniqueIdGetter: (i) => i.invoice_number,
};

export default config;
