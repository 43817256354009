import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
} from 'react-mdc-web';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { RATE_WITH_VAT_FACTOR } from '../../../data/enums/enums';

class SalesInvoiceDetailTableBody extends Component {
  render() {
    const { data, index, printInfoBill, isAbbreviatedInvoice } = this.props;
    return (
      <tr className={data?.requiredLines == 2 ?'two': data?.requiredLines ==3 ? 'three':'one'}>
        <td className='srn'>{data.index ? data.index + 1 : index + 1}</td>
        <td className='hide-overflow-text sku service'><p>{data.sku}</p></td>
        <td className='hide-overflow-text description'><p>{data?.particulars}</p> </td>
        <td className='right-align quantity'>{data.quantity}</td>
        <td className='right-align rate'>
          {isAbbreviatedInvoice
            ? fixedFloatAndCommas(data.rate * (1 + data.vatPercent / 100))
            : fixedFloatAndCommas(data.rate)}
        </td>
        <td className='right-align amount font-bold'>{fixedFloatAndCommas(data.amount)}</td>
      </tr>
    );
  }
}

SalesInvoiceDetailTableBody.defaultProps = { enableUpdate: true, };

SalesInvoiceDetailTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDeleteIconClick: PropTypes.func,
  handleEditIconClick: PropTypes.func,
  enableUpdate: PropTypes.bool,
};

const salesInvoiceDetailConfig = {
  headerDetails: [
    { id: 1, label: 'test', title: 'SN', className: null, sortable: false },
    { id: 2, label: 'sku', title: 'SKU', className: null, sortable: false },
    {
      id: 3,
      label: 'quantity',
      title: 'Quantity',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 4,
      label: 'rate',
      title: 'Rate',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 5,
      label: 'amount',
      title: 'Amount',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 6,
      label: 'discount',
      title: 'Discount',
      className: ' right-align',
      sortable: false,
    },
    {
      id: 7,
      label: 'net_amount',
      title: 'Net Amount',
      className: 'right-align',
      sortable: false,
    },
  ],
};

export { salesInvoiceDetailConfig, SalesInvoiceDetailTableBody };
