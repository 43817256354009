import { compose } from 'recompose';

import Customer from './Customer';

import apiInterceptor from '../../../api/interceptor';

import { ledgerApi } from '../../common/base.api';

const composedCustomer = compose(apiInterceptor({ ...ledgerApi }))(Customer);

export default composedCustomer;
