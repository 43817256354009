import { PAN_NO_VALID_DIGIT, PASSWORD_RULE,EMAIL_RULE } from '../../data/enums/enums';

const VALIDATION_TYPES = {
  PAN_NO: 'PAN NO',
  ZERO_ERROR: 'ZERO ERROR',
  ZERO_VALID: 'ZERO_VALID',
  NULL_ERROR: 'NULL ERROR',
  PASSWORD_RULE: 'PASSWORD RULE',
  EMAIL_RULE:'EMAIL RULE',
};

const validationCondition = (type, value) => {
  switch (type) {
    case VALIDATION_TYPES.PAN_NO:
      if (value && value.toString().length > 0) {
        if (value.toString().length === PAN_NO_VALID_DIGIT) return true;

      return false;
    }

    return true;
  case VALIDATION_TYPES.ZERO_ERROR:
    if (value !== 0 && value !== null && typeof value !== 'undefined') return true;

    return false;
  case VALIDATION_TYPES.ZERO_VALID:
    if (value !== '' && value !== null && typeof value !== 'undefined') return true;

    return false;
  case VALIDATION_TYPES.PASSWORD_RULE:
    if (value && value.match(PASSWORD_RULE)) return true;

    return false;
    case VALIDATION_TYPES.EMAIL_RULE:
      if (value && value.match(EMAIL_RULE)) return true;
  
      return false;
  case VALIDATION_TYPES.NULL_ERROR:
    if (value !== '' && typeof value !== 'undefined' && value !== null) return true;
    return false;

  default:
    return !!value;
  }
};

/**
 *
 * @param formObject: date
 * @param config: validationConfiguration
 * @param stateSetter
 * @param validationCondition
 * @returns {boolean}
 */

// validation: flag, field,
// outside Page: validation: flag, field

const validateForm = (formObject, config, stateSetter) => {
  const keysList = Object.keys(formObject);

  const data = keysList.some((k) => {
    const fieldIndex = config.fieldList ? config.fieldList.findIndex((item) => item.title === k) : -1;  
    if (fieldIndex > -1) {
      const valid = validationCondition(
        config.fieldList[fieldIndex].type || VALIDATION_TYPES.NULL_ERROR,
        formObject[k],
      );
      if (!valid) {
        // eslint-disable-next-line no-param-reassign
        config.flag = true;
        stateSetter(config);

        return true;
      }
      return false;
    }
  });

  return !data;
};

export { validateForm, VALIDATION_TYPES };
