import React, { Component } from 'react';
import moment from 'moment/moment';
import DetailView from '../../common/detail/DetailView';
import PageButtonWrapper from '../../common/PageButtonWrapper';
import config from './config';
import { checkIfApiCallSuccess, jvApi } from '../../common/base.api';
import withBaseState from '../../common/withBaseState';
import { CONTRA_ENTRY_BASE } from '../../../data/enums/Route';
import { Button } from '../../../components/BillingMDC';
import history from '../../../utils/history';
import { voucherDecoder } from '../../../utils/miscellaneous';
import { clone } from '../../../utils/arrayProcessor';
import Header from '../../../components/PrintComponent/Header';
import TableHeader from '../../../components/TableHeader/TableHeader';
import VoucherFooter from '../../../components/PrintComponent/voucherFooter';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import numberToWords from '../../../utils/numberToTextConverter';
import ContraEntryStyled from '../ContraEntryStyled';
import {flattenDataPrintAttributeWise, groupPrintDataSet} from "../../common/print.service";

const propTypes = {};
const defaultProps = {};

class ContraEntryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        summary: {},
      },
      userDetail: { id: 'userId', title: '' },
      preparedBy: { title: 'Prepared By', value: '' },
      dataList: [],
      print: false,
    };
    this.dataListLength = 0;
  }

  componentDidMount() {
    this.getData();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  getData = () => {
    const { match } = this.props;
    const { preparedBy } = this.state;
    const voucher_number = voucherDecoder(match.params.id);

    jvApi.getDetails({ voucher_number, user_id: match.params.userId || '' }).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const { journalVoucherDetail, journalVoucher } = response.data;
        const formattedList = flattenDataPrintAttributeWise(journalVoucherDetail, config.lengthyAttributes, config.getData);
        const {
          narration,
          drAmount,
          crAmount,
          date = journalVoucher.transactionDate,
          mitiTitle,
          voucherNumber,
        } = journalVoucher;
        const data = {
          list: formattedList,
          summary: {
            narration,
            drAmount,
            crAmount,
            date,
            mitiTitle,
            voucherNumber,
            totalInWords: numberToWords(drAmount || 0),
          },
        };
        const userDetail = config.userDetailMapper(response.data);

        preparedBy.value = userDetail.title;
        this.setState({ data, userDetail, preparedBy });
      }
    });
  };

  printInvoice = () => {
    const self = this;
    const { match } = this.props;
    self.setState({ print: true }, () => {
      window.print();
    });

    window.onafterprint = function () {
      if (match.params.userId) {
        window.close();
      } else {
        history.push(`/${CONTRA_ENTRY_BASE}`);
      }
    };
  };

  btnWrapper = () => (
    <>
      <Button accent className="cancel-btn modal-btn" onClick={() => history.push(`/${CONTRA_ENTRY_BASE}`)}>
        Cancel
      </Button>
      <Button accent className="margin-right-0 save-btn modal-btn" onClick={() => this.handlePrintClick()}>
        Print
      </Button>
    </>
  );
  groupPrintDataSetNew() {
    const { data} = this.state;
    const { company  } = this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(data.list);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  }

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };
  handlePrintClick = () => {
    const self = this;
    this.groupPrintDataSetNew();
    this.setState({ print: true }, () => {
      window.print();
    });
    window.onafterprint = function () {
      self.setState({ print: false });
    };
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  render() {
    const { serverResponseWaiting, company } = this.props;
    const { data, print, dataMiscellaneousList, dataList, preparedBy } = this.state;

    return (
      <ContraEntryStyled className={`contra-entry-details${serverResponseWaiting ? 'clickable-false' : ''}`}>
        <div className={print ? 'no-print' : 'display-block'}>
          <DetailView
            display={{
              header: true,
              footer: true,
              summary: true,
            }}
            serverResponseWaiting={serverResponseWaiting}
            headerConfig={{
              company,
              title: config.title,
              miti: data.summary.mitiTitle,
              date: moment(data.summary.date || new Date()).format('DD MMM YYYY'),
            }}
            pageConfig={config}
            data={data}
          />
          <PageButtonWrapper renderBtn={this.btnWrapper} />
        </div>
        <div className={print ? 'display-block portrait-type ce' : 'no-print'}>
          {dataList.map((ceItem, key) => (
            <div className="print-body zoom-reduce voucher-print">
              <div className="jv card-body">
                <Header
                  company={company}
                  divClassName={key === 0 ? 'first-header-bar header-border-bottom' : 'header-bar header-border-bottom'}
                  date={moment(data.summary.date || new Date()).format('DD MMM YYYY')}
                  miti={data.summary.mitiTitle}
                  pageTitle={<h2>{config.title}</h2>}
                  dataMiscellaneousList={dataMiscellaneousList}
                />
                <div
                  className={`overflow-scrollable fixed-table-wrapper ${
                    !ceItem.footer ? 'fixed-table-height ' : 'payment-total-footer'
                  }`}
                >
                  <table className="voucher-table">
                    <TableHeader headerDetails={config.header} filterHeaderLabel={false} />

                    {config.getTableBody({ dataList: ceItem.list,remainingLines:ceItem.remainingLines,dataSummary:data.summary,footer:ceItem.footer })}
                  </table>
                </div>
                {ceItem.footer && (
                  <table className="left-footer print-active total-section-wrapper footer clearfix">
                    <>
                      <VoucherFooter
                        preparedBy={preparedBy}
                        totalInWords={data.summary.totalInWords}
                        signatureList={config.signatureTitleList}
                        lineMargin="padding-r-24"
                        remarksStatus
                        remarks={data.summary.narration}
                      />
                    </>
                  </table>
                )}
              </div>
              <div className="footer-block">
                <PageNumber value={ceItem.page} totalPage={dataList.length} />
              </div>
            </div>
          ))}
        </div>
      </ContraEntryStyled>
    );
  }
}

ContraEntryDetail.propTypes = propTypes;
ContraEntryDetail.defaultProps = defaultProps;

const ContraEntryDetailWithState = withBaseState(ContraEntryDetail);

export default ContraEntryDetailWithState;
