import React from 'react';
import { ACTION } from '../../../data/enums/enums';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const getServiceData = (sku = {}) => {
  const details = { businessId: sku.businessId || '', };

  return details;
};

const getBatchData = (batch = {}) => {
  const details = {
    businessSkuId: batch.businessSkuId || '',
    title: batch.title,
    dlp: batch.dlp,
  };

  return details;
};

const getApiTransformedBatchData = (batch) => ({
  business_sku_id: batch.businessSkuId,
  title: batch.title,
  dlp: batch.dlp,
});

const getApiTransformedData = (sku, action = ACTION.ADD) => {
  const details = { business_id: sku.businessId || '', };

  if (action === ACTION.ADD) {
    // TODO
  }

  return details;
};

const ServiceTableBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <>
        <tr onClick={() => onRowClick(data)}>
          <td className='left-align'>{data.businessSkuId}</td>
          <td className='left-align'>{data.title}</td>
          <td className='left-align'>{data.description}</td>
          <td className='left-align'>{data.businessCompany.businessAlias}</td>
          <td className='left-align'>{data.vatPercent}</td>
          <td>{fixedFloatAndCommas(data.rlp)}</td>
        </tr>
      </>
    ))}
  </tbody>
);

const customerLedgerConfig = [
  {
    id: 1,
    label: 'business_sku_id',
    title: 'ID',
    className: 'cursor-pointer left-align',
    sortable: true,
  },
  {
    id: 2,
    label: 'skus.title',
    title: 'Service',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 3,
    label: 'skus.description',
    title: 'Description',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 4,
    label: 'skus.businessCompany.businessAlias',
    title: 'Business',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 5,
    label: 'skus.vatPercent',
    title: 'VAT(%)',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 6,
    label: 'rlp',
    title: 'RLP',
    className: 'cursor-pointer',
    sortable: true,
  },
];

const config = {
  header: customerLedgerConfig,
  getTableBody: ServiceTableBody,
  getDetail: getServiceData,
  getBatchDetail: getBatchData,
  getApiTransformedData,
  getApiTransformedBatchData,
};

export default config;
