import styled from 'styled-components';

const EmailTemplateStyled = styled.div`
  .email__container {
    background-color: #fbfdff;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .email__body {
      background-color: #ffffff;
      padding:40px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    h3 {
      color: rgba(0, 0, 0, 0.87);
      font-family: Roboto;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0;
      margin-bottom: 25px;
    }
    span {
      color: rgba(0, 0, 0, 0.87);
      font-family: Roboto;
      font-size: 16px;
      letter-spacing: 0;
      text-align: center;
    }
    p {
      color: rgba(0, 0, 0, 0.54);
      font-family: Roboto;
      font-size: 10px;
      margin:0;
      letter-spacing: 0;
      margin: 8px 0 40px 0;
    }
    .emailTemplate__button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default EmailTemplateStyled;
