import styled from 'styled-components';

const SettingStyled = styled.div`
  .flex {
    display: flex;
    flex-wrap: wrap;
  }
  .pannel-section {
    margin: 0;
    margin: 18px 24px;
    .pannel-header h1 {
      font-size: 20px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 40px;
    }
    .pannel-card {
      padding: 18px 30px;
      overflow: auto;
      .radio-list-wrapper {
        border: 0;
        padding: 0;
        padding-top: 3px;
      }
      .custom-checkbox {
        label {
          position: relative;
          top: 2px;
        }
        white-space: nowrap;
      }
      .select-custom-label {
        font-size: 13px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.54);
      }
      .switch_radio {
        display: flex;
        flex-wrap: wrap;
      }
      .select-css {
        border: 0;
        width: 262px;
        .zindex-2__menu {
          z-index: 2;
        }
        .zindex-2__menu-list {
          max-height: 150px;
          overflow: auto;
        }
        .zindex-2__control {
          background-color: rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          .zindex-2__indicators {
            min-height: 40px;
          }
          .zindex-2__single-value {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.54);
          }
        }
      }
      .form-switchs {
        margin-right: 24px;
        .label {
          font-size: 13px;
          line-height: 14px;
          color: rgba(0, 0, 0, 0.54);
          margin-bottom: 8px;
          display: block;
        }
        .switch {
          border-right: 1px solid #d8d8d8;
        }
      }
    }
  }
`;
export default SettingStyled;
