import React from 'react';
import PropTypes from 'prop-types';
import { has } from '../../../utils/hasOwnProperty';
import { Cell, Grid ,Checkbox} from '../../../components/BillingMDC';
import { getPercentage } from '../../../utils/miscellaneous';
import { isObjectEmpty } from '../../../utils/objectProcessor';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { VAT_PERCENTAGE, VAT_ZERO_PERCENTAGE } from '../../../data/enums/enums';

const getFooterContent = (data) => {
  const billPer = getPercentage(data.billDiscount || 0, data.subTotal);
  const tradePer = getPercentage(data.tradeDiscount || 0, data.subTotal - data.billDiscount);

  return [
    {
      title: 'Sub Total',
      value: data.subTotal || 0,
    },
    {
      title: `Bill Discount${billPer ? ` (${billPer}%)` : ''}`,
      value: data.billDiscount || 0,
    },
    {
      title: `Trade Discount${tradePer ? ` (${tradePer}%)` : ''}`,
      value: data.tradeDiscount || 0,
    },
    JSON.parse(localStorage.getItem('rosiaCompany'))?.enableExcise? {
      title: 'Excise Duty',
      value: data.excise || 0,
    }:{},
    {
      title: 'Taxable Amount',
      value: data.actualTaxableAmount || 0,
    },
   
    {
      title: 'VAT',
      value: data.vat || 0,
    },
  ];
};

const DetailFooter = ({ data, pageConfig = {} ,onChange,vatStatus}) => {
  const footerList = has.call(pageConfig, 'getFooterContent')
    ? pageConfig.getFooterContent(data)
    : getFooterContent(data);
  const totalConfig = has.call(pageConfig, 'getTotalContent') ? pageConfig.getTotalContent(data) : {};
  return (
    <div className='total-section-wrapper'>
      {footerList.map(
        (item) =>
          (has.call(item, 'display') ? item.display : true) && (
            <Grid>
              <Cell col={8} />
              <Cell col={3} className={`right-align active-opacity-text ${item.title}`}>
                <span className='title'>{item.title} </span>
                { !!onChange && item.title==='VAT' &&
                <>
                  <span>{ vatStatus ? `(${VAT_PERCENTAGE} %) `: `(${VAT_ZERO_PERCENTAGE} %)`}</span>
                       <Checkbox
                       checked={vatStatus}
                       onChange={({ target: { checked } }) => {
                         onChange('vatStatus', checked);
                       }}
                     />
                     </>
                }
              </Cell>
              <Cell col={1} className={`right-align ${item.title}`}>
                {fixedFloatAndCommas(item.value)}
              </Cell>
            </Grid>
          ),
      )}
      {!isObjectEmpty(totalConfig) && (
        <>
          <div className='hr' />
          <Grid>
            <Cell col={8} />
            <Cell col={3} className='right-align active-opacity-text'>
              {totalConfig.title}
            </Cell>
            <Cell col={1} className='right-align font-bold'>
              {fixedFloatAndCommas(totalConfig.value)}
            </Cell>
          </Grid>
        </>
      )}
    </div>
  );
};

DetailFooter.propTypes = {
  data: PropTypes.instanceOf(Object),
  pageConfig: PropTypes.instanceOf(Object),
};

DetailFooter.defaultProps = {
  data: {},
  pageConfig: {},
};

export default DetailFooter;
