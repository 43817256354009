import React from 'react'
import BillingSelect from '../../../../components/Select/BillingSelect';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import {
  periodType, repeatTimes, week
} from './config';
import {
  Cell, Checkbox, Grid
} from '../../../../components/BillingMDC';
import { EMPTY } from '../../../../data/enums/errorMessage';


const General = (props)=> {
  const { data,  handleInputChange,handleCheckboxChange, enableErrorDisplay } = props;
  return (
    <>
      <div className='frequency-title'>
        <BillingTextField
          value={data.title}
          floatingLabel='Email Frequency Category Title'
          type='text'
          param='title'
          handleChange={handleInputChange}
          required
          emptyField={enableErrorDisplay}
          errorMessage={EMPTY}
        />
      </div>
      <div className='bill-issue'>
        <h4>Bill Issue Configuration</h4>
        <Grid>
          <Cell col={3}>
            <label>Repeat Times</label>
            <BillingSelect
              name='form-field-name'
              param='type'
              clearable={false}
              value={data.type}
              valueKey='value'
              labelKey='title'
              options={repeatTimes}
              handleChange={handleInputChange}
            />
          </Cell>
          {data.type==='weekly'?(
            <Cell col={9}>
              <div className='reapeat__days'>
                <label>Repeat Days</label>
                <div className='checkbox-list'>
                  {week.map((d, index) => (
                    <>
                      <Checkbox
                        key={index}
                        checked={data.day?.includes(d)}
                        onChange={({ target: { checked } }) => {
                          handleCheckboxChange('day',`${d}`, checked);
                        }}
                      />
                      <label>{d.slice(0, 3).toLowerCase()}</label>
                    </>
                  ))}
                </div>
              </div>
            </Cell>)
            :(
              data.type!=='daily'&&<Cell col={3}>
                <BillingTextField
                  value={data.occurenceDay}
                  floatingLabel='Occurence Day (Nepali Date in BS)'
                  type='number'
                  param='occurenceDay'
                  handleChange={handleInputChange}
                  max= '31'
                />
              </Cell>
            )}
            </Grid>
            <Grid>
          <Cell col={3}>
            <label className='time-label'>Time</label>
            <div className='time'>
              <div className='time__hours'>
                <BillingTextField
                  value={data.hours}
                  type='number'
                  placeholder='HH'
                  handleChange={handleInputChange}
                  max= '12'
                  param='hours'
                />
                <label>HH</label>
              </div>
              <div className='time__hours'>
                <BillingTextField
                  value={data.minutes}
                  zeroError = {false}
                  type='number'
                  placeholder='MM'
                  handleChange={handleInputChange}
                  max= '60'
                  param='minutes'
                />
                <label>MM</label>
              </div>
              <div className='time__hours'>
                <BillingSelect
                  name='form-field-name'
                  param='period'
                  clearable={false}
                  value={data.period}
                  valueKey='value'
                  labelKey='title'
                  options={periodType}
                  handleChange={handleInputChange}
                />
                <label>AM/PM</label>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  )
}

export default General;
