import React from 'react';
import TableHeader from '../../../components/TableHeader';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const View = ({ data, company, config, queryParameters, activeView, onRowClick, activeIncomes, activeExpenses }) => {
  return (
    <div className='inner-view'>
      <div className='trial-view'>
        <div className='top-header'>
          <h3>{company.title}</h3>
          <span>
            <b>Profit and Loss A/C</b> as of date <b>{queryParameters.singleDate}</b>
          </span>
        </div>
        <div className='mid-header'>
          <span>Expenditure</span>
          <span>Income</span>
        </div>
        <div className='table-view'>
          <table>
            <TableHeader headerDetails={config.header} />
            {config.getProfitBody({
              dataList: data.expenseLedgers,
              activeView,
              onRowClick,
              activeData: activeExpenses,
              label: 'activeExpenses',
            })}
          </table>
          <table>
            <TableHeader headerDetails={config.header} />
            {config.getLossBody({
              dataList: data.incomeLedgers,
              activeView,
              activeData: activeIncomes,
              onRowClick,
              label: 'activeIncomes',
            })}
          </table>
        </div>
        <div className='total'>
          <span className='left-align'>Total</span>
          <span className='right-align'>{fixedFloatAndCommas(data.totalBalance.expense)}</span>
          <span className='left-align'>Total</span>
          <span className='right-align'>{fixedFloatAndCommas(data.totalBalance.income)}</span>
        </div>
      </div>
    </div>
  );
};

export default View;
