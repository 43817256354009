import React from 'react';
import Download from '../../common/Download';
import DateToRender from '../../../components/DateToRender';
import {
  Grid, Cell, Icon
} from '../../../components/BillingMDC';
import BillingSelect from '../../../components/Select/BillingSelect';

const Header = ({
  customerId,
  reportDate,
  customerList,
  domainConfig,
  onInputChange,
  queryParameters,
  onDownloadClick,
  onDrawerIconClick,
}) => {
  return (
    <div>
      <div className='header-bar'>
        <Grid>
          <Cell col={2} className='input-select'>
            <label>Customer</label>
            <BillingSelect
              name='form-field-name'
              param='customerId'
              clearable={false}
              value={customerId}
              valueKey='customerId'
              labelKey='title'
              options={customerList}
              handleChange={onInputChange}
            />
          </Cell>
          <Cell col={2} />
          <Cell col={4}>
            <div className='center-align'>
              <h2>Outstanding Report</h2>
              <div className='default-margin-top-12'>
                <span className='header-title-content no-margin-bottom'>
                  <span className='active-opacity-text'>
                    Report from:
                    <span className='date-rendered'>
                      <DateToRender date={queryParameters.date} />
                    </span>
                  </span>
                </span>
                <span className='header-title-content'>
                  <span className='active-opacity-text'>
                    As on Date:
                    <span className='date-rendered'>
                      <span>{reportDate}</span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </Cell>
          <Cell col={4}>
            <div className='header-menu-right'>
              <Download config={domainConfig} onClick={onDownloadClick} />
              <span onClick={() => onDrawerIconClick('filter', true)}>
                <Icon name='filter_list' />
              </span>
            </div>
            <div className='header-menu-right'></div>
          </Cell>
        </Grid>
      </div>
    </div>
  );
};

export default Header;
