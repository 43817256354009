import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../../../components/BillingMDC';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { DISCOUNT_OPTIONS } from '../../../../data/enums/enums';
import { discountOptions } from '../../sales/create/config';

class CreateGRNTableBody extends Component {
  render() {
    const { data, index, handleEditIconClick, handleDeleteIconClick } = this.props;
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{data.skuTitle}</td>
        <td>{data.batchTitle}</td>
        <td className='right-align'>{data.normal}</td>
        <td className='right-align'>{data.damage}</td>
        <td className='right-align'>{data.shortage}</td>
        <td className='right-align'>{data.quantity}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.rate)}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.amount)}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.discount)}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.net_amount)}</td>
        <td className='right-align'>
          <Icon name='edit' onClick={() => handleEditIconClick(data, index)} />
          <Icon name='delete' onClick={() => handleDeleteIconClick(index)} />
        </td>
      </tr>
    );
  }
}

CreateGRNTableBody.defaultProps = { enableUpdate: true, };

CreateGRNTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDeleteIconClick: PropTypes.func.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  enableUpdate: PropTypes.bool,
};
const isGRNConstraintError='GRN is already created of this Vendor Invoice Number '
const createGRNConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'test',
      title: 'SN',
      className: null,
      sortable: false,
    },
    {
      id: 2,
      label: 'sku',
      title: 'SKU',
      className: null,
      sortable: false,
    },
    {
      id: 2,
      label: 'batchTitle',
      title: 'Batch',
      className: null,
      sortable: false,
    },
    {
      id: 3,
      label: 'normal',
      title: 'Sellable (Pcs)',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 4,
      label: 'damage',
      title: 'Damage (Pcs)',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 5,
      label: 'shortage',
      title: 'Shortage (Pcs)',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 6,
      label: 'quantity',
      title: 'Quantity (Pcs)',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 7,
      label: 'rate',
      title: 'Rate',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 8,
      label: 'amount',
      title: 'Amount',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 9,
      label: 'discount',
      title: 'Discount',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 10,
      label: 'net_amount',
      title: 'Net Amount',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 11,
      label: 'test',
      title: '',
      className: 'right-align',
      sortable: false,
    },
  ],
};

const validationList = {
  form: ['sku_id', 'batch_id', 'rate', 'quantity', 'discount', 'net_amount'],
  page: ['title', 'address', 'invoiceId', 'remarks'],
  batch: ['title'],
};

const createSKUMapper = (data = {}) => ({
  batch_id: data.batch_id || '',
  batchTitle: data.batchTitle || '',
  brand: data.brand || '',
  brandTitle: data.brandTitle || '',
  vendor_invoice_number: data.vendor_invoice_number || '0',
  discount: data.discount || 0,
  discount_label: data.discount_label || discountOptions[0].value,
  discount_input: data.discount_input || null,
  normal: data.normal || 0,
  damage: data.damage || 0,
  shortage: data.shortage || 0,
  quantity: data.quantity || 0,
  rate: data.rate || 0,
  sku: data.sku || '',
  skuTitle: data.skuTitle || '',
  sku_id: data.sku_id || '',
  vat: data.vat || 0,
  amount: data.amount || 0,
  net_amount: data.net_amount || 0,
  dlp: data.dlp || data.rate || 0,
  freeSku: data.freeSku || false,
  vat_percent: data.vatPercent || data.vat_percent || 0,
  tax_exempted_amount: data.taxExemptedAmount || data.tax_exempted_amount || 0,
});

const vendorDetailsMapper = (vendor = {}) => ({
  id: vendor.idLedger || '',
  vendorId: vendor.customerId || '',
  name: vendor.name || '',
  address: vendor.address || '',
  panNo: vendor.panNo || '',
});

const billSummaryMapper = ({ user, ...data }) => ({
  remarks: '',
  net_amount: 0,
  entered_by: user.idUsers,
  vat: 0,
  gross_amount: 0,
  voucher_number: 1,
  taxable_amount: 0,
  bill_discount_param: DISCOUNT_OPTIONS[0].value,
  bill_discount: 0,
  bill_discount_value: 0,
  trade_discount_param: DISCOUNT_OPTIONS[0].value,
  trade_discount: 0,
  trade_discount_value: 0,
  actualTaxableAmount: 0,
  taxExemptedAmount: 0,
});

export {
  createGRNConfig, CreateGRNTableBody, validationList, createSKUMapper, vendorDetailsMapper, billSummaryMapper,isGRNConstraintError
};
