import { compose } from 'recompose';

import apiInterceptor from '../../../../api/interceptor';

import CreateServiceInvoice from './CreateServiceInvoice';
import {
  buApi, catalogsApi, ledgerApi, salesInvoiceApi
} from '../../../common/base.api';

const composedCreateServiceInvoice = compose(
  apiInterceptor({
    ...buApi,
    ...catalogsApi,
    create: { ...salesInvoiceApi },
    getCustomerList: { ledgerApi },
  }),
)(CreateServiceInvoice);

export default composedCreateServiceInvoice;
