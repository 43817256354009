import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import '../../../../styles/printStyle.css';
import history from '../../../../utils/history';
import { has } from '../../../../utils/hasOwnProperty';
import { clone } from '../../../../utils/arrayProcessor';
import { SALES_RETURN_BASE } from '../../../../data/enums/Route';
import Header from '../../../../components/PrintComponent/Header';
import { checkIfApiCallSuccess, srnApi } from '../../../common/base.api';
import TableHeader from '../../../../components/TableHeader/TableHeader';
import PageNumber from '../../../../components/PrintComponent/PageNumber';
import BillTotalContent from '../../../../components/PrintComponent/BillTotalContent';
import { Button, Cell, Grid, LinearProgress, Textfield } from '../../../../components/BillingMDC';
import config from './config';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { getPercentage, voucherDecoder } from '../../../../utils/miscellaneous';
import { CLIENT, VAT_PERCENTAGE, GET_BATCH_TH } from '../../../../data/enums/enums';
import numberToWords from '../../../../utils/numberToTextConverter';
import ReportView from '../../../../components/PrintComponent/ReportView';
import { isObjectEmpty } from '../../../../utils/objectProcessor'
import InvoiceDetailsStyled from '../../sales/detail/InvoiceDetailsStyled';
import {groupPrintDataSet} from "../../../common/print.service";

// invoice id is present in the list.
class SalesDetails extends Component {
  getDetail = () => {
    const { invoiceDetail, client, paymentDetail } = this.state;
    const { match, company ,location} = this.props;
    const srnNumber = voucherDecoder(match.params.id);
    const userId = match.params.userId || null;
    this.setState({ loading: true, client: userId ? CLIENT.ROSIA : CLIENT.DBS });
    srnApi
      .getPrintDetails(srnNumber)
      .then(response => {
        this.setState({ loading: false });
        if (checkIfApiCallSuccess(response)) {
          const salesReturnDetail = { ...response.data };
          let companyInfo = { ...company };
          if (salesReturnDetail?.paymentMode?.toLowerCase() === config.CASH_MODE) {
            paymentDetail.status = true;
            paymentDetail.mode = 'CASH';
          }
          salesReturnDetail.subTotal = salesReturnDetail.details.length
            ? this.calculateTotal(salesReturnDetail.details, 'netAmount')
            : 0;
          salesReturnDetail.taxableAmount = Number(
            (
              salesReturnDetail.subTotal -
              (salesReturnDetail.billDiscount || 0) -
              (salesReturnDetail.tradeDiscount || 0) -
              (salesReturnDetail.taxExemptedAmount || 0)
            ).toFixed(2),
          );
          salesReturnDetail.totalInWords = numberToWords(salesReturnDetail.netAmount || 0);
          const totalQuantity = salesReturnDetail.details.reduce((acc, curr) => {
            return acc + curr.quantity;
          }, 0);
          const customerDetails = config.customerDetailMapper(salesReturnDetail);
          const billPercent = getPercentage(salesReturnDetail.billDiscount, salesReturnDetail.subTotal);
          const tradePercent = getPercentage(
            salesReturnDetail.tradeDiscount,
            salesReturnDetail.subTotal - salesReturnDetail.billDiscount || 0,
          );

          const billSummary = config.billSummaryMapper({ ...salesReturnDetail, billPercent, tradePercent });

          const dataMiscellaneousList = [
            {
              title: 'Invoice Number',
              value: customerDetails.invoiceId,
            },
            { title: 'Sales Return Invoice Number', value: salesReturnDetail.salesReturnInvoiceNumber },
            { title: 'Customer Name', value: customerDetails.customerName },
            {
              title: 'PAN No',
              value: salesReturnDetail.customer.panNo,
            },
          ];
          const billTotalList = [
            { title: 'Sub Total', value: salesReturnDetail.subTotal },
            {
              title: `Discount${billPercent ? ` (${billPercent}%)` : ''}`,
              value: billSummary.billDiscount,
            },
            { title: 'Taxable Amount', value: salesReturnDetail.taxableAmount },
            { title: `VAT`, value: billSummary.vat },
          ];
          if (has.call(salesReturnDetail.user || {}, 'name')) {
            invoiceDetail.value = salesReturnDetail.user.name;
          }
          if (client === CLIENT.ROSIA) {
            companyInfo = response.data.company || company;
          }
          this.setState(
            {
              totalQuantity,
              date: moment(salesReturnDetail.date).format('DD MMM YYYY'),
              miti: salesReturnDetail.mitiTitle,
              documentDate: salesReturnDetail.documentDate,
              invoiceList: salesReturnDetail.details || [],
              billSummary,
              invoiceDetail,
              billTotalList,
              customerDetails,
              dataMiscellaneousList,
              company: companyInfo,
              paymentDetail,
            },
            () => {
              location && location.state &&  location.state.printEnable && this.handlePrintClick();
            },
          );
        } else {
        }
      })
      .catch(err => this.setState({ loading: false }));
  };

  calculateTotal = (detailList, key) => {
    let totalValue = 0;
    detailList.map(item => {
      totalValue += item[key];
    });

    return Number(Number(totalValue).toFixed(2));
  };

  setupConfig() {
    const { client } = this.state;
    if (client === CLIENT.ROSIA) {
      document.getElementsByClassName('scrollbar-container')[0].classList.add('visibility-hidden');
      document.getElementsByClassName('nav-bar')[0].classList.add('visibility-hidden');
    }
  }
  
  handlePrintClick = () => {
    const { client } = this.state;
    const self = this;
    this.groupPrintDataSetNew();
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.setState({ printButtonClicked: false }, () => {
        if (client === CLIENT.ROSIA) window.close();
      });
    };
  };
  groupPrintDataSetNew() {
    const { invoiceList} = this.state;
    const { company  } = this.props;
    const printInfoBill = company.printInfo || {};
    const orders = clone(invoiceList);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  }


  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  handleCancelClick = () => {
    history.push(`/${SALES_RETURN_BASE}`);
  };

  constructor(props) {
    super(props);
    this.state = {
      client: CLIENT.DBS,
      loading: false,
      customerDetails: config.customerDetailMapper({}),
      billSummary: config.billSummaryMapper({}),
      date: '',
      miti: '',
      documentDate: '',
      invoiceList: [],
      printButtonClicked: false,
      billTotalList: [
        { title: 'Sub Total', value: 0 },
        { title: 'Bill Discount', value: 0 },
        { title: 'VAT', value: 0 },
      ],
      dataMiscellaneousList: [
        { title: 'Invoice Number', value: '' },
        {
          title: 'Sales Return Number',
          value: '',
        },
        { title: 'PAN No', value: '' },
        { title: 'Customer Name', value: '' },
      ],
      dataList: [],
      invoiceDetail: { title: 'Prepared By', value: '' },
      company: isObjectEmpty(props.company) ? { printInfo: {} } : props.company,
      paymentDetail: {
        status: true,
        mode: 'CREDIT',
      },
      totalQuantity: 0,
    };
    this.dataListLength = 0;
    this.pageConfig = this.getPageConfig();
  }

  getPageConfig = () => {
    const {
      company: {
        printInfo: { batchEnabled },
      },
    } = this.state;
    const header = [...config.header];
    // if (batchEnabled) {
    //   header.splice(2, 0, GET_BATCH_TH);
    //   return { ...config, header };
    // }
    return config;
  };

  componentDidMount() {
    this.getDetail();
    this.setupConfig();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  render() {
    const {
      loading, invoiceList, dataList, billSummary, customerDetails, billTotalList, invoiceDetail,
      date, miti, printButtonClicked, dataMiscellaneousList,documentDate, company,paymentDetail,totalQuantity,
    } = this.state;
    const printInfoBill = company.printInfo;
    return (
      <InvoiceDetailsStyled>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={`srn-detail sales-invoice ${loading ? 'clickable-false' : ''}`}>
          <div className={printButtonClicked ? 'no-print' : 'display-block'}>
            <div className="card-body" ref="tableReference">
              <div className="header-bar">
                <Grid>
                  <Cell col={4} tablet={8}>
                    <div className="header-title">{company.title}</div>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Address: </span>
                      <span>{company.address}</span>
                    </span>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Phone: </span>
                      <span>{company.phone}</span>
                    </span>
                    <span className="no-margin-bottom header-title-content">
                      <span className="active-opacity-text">PAN No: </span>
                      <span>{company.panNumber}</span>
                    </span>
                  </Cell>
                  <Cell col={4} tablet={8}>
                    <div className="header-middle-title center-align">
                      <h2>{config.title.original}</h2>
                    </div>
                  </Cell>
                  <Cell col={4} tablet={8}>
                    <div className="right-align">
                      <span className="header-right-content">
                        <span className="">Date: </span>
                        <span>{moment(documentDate).format('ll')}</span>
                      </span>
                      <span className="">Payment Mode: </span>
                      <span>{paymentDetail.mode}</span>
                    </div>
                  </Cell>
                </Grid>
              </div>
              <div className="print-invoice three-input-wrapper">
                <Grid>
                  <Cell col={3}>
                    <Textfield
                      className="right-side-gap no-pointer-events"
                      floatingLabel="Sales Return Number"
                      value={customerDetails.salesReturnNumber}
                      onChange={() => null}
                    />
                  </Cell>
                  <Cell col={3}>
                    <Textfield
                      className="right-side-gap no-pointer-events"
                      floatingLabel="Invoice Number"
                      value={customerDetails.invoiceId}
                      onChange={() => null}
                    />
                  </Cell>
                  <Cell col={3}>
                    <Textfield
                      className="right-side-gap no-pointer-events"
                      floatingLabel="Customer Name"
                      value={customerDetails.customerName}
                      onChange={() => null}
                    />
                  </Cell>
                  <Cell col={3}>
                    <Textfield
                      className="right-side-gap no-pointer-events"
                      floatingLabel="Address"
                      value={customerDetails.customerAddress}
                      onChange={() => null}
                    />
                  </Cell>
                  <Cell col={3}>
                    <Textfield
                      className="right-side-gap no-pointer-events"
                      floatingLabel="PAN No"
                      value={customerDetails.panNo}
                      onChange={() => null}
                    />
                  </Cell>
                  <Cell col={3}>
                    <Textfield
                      className="right-side-gap no-pointer-events"
                      floatingLabel="Remarks"
                      value={billSummary.remarks}
                      onChange={() => null}
                    />
                  </Cell>
                </Grid>
              </div>
              <div ref="fixedTableBody" className="fixed-table-wrapper">
                <table>
                  <TableHeader headerDetails={this.pageConfig.header} filterHeaderLabel={false} />
                  {config.getTableBody({ dataList: invoiceList, printInfoBill })}
                </table>
              </div>
              <div className="total-section-wrapper">
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Sub Total
                  </Cell>
                  <Cell col={1} className="right-align">
                    {' '}
                    {fixedFloatAndCommas(billSummary.subTotal)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                   Discount {billSummary.billPercent ? `(${billSummary.billPercent}%)` : ''}
                  </Cell>
                  <Cell col={1} className="right-align">
                    {' '}
                    {fixedFloatAndCommas(billSummary.billDiscount)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Taxable Amount
                  </Cell>
                  <Cell col={1} className="right-align">
                    {' '}
                    {fixedFloatAndCommas(billSummary.taxableAmount)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    {' '}
                    VAT
                  </Cell>
                  <Cell col={1} className="right-align">
                    {' '}
                    {fixedFloatAndCommas(billSummary.vat)}
                  </Cell>
                </Grid>
                <div className="hr" />
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Total
                  </Cell>
                  <Cell col={1} className="right-align font-bold">
                    {' '}
                    {fixedFloatAndCommas(billSummary.totalValue)}
                  </Cell>
                </Grid>
              </div>
            </div>
            <div className="newinvoice-btn-wrapper">
              <Grid>
                <Cell col={8} />
                <Cell col={4} className="right-align">
                  <Button
                    accent
                    className="cancel-btn modal-btn"
                    onClick={() => {
                      this.handleCancelClick();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    accent
                    className="save-btn modal-btn"
                    onClick={() => {
                      this.handlePrintClick();
                    }}
                  >
                    Print
                  </Button>
                </Cell>
              </Grid>
            </div>
          </div>
          <div
                  className={printButtonClicked ? 'display-block portrait visibility-shown' : 'no-print'}
                >
            <ReportView
              billTotalList={billTotalList}
              billSummary={billSummary}
              dataList={dataList}
              dataMiscellaneousList={dataMiscellaneousList}
              date={moment(documentDate).format('ll')}
              company={company}
              miti={miti}
              title={config.title}
              invoiceDetail={invoiceDetail}
              paymentDetail={paymentDetail}
              invoiceList={invoiceList}
              config={config}
              pageConfig={this.pageConfig}
              batchControl
              totalQuantity={totalQuantity}
            />
          </div>
        </div>
      </InvoiceDetailsStyled>
    );
  }
}

SalesDetails.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
});
const SRNDetails = connect(mapStateToProps)(SalesDetails);

export default SRNDetails;
