import React, { Component } from 'react';
import {PaymentDialog,ConfirmationDialog} from './Dialog';
import tableConfig from './config';
import { checkIfApiCallSuccess, ledgerApi, tagApi } from '../../common/base.api';
import { Tab, TabList, Tabs } from 'react-tabs';
import PaymentStyled from './PaymentStyled';
import * as filterUtil from '../../../utils/filterUtil';
import { ACTION, paymentTypes } from '../../../data/enums/enums';
import { validateForm } from '../../common/validation';
import withBaseState from '../../common/withBaseState';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import TableView from '../../common/pagination/TableView';
import PageHeader from '../../common/pagination/PageHeader';
import { PAYMENT_CONFIG } from '../../common/domain.config';
import { BillingSnackBar } from '../../../components/BillingMDC';
import { clone } from '../../../utils/arrayProcessor';
import { Grid, Cell } from '../../../components/BillingMDC';
import TableHeader from '../../../components/TableHeader/TableHeader';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import { JWT } from '../../../environment';
import * as downloadService from '../../common/download.service';
import {groupPrintDataSet} from "../../common/print.service";
import {getSortedByInvoiceNumber} from '../../common/common';

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        filter: false,
      },
      dialogType: ACTION.NULL,
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          payment_type: [],
        },
      },
      data: {
        list: [],
        total: 0,
      },
      confirmDialog:false,
      confirmStatus:false,
      paymentBackUp:{},
      printData: [],
      chequeValidation: {
        flag: false,
        fieldList: tableConfig.chequeValidationFieldList,
      },
      printButtonClicked: false,
      dataList: [],
      date: filterUtil.getCurrentDay(),
      payment: tableConfig.getDetail(),
      validation: {
        flag: false,
        fieldList: tableConfig.formValidationFieldList,
      },
      activeTab: 0,
      ledgerList: [],
      tagList: [],
      cashBank: [],
      paymentType: paymentTypes,
      addTag: false,
      to_ledger: {
        amount: 0,
        show: false
      }
    };

    this.reference = {
      tableHeader: React.createRef(),
      tableFooter: React.createRef(),
      tableBody: React.createRef(),
      fileRef: React.createRef(),
    };
    const { paymentDownloadList } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      paymentDownloadList,
    );
    this.basePaginationService.resetFilter();
    this.tabListGetterMapper = {
      [tableConfig.PAYMENT_TABS.PENDING]: this.loadTableData,
      [tableConfig.PAYMENT_TABS.RECONCILED]: this.loadTableData,
    };
  }
  componentDidMount() {
    this.loadTableData();
    this.getLedgerList();
    this.getTagList();
    this.getCashBankList();
    this.setAddTagStatus();
    window.addEventListener('keydown', this.handleKeyDown);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }
  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.getDataForPrint();
    }
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);
  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  loadPrintData = extraQueryString => {
    const { queryParameters } = this.state;
    const { getReceiptList } = this.props;
    let query = clone(queryParameters);
    query.pagination.limit = 1000;
    getReceiptList({
      query,
      extraQueryString,
    })
      .then(response => {
        const printData = response.list || [];
        this.setState({ printData });
      })
      .catch(err => {
        console.log(err);
        this.sendErrorMessage('Error while fetching print data set');
      });
  };

  loadTableData = () => {
    const { getReceiptList } = this.props;
    const { queryParameters, activeTab } = this.state;
    const extraQueryString = `&transaction_type=1&status=${activeTab}`;
    this.loadPrintData(extraQueryString);
    getReceiptList({
      query: queryParameters,
      extraQueryString,
    })
      .then(response => {
        const { data } = this.state;
        data.list = response.list || [];
        getSortedByInvoiceNumber(data.list );
        data.total = response.total || 0;
        this.setState({ data });
      })
      .catch(err => {
        console.log(err);
        this.sendErrorMessage();
      });
  };

  getLedgerList = () => {
    ledgerApi.getList({}, 'isCashBankLedger=false').then(response => {
      if (checkIfApiCallSuccess(response)) {
        let responseDataList = response.data.list;
        responseDataList.map(customer => {
          customer['label'] = customer.customerId + '. ' + customer.title;
        });
        this.setState({ ledgerList: responseDataList });
      }
    });
  };

  fetchLedgerOutStandingBalance = (field, customerId) => {
    const {to_ledger} = this.state;
    // display sign to represent credit or debit
    const displaySign = -1;
    ledgerApi.getOutstandingValue(customerId).then((response) => {
      if (checkIfApiCallSuccess(response)) {
        if (field === 'to_ledger') {
          to_ledger.show = true;
          to_ledger.amount = response.data.outstandingAmount * displaySign || 0;
          this.setState({to_ledger})
        }
      }
    });
  };

  setAddTagStatus() {
    const settings = localStorage.getItem(JWT.LOCAL_STORAGE.SETTINGS.NAME);
    if (settings) {
      let parsedSettings = JSON.parse(settings);
      if (parsedSettings) {
        this.setState({ addTag: !!parsedSettings.addTag });
      }
    }
  }

  handleFabButtonClick = () => {
    const payment = tableConfig.getDetail();
    this.setState({ dialogType: ACTION.ADD, payment });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleInputChange = (field, value) => {
    const { payment } = this.state;
    if (field === 'to_ledger') {
      this.fetchLedgerOutStandingBalance(field, value);
    }
    if (field === 'document_date' || field === 'reference_date') {
      const formattedDate = filterUtil.formatToNumericDate(value);
      payment[field] = formattedDate;
    } else {
      payment[field] = value;
    }
    this.setState({ payment });
  };
  fetchActiveTabPaginatedList = (tab) => {
    const type = tableConfig.tabMapper[tab];
    this.tabListGetterMapper[type]();
  };

  handleTabChange = (tab) => {
    this.setState(
      {
        activeTab: tab,
      },
      () => this.fetchActiveTabPaginatedList(tab)
    );
  };

  handleEditIconClick = (data) => {
    const { activeTab } = this.state;
    const payment = tableConfig.getDetail(data);
    const backUpData =clone({...payment});
    this.setState({
      dialogType: ACTION.UPDATE,
      payment,
      paymentBackUp:backUpData,
    });
    this.fetchLedgerOutStandingBalance('to_ledger', payment.to_ledger)
  };

  handleDeleteIconClick = data => {
    const payment = tableConfig.getDetail(data);
    this.setState({
      dialogType: ACTION.DELETE,
      deleteId: data.id,
      payment,
    });
  };

  getDataForPrint = () => {
    const self = this;
    this.groupPrintDataSetNew();
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.setState({ printButtonClicked: false });
    };
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  deletePayment = () => {
    const { deletePayment } = this.props;
    const { data, deleteId } = this.state;

    deletePayment(
      {
        id: deleteId,
      },
    )
      .then((res) => {
        this.loadTableData();
        const snack = snackService.generateUpdateMessage();
        this.setState({
          data,
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage('Error while deleting Tag!!');
        this.setState({
          data,
          snack,
        });
      });
  };

  createPayment = () => {
    const { payment } = this.state;
    const { createReceipt } = this.props;
    const apiTransformedData = tableConfig.getApiTransformedData(payment);

    createReceipt(apiTransformedData)
      .then(response => {
        const snack = snackService.generateSuccessMessage();
        this.setState({
          snack,
        });
        this.loadTableData();
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage('Error while creating payment');
        this.setState({ snack });
      });
  };

  updatePayment = () => {
    const { payment,activeTab,confirmDialog } = this.state;
    const { update,updateReconcile } = this.props;
    const {updateApiTransformedData, updateApiTransformedDataReconcile, PAYMENT_TAB} = tableConfig;
    if (activeTab ===PAYMENT_TAB.PENDING){
    const apiTransformedData = updateApiTransformedData(payment);
    update({
      id: payment.id,
      query: apiTransformedData,
    })
      .then(response => {
        const snack = snackService.generateUpdateMessage();
        this.setState({ snack });
        this.loadTableData();
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage('Error while updating receipt pending');
        this.setState({ snack });
      });
    }
    else{
      if(confirmDialog){
      const apiTransformedData = updateApiTransformedDataReconcile(payment);
      updateReconcile({
        id: payment.id,
        query: apiTransformedData,
      })
        .then(response => {
          const snack = snackService.generateUpdateMessage();
          this.setState({ snack });
          this.loadTableData();
        })
        .catch(err => {
          const snack = snackService.generateFailureMessage('Error while updating receipt reconcile');
          this.setState({ snack });
        });
        }
        else{
          this.handleModalClose();
         }
    }
  };

  getTagList = () => {
    tagApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ tagList: response.data.list });
      }
    });
  };

  getCashBankList = () => {
    ledgerApi.getList({}, 'isCashBankLedger=true').then(response => {
      if (checkIfApiCallSuccess(response)) {
        let responseDataList = response.data.list;
        responseDataList.map(customer => {
          customer['label'] = customer.customerId + '. ' + customer.title;
        });
        this.setState({ cashBank: responseDataList });
      }
    });
  };

  handleModalSubmit = () => {
    const { payment, validation, dialogType, chequeValidation, addTag,confirmStatus,activeTab } = this.state;
    if (addTag) {
      validation.fieldList.push({
        title: 'tagid',
      });
    }
    const formValid = validateForm(payment, validation, (valid) =>
      this.setState({ validation: valid })
    );
    const refNoValidation = validateForm(payment, chequeValidation, (valid) =>
      this.setState({ chequeValidation: valid})
    );
  if (!formValid && dialogType !== ACTION.DELETE) return false;
  if (dialogType === ACTION.DELETE) {
    this.deletePayment();
  }
    if (payment.payment_type !== 1 ? refNoValidation : formValid) {
      this.handleModalClose();
      if (dialogType === ACTION.ADD) {
        this.createPayment();
      } else {
        (activeTab === tableConfig.PAYMENT_TAB.PENDING || confirmStatus)  ? this.updatePayment(): this.getConfirmDialog();
      }
    }
  };
    handleModalClose = () => {
    const {validation, to_ledger} = this.state;
    validation.flag = false;
    to_ledger.show = false;
    this.setState({dialogType: ACTION.NULL, validation, to_ledger,confirmStatus:false,confirmDialog:false});
  };
  onModalConfirmClose = () => {
    const { payment } = this.state;
    this.setState({
      payment: { ...payment },
      dialogType: ACTION.UPDATE,
      confirmDialog: false,
    });
  };
  handleConfirmModalSubmit =() =>{
    this.setState({confirmStatus:true,dialogType :ACTION.UPDATE,});
  }

  getConfirmDialog =() =>{
    const {confirmStatus,paymentBackUp,payment} =this.state;
    const inputChangeStatus =paymentBackUp.amount !== payment.amount || paymentBackUp.from_ledger !== payment.from_ledger ||  paymentBackUp.to_ledger !== payment.to_ledger || paymentBackUp.document_date !== payment.document_date || paymentBackUp.tagid !== payment.tagid  ||paymentBackUp.narration !== payment.narration  ;
    if(inputChangeStatus){
    this.setState({confirmDialog:true,dialogType :ACTION.READ},()=>{
      confirmStatus &&  this.handleModalSubmit(); confirmStatus &&  this.onModalConfirmClose();
    });
    return
  }
    this.setState({confirmDialog:false,dialogType :ACTION.UPDATE},()=>{
      this.handleModalClose();
    });
  }

  groupPrintDataSetNew() {
    const { printData  } = this.state;
    const {company} =this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(printData);
    const odering= true;
    const sortedOrder =getSortedByInvoiceNumber(orders);
    const dataList = groupPrintDataSet(sortedOrder, printInfoBill, tableConfig,odering);
    this.setState({ dataList });
  }

  handleDownloadClick = (reportType = 'CSV') => {
    const { paymentDownloadList } = this.props;
    const { queryParameters, activeTab } = this.state;
    const extraQueryString = `&transaction_type=1&status=${activeTab}`;
    paymentDownloadList({
      type: reportType,
      query: queryParameters,
      extraQueryString,
    }).then(response => downloadService.resolver(response));
  };
  handleResetFilter =()=>{
    this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        payment_type: [],
      },
    });
  }
  handleFilterChange = (fieldValue, selectedValue) => {
    const { queryParameters } = this.state;
    queryParameters.filter[fieldValue] = selectedValue;
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters });
    this.basePaginationService.handleFilterChange(fieldValue, selectedValue);
  };

  render() {
    const {
      queryParameters,
      data,
      display,
      payment,
      snack,
      dialogType,
      validation,
      ledgerList,
      activeTab,
      tagList,
      cashBank,
      paymentType,
      chequeValidation,
      printButtonClicked,
      dataList,
      date,
      to_ledger,
      addTag,
      confirmDialog,
      confirmStatus,
    } = this.state;
    const { serverResponseWaiting, fiscalYearRange, company } = this.props;
    return (
      <PaymentStyled className="payment">
        <div className={printButtonClicked ? 'no-print' : 'display-block'}>
          <PageHeader
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            handleFilterChange={this.handleFilterChange}
            controlDisplay={this.controlDisplay}
            clearSearchText={this.basePaginationService.clearSearchText}
            queryParameters={queryParameters}
            domainConfig={PAYMENT_CONFIG}
            display={display}
            config={{
              search: true,
              filter: true,
              date: true,
              create: true,
              print: true,
              download: true,
            }}
            handlePrintClick={this.getDataForPrint}
            resetFilter={this.handleResetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.handleDownloadClick}
            serverResponseWaiting={serverResponseWaiting}
          />
          <Tabs selectedIndex={activeTab} onSelect={tabIndex => this.handleTabChange(tabIndex)}>
            <TabList>
              <Tab>Pending</Tab>
              <Tab>Reconciled</Tab>
            </TabList>
          </Tabs>
          <TableView
            display={display}
            onPageSelect={this.basePaginationService.onPageSelect}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            data={data}
            config={tableConfig}
            serverResponseWaiting={serverResponseWaiting}
            pagination={queryParameters.pagination}
            createHandler={this.handleFabButtonClick}
            btnDisplay={true}
            onTableBodyClick={this.handleEditIconClick}
            viewType={activeTab}
            header={tableConfig.tabBasedHeaderConfig[activeTab]}
            deleteHandler={this.handleDeleteIconClick}
            tagList={tagList}
          />
          {confirmDialog && !confirmStatus && (
            <ConfirmationDialog actionType={dialogType} onModalConfirmClose={this.onModalConfirmClose} handleConfirmModalSubmit={this.handleConfirmModalSubmit} data={payment}     cashBank={cashBank}   ledgerList={ledgerList}/>
          )}

        <PaymentDialog
          payment={payment}
          addTag ={addTag}
          validationFlag={validation.flag}
          actionType={dialogType}
          formEmptyField={false}
          onInputChange={this.handleInputChange}
          onModalClose={this.handleModalClose}
          onModalSubmit={this.handleModalSubmit}
          ledgerList={ledgerList}
          tagList={tagList}
          cashBank={cashBank}
          paymentType={paymentType}
          chequeValidation={chequeValidation.flag}
          fiscalYearRange ={fiscalYearRange}
          to_ledger={to_ledger}
          activeTab={activeTab}
          confirmStatus={confirmStatus}
        />
        </div>
        <div className={printButtonClicked ? 'display-block payment_print portrait-type' : 'no-print'}>
          {dataList.map((ceItem, key) => (
            <div className="print-body zoom-reduce voucher-print">
              <div className="jv card-body">
                <Grid>
                  <Cell col={2} tablet={2}>
                    <div className="header-title">{company.title}</div>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Address: </span>
                      <span>{company.address}</span>
                    </span>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Phone: </span>
                      <span>{company.phone}</span>
                    </span>
                    <span className="no-margin-bottom header-title-content">
                      <span className="active-opacity-text">PAN No: </span>
                      <span>{company.panNumber}</span>
                    </span>
                  </Cell>
                  <Cell col={4} tablet={4}>
                    <div className="center-align">
                      <h2>{PAYMENT_CONFIG.title}</h2>
                      <div className="default-margin-top-2">
                        <span className="header-title-content no-margin-bottom">
                          <span className="active-opacity-text fs-14">
                            Report from
                            <span className="date-rendered">
                              {` ${filterUtil.formatToReadableDate(
                                queryParameters.date.start,
                              )} - ${filterUtil.formatToReadableDate(queryParameters.date.end)}`}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </Cell>
                  <Cell col={2} tablet={2}>
                    <div className="header-menu-right date text-right">
                      <span>
                        {' '}
                        <span className="">Date: </span>
                        <span>{date}</span>
                      </span>
                    </div>
                  </Cell>
                </Grid>
                <div className={` fixed-table-wrapper ${!data.footer ? 'fixed-table-height ' : 'total-footer'}`}>
                  <table>
                    <TableHeader headerDetails={tableConfig.tabBasedHeaderConfig[activeTab]} handleSorting={null} />
                    {tableConfig.getTableBody({
                      dataList: ceItem.list,
                      remainingLines: ceItem.remainingLines,
                      viewType: activeTab,
                    })}
                  </table>
                </div>
              </div>
              <div className="footer-block">
                <PageNumber value={ceItem.page} totalPage={dataList.length} />
              </div>
            </div>
          ))}
        </div>

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </PaymentStyled>
    );
  }
}

const PaymentWithState = withBaseState(Payment);

export default PaymentWithState;
