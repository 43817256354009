import React, { Fragment } from 'react';
import TagWiseChildView from './childView';
import AccordionTable from '../../../components/CollapsibleTable/AccordionTable';
import { roundAndCommas } from '../../../utils/conversion';

const TableBody = ({ data, onRowClick ,onSpanClick }) => (
  <>
    <td className={`left-align tag ${data.ledgers.length > 0 ? 'tagExist' : 'tagNotExist'}`} onClick={() => onRowClick(data)}>
      <span ><b data-tooltip={`${data.title} Detail Page`}   onClick={(e) => {
        e.stopPropagation();
        onSpanClick(data)}}>{data.title}</b></span>
    </td>
    <td className='right-align opening' onClick={() => onRowClick(data)}>
      {data.opening ? roundAndCommas(data.opening) : data.opening}
    </td>
    <td className='right-align openingStatus' onClick={() => onRowClick(data)}>
      {data.openingType}
    </td>
    <td className='right-align debit' onClick={() => onRowClick(data)}>
      {data.debit ? roundAndCommas(data.debit) : data.debit }
    </td>
    <td className='right-align credit' onClick={() => onRowClick(data)}>
      {data.credit ? roundAndCommas(data.credit) : data.credit}
    </td>
    <td className='right-align closing' onClick={() => onRowClick(data)}>
      {data.closing ? roundAndCommas(data.closing) : data.closing}
    </td>
    <td className='right-align closingStatus' onClick={() => onRowClick(data)}>
      {data.closingType}
    </td>
  </>
);

const TagTableBody = ({ dataList, onRowClick, onTableClickData = [],onSpanClick }) => (
  <tbody>
    <AccordionTable>
      {dataList.map((data, index) => (
       data.ledgers.length > 0 &&  <tr
          key={index}
          trigger={TableBody({
            data,
            onRowClick,
            onSpanClick,
          })}
          accordionStatus={data.ledgers.length > 0}
        >
          {TagWiseChildView({ data: onTableClickData })}
        </tr>
      ))}
    </AccordionTable>
  </tbody>
);

const customerLedgerConfig = [
  {
    id: 1,
    label: 'title',
    title: 'Tag',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 2,
    label: 'opening',
    title: 'Opening',
    className: 'cursor-pointer text-right',
    sortable: true,
  },
  {
    id: 3,
    label: 'openingType',
    title: '',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 4,
    label: 'debit',
    title: 'Debit',
    className: 'cursor-pointer text-right',
    sortable: true,
  },
  {
    id: 5,
    label: 'credit',
    title: 'Credit',
    className: 'cursor-pointer text-right',
    sortable: true,
  },
  {
    id: 6,
    label: 'closing',
    title: 'Closing',
    className: 'cursor-pointer text-right',
    sortable: true,
  },
  {
    id: 7,
    label: 'closingType',
    title: '',
    className: 'cursor-pointer',
    sortable: true,
  },
];

const config = {
  header: customerLedgerConfig,
  getTableBody: TagTableBody,
};

export default config;
