import React from 'react';

const formValidationFieldList = [{}];

const roundingOptions = [
  { value: 'round_up', title: 'Round Up' },
  { value: 'round_down', title: 'Round Down' },
  { value: 'round_to_nearest_whole_number', title: 'Round to Nearest Whole Number' },
];
const roundingDigits = [
  { value: 2, title: 2 },
  { value: 3, title: 3 },
  { value: 4, title: 4 },
];

const config = {
  formValidationFieldList,
  roundingOptions,
  roundingDigits,
};

export default config;
