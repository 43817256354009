
import {
  ACTION, VAT_PERCENTAGE,VAT_ZERO_PERCENTAGE
} from '../../../../data/enums/enums';

const skuDetailsMapper = (data = {}) => ({
  title: data.title || '',
  description: data.description || '',
  rlp: data.rlp || 0,
  status:1,
  businessId: data.businessId || null,
  businessSkuId: data.businessSkuId || null,
  vatPercent:  data?.vatPercent || VAT_ZERO_PERCENTAGE,
});
const formValidationFields = [{ title: 'title' }, { title: 'description' }, { title: 'rlp' }];

const getApiTransformedData = (data, eventType) => {
  let formattedData = { ...data };
  if (eventType === ACTION.ADD) {
    const { businessSkuId,catalogSkusList, ...modifiedData } = data;
    formattedData = { ...modifiedData };
  }

  return {
    ...formattedData,
  };
};

const message = {
  ADD: 'Service created successfully!',
  UPDATE: 'Service updated successfully!',
  SERVER_ERROR: '!Server Error',
  CREATE_ERROR: '!Error while creating Service',
  UPDATE_ERROR: '!Error while updating Service',
};

export {
  skuDetailsMapper, formValidationFields, getApiTransformedData, message,
};
