import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PASSWORD_CRITERIA_MSG, PASSWORD_RULE } from '../../../data/enums/enums';
import history from '../../../utils/history';
import ResetPWStyled from './ResetPWStyled';
import EvolveLogo from '../../../styles/svg/Evolve.svg';
import { Button } from '../../../components/BillingMDC';
import * as snackService from '../../common/snack.service';
import BillingTextField from '../../../components/TextField/BillingTextField';
import BillingSnackBar from '../../../components/BillingMDC/BillingSnackbar';

const propTypes = {
  serverResponseWaiting: PropTypes.bool.isRequired,
  serverResponseError: PropTypes.string.isRequired,
  formEmptyField: PropTypes.bool.isRequired,
  pwCriteriaStatus: PropTypes.bool.isRequired,
};
const defaultProps = {
  formEmptyField: false,
  pwCriteriaStatus: false,
};

class ResetPassword extends Component {
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  constructor(props) {
    super(props);
    this.state = {
      form: {
        newPassword: '',
        repeatPassword: '',
      },
      validationArr: ['newPassword', 'repeatPassword'],
      formEmptyField: false,
      token: props.match.params.token,
      snack: { ...snackService.snackParameters },
    };
  }

  handleInputChange = (field, value) => {
    const { form } = this.state;
    form[field] = value;
    this.setState({ form });
  };

  validateForm = (formObject, config, flag) => {
    const data = Object.keys(formObject).some(k => {
      if (config.indexOf(k) > -1) {
        const value = formObject[k];
        if (!value) {
          this.setState({ [flag]: true });
          return true;
        }
        if (k === 'newPassword' && value && flag === 'formEmptyField') {
          const valid = value.match(PASSWORD_RULE);
          if (!valid) {
            this.setState({ [flag]: true });
            return true;
          }
          return false;
        }
        if (k === 'repeatPassword' && value && flag === 'formEmptyField') {
          const { form } = this.state;
          const valid = value !== form.newPassword;
          if (valid) {
            this.setState({ [flag]: true });
            return true;
          }
          return false;
        }
        return false;
      }
      return false;
    });

    return !data;
  };

  handleModalSubmit = () => {
    const { form, validationArr } = this.state;
    const formValid = this.validateForm(form, validationArr, 'formEmptyField');
    if (formValid) {
      this.resetPassword();
      this.handleModalClose();
    }
  };
  resetPassword = () => {
    const { form, token } = this.state;
    const { resetPassword } = this.props;
    const data = {
      password: form.repeatPassword,
      reset_code: token,
    };
    resetPassword({
      query: data,
    })
      .then(response => {
        const snack = snackService.generateSuccessMessage('New Password is Sucessfully Created');
        this.setState({
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage(err.message);
        this.setState({ snack });
      });
    this.handleModalClose();
  };
  handleModalClose = () => {
    setTimeout(() => {
      history.push('/login');
    }, 900);
    this.setState({
      form: {
        newPassword: '',
        repeatPassword: '',
      },
    });
  };
  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { form, formEmptyField, snack } = this.state;
    const { serverResponseWaiting, serverResponseError } = this.props;
    const check = form.newPassword.match(PASSWORD_RULE);
    const pwCriteriaStatus = check === null;
    return (
      <ResetPWStyled className="form">
        <div className="form__logo">
          <img src={EvolveLogo} alt="logo" />
        </div>
        <h3>
          Billing <strong>System</strong>
        </h3>
        <div className="form__wrapper">
          <header className="form__header">
            <h3>Create New Password</h3>
          </header>
          <div className="form__body">
            <BillingTextField
              value={form.newPassword}
              handleChange={this.handleInputChange}
              floatingLabel="New Password"
              param="newPassword"
              required={true}
              type="password"
              className="right-side-gap"
              pattern={PASSWORD_RULE}
              emptyField={formEmptyField}
              errorMessage={
                form.newPassword === '' ? ' Should not be empty' : pwCriteriaStatus ? PASSWORD_CRITERIA_MSG : ''
              }
              invalidError={formEmptyField}
            />
            <BillingTextField
              value={form.repeatPassword}
              handleChange={this.handleInputChange}
              floatingLabel="Confirm Password"
              param="repeatPassword"
              required={true}
              type="password"
              className="right-side-gap"
              emptyField={formEmptyField}
              errorMessage={
                form.repeatPassword === ''
                  ? 'Should not be empty'
                  : form.newPassword !== form.repeatPassword
                  ? 'Should be same as New Password'
                  : ''
              }
              invalidError={formEmptyField && form.newPassword !== form.repeatPassword}
            />
          </div>
          <footer className="form__footer">
            <Button accent className="dialog-cancel modal-btn" onClick={() => this.handleModalClose()}>
              Cancel
            </Button>
            <Button
              accent
              className="dialog-ok modal-btn"
              onClick={() => {
                this.handleModalSubmit();
              }}
            >
              Save
            </Button>
          </footer>
        </div>
        <p className="copyright">© 2017 Evolve</p>
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </ResetPWStyled>
    );
  }
}

ResetPassword.propTypes = propTypes;
ResetPassword.defaultProps = defaultProps;

export default ResetPassword;
