import styled from 'styled-components';

const SRNStyled = styled.div`
.block-overlay-wrapper{
  cursor:not-allowed;
}
  .header-menu-right .drawer_filter{
      span{
        top: 18px;
      }
    }
`;
export default SRNStyled;
