const schema = 'https://';
const localUrl = '127.0.0.1:8080';
const serverUrl = window.location.hostname;
const devConfig = {
  baseUrl: 'http://localhost:8850/api/v1/',
  imageUrl: 'https://storage.googleapis.com/some-folder',
};
const prodConfig = {
  baseUrl: `${schema + serverUrl}/api/v1/`,
  imageUrl: 'https://storage.googleapis.com/some-folder',
};
if (process.env.NODE_ENV === 'production') {
  module.exports = prodConfig;
} else {
  module.exports = devConfig;
}
