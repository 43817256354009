import styled from 'styled-components';

const ArchivedEmailStyled = styled.div`
  table{
    thead,
    tbody{
      th{
        z-index:2;
      }
      th.download-icon,
      td.download-icon{
        width:40px;
      }
    }
  }
  .archiveEmail-title{
    font-weight:400;
    b{
      font-weight:600;
    }
  }
`;
export default ArchivedEmailStyled;
