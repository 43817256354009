import React, { Component } from 'react';
import Dialog from './Dialog';
import tableConfig from './config';
import { validateForm } from '../../common/validation';
import PageView from '../../common/pagination/PageView';
import { AGENT_CONFIG } from '../../common/domain.config';
import * as queryService from '../../common/query.service';
import * as snackService from '../../common/snack.service';
import { ACTION } from '../../../data/enums/enums';
import { BillingSnackBar } from '../../../components/BillingMDC';

export class Agent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: { ...queryService.queryParameters },
      dialogType: ACTION.NULL,
      data: {
        list: [],
        total: 0,
      },
      agent: {
        name: '',
        phone: '',
        status:true,
      },
      validation: {
        flag: false,
        fieldList: tableConfig.formValidationFieldList,
      },
    };
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { getAgentList } = this.props;
    const { queryParameters } = this.state;

    getAgentList(queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.total;
      this.setState({ data });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleEditIconClick = data => {
    const { agent } = this.state;
    agent.id = data.idAgent;
    agent.name = data.name;
    agent.phone = data.phone;

    this.setState({
      dialogType: ACTION.UPDATE,
      agent,
    });
  };

  handleFabButtonClick = () => {
    const { agent } = this.state;
    agent.name = '';
    agent.phone = '';
    this.setState({
      dialogType: ACTION.ADD,
      agent,
    });
  };

  handleModalSubmit = () => {
    const { agent, validation, dialogType } = this.state;
    const formValid = validateForm(agent, validation, valid => this.setState({ validation: valid }));

    if (!formValid) return false;

    this.handleModalClose();

    switch (dialogType) {
      case ACTION.ADD:
        this.createAgent();
        break;
      case ACTION.UPDATE:
        this.editAgent();
        break;
      default:
        break;
    }
  };

  createAgent = () => {
    const { create } = this.props;
    const { agent, data } = this.state;

    const agentForRequest = {
      phone: agent.phone,
      name: agent.name,
    };
    create(agentForRequest)
      .then(response => {
        data.list.push(response.agent);
        data.list.total += 1; // (data.list.total + 1);
        const snack = snackService.generateSuccessMessage();
        this.setState({
          data,
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage();
        this.setState({
          data,
          snack,
        });
      });
  };

  editAgent = () => {
    const { update } = this.props;
    const { agent, data } = this.state;

    update({
      id: agent.id,
      query: {
        name: agent.name,
        phone: agent.phone,
      },
    })
      .then(res => {
        const updatedDataList = data.list.map(item =>
          item.idAgent === Number(res.agent.id_agent)
            ? {
                ...res.agent,
                idAgent: Number(res.agent.id_agent),
              }
            : item,
        );
        data.list = updatedDataList;
        const snack = snackService.generateUpdateMessage();
        this.setState({
          data,
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage();
        this.setState({ snack });
      });
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.setState({ dialogType: ACTION.NULL });
  };

  handleInputChange = (field, value,data) => {
    const { agent } = this.state;
    agent[field] = value;
    this.setState({ agent },()=>{
      if(field==="status"){
      this.changeStatus(data);
      }
    });
  };
  changeStatus = data => {
    const { updateStatus} = this.props;
    const { agent} = this.state;
    const queryData = {
      status: agent.status,
    };
    updateStatus({
      id: data.idAgent,
      query: queryData,
    })
      .then(response => {
        const snack = snackService.generateUpdateMessage();
        this.loadTableData();
        this.setState({ snack });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage();
        this.setState({ snack });
      });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { data, display, snack, dialogType, agent, validation } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <div className="agents">
        <PageView
          domainConfig={AGENT_CONFIG}
          headerConfig={{
            search: false,
            date: false,
            download: false,
            filter: false,
            create: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          data={data}
          tableConfig={tableConfig}
          onTableBodyClick={this.handleEditIconClick}
          createHandler={this.handleFabButtonClick}
          onInputChange={this.handleInputChange}
        />

        <Dialog
          actionType={dialogType}
          onModalSubmit={this.handleModalSubmit}
          onModalClose={this.handleModalClose}
          onInputChange={this.handleInputChange}
          agent={agent}
          validationFlag={validation.flag}
        />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}

export default Agent;
