import React from 'react';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import moment from 'moment/moment';
import { has } from '../../../utils/hasOwnProperty';

const title = 'Contra Entry';
const getSummaryViewList = (data = {}) => {
  const summaryList = [
    {
      title: 'Narration',
      value: data.narration || '',
    },
  ];

  return summaryList;
};

const getPrintSummaryDetails = (data = {}) => {
  const printSummaryDetails = [{ title: 'Voucher Number', value: data.voucherNumber }];

  return printSummaryDetails;
};

const CeDetailBody = ({ dataList,remainingLines,dataSummary,footer=false }) => (
  <tbody>
    {dataList.map((data, index) => (
      <tr className='cursor-pointer'>
        <td className='left-align title'>{data.customer.title}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.drAmount) || data.dramount}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.crAmount) || data.crAmount}</td>
        <td className='left-align ref-no'>{data.refNumber}</td>
        <td className='left-align voucher-num'>{data.voucherNumber}</td>
        <td className='left-align'>{moment(data.refDate).format('ll')}</td>
        <td className='left-align miti'>{data.mitiTitle}</td>
        <td className='left-alig narration'>{data.narration}</td>
      </tr>
    ))}
    {
      footer &&
      <tr className='total'>
        {config.getFooterContent(dataSummary).map((item, index) => (
          <td className={`font-bold ${index !== 0 ? 'right-align' : ''}`}>
            {fixedFloatAndCommas(item.value)}
          </td>
        ))}
        <td colSpan='5'></td>
      </tr>
    }
    {remainingLines && remainingLines.length
      ? remainingLines.map((i) => (
        <tr className='blank-tr'>
          <td></td>
          <td colSpan ='11' className=''></td>
        </tr>
      ))
      : null}
  </tbody>
);

const getFooterContent = (data) => {
  const footerList = [
    { title: 'Total', value: 'Total', display: false },
    { title: 'Total Debit:', value: data.drAmount },
    { title: 'Total Credit:', value: data.crAmount },
  ];
  return footerList;
};

const userDetailMapper = (data = {}) => {
  let userDetail = { id: 'userId', title: '' };
  if (has.call(data, 'actionUserDetail') && data.actionUserDetail) {
    userDetail.title = data.actionUserDetail.name || '';
  } else userDetail.title = data.journalVoucher ? (data.journalVoucher.user ? data.journalVoucher.user.name : '') : '';
  return userDetail;
};

const ceDetailConfig = [
  { id: 2, label: 'ledgers.title', title: 'Ledger', className: 'cursor-pointer left-align', sortable: true },
  { id: 3, label: 'dr_amount', title: 'Debit', className: 'cursor-pointer right-align', sortable: true },
  { id: 4, label: 'cr_amount', title: 'Credit', className: 'cursor-pointer right-align', sortable: true },
  { id: 5, label: 'ref_number', title: 'Ref No', className: 'cursor-pointer left_align', sortable: true },
  { id: 7, label: 'voucher_number', title: 'Voucher No.', className: 'cursor-pointer left_align', sortable: true },
  { id: 1, label: 'ref_date', title: 'Ref Date', className: 'cursor-pointer left-align', sortable: true },
  { id: 1, label: 'miti_title', title: 'Miti', className: 'cursor-pointer left-align', sortable: true },
  { id: 6, label: 'narration', title: 'Narration', className: 'cursor-pointer left-align', sortable: true },
];
const getData = (data, label) => {
  switch(label) {
  case 'customerTitle': return (data.customer && data.customer.title) || null;
  default:
    return null;
  }
}

const signatureTitleList = [
  { id: 0, title: 'Received By', value: '' },
  {
    id: 1,
    title: 'Prepared By',
    value: '',
  },
  { id: 3, title: 'ApprovedBy', value: '' },
];
const lengthyAttributes = ['customerTitle', 'narration' ];
const printEnums = {
  customerTitle: 20,
  narration: 20,
  lengthyAttributes,
  withoutFooterLimit: 44,
  withFooterLimit: 41,
};

const config = {
  title,
  header: ceDetailConfig,
  getTableBody: CeDetailBody,
  getFooterContent,
  getSummaryViewList,
  userDetailMapper,
  getPrintSummaryDetails,
  signatureTitleList,
  printEnums,
  lengthyAttributes,
  getData,
  uniqueIdGetter: (i) => i.idJournalVoucherDetail,
};

export default config;
