import React from 'react';
import { Icon } from '../../../components/BillingMDC';
import { VALIDATION_TYPES } from '../../common/validation';

const createUserMapper = (data = {}) => ({
  idUsers: data.idUsers || '',
  name: data.name || '',
  email: data.email || '',
  phone_number: data.phone_number || data.phoneNumber || '',
  group_id: data.group_id || data.group || '',
  status: data.status,
});

const StockLedgerBody = ({ dataList, onRowClick, userGroupList }) => {
  const getUserGroupName = (id) => {
    return (
      userGroupList.filter((list) => list.group_id === id).length &&
      userGroupList.filter((list) => list.group_id === id)[0].name
    );
  };
  return (
    <tbody>
      {dataList.map((data, index) => (
        <tr>
          <td className='left-align'>{data.idUsers}</td>
          <td className='left-align'>{data.name}</td>
          <td className='left-align'>{data.email}</td>
          <td className='left-align'>{getUserGroupName(data.group_id)}</td>
          <td className='left-align'>{userStatus.find((item) => item.status === data.status).name}</td>
          <td className='left-align'>{data.phone_number}</td>
          <td className='right-align'>
            <Icon name='edit' onClick={() => onRowClick(data, index)} />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const stockLedgerConfig = [
  {
    id: 1,
    label: 'id_users',
    title: 'ID',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 2,
    label: 'name',
    title: 'Name',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 3,
    label: 'email',
    title: 'Email',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 3,
    label: 'group',
    title: 'User Group',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 4,
    label: 'status',
    title: 'Status',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 5,
    label: 'phone_number',
    title: 'Phone',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 6,
    label: '',
    title: '',
    className: 'cursor-pointer',
    sortable: false,
  },
];

const userStatus = [
  { status: 1, name: 'Active' },
  { status: 0, name: 'Inactive' },
];

const formValidationFieldList = [
  { title: 'name', },
  {
    title: 'email',
    type: VALIDATION_TYPES.EMAIL,
  },
  {
    title: 'password',
    type: VALIDATION_TYPES.PASSWORD_RULE,
  },
];

const config = {
  header: stockLedgerConfig,
  getTableBody: StockLedgerBody,
  formValidationFieldList,
  createUserMapper,
  userStatus,
};
export { createUserMapper };
export default config;
