import React, { Component } from 'react';
import tableConfig from './config';
import history from '../../../utils/history';
import { ACTION, STATUS } from '../../../data/enums/enums';
import { validateForm } from '../../common/validation';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { SERVICE_CONFIG } from '../../common/domain.config';
import { SERVICE_CREATE, SERVICE_DETAIL } from '../../../data/enums/Route';
import { AddFab, BillingSnackBar } from '../../../components/BillingMDC';
import { catalogsApi, checkIfApiCallSuccess } from '../../common/base.api';

class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
      },
      dialogType: ACTION.NULL,
      snack: { ...snackService.snackParameters },
      queryParameters: {
        search: queryService.queryParameters.search,
        sort: queryService.queryParameters.sort,
        pagination: queryService.queryParameters.pagination,
      },
      data: {
        list: [],
        total: 0,
      },
      sku: tableConfig.getDetail(),
      batch: tableConfig.getBatchDetail(),
      buList: [],
      brandList: [],
      skuList: [],
    };

    this.reference = {
      tableHeader: React.createRef(),
      tableFooter: React.createRef(),
      tableBody: React.createRef(),
      fileRef: React.createRef(),
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
    this.loadBUList();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadBUList = () => {
    const { getBUList } = this.props;
    const { buList } = this.state;

    getBUList().then(response => {
      const filteredBUList = response.list.filter(list => list.status === STATUS.ACTIVE);
      this.setState({ buList: filteredBUList });
    });
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getSKUList } = this.props;
    const { queryParameters } = this.state;

    getSKUList({ query: queryParameters, extraQuery: '&with_inactive=true' }).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.total;
      this.setState({ data });
    });
  };

  handleEditIconClick = data => {
    const sku = tableConfig.getDetail(data);
    this.setState({ dialogType: ACTION.UPDATE, sku });
  };

  handleFabButtonClick = () => {
    const sku = tableConfig.getDetail();
    history.push(`/${SERVICE_CREATE}`);
    // this.setState({ dialogType: ACTION.ADD, sku });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  // get the brand list
  getBrandList = (businessUnitId = this.state.businessUnitId) => {
    catalogsApi.getBrandList({ business_id: businessUnitId }).then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ brandList: response.data.list });
      }
    });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = (brandId, businessId) => {
    catalogsApi
      .getSkuUnderBrand({
        business_id: businessId,
        brand_id: brandId,
      })
      .then(response => {
        if (checkIfApiCallSuccess(response)) {
          this.setState({
            skuList: response.data.list,
          });
        }
      });
  };

  handleInputChange = (field, value) => {
    const { sku, batch } = this.state;
    sku[field] = value;
    this.setState({ sku });
    if (field === 'businessId') {
      this.getBrandList(value);
    }
    batch[field] = value;
    if (field === 'brand') {
      this.getSKUListUnderBrand(value, batch.businessId);
      batch.skuId = '';
    }
    this.setState({ batch });
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.setState({ dialogType: ACTION.NULL, validation });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  createService = () => {
    const { sku, data } = this.state;
    const { create } = this.props;
    const apiTransformedData = tableConfig.getApiTransformedData(sku);
    create(apiTransformedData).then(response => {
      const { skuData } = response;
      data.list.push(skuData);
      data.list.total += 1; // (data.list.total + 1);
      const snack = snackService.generateSuccessMessage();
      this.setState({ data, snack });
    });
  };

  editService = () => {
    const { sku, data } = this.state;
    const { update } = this.props;
    const apiTransformedData = tableConfig.getApiTransformedData(sku, ACTION.UPDATE);
    update({ id: sku.idLedger, query: apiTransformedData }).then(res => {
      const updatedDataList = data.list.map(item => (item.idLedger === Number(res.sku.idLedger) ? res.sku : item));
      data.list = updatedDataList;
      const snack = snackService.generateUpdateMessage();
      this.setState({ data, snack });
    });
  };

  createServiceBatch = () => {
    const { batch } = this.state;
    const { createSkuBatch } = this.props;
    const apiTransformedData = tableConfig.getApiTransformedBatchData(batch);
    createSkuBatch(apiTransformedData).then(response => {
      const snack = snackService.generateSuccessMessage();
      this.setState({ snack });
    });
  };

  handleModalSubmit = () => {
    const { sku, batch, validation, skuValidation, dialogType } = this.state;
    if (dialogType === ACTION.ADD) {
      const batchValidation = validateForm(batch, validation, valid => this.setState({ validation: valid }));
      if (!batchValidation) {
        return false;
      }
    } else {
      const skuDialogValid = validateForm(sku, skuValidation, valid => this.setState({ validation: valid }));
      if (!skuDialogValid) {
        return false;
      }
    }
    this.handleModalClose();
    switch (dialogType) {
      case ACTION.ADD:
        this.createServiceBatch();
        break;
      case ACTION.UPDATE:
        this.editService();
        break;
      default:
        break;
    }
  };
  handleRowClick = sku => {
    history.push(`/${SERVICE_DETAIL}/${sku.idSku}`);
  };

  render() {
    const { queryParameters, data, display, snack} =
      this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <div className="sku service">
        <PageView
          domainConfig={SERVICE_CONFIG}
          headerConfig={{
            search: true,
            download: false,
            date: false,
            filter: false,
            create: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          resetFilter={this.basePaginationService.resetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onTableBodyClick={this.handleRowClick}
          onPageSelect={this.basePaginationService.onPageSelect}
          createHandler={this.handleFabButtonClick}
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}

const ServiceWithState = withBaseState(Service);

export default ServiceWithState;
