import { compose } from 'recompose';

import ProfitLoss from './ProfitLoss';

import apiInterceptor from '../../../api/interceptor';

import { profitLossApi } from '../../common/base.api';

const composedProfitLoss = compose(apiInterceptor({ ...profitLossApi }))(ProfitLoss);

export default composedProfitLoss;
