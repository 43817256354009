import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../../utils/conversion';

const SRNDetailTableBody = ({
  dataList = [],
  printInfoBill = false,
  remainingLines = [],
}) => {
  return (
    <tbody>
      {dataList.map((data, index) => (
        <tr className='cursor-pointer'>
          <td className='left-align sn'>{data.index ? data.index + 1 : index + 1}</td>
          <td className='left-align sku service'>{data.sku}</td>
          <td className='left-align description'>{data.particulars}</td>
          <td className='right-align quantity'>{data.quantity}</td>
          <td className='right-align rate'>{fixedFloatAndCommas(data.rate)}</td>
          <td className='right-align netAmount font-bold' >
            {fixedFloatAndCommas(data.netAmount)}
          </td>
        </tr>
      ))}
      {remainingLines.length ? remainingLines.map((i) => (
        <tr className='blank-tr'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      )):null}
    </tbody>
  );
};

SRNDetailTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
};

const headerConfig = [
  {
    id: 1,
    label: 'idSalesReturnDetail',
    title: 'SN',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 3,
    label: 'sku',
    title: 'Service',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 11,
    label: 'particulars',
    title: 'Description',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 8,
    label: 'quantity',
    title: 'Quantity',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 4,
    label: 'rate',
    title: 'Rate',
    className: 'cursor-pointer right-align',
    sortable: true,
  },
  {
    id: 10,
    label: 'netAmount',
    title: 'Amount',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
];

const title = {
  original: 'SALES RETURN',
};
const signatureTitle = 'Prepared By';

const customerDetailMapper = (detail) => ({
  invoiceId: detail.refNumber || '',
  customerName: detail.customerName
    ? detail.customerName
    : detail.customer
      ? detail.customer.title
      : '',
  address: detail.customer ? detail.customer.address : '',
  panNo: detail.customer ? detail.customer.panNo : '',
  salesReturnNumber: detail.salesReturnInvoiceNumber || '',
});

const billSummaryMapper = (data) => ({
  subTotal: data.subTotal || 0,
  billDiscount: data.billDiscount || 0,
  tradeDiscount: data.tradeDiscount || 0,
  taxableAmount: data.taxableAmount || 0,
  totalValue: data.netAmount || 0,
  vat: data.vat || 0,
  remarks: data.remarks || '',
  totalInWords: data.totalInWords || '',
  billPercent: data.billPercent || 0,
  tradePercent: data.tradePercent || 0,
  agentName: data?.agentData?.name
});
const lengthyAttributes = ['sku'];
const printEnums = {
  withoutFooterLimit: 42,
  withFooterLimit: 34,
  sku: 72,
  batch: 1,
  lengthyAttributes,
};
const printEnumsBatch = {
  withoutFooterLimit: 42,
  withFooterLimit: 34,
  sku: 40,
  batch: 20,
  lengthyAttributes: [...lengthyAttributes, 'batch'],
};
const CASH_MODE='cash';

const config = {
  header: headerConfig,
  getTableBody: SRNDetailTableBody,
  billSummaryMapper,
  customerDetailMapper,
  signatureTitle,
  title,
  printEnums,
  printEnumsBatch,
  CASH_MODE,
  uniqueIdGetter: (i) => i.idSalesReturnDetail,
};
export default config;
