import styled from 'styled-components';

const InvoiceDetailsStyled = styled.div`
@media print {
    @page {
        margin:0 !important;
    }
    .print-body {
      padding:0 !important;
      page-break-after: always;
      height:100% !important;
      margin:0.35in !important;
      overflow:hidden;
    }

    .portrait .footer.total-section-wrapper,
    .footer-totalList{
        position:absolute;
        width:99.8%;
        padding:0 !important;
        bottom:0;
        left:0;
        border-top:1px solid rgba(0,0,0,0.54);
    }
    .sales-invoice .portrait .footer.total-section-wrapper .total_quantity_td{
        padding:0 !important;
    }
    .sales-invoice .three-input-wrapper .mdc-layout-grid .input-print:last-child{
        display:none;
    }
    .sales-invoice.srn-detail .three-input-wrapper .mdc-layout-grid .input-print:last-child{
        display:unset;
    }

    .sales-invoice .print-body table th{
    white-space: nowrap !important;
}
.sales-invoice .print-body table td,
.sales-invoice .print-body table th{
    line-height:16px !important;
    padding:0 !important;
    padding-right:8px !important;
}
.sales-invoice .print-body table td:last-child,
.sales-invoice .print-body table th:last-child{
    padding-right:2px !important;
}
.sales-invoice .print-body .total-section-wrapper td.pad-r-2{
    padding-right:2px !important;
}
.sales-invoice .print-body table td{
    vertical-align: top !important;
    white-space: normal !important;
    word-break: break-word !important;
    text-overflow: unset !important;
    overflow:unset !important;
}
.sales-invoice .print-body table th{
    white-space: nowrap !important;
}
.sales-invoice .print-body table td,
.sales-invoice .print-body table th{
    line-height:16px !important;
    padding:0 !important;
    padding-right:8px !important;
}
.sales-invoice .print-body table td:last-child,
.sales-invoice .print-body table th:last-child{
    padding-right:2px !important;
}
.sales-invoice .print-body .total-section-wrapper td.pad-r-2{
    padding-right:2px !important;
}
.sales-invoice .print-body table td{
    vertical-align: top !important;
    white-space: normal !important;
    word-break: break-word !important;
    text-overflow: unset !important;
    overflow:unset !important;
    padding-top:3px !important;
}
.sales-invoice .print-body table th,
.sales-invoice .print-body table td{
         box-sizing:border-box;
     }
     .sales-invoice .print-body .fixed-table-wrapper{
         border-bottom:1px solid rgba(0,0,0,0.54) !important;
     }
            /* grn-details styling */
        .total_quantity_td{
            position: relative;
        }
        .batch-disable{
            position: absolute;
            left: 39%;
            top: 6px;
        }
        .srn-detail .batch-disable{
            left: 59%;
            display: flex;
            gap: 60px;
        }
        .srn-detail .batch-enable{
            left: 64%;
        }
        .batch-enable{
            position: absolute;
            left:42%;
            top: 6px;
        }
        .invoice_value{
            text-transform: capitalize;
        }
        /* grn-details styling */
}

@media print and (max-width: 8.27in){
       /* styling for A4 */
  
     .sales-invoice .print-body{
        height:1160px !important;
     }
.sales-invoice .fixed-table-wrapper.fixed-table-height {
        min-height: 945px !important;
        height: 945px;
        max-height: 945px;
        border:0;
    }
    .sales-invoice .fixed-table-wrapper.total-footer {
        min-height: 648px !important;
        height: 648px;
        max-height: 648px;
    }
    
     .sales-invoice .print-body table td.sn {
        width:30px !important;
        min-width:30px !important;
        max-width:30px !important;
     }
     .sales-invoice .print-body table td.sku{
        width:240px !important;
        min-width:240px !important;
        max-width:240px !important;
        display: table-cell !important;
     }
     .sales-invoice .print-body table td.batch{
        width:110px !important;
        min-width:110px !important;
        max-width:110px !important;
        line-height:14px !important;
        word-break: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
         overflow:hidden;
         text-overflow: ellipsis;
     }
     .sales-invoice .print-body table td.quantity
    {
        width:60px !important;
        min-width:60px !important;
        max-width:60px !important;
     }
     .sales-invoice .print-body table td.discount,
     .sales-invoice .print-body table td.rate{
        width:65px;
        min-width:65px !important;
        max-width:65px !important;
     }
     .sales-invoice .print-body table td.amount {
        width:80px;
        min-width:80px !important;
        max-width:80px !important;
    }
    .sales-invoice .print-body table td.netAmount{
        width:80px;
        min-width:80px !important;
        max-width:80px !important;
     }

     /* batch not exist  */
     /* .sales-invoice .print-body .batch_not_exist td.sku{
        width:calc(216px + 113px - 20px) !important;
        min-width:calc(216px + 113px - 20px) !important;
        max-width:calc(216px + 113px - 20px) !important;
     } */
     .sales-invoice .print-body .batch_not_exist td.amount {
        width:calc(80px + 10px) !important;
        min-width:calc(80px + 10px) !important;
        max-width:calc(80px + 10px) !important;
    }
    .sales-invoice .print-body .batch_not_exist td.netAmount{
        width:calc(80px + 10px) !important;
        min-width:calc(80px + 10px) !important;
        max-width:calc(80px + 10px) !important;
     }
        .sales-invoice .fixed-table-wrapper table,.sales-invoice.srn-detail .fixed-table-wrapper table{
            height:100%;
        }
        .sales-invoice.srn-detail .print-body{
         height:1160px !important;
     }
    .sales-invoice .blank-tr,.sales-invoice.srn-detail .blank-tr{
        height:24.5px !important;
    }
    .sales-invoice .header-bar,
    .sales-invoice .first-header-bar,
    .sales-invoice.srn-detail .header-bar,
    .sales-invoice.srn-detail .first-header-bar{
        border:1px solid rgba(0,0,0,0.54) !important;
        padding:12px 12px 0 12px !important;
    }
   .sales-invoice .three-input-wrapper{
        padding:15px;
        border-right:1px solid rgba(0,0,0,0.54) !important;
        border-left:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .fixed-table-wrapper,.sales-invoice.srn-detail .fixed-table-wrapper{
        margin-top:0 !important;
        overflow:auto !important;
        border-bottom:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .fixed-table-wrapper table,.sales-invoice.srn-detail .fixed-table-wrapper table{
        border-left:1px solid rgba(0,0,0,0.54) !important;
        border-right:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .print-body .fixed-table-wrapper table td,.sales-invoice.srn-detail .print-body .fixed-table-wrapper table td{
        vertical-align: middle !important;
    }
    .sales-invoice table.print-active tfoot ,.sales-invoice.srn-detail table.print-active tfoot {
        border-top:1px solid (0,0,0,0.54) !important;
    }
    .sales-invoice .print-body .fixed-table-wrapper thead{
        border:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .print-body .fixed-table-wrapper thead th:not(:last-child),.sales-invoice.srn-detail .print-body .fixed-table-wrapper thead th:not(:last-child),.grn-detail .print-body .fixed-table-wrapper thead th:not(:last-child){
        height:17.5px !important;
        border-right:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .footer .in-word,.sales-invoice.srn-detail .footer .in-word{
        border:1px solid rgba(0,0,0,0.54) !important;
        border-top:0 !important;
    }
    .sales-invoice .footer .in-word td,.sales-invoice.srn-detail .footer .in-word td{
        padding-top:6px !important;
        padding-bottom:6px !important;
    }
    .sales-invoice .print-body .footer tr:first-child td ,
    .sales-invoice .print-body .footer td ,
    .sales-invoice.srn-detail .print-body .footer td ,
    .sales-invoice.srn-detail .print-body .footer tr:first-child td{
        padding-top:6px !important;
    }
    .sales-invoice .print-body .footer tr:nth-child(5) td ,
    .sales-invoice .print-body .footer td ,
    .sales-invoice.srn-detail .print-body .footer td ,
    .sales-invoice.srn-detail .print-body .footer tr:nth-child(5) td {
        padding-bottom:6px !important;
    }
    .sales-invoice .footer .remark,.sales-invoice.srn-detail .footer .remark{
        padding-top:10px !important;
    }
    .sales-invoice .footer .total td:first-child,
    .sales-invoice .footer .total-info td:first-child,
    .sales-invoice .footer .in-word td:first-child,
    .sales-invoice.srn-detail .footer .total td:first-child,
    .sales-invoice.srn-detail .footer .total-info td:first-child,
    .sales-invoice.srn-detail .footer .in-word td:first-child {
        padding-left:6px !important;
        padding-top:16px;
    }
    .sales-invoice .footer .total td:last-child,
    .sales-invoice .footer .total-info td:last-child,
    .sales-invoice .footer .in-word td:last-child,
    .sales-invoice.srn-detail .footer .total td:last-child,
    .sales-invoice.srn-detail .footer .total-info td:last-child,
    .sales-invoice.srn-detail .footer .in-word td:last-child {
        padding-right:6px !important;
    }
    
    .sales-invoice .footer .total_quantity_td,
    .sales-invoice .footer .total-info,
    .sales-invoice.srn-detail .footer .total_quantity_td,
    .sales-invoice.srn-detail .footer .total-info{
        border-right:1px solid rgba(0,0,0,0.54) !important;
        border-left:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice,.sales-invoice.srn-detail .footer .total td{
        padding-top:6px !important;
        padding-bottom:6px !important;
    }
    .sales-invoice .total,.sales-invoice.srn-detail .total{
        border:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .footer .total-info:last-child,.sales-invoice.srn-detail .footer .total-info:last-child{
        border-bottom:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .print-body .fixed-table-wrapper td:not(:last-child),
    .sales-invoice .print-body .fixed-table-wrapper th:not(:last-child),
    .sales-invoice.srn-detail .print-body .fixed-table-wrapper td:not(:last-child),
    .sales-invoice.srn-detail .print-body .fixed-table-wrapper th:not(:last-child){
        border-right:1px solid rgba(0,0,0,0.54) !important;
        padding:4px 8px !important;
    }
    /* .sales-invoice.srn-detail .print-body table td.sku{
        width:300px !important;
        min-width:300px !important;
        max-width:300px !important;
     }
     .sales-invoice.srn-detail .print-body .batch_not_exist td.sku{
        width:calc(270px + 130px) !important;
        min-width:calc(270px + 130px) !important;
        max-width:calc(270px + 130px) !important;
     }
     .sales-invoice.srn-detail .print-body table td.batch{
        width:150px !important;
        min-width:150px !important;
        max-width:150px !important;
     } */
    .sales-invoice.srn-detail .fixed-table-wrapper.fixed-table-height {
        min-height: 980px !important;
        height: 980px;
        max-height: 980px;
        border:0;
    }
    .sales-invoice.srn-detail .fixed-table-wrapper.total-footer {
        min-height: 730px !important;
        height: 730px;
        max-height: 730px;
    }
    .abbreviate-invoice-detail .print-body .fixed-table-wrapper.fixed-table-height {
        min-height: 980px;
    height: 980px;
    border-bottom: 1px solid rgba(0,0,0,0.54);
    }
    .abbreviate-invoice-detail .print-body .fixed-table-wrapper.total-footer{
        min-height: 730px;
    height: 730px;
    border-bottom: 1px solid rgba(0,0,0,0.54);
    }
     .abbreviate-invoice-detail .print-body .fixed-table-wrapper  table td {
        vertical-align: middle !important;
    }
    .abbreviate-invoice-detail .print-body .fixed-table-wrapper .blank-tr{
        height:25px !important;
    }
    .srn-detail.sales-invoice{
        .sign{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        }
            .note,
            .extra_tr{
                display:none !important;
            }
        }
}
@media print and (max-width:5.83in) {
    .portrait .print-body{
         height:1160px !important;
     }
    .sales-invoice .fixed-table-wrapper{
         min-height:875px ;
     }
     .sales-invoice .fixed-table-wrapper.total-footer {
        min-height: 660px !important;
        height:660px;
        }
     .sales-invoice.srn-detail .fixed-table-wrapper.total-footer {
        min-height: 735px !important;
        height: 735px;
        max-height: 735px;
    }
     .sales-invoice .fixed-table-wrapper.fixed-table-height {
         min-height:800px ;
     }
     .abbreviate-invoice-detail .print-body .fixed-table-wrapper.total-footer{
        min-height: 740px;
    height: 740px;
    border-bottom: 1px solid rgba(0,0,0,0.54);
    }
/* batch not exist */
     /* .sales-invoice .print-body .batch_not_exist td.sku{
        width:calc(218px + 114px - 20px) !important;
        min-width:calc(218px + 114px - 20px) !important;
        max-width:calc(218px + 114px - 20px) !important;
     }
    .sales-invoice .print-body .fixed-table-wrapper .batch_exist td.sku{
        width:200px !important;
        min-width:200px !important;
        max-width:200px !important;
     } */
}
`;
export default InvoiceDetailsStyled;
