import styled from 'styled-components';

const InvoiceDetailsStyled = styled.div`
.email_dialog.confirmation__dialog .mdc-dialog__surface{
  max-width:420px;
  p{
    font-size:16px;
    line-height:24px;
    color:rgba(0,0,0,0.87);
    padding: 24px;
  }
  .mdc-textfield{
    margin-top: 16px !important;
    max-width:unset;
    label{
      color:rgba(0,0,0,0.54);
      font-size:12px;
    }
    input{
      font-size:16px;
    line-height:24px;
    color:rgba(0,0,0,0.87);
    border-bottom:1px solid rgba(0,0,0,0.42);
    }
  }
}
p{
    margin:0;
}
@media print {

    @page {
        margin:0 !important;
    }
    .print-body {
      padding:0 !important;
      page-break-after: always;
      height:100% !important;
      margin:0.35in !important;
      background-color:#FFFFFF;
      position:relative;
      overflow:unset;
    }

    .portrait .footer.total-section-wrapper,
    .footer-totalList{
        position:absolute;
        width:99.8%;
        padding:0 !important;
        bottom:0;
        left:0;
        /* border-top:1px solid rgba(0,0,0,0.54); */
    }
        .note_p{
            position: absolute;
            bottom: -6px;
            left: 12px;
            font-weight:600;
            }
    .sales-invoice .portrait .footer.total-section-wrapper .note td,
    .sales-invoice .sender_receiver-details ~ .footer-totalList table tfoot tr.note td,
    .sales-invoice .sender_receiver-details ~ .footer-totalList table tfoot tr:first-child .total_quantity_td,
    .sales-invoice .sender_receiver-details ~ .footer-totalList table tfoot .extra_td,
    .sales-invoice .portrait .footer.total-section-wrapper .total_quantity_td{
        padding:0 !important;
    }
    .no-pdf,
    .sales-invoice .three-input-wrapper .mdc-layout-grid .input-print:last-child{
        display:none;
    }
    .sales-invoice.srn-detail .three-input-wrapper .mdc-layout-grid .input-print:last-child{
        display:unset;
    }

    .sales-invoice .print-body table th{
    white-space: nowrap !important;
}
.sales-invoice-detail .remark{
            max-width: 350px;
            overflow: hidden;
            white-space: nowrap;
            display: flex;
        span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.sales-invoice .print-body table td,
.sales-invoice .print-body table th{
    line-height:16px !important;
    padding:0 !important;
    padding-right:8px !important;
}
.sales-invoice .print-body table td:last-child,
.sales-invoice .print-body table th:last-child{
    padding-right:2px !important;
}
.sales-invoice .print-body .total-section-wrapper td.pad-r-2{
    padding-right:2px !important;
}
.sales-invoice .print-body table td{
    vertical-align: top !important;
    white-space: normal !important;
    word-break: break-word !important;
    text-overflow: unset !important;
    overflow:unset !important;
}
.sales-invoice .print-body table th{
    white-space: nowrap !important;
}
.sales-invoice .print-body table td,
.sales-invoice .print-body table th{
    line-height:16px !important;
    padding:0 !important;
    padding-right:8px !important;
}
.sales-invoice .print-body table td:last-child,
.sales-invoice .print-body table th:last-child{
    padding-right:2px !important;
}
.sales-invoice .print-body .total-section-wrapper td.pad-r-2{
    padding-right:2px !important;
}
.sales-invoice .print-body table td{
    vertical-align: top !important;
    white-space: normal !important;
    word-break: break-word !important;
    text-overflow: unset !important;
    overflow:unset !important;
    padding-top:3px !important;
}
.sales-invoice .print-body table th,
.sales-invoice .print-body table td{
         box-sizing:border-box;
     }
     .sales-invoice .print-body .fixed-table-wrapper{
         border-bottom:1px solid rgba(0,0,0,0.54) !important;
     }
            /* grn-details styling */
        .total_quantity_td{
            position: relative;
        }
        .batch-disable{
            position: absolute;
            left: 39%;
            top: 6px;
        }
        .srn-detail .batch-disable{
            left: 61%;
        }
        .srn-detail .batch-enable{
            left: 64%;
        }
        .batch-enable{
            position: absolute;
            left:42%;
            top: 6px;
        }
        .invoice_value{
            text-transform: capitalize;
        }
        /* grn-details styling */

        /* upaya styling */
        .upaya-invoice{
            border:1px solid #FFFFFF !important;
            .card-body{
                .first-header-bar{
                    padding:12px !important;
                }
                .header-middle-content{
                    height:60px;
                }
                .footer-totalList{
                    tr:nth-child(3){
                        border-top:1px solid rgba(0,0,0,0.54);
                    }
                }
        }
        .header-right-content{
            display:flex;
            justify-content: flex-end;
        }
        .continue-nxt{
            bottom: -12px;
        }
        .footer-block{
            bottom:-30px;
        }
        .extra_tr .extra_td{
            padding:0 !important;
                .extra_div{
                position: absolute;
                left: 12px;
                top: 55px;
                display: flex;
                flex-direction: column;
                gap:4px;
                span{
                    margin-left:3px;
                    &.value{
                        font-weight:600;
                    }
                }
                }
        }
        .footer-out{
       .td-wrap {
                display: flex;
                justify-content: unset;
                flex-direction:column;
                flex-wrap: wrap;
                &>span{
                    margin-bottom:6px;
                    &:last-child{
                        margin-bottom:0;
                    }
                }
            }
            .sign{
                justify-content: end;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: end;
                text-align: center;
                margin-top:32px;
                .signature-line{
                    width:138px;
                }
            }
            .wrap{
                display:flex;
                gap:60px;
                margin-top:6px;
                .left{
                    margin-left:12px;
                    .print-on{
                        margin-bottom:6px;
                        span{
                            margin-left:4px;
                        }
                    }
                    .remark{
                        span{
                            margin-left:4px;
                        }
                    }
                }
            }
        }
    }
        .sender_receiver-details{
            display:flex;
            border-right:1px solid rgba(0,0,0,0.54) !important; ;
             border-left:1px solid rgba(0,0,0,0.54) !important;
             border-bottom:1px solid rgba(0,0,0,0.54) !important;
            padding:16px 12px;
            .font-bold{
            font-weight:700 !important;
            }
            &>div{
                &.input{
                    flex: 1;
                    .trip_no{
                        margin-bottom:24px;
                    }
                    h3{
                        font-size:12px;
                        font-weight:400;
                        margin-bottom:4px;
                    }
                    p{
                        font-size:14px;
                        font-weight:600;
                    }
                }
                &.details{
                    flex: 2;
                    display:flex;
                    justify-content: space-evenly;
                    &>div{
                        .address,
                        .phone{
                            font-size:12px;
                            font-weight:600;
                        }
                        .title{
                            font-size:12px;
                        }
                        .name{
                            font-size:14px;
                            font-weight:600;
                            margin:8px 0 4px 0;
                        }
                        p{
                            span{
                                margin-left:2px;
                            }
                        }  
                    }
                }
            }
        }
        .sales-invoice  .sender_receiver-details ~ .footer-totalList{
                        .batch-enable,
                        .batch-disable {
                        position: absolute;
                        right: 28%;
                        left: unset;
                        top: 6px;
                        display: flex;
                        .invoice_title{
                            margin-right: 280px;
                        }
            }
            table tfoot tr {
                td{
                    color:#000000;
                    opacity:unset !important;
                    .remark{
                        padding-top:0 !important;
                    }
                    &.total-content-data.item_title{
                        min-width:500px;
                    }
                &.total-content-data.item_value{
                    min-width:122px;
            }
        }
        &.in-word {
            height:48px;
            .right{
                max-width:400px;
                text-align:left;
            }
        }
        }
        }
        .sales-invoice  .sender_receiver-details + .fixed-table-wrapper {
            &.total-footer table,
            &.fixed-table-height table {
                height:unset !important;
                .blank-tr{
                        /* height:24px !important; */
                        height:50px !important;
                        &:last-child{
                            /* border:1px solid blue; */
                        }
                    }
                    tr{
                        &:first-child{
                            td{
                                padding-top:24px !important;
                            }
                        }
                        &:last-child{
                            td{
                                padding-bottom:24px  !important;
                            }
                        }
                    }
                    .three td p,
                    .two td p{
                        line-height:16px;
                        }
                    th{
                        &:last-child{
                            padding-right:4px !important;
                        }
                    }
                    td{
                        vertical-align:top !important;
                       font-size:14px !important;
                       line-height:18px !important;
                        &:last-child{
                            padding-right:4px !important;
                        }
                        &.sku.service{
                            min-width: 100px !important;
                            max-width: 100px !important;
                            width: 100px !important;
                            display:table-cell !important;
                            p{
                                max-width:20ch;
                            }
                        }
                        &.description{
                            min-width: 240px !important;
                            max-width: 240px !important;
                            width: 240px !important;
                            display:table-cell !important;
                            p{
                                max-width:40ch;
                            }
                        }
                    }
                }
        }
}

@media print and (max-width: 8.27in){
       /* styling for A4 */
  
.sales-invoice .print-body{
height:1160px !important;
position: relative;
}

.sales-invoice .print-body.isCancelled:after {
  content: "Cancelled";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; /** Y-axis align **/
  justify-content: center;
  font-size:78px;
  opacity:.2;
  transform: rotate(-45deg); 
  color:#DA1414;
}
.sales-invoice .fixed-table-wrapper.fixed-table-height {
        min-height: 970px !important;
        height: 970px;
        max-height: 970px;
        border:0;
    }
    .sales-invoice .fixed-table-wrapper.total-footer {
        min-height: 680px !important;
        height: 680px;
        max-height: 680px;
    }
     .sales-invoice .print-body table td.sn {
        width:30px !important;
        min-width:30px !important;
        max-width:30px !important;
     }
     .sales-invoice .print-body table td.sku{
        width:240px !important;
        min-width:240px !important;
        max-width:240px !important;
        display: table-cell !important;
     }
     .sales-invoice .print-body table td.batch{
        width:110px !important;
        min-width:110px !important;
        max-width:110px !important;
        line-height:14px !important;
        word-break: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
         overflow:hidden;
         text-overflow: ellipsis;
     }
     .sales-invoice .print-body table td.quantity
    {
        width:60px !important;
        min-width:60px !important;
        max-width:60px !important;
     }
     .sales-invoice .print-body table td.discount,
     .sales-invoice .print-body table td.rate{
        width:65px;
        min-width:65px !important;
        max-width:65px !important;
     }
     .sales-invoice .print-body table td.amount {
        width:80px;
        min-width:80px !important;
        max-width:80px !important;
    }
    .sales-invoice .print-body table td.netAmount{
        width:80px;
        min-width:80px !important;
        max-width:80px !important;
     }

     /* batch not exist  */
     .sales-invoice .print-body .batch_not_exist td.sku{
        width:calc(216px + 113px - 20px) !important;
        min-width:calc(216px + 113px - 20px) !important;
        max-width:calc(216px + 113px - 20px) !important;
     }
     .sales-invoice .print-body .batch_not_exist td.amount {
        width:calc(80px + 10px) !important;
        min-width:calc(80px + 10px) !important;
        max-width:calc(80px + 10px) !important;
    }
    .sales-invoice .print-body .batch_not_exist td.netAmount{
        width:calc(80px + 10px) !important;
        min-width:calc(80px + 10px) !important;
        max-width:calc(80px + 10px) !important;
     }
        .sales-invoice .fixed-table-wrapper table,.sales-invoice.srn-detail .fixed-table-wrapper table{
            height:100%;
        }
        .sales-invoice.srn-detail .print-body{
         height:1160px !important;
     }
     .sales-invoice .blank-tr,.sales-invoice.srn-detail .blank-tr{
        height:24.5px !important;
    }
    .sales-invoice .header-bar,
    .sales-invoice .first-header-bar,
    .sales-invoice.srn-detail .header-bar,
    .sales-invoice.srn-detail .first-header-bar{
        border:1px solid rgba(0,0,0,0.54) !important;
        padding:12px 12px 0 12px !important;
    }
   .sales-invoice .three-input-wrapper{
        padding:15px;
        border-right:1px solid rgba(0,0,0,0.54) !important;
        border-left:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .fixed-table-wrapper,.sales-invoice.srn-detail .fixed-table-wrapper{
        margin-top:0 !important;
        overflow:auto !important;
        border-bottom:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .fixed-table-wrapper table,.sales-invoice.srn-detail .fixed-table-wrapper table{
        border-left:1px solid rgba(0,0,0,0.54) !important;
        border-right:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .print-body .fixed-table-wrapper table td,.sales-invoice.srn-detail .print-body .fixed-table-wrapper table td{
        vertical-align: middle !important;
    }
    .sales-invoice table.print-active tfoot ,.sales-invoice.srn-detail table.print-active tfoot {
        border-top:1px solid (0,0,0,0.54) !important;
    }
    .sales-invoice .print-body .fixed-table-wrapper thead{
        border:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .print-body .fixed-table-wrapper thead th:not(:last-child),.sales-invoice.srn-detail .print-body .fixed-table-wrapper thead th:not(:last-child),.grn-detail .print-body .fixed-table-wrapper thead th:not(:last-child){
        height:17.5px !important;
        border-right:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .footer .in-word,.sales-invoice.srn-detail .footer .in-word{
        border:1px solid rgba(0,0,0,0.54) !important;
        border-top:0 !important;
    }
    .sales-invoice .footer .in-word td,.sales-invoice.srn-detail .footer .in-word td{
        padding-top:6px !important;
        padding-bottom:6px !important;
    }
    .sales-invoice .print-body .footer tr:first-child td ,
    .sales-invoice .print-body .footer td ,
    .sales-invoice.srn-detail .print-body .footer td ,
    .sales-invoice.srn-detail .print-body .footer tr:first-child td{
        padding-top:6px !important;
    }
    .sales-invoice .print-body .footer tr:nth-child(5) td ,
    .sales-invoice .print-body .footer td ,
    .sales-invoice.srn-detail .print-body .footer td ,
    .sales-invoice.srn-detail .print-body .footer tr:nth-child(5) td {
        padding-bottom:6px !important;
    }
    .sales-invoice .footer .remark,.sales-invoice.srn-detail .footer .remark{
        padding-top:10px !important;
    }
    .sales-invoice .footer .total td:first-child,
    .sales-invoice .footer .total-info td:first-child,
    .sales-invoice .footer .in-word td:first-child,
    .sales-invoice.srn-detail .footer .total td:first-child,
    .sales-invoice.srn-detail .footer .total-info td:first-child,
    .sales-invoice.srn-detail .footer .in-word td:first-child {
        padding-left:6px !important;
        padding-top:16px;
    }
    .sales-invoice .footer .total td:last-child,
    .sales-invoice .footer .total-info td:last-child,
    .sales-invoice .footer .in-word td:last-child,
    .sales-invoice.srn-detail .footer .total td:last-child,
    .sales-invoice.srn-detail .footer .total-info td:last-child,
    .sales-invoice.srn-detail .footer .in-word td:last-child {
        padding-right:6px !important;
    }
    
    .sales-invoice .footer .total_quantity_td,
    .sales-invoice .footer .total-info,
    .sales-invoice.srn-detail .footer .total_quantity_td,
    .sales-invoice.srn-detail .footer .total-info{
        border-right:1px solid rgba(0,0,0,0.54) !important;
        border-left:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice,.sales-invoice.srn-detail .footer .total td{
        padding-top:6px !important;
        padding-bottom:6px !important;
    }
    .sales-invoice .total,.sales-invoice.srn-detail .total{
        border:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .footer .total-info:last-child,.sales-invoice.srn-detail .footer .total-info:last-child{
        border-bottom:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .print-body .fixed-table-wrapper td:not(:last-child),
    .sales-invoice .print-body .fixed-table-wrapper th:not(:last-child),
    .sales-invoice.srn-detail .print-body .fixed-table-wrapper td:not(:last-child),
    .sales-invoice.srn-detail .print-body .fixed-table-wrapper th:not(:last-child){
        border-right:1px solid rgba(0,0,0,0.54) !important;
        padding:4px 8px !important;
    }
    .sales-invoice.srn-detail .print-body table td.sku{
        width:300px !important;
        min-width:300px !important;
        max-width:300px !important;
     }
     .sales-invoice.srn-detail .print-body .batch_not_exist td.sku{
        width:calc(270px + 130px) !important;
        min-width:calc(270px + 130px) !important;
        max-width:calc(270px + 130px) !important;
     }
     .sales-invoice.srn-detail .print-body table td.batch{
        width:150px !important;
        min-width:150px !important;
        max-width:150px !important;
     }
    .sales-invoice.srn-detail .fixed-table-wrapper.fixed-table-height {
        min-height: 980px !important;
        height: 980px;
        max-height: 980px;
        border:0;
    }
    .sales-invoice.srn-detail .fixed-table-wrapper.total-footer {
        min-height: 730px !important;
        height: 730px;
        max-height: 730px;
    }
    .abbreviate-invoice-detail .print-body .fixed-table-wrapper.fixed-table-height {
        min-height: 980px;
    height: 980px;
    border-bottom: 1px solid rgba(0,0,0,0.54);
    }
    .abbreviate-invoice-detail .print-body .fixed-table-wrapper.total-footer{
        min-height: 730px;
    height: 730px;
    border-bottom: 1px solid rgba(0,0,0,0.54);
    }
     .abbreviate-invoice-detail .print-body .fixed-table-wrapper  table td {
        vertical-align: middle !important;
    }
    .abbreviate-invoice-detail .print-body .fixed-table-wrapper .blank-tr{
        height:25px !important;
    }
}
@media print and (max-width:5.83in) {
    .portrait .print-body{
         height:1160px !important;
     }
    .sales-invoice .fixed-table-wrapper{
         min-height:875px ;
     }
     .sales-invoice .fixed-table-wrapper.total-footer {
    min-height: 554px;
    height:554px;
}
     .sales-invoice.srn-detail .fixed-table-wrapper.total-footer {
        min-height: 73px !important;
        height: 735px;
        max-height: 735px;
    }
     .sales-invoice .fixed-table-wrapper.fixed-table-height {
         min-height:810px ;
     }
     .abbreviate-invoice-detail .print-body .fixed-table-wrapper.total-footer{
        min-height: 740px;
    height: 740px;
    border-bottom: 1px solid rgba(0,0,0,0.54);
    }
/* batch not exist */
     .sales-invoice .print-body .batch_not_exist td.sku{
        width:calc(218px + 114px - 20px) !important;
        min-width:calc(218px + 114px - 20px) !important;
        max-width:calc(218px + 114px - 20px) !important;
     }
    .sales-invoice .print-body .fixed-table-wrapper .batch_exist td.sku{
        width:200px !important;
        min-width:200px !important;
        max-width:200px !important;
     }
}
.sales-invoice  .sender_receiver-details + .fixed-table-wrapper {
            &.total-footer{
                /* max-height: 630px !important; */
                max-height: 700px !important;
                height:unset !important;
                min-height:unset !important;
            }
            &.fixed-table-height{
                max-height:  888px !important;
                height:unset !important;
                min-height:unset !important;
            }
}

        &.pdf{
        .no-pdf{
            display:none;
        }
        .right-align{
            .header-right-content{
                display: flex;
                justify-content: end;
            }
        }
        .input p{
            font-size:13px;
        }
        .phone span,
        .address span,
        .name span,
        .input .invoice_id p,
        .input .trip_no p,
        .miti,
        .date,
        .active-opacity-text{
            font-weight:500;
            color:#000000;
        }       
         .input .invoice_id  h3,
        .input .trip_no h3,
        .input h3,
        .font-bold{
            font-weight:400;
        }
        .print-body.isCancelled:after {
        content: "Cancelled";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center; /** Y-axis align **/
        justify-content: center;
        font-size:78px;
        opacity:.2;
        transform: rotate(-45deg); 
        color:#DA1414;
        }
        .visibility-shown{
            visibility: visible;
        }
    .note_p{
                position: absolute;
                bottom: 0px;
                left: 12px;
                font-weight:600;
    }
    .footer-out{
        .tfoot-td{
            .wrap{
                display: flex;
                gap: 60px;
                .td-wrap{
                    display: flex;
                    flex-direction: column;
                }
                .remark{
                        max-width: 350px;
                        overflow: hidden;
                        white-space: nowrap;
                        display: flex;
                            span {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        @supports (-webkit-line-clamp: 2) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: initial;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        }
                    }
                    }
            }
            .sign{
                flex:1;
                justify-content: end;
                margin-top:40px;
            }
        }
    }
  .pad-b-24 {
    padding:0;
  }
  .footer-block{
    position: absolute;
    bottom: 8px;
    z-index: 1;
    width: 100%;
}
.note_p {
    position: absolute;
    bottom: -26px;
    left: 19px;
}
.header-title{
  font-weight:600;
}
.sign{
    display: flex;
    flex-direction: column;
    align-items: end;
.signature-line{
  border-top: 1px solid rgba(0,0,0,0.54) ;;
    width: 138px;
    position: relative;
    top: -5px;
}
}
 p,
 td,th,
 div,span{
 font-size:12px !important;
 }
 h2{
 font-size:15px;
 }
 h3{
 font-size:13px;
 }
 div{
 border:0;
 }
 .print-body{
 height:1120px;
 overflow:hidden;
 position:relative;
 }
 .mdc-layout-grid__inner{
 display:unset;
 }
 .header-middle-content{
 padding:0;
 }
 .sender_receiver-details{
 padding:12px !important;
 }
 .header-bar,
 .first-header-bar{
 border:1px solid rgba(0,0,0,0.54) ;
 border-bottom:0;
 margin-top:12px;
 }
 .header-bar,
 .first-header-bar{
 &>div{
 padding:12px;
 }
 .mdc-layout-grid__inner{
 display:flex;
 &>div{
 flex:1;
 }
 }
 }
 .details,
 .sender_receiver-details{
 display:flex;
 .details,
 .input{
 flex:2;
 }
 }
 .sales-invoice .sender_receiver-details + .fixed-table-wrapper.total-footer{
 max-height:unset !important;
 table tr{
    &:last-child{
    border-bottom:1px solid rgba(0,0,0,0.54) !important;
 }
 }
 }
 .sender_receiver-details{
 padding: 24px;
 border:1px solid rgba(0,0,0,0.54) ;
 border-bottom:0;
 }
 .sales-invoice .sender_receiver-details + .fixed-table-wrapper.fixed-table-height {
 max-height:unset !important;
 }
 .fixed-table-wrapper{
 border:1px solid rgba(0,0,0,0.54) ;
 &.total-footer{
 border-bottom:0;
 }
 thead{
 tr{
 th{
 line-height: 21px;
 color:#000000;
 border-right:1px solid rgba(0,0,0,0.54) ;
 border-bottom:1px solid rgba(0,0,0,0.54) ;
 padding-right: 4px;
  padding-left: 4px;
  box-sizing: border-box;
  font-weight:500;
 &:last-child{
 border-right:0;
 }
 }
 }
 }
 tbody{
 tr{
 border:0;
 td{
 border-right:1px solid rgba(0,0,0,0.54) ;
 line-height: 21px;
    padding-bottom: 4px;
    color:#000000;
    padding-right: 6px;
    padding-left: 6px;
    vertical-align: baseline;
    box-sizing: border-box;
    &.srn{
        width:30px;
        min-width:30px;
    max-width:30px
    }
&.sku {
    width:134px;
    min-width:134px;
    max-width:134px;
    p{
  max-width:20ch;
  line-height:14px;
  white-space:normal !important;
}
}
&.rate,
&.amount,
&.quantity{
    width:80px;
    min-width:80px;
    max-width:80px;
}
&.description {
    width:300px;
    min-width:300px;
    max-width:300px;
    p{
  max-width:50ch;
  line-height:14px;
  white-space:normal !important;
}
}
 &:last-child{
 border-right:0;
 }
 &.hide-overflow-text{
 display:table-cell;
 }
 }
 }
 }
 }
 .blank-tr{
  height:24px !important;
 }
 .card-body{
 margin:24px;
 }
 .footer-totalList{
 position: absolute;
 width: 94%;
    bottom: 65px;
 /* border:1px solid rgba(0,0,0,0.54) ; */
 .total-section-wrapper{
 &.print-active{
    position:relative;
 display: table;
 .in-word td,
 .tfoot-td.total  td,
 .total-content-data.item_value{
     padding:4px 6px 4px 12px !important;
    }
 .extra_div,
 .total_quantity{
 position: absolute;
 }
 .total_tr{
     padding:0;
 .total_quantity_td{
     &>span{
 position: absolute;
 width: 76%;
 display: flex;
 right: 0%;
 padding-top:4px;
 span{
 margin-left: 52.4%;
 }
     }
 }
}
 tfoot>tr{
     &.note{
         td{
             padding:0;
         }
     }
   td{
     width:unset;
   }
   &.total,
   &.in-word,
   &.total-info{
     border-right:1px solid rgba(0,0,0,0.54) !important;
     border-left:1px solid rgba(0,0,0,0.54) !important;
   }
   &.total,
   &.total-info
   {
    td:first-child{
      width: 86%;
      padding-right:0 !important;
     }
   }
   &:nth-child(2){
      border-top:1px solid rgba(0,0,0,0.54) ;
   }
 td{
 padding:4px 24px;
 padding-right:24px !important;
 &.extra_td,
 &.total_quantity_td{
 padding:0;
 }
 .extra_div{
  bottom: 50%;
  left: 3%;
 }
 .total_quantity{
 position: absolute;
 width: 76%;
 display: flex;
 right: 0%;
 padding-top:4px;
 span{
 margin-left: 52.4%;
 }
 }
 }
 &.in-word{
 border:1px solid rgba(0,0,0,0.54) ;
 font-style:italic;
 .right{
     margin-left:4px;
 }
 }
 }
 }
 }
 }
 }
`;
export default InvoiceDetailsStyled;
