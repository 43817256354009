import styled from 'styled-components';

const ProfitLossStyle = styled.div`
  .inner-view {
    background: white;
    height: 100%;
    padding: 38px 24px;
    box-shadow: 0 6px 6px 0 rgba(33, 150, 243, 0.24), 0 0 6px 0 rgba(33, 150, 243, 0.12);
    .trial-view {
      width: 80%;
      min-width: 60%;
      border: 1px solid #e7e7ed;
      .top-header {
        text-align: center;
        padding: 10px;
        span {
          font-size: 12px;
          padding: 10px;
          color: #393a4a;
        }
      }
      .mid-header {
        height: 32px;
        background: #dff3ff;
        padding: 8px;
        display: flex;
        text-align: center;
        align-items: center;

        span {
          color: #2196f3;
          width: 50%;
          padding: 10px;
          &:first-child {
            border-right: 1px solid #e7e7ed;
          }
        }
      }
      .profit-loss {
        display: flex;
        background: #fff;
        border-top: 1px solid #e7e7ed;
        height: 48px;
        span {
          color: #000000;
          width: 50%;
          padding: 14px 24px;
          font-size: 12px;
          &:nth-child(3) {
            border-left: 1px solid #e7e7ed;
          }
        }
      }
      .total {
        display: flex;
        background: #f8f8f8;
        border-top: 1px solid #e7e7ed;
        height: 48px;
        span {
          font-weight: bold;
          color: #000000;
          font-size: 13px;
          width: 50%;
          padding: 14px 24px;
          font-size: 12px;
          &:nth-child(3) {
            border-left: 1px solid #e7e7ed;
          }
        }
      }
      .table-view {
        display: flex;
        table {
          width: 50%;
          min-width: 50%;
          th,
          td {
            white-space: normal;
          }
          td {
            line-height: 21px;
            padding: 5px 24px;
          }
          &:first-child {
            tbody {
              tr {
                td:last-child {
                  border-right: 0.5px solid #e7e7ed;
                }
              }
            }
          }
          &:last-child {
            border-left: 0.5px solid #e7e7ed;
          }
          tr {
            height: 32px;
            th {
              background: white;
              font-weight: bold;
              color: #000000;
            }
            td {
              font-size: 12px;
              color: #000000;
            }
          }
          td:last-of-type,
          th:last-of-type {
            width: 10%;
          }
        }
      }
    }
  }
  .collaspsible-disabled {
    td {
      span {
        padding-left: 0 !important;
        &:before {
          border: 0 !important;
        }
      }
    }
  }
  .table-view {
    table {
      thead {
        tr th {
          width: 50%;
        }
      }
      tbody {
        tr {
          td {
            width: 50%;
          }
        }
        .collapsible-body-tr {
          border-top: 2px solid #2196f3;
          & > td {
            padding: 0;
            border: 0 !important;
            table {
              min-width: 100%;
              max-width: 100%;
              box-shadow: rgb(39 40 51 / 12%) 0px 4px 8px 0px;
              border: none;
              td {
                border: 0 !important;
                padding-left: 50px;
              }
            }
          }
        }
        .collapsible-body-tr {
          & > td {
            padding: 0;
          }
          table {
            width: 100% !important;
            max-width: 100% !important;
            border: 0 !important;
          }
        }
      }
    }
  }
  table tbody {
    tr {
      &.active {
        td:first-child > span:before {
          transform: rotate(180deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #2196f3;
        }
      }
      td:first-child {
        & > span {
          position: relative;
          padding-left: 26px;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: 0;
            transition: all 0.23s ease-in-out;
          }
        }
      }
    }
  }
`;
export default ProfitLossStyle;
