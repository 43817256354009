import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  DialogFooter,
  Button,
} from '../../../components/BillingMDC';
import BillingTextField from '../../../components/TextField/BillingTextField';
import { EMAIL_RULE } from '../../../data/enums/enums';

export const ConfirmationDialog = (
  { readModal,sendmail,onConfirmModalClose,getConfirmation,cancelInvoiceData, externalEmail,emailValidate,onInputChange,checkList,customerName},
) => {
  const check = externalEmail?.match(EMAIL_RULE);
  const emailCriteriaStatus = check === null;
  return(
    <>
      <Dialog
        open={readModal || sendmail }
        onClose={() => {
          onConfirmModalClose();
        }}
        className={`confirmation__dialog ${sendmail && 'email_dialog'}`}
      >
        <DialogHeader>
      <DialogTitle>{sendmail ? "Send Email" : 'Confirmation'}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          { readModal &&
          <p className='read pad-r-l-24'>
          Are you sure you want to cancel order of invoice number <b>{cancelInvoiceData?.invoiceNumber}</b>.
          </p> }
          { sendmail &&
            <p className='read pad-r-l-24'>
                Are you sure you want to send email to <b>{!!customerName? customerName: checkList?.length || 0 }</b> {!!customerName ?'':checkList?.length >1? 'customers' :'customer'}?
                      <BillingTextField
                        value={externalEmail}
                        handleChange={onInputChange}
                        floatingLabel='External Email ID'
                        param='externalEmail'
                        type='text'
                        className='right-side-gap'
                        emptyField={emailValidate}
                        errorMessage={externalEmail === '' ? '' :emailCriteriaStatus ? 'Email is not valid' : ''}
                        invalidError={emailValidate}
                      />
            </p>
          }
        </DialogBody>
        <DialogFooter>
          <Button
            accent
            className='dialog-cancel modal-btn'
            onClick={() => onConfirmModalClose()}
          >
          Cancel
          </Button>
          <Button
            accent
            className='dialog-ok modal-btn'
            onClick={() => {
              getConfirmation(sendmail);
            }}
          >
          Ok
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};
