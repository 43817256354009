import React from 'react';
import PropTypes from 'prop-types';

const EmailFrequencyTableBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList?.map((data, index) => (
      <tr className='cursor-pointer' key={'email'- index} onClick={() => onRowClick(data.id)}>
        <td className='left-align'>{data?.title}</td>
        <td className='left-align'>
          {data.frequency}
        </td>
      </tr>
    ))}
  </tbody>
);
EmailFrequencyTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const EmailFrequencyConfig = [
  {
    id: 1, label: 'title', title: 'Email Frequency Category Title', className: 'cursor-pointer left-align', sortable: false,
  },
  {
    id: 2, label: 'frequency', title: 'Frequency', className: 'cursor-pointer left-align', sortable: false,
  },
];

const config = {
  header: EmailFrequencyConfig,
  getTableBody: EmailFrequencyTableBody,
};
export default config;
