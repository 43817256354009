import { compose } from 'recompose';

import apiInterceptor from '../../../api/interceptor';

import ServiceInvoice from './ServiceInvoice';
import { salesInvoiceApi } from '../../common/base.api';

const composedServiceInvoice = compose(apiInterceptor({ ...salesInvoiceApi }))(ServiceInvoice);

export default composedServiceInvoice;
