import React, { Component } from 'react';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';
import { Button, Cell, Grid, LinearProgress, Snackbar, Icon } from '../../../../components/BillingMDC';
import { ACTION } from '../../../../data/enums/enums';
import { EMAIL_FREQUENCY } from '../../../../data/enums/Route';
import * as filterUtil from '../../../../utils/filterUtil';
import history from '../../../../utils/history';
import { EMAIL_FREQUENCY_CONFIG } from '../../../common/domain.config';
import { validateForm } from '../../../common/validation';
import { formValidationFields, generalDataMapper, message, validationArr } from './config';
import EmailFrequencyStyled from '../EmailFrequencyStyled';
import Customers from './Customers';
import General from './General';

class Create extends Component {
  constructor(props) {
    super(props);
    const id = props.match.params.id ? parseInt(props.match.params.id) : 0;
    this.state = {
      success: true,
      loading: false,
      showMessage: false,
      message: message.success,
      date: {
        start: filterUtil.getStartOfCurrentMonth(),
        end: filterUtil.getCurrentDay(),
      },
      activeTab: 0,
      data: generalDataMapper(),
      modalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      customerList: [],
      customers: [],
      indexInInvoiceList: 0,
      createCustomer: {id:""},
      saveDisable: false,
      id,
      crudMode: id ? ACTION.READ : ACTION.ADD,
      validation: {
        flag: false,
        fieldList: formValidationFields,
      },
      formValidation:{
        flag: false,
        fieldList: validationArr,
      }
    };
  }
  componentDidMount() {
    const { id } = this.state;
    if (id) this.getDetailsList(id);
    this.getCustomerList();
  }
  

  getDetailsList = id => {
    const { getDetails } = this.props;

    getDetails({ id }).then(response => {
      const list = response?.[0];
      const filteredNullCustomerList = list?.customers?.filter(element => {
        return element.id !== null;
      });

      this.setState({
        data: generalDataMapper(list),
        customers: filteredNullCustomerList?.length>0 ?filteredNullCustomerList?.map(a => ({ id: a.id, title: a.name })):[],
      });
    });
  };

  handleTabChange = tab => {
    this.setState({ activeTab: tab });
  };

  handleInputChange = (param, value) => {
    const { data } = this.state;
    data[param] = value;
    if (param === 'type') {
      if (value === 'weekly') {
        data.occurenceDay = null;
      } else {
        data.day = null;
      }
    }
    this.setState({ data });
  };

  handleCheckboxChange = (param, day, value) => {
    const { data } = this.state;
    if (value) {
      data.day?.push(day);
    } else {
      data.day = data.day.filter(a => a !== day);
    }
    this.setState({ data });
  };

  handleModalClose = () => {
    const {formValidation} =this.state;
    formValidation.flag=false;
    this.setState({
      modalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      createCustomer: {id:""},
      formValidation,
    });
  };

  handleModalOk = () => {};

  handleFabIconClick = () => {
    this.setState({ modalOpen: true });
    this.getCustomerList();
  };

  deleteCustomer = () => {
    const { customers, indexInInvoiceList, customerList } = this.state;
    //add customer to the state when customer is deleted
    const indexCustomer = customers.splice(indexInInvoiceList, 1)
    const addDeletedCustomer = [...indexCustomer, ...customerList];
    this.setState({ customerList: [...new Map(addDeletedCustomer.map(item => [item['id'], item])).values()] }); //to get unique customer list
    // customers.splice(indexIncustomerList, 1);
    this.setState({ customers }, () => {
      this.handleModalClose();
    });
  };

  // handle delete icon click.
  handleDeleteIconClick = index => {
    this.setState({
      deleteModalOpen: true,
      indexInInvoiceList: index,
    });
  };

  handleCustomerChange = (id, feild, detail) => {
    this.setState({ createCustomer: detail });
  };

  createCustomerList = () => {
    const { createCustomer, customers } = this.state;
    customers.push(createCustomer);
    this.setState({ customers }, () => {
      this.handleModalClose();
    });
  };

  handleModalSubmit = () => {
    const { modalOpen ,formValidation,createCustomer} = this.state;
    if (modalOpen) {
      const validStatus =validateForm(createCustomer, formValidation, valid => this.setState({ validation: valid }));
      if(!validStatus){
      formValidation.flag=true;
      this.setState({formValidation})
      return;
      }
       this.createCustomerList();
    } else {
      this.deleteCustomer();
    }
  };

  getValidation = () => {
    const { data, validation } = this.state;
    const getDetailsValidation = validateForm(data, validation, valid => this.setState({ validation: valid }));
    if (getDetailsValidation) return true;
    return false;
  };

  handleSaveClick = () => {
    const { create, update } = this.props;
    const { customers, data, id, crudMode } = this.state;
    const occurenceDay = this.getRepeatDates(data.type, data.occurenceDay);
    const input = {
      customerId: customers?.map(a => a.id),
      title: data.title,
      emailFrequencyConfig: {
        type: data.type,
        day: data?.day?.map(a => a.toUpperCase()),
        occurenceDay,
        time: `${data.hours}:${data.minutes} ${data.period}`,
      },
    };

    const isValid = this.getValidation();

    if (isValid) {
      if (crudMode === ACTION.ADD) {
        create(input)
          .then(response => {
            this.setState({ loading: false });
            if (response) {
              this.setState({
                showMessage: true,
                success: true,
                message: message.success,
                saveDisable: true,
              });
              setTimeout(() => {
                this.handleCancelClick();
              }, 1200);
            }
          })
          .catch(err => {
            let errormessage = '';
            {
              try {
                errormessage = err.error.message;
              } catch {
                errormessage = err.error.message;
              }
              this.setState({
                loading: false,
                showMessage: true,
                success: false,
                message: errormessage,
                saveDisable: false,
              });
            }
          });
      } else {
        update({ id, ...input })
          .then(response => {
            this.setState({ loading: false });
            if (response) {
              this.setState({
                showMessage: true,
                success: true,
                message: message.updateSucess,
                saveDisable: true,
              });
              setTimeout(() => {
                this.handleCancelClick();
              }, 1200);
            }
          })
          .catch(err => {
            let errormessage = '';
            {
              try {
                errormessage = err.error.message;
              } catch {
                errormessage = err.error.message;
              }
              this.setState({
                loading: false,
                showMessage: true,
                success: false,
                message: errormessage,
                saveDisable: false,
              });
            }
          });
      }
    }
  };

  // handle cancel button click
  handleCancelClick = () => {
    history.push(`/${EMAIL_FREQUENCY}`);
  };

  getRepeatDates = (param, dayOfTheMonth) => {
    let repeatDates = [];
    const todayDate = new Date();
    let [year, month, day] = [todayDate.getFullYear(), todayDate.getMonth() + 1, todayDate.getDate()];
    day = parseInt(dayOfTheMonth);
    const lastDayOfMonth = new Date(year, month, 0);

    if (param === 'fortnightly') {
      if (day + 14 > lastDayOfMonth) {
        repeatDates.push(day);
      } else {
        repeatDates.push(day);
        repeatDates.push(day + 14);
      }
    } else if (param === 'monthly') {
      repeatDates.push(day);
    } else if (param === 'trimonthly') {
      if (day + 10 > lastDayOfMonth) {
        repeatDates.push(day);
      } else if (day + 20 > lastDayOfMonth) {
        repeatDates.push(day);
        repeatDates.push(day + 10);
      } else {
        repeatDates.push(day);
        repeatDates.push(day + 10);
        repeatDates.push(day + 20);
      }
    }
    return repeatDates;
  };

  updateCrudMode = crudMode => {
    this.setState({ crudMode });
  };

  // get the customer list for the selection.
  getCustomerList = async () => {
    const { getCustomerList } = this.props;

    const { customers, customerList } = this.state;

    if (customerList.length > 0) {
      const filterByReference = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
          return !arr2.find(element => {
            return element.id === el.id;
          });
        });
        return res;
      };

      const customerArrayFiltered = await filterByReference([...customerList], [...customers]);

      this.setState({ customerList: customerArrayFiltered });
    } else {
      const response = await getCustomerList();
      this.setState({ customerList: response });
    }
  };

  render() {
    const {
      loading,
      showMessage,
      success,
      message,
      date,
      activeTab,
      data,
      modalOpen,
      editModalOpen,
      deleteModalOpen,
      customerList,
      customers,
      createCustomer,
      saveDisable,
      id,
      crudMode,
      validation,
      formValidation,
    } = this.state;
    return (
      <EmailFrequencyStyled className="create">
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        {
          <div className={`customer-create pad-b-24${loading ? 'clickable-false' : ''}`}>
            <div className="message-snackbar">
              {
                <Snackbar
                  multiline
                  className={success ? 'success-message' : ''}
                  timeout={success ? 3000 : 10000}
                  open={showMessage}
                >
                  {message}
                </Snackbar>
              }
            </div>
            <div className="card-header-bar clearfix">
              <div className="header-left">
                <h2>{id ? `${EMAIL_FREQUENCY_CONFIG.title} Detail` : `Create ${EMAIL_FREQUENCY_CONFIG.title}`}</h2>
              </div>
              <div className="header-right">
                {crudMode === ACTION.READ ? (
                  <Icon name="edit" onClick={() => this.updateCrudMode(ACTION.UPDATE)} />
                ) : (
                  date.end
                )}
              </div>
            </div>
            <div className="tab-section">
              <Tabs selectedIndex={activeTab} onSelect={tabIndex => this.handleTabChange(tabIndex)}>
                <TabList>
                  <Tab>General</Tab>
                  <Tab>Customers</Tab>
                </TabList>
                <TabPanel>
                  <section
                    id="general-tab"
                    className="detail-tab-section general"
                    style={{ pointerEvents: crudMode === ACTION.READ ? 'none' : '' }}
                  >
                    <General
                      data={data}
                      enableErrorDisplay={validation.flag}
                      handleInputChange={this.handleInputChange}
                      handleCheckboxChange={this.handleCheckboxChange}
                    />
                  </section>
                </TabPanel>
                <TabPanel>
                  <section
                    id="customers-tab"
                    className="detail-tab-section customer"
                    style={{ pointerEvents: crudMode === ACTION.READ ? 'none' : '' }}
                  >
                    <Customers
                      handleModalClose={this.handleModalClose}
                      modalOpen={modalOpen}
                      editModalOpen={editModalOpen}
                      deleteModalOpen={deleteModalOpen}
                      customerList={customerList}
                      customers={customers}
                      createCustomer={createCustomer}
                      handleInputChange={this.handleInputChange}
                      handleModalModalSubmit={this.handleModalOk}
                      handleFabIconClick={this.handleFabIconClick}
                      handleCustomerChange={this.handleCustomerChange}
                      handleModalSubmit={this.handleModalSubmit}
                      handleDeleteIconClick={this.handleDeleteIconClick}
                      formValidation ={formValidation}
                    />
                  </section>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        }
        {crudMode !== ACTION.READ && (
          <div className="newinvoice-btn-wrapper">
            <Grid>
              <Cell col={8} />
              <Cell col={4} className="right-align">
                <Button
                  accent
                  className="cancel-btn modal-btn"
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  accent
                  className={saveDisable ? 'save-btn modal-btn btn-disabled' : 'save-btn modal-btn'}
                  disabled={saveDisable}
                  onClick={() => {
                    this.handleSaveClick();
                  }}
                >
                  Save
                </Button>
              </Cell>
            </Grid>
          </div>
        )}
      </EmailFrequencyStyled>
    );
  }
}

export default Create;
