import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../../components/TableHeader';
import BillingTextField from '../../../components/TextField/BillingTextField';
import { Button, Cell, Fab, Grid, Icon, LinearProgress, Snackbar } from '../../../components/BillingMDC';
import { connect } from 'react-redux';
import history from '../../../utils/history';
import { clone } from '../../../utils/arrayProcessor';
import { CONTRA_ENTRY_BASE, CONTRA_ENTRY_DETAILS } from '../../../data/enums/Route';
import { checkIfApiCallSuccess, jvApi, ledgerApi } from '../../common/base.api';
import { validateForm } from '../../common/validation';
import CeDialogView from './DialogView';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import * as filterUtil from '../../../utils/filterUtil';
import { LEDGERS } from '../../../data/enums/enums';
import {
  apiTransformedCeList,
  apiTransformedCeSummary,
  createContraEntryConfig,
  CreateContraEntryTableBody,
  formValidateFieldArray,
  initializeCreateCE,
  message,
  pageValidateFieldArray,
  partyHeaderList,
} from './config';
import { voucherEncoder } from '../../../utils/miscellaneous';
import BillingSelect from '../../../components/Select/BillingSelect';
import ContraEntryStyled from '../ContraEntryStyled';
import { formatToNumericDate } from '../../../utils/filterUtil';

class CreateContraEntry extends Component {
  // get the customer list for the selection.
  getLedgerList = () => {
    ledgerApi.getList({}, 'isCashBankLedger=true').then(response => {
      if (checkIfApiCallSuccess(response)) {
        let responseDataList = response.data.list;
        responseDataList.map(ledger => {
          ledger['label'] = ledger.customerId + '. ' + ledger.title;
        });
        this.setState({ ledgerList: responseDataList });
      }
    });
  };

  onInputFieldChange = (field, value) => {
    const { ceSummary } = this.state;
    ceSummary[field] = value;

    if (field === 'amount') {
      ceSummary.net_cr_amount = value;
    }
    this.setState({ ceSummary: ceSummary });
  };
  // handle change of create ce events.
  onChange = (field, value, all = {}) => {
    const { createCE, ceSummary } = this.state;
    createCE[field] = value;

    if (field === 'amount') {
      createCE['dr_amount'] = value;
    }

    // update the changes in the field value.
    if (field === 'ledger_id') {
      createCE.ledger_name = all['title'] || 'title';
      createCE.to_account = all['title'] || 'title';
      createCE.type_id = all.typeId;
      createCE.party_id = '';
      createCE.party_name = '';
      createCE.party_pan_no = '';
      createCE.party_taxable_amount = 0;
    }

    if (field === 'from_account') {
      ceSummary.from_account = all.customerId;
    }
    this.setState({ createCE: clone(createCE) });
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (createCE, index) => {
    this.setState({ createCE: clone(createCE), editModalOpen: true, indexInJVList: index });
  };

  // create the sales Return, update the discount value
  createContraEntry = () => {
    const { ceList, createCE } = this.state;
    ceList.push(createCE);
    this.setState({ ceList: ceList }, () => {
      this.handleModalClose();
      this.handleVoucherListUpdate();
    });
  };

  handleVoucherListUpdate = () => {
    const { ceList, ceSummary } = this.state;
    let net_dr_amount = 0;
    ceSummary.net_cr_amount = 0;
    ceList.map(element => {
      net_dr_amount += Number(element.dr_amount);
    });

    ceSummary.net_dr_amount = Number(Number(net_dr_amount).toFixed(2));
    ceSummary.net_cr_amount = Number(Number(ceSummary.amount).toFixed(2));
    this.setState({ ceSummary });
  };

  balanceDebitCredit = () => {
    const { ceSummary } = this.state;
    const debit = Number(ceSummary.net_dr_amount);
    const credit = Number(ceSummary.net_cr_amount);
    if (debit !== credit) {
      this.setState({ showMessage: true, success: false, message: message.balanceError });
      return false;
    } else return true;
  };

  editContraEntry = (data, index) => {
    const { ceList, createCE, partyHeaderIncluded } = this.state;
    if (createCE.party_id && !partyHeaderIncluded) {
      this.setState({ partyHeaderIncluded: true }, () => this.updateTableHeaderList(true, partyHeaderList));
    }
    ceList[index] = createCE;
    this.setState({ ceList: ceList }, () => {
      this.handleVoucherListUpdate();
      this.handleModalClose();
    });
  };
  // delete the particular contra entry from ceList.
  deleteCEItem = index => {
    const { ceList, indexInJVList } = this.state;
    if (ceList.length >= indexInJVList) {
      ceList.splice(indexInJVList, 1);
      this.setState({ ceList: ceList }, () => {
        this.handleVoucherListUpdate();
        this.handleModalClose();
      });
    }
  };
  // close the modal resetting  all the value
  handleModalClose = () => {
    this.setState({ createModalOpen: false, editModalOpen: false, deleteModalOpen: false });
    this.resetCEDialog();
  };

  // reset JV Dialog
  resetCEDialog = () => {
    this.setState({
      createCE: initializeCreateCE(),
      formEmptyField: false,
      transactionDisabled: { credit: false, debit: false },
    });
  };

  handleDateChange = (field, value)  => {
    const { createCE } = this.state;
    createCE.ref_date = filterUtil.formatToDateMonthYear(value);
    this.setState({ createCE });
  };

  addFromAccountToJvList(ceList, user, ceSummary) {
    const jvListItems = [...ceList];
    jvListItems.push({
      ledger_name: ceSummary.ledger_name,
      ledger_id: ceSummary.from_account,
      dr_amount: 0,
      cr_amount: ceSummary.amount,
      ref_date: formatToNumericDate(new Date()),
      narration: ceSummary.narration,
      entered_by: user.idUsers,
      party_taxable_amount: 0,
      type_id: 1,
      party_id: '',
      party_name: '',
      business_id: null,
    });
    return jvListItems;
  }
  // handle on modal ok click
  handleModalOk = () => {
    // check edit or create config //close the dialog
    const { createModalOpen, editModalOpen, createCE } = this.state;
    if (createModalOpen || editModalOpen) {
      const valid = validateForm(createCE, this.getFormValidationConfig(), valid =>
        this.setState({ formValidation: valid }),
      );
      if (valid) {
        if (createModalOpen) {
          this.createContraEntry();
        } else {
          this.editContraEntry(this.state.createCE, this.state.indexInJVList);
        }
      }
    } else {
      this.deleteCEItem();
    }
  };

  handleSaveClick = () => {
    // generate the object to send to the server
    const { ceList, ceSummary, businessUnitId, pageValidation } = this.state;
    const { user } = this.props;
    let valid = validateForm(ceSummary, pageValidation, validStatus => this.setState({ pageValidation: validStatus }));
    const jvListCheck = ceList.length > 0;
    if (!jvListCheck) {
      alert(' Contra Entry List  is empty');
    }
    let from_account = ceSummary.from_account;
    const isToAndFromSameAccount = ceList.find(ce => {
      return ce.ledger_id === from_account;
    });
    if (isToAndFromSameAccount) {
      alert('Sender and receiver are same');
      valid = false;
    }

    if (valid && jvListCheck) {
      const balanceCheck = this.balanceDebitCredit();
      if (balanceCheck) {
        this.setState({ loading: true });
        const updatedJvList = this.addFromAccountToJvList(ceList, user, ceSummary);
        const filteredJvList = apiTransformedCeList(updatedJvList, user, businessUnitId);
        const object = {
          jv: {
            summary: apiTransformedCeSummary(ceSummary, user, businessUnitId),
            detail: filteredJvList,
          },
        };

        jvApi
          .create(object)
          .then(response => {
            this.setState({ loading: false });
            if (response.success) {
              const { voucher_number = '' } = response.data.journalVoucherData;
              this.setState({
                showMessage: true,
                success: true,
                message: message.success,
                voucherNumber: voucher_number,
                partyHeaderIncluded: false,
              });
            }
          })
          .catch(error => {
            this.setState({ loading: false, showMessage: true, success: false, message: message.error });
          });
      }
    }
  };

  directToMainPage = () => {
    const { showMessage, success, voucherNumber } = this.state;
    if (!showMessage && success) {
      const modifiedVoucherNumber = voucherEncoder(voucherNumber) || '';
      history.push(`/${CONTRA_ENTRY_DETAILS}/${modifiedVoucherNumber}`);
    }
  };

  handleCancelClick = () => {
    history.push(`/${CONTRA_ENTRY_BASE}`);
  };

  handleDeleteIconClick = index => {
    this.setState({ deleteModalOpen: true, indexInJVList: index });
  };

  updateTableHeaderList = (flag, list = []) => {
    createContraEntryConfig.headerDetails = createContraEntryConfig.headerDetails.map(item => {
      item.display = list.includes(item.label) ? flag : item.display;
      return item;
    });
  };

  getFormValidationConfig = () => {
    const { formValidation, createCE } = this.state;

    const validationConfig =
      createCE.ledger_id === LEDGERS.VAT.ledger_id
        ? {
            flag: formValidation.flag,
            fieldList: [...formValidation.fieldList, { title: 'party_id' }, { title: 'party_taxable_amount' }],
          }
        : formValidation;

    return validationConfig;
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      createModalOpen: false,
      editModalOpen: false,
      formEmptyField: false,
      pageEmptyField: false,
      deleteModalOpen: false,
      indexInJVList: 0,
      businessUnitId: '',
      voucherNumber: '',
      createCE: initializeCreateCE(),
      ceSummary: {
        from_account: '',
        amount: 0,
        narration: '',
        net_cr_amount: 0,
        net_dr_amount: 0,
      },
      ceList: [],
      ledgerList: [],
      transactionDisabled: {
        credit: false,
        debit: false,
      },
      invalidPAN: false,
      partyHeaderIncluded: false,
      formValidation: {
        flag: false,
        fieldList: formValidateFieldArray,
      },
      pageValidation: {
        flag: false,
        fieldList: pageValidateFieldArray,
      },
      showMessage: false,
      success: false,
      message: message.success,
    };
  }

  componentDidMount() {
    this.getLedgerList();
    this.updateTableHeaderList(false, partyHeaderList);
  }

  render() {
    const {
      loading,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      ceList,
      showMessage,
      createCE,
      partyHeaderIncluded,
      formValidation,
      transactionDisabled,
      pageValidation,
      ceSummary,
      success,
      message,
      ledgerList,
    } = this.state;
    return (
      <ContraEntryStyled className="jv-create">
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}

        <div className={loading ? 'clickable-false' : ''}>
          <div className="message-snackbar">
            {
              <Snackbar
                className={success ? 'success-message' : ''}
                timeout={success ? 100 : 4000}
                onTimeout={() => {
                  this.setState({ showMessage: false }, () => {
                    this.directToMainPage();
                  });
                }}
                open={showMessage}
              >
                {message}
              </Snackbar>
            }
          </div>
          <div className="card-header-bar clearfix" ref="tableReference">
            <div className="header-left">
              <h2>Add Contra Entry</h2>
            </div>
            <div className="header-right">{filterUtil.getCurrentDay()}</div>
          </div>
          <div className="card-body">
            <div>
              <div className="three-input-wrapper">
                <Grid>
                  <Cell col={4} tablet={6}>
                    <label className="select-custom-label">From Account</label>
                    <BillingSelect
                      name="form-field-name"
                      param="from_account"
                      clearable={false}
                      value={ceSummary.from_account}
                      valueKey="customerId"
                      labelKey="title"
                      options={ledgerList}
                      handleChange={this.onChange}
                      required={true}
                      multipleParam={true}
                    />
                  </Cell>

                  <Cell col={4} className="input-field">
                    <BillingTextField
                      value={ceSummary.amount}
                      param="amount"
                      floatingLabel="Amount"
                      type="number"
                      required={true}
                      className="billing-required"
                      emptyField={pageValidation.flag}
                      handleChange={this.onInputFieldChange}
                    />
                  </Cell>
                  <Cell col={4} className="input-field">
                    <BillingTextField
                      value={ceSummary.narration}
                      param="narration"
                      floatingLabel="Narration"
                      required={true}
                      className="billing-required"
                      emptyField={pageValidation.flag}
                      handleChange={this.onInputFieldChange}
                    />
                  </Cell>
                </Grid>
              </div>
              <div className="table-wrapper">
                <div ref="fixedTableBody" className="overflow-scrollable fixed-table-wrapper">
                  <table>
                    <TableHeader
                      headerDetails={createContraEntryConfig.headerDetails}
                      filterHeaderLabel={false}
                      handleSorting={this.handleTableSorting}
                    />
                    <CreateContraEntryTableBody
                      dataList={ceList}
                      handleEditIconClick={this.handleEditIconClick}
                      handleDeleteIconClick={this.handleDeleteIconClick}
                      partyIncluded={partyHeaderIncluded}
                    />
                  </table>
                </div>

                {/* Fab Icon*/}
                <div className="mini-fab-button">
                  <Fab
                    mini
                    onClick={() => {
                      this.setState({ createModalOpen: true });
                    }}
                  >
                    <Icon name="add" />
                  </Fab>
                </div>
              </div>
              {/* Sales invoice table summary goes over here.*/}
              <div className="total-section-wrapper">
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Total Debit:{' '}
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(ceSummary.net_dr_amount)}
                  </Cell>
                </Grid>

                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Total Credit:
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(ceSummary.net_cr_amount)}
                  </Cell>
                </Grid>
              </div>
            </div>
          </div>
          <div className="newinvoice-btn-wrapper ">
            <Grid>
              <Cell col={8} />
              <Cell col={4} className="right-align">
                <Button
                  accent
                  className="cancel-btn modal-btn"
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  accent
                  className="save-btn modal-btn"
                  onClick={() => {
                    this.handleSaveClick();
                  }}
                >
                  Save
                </Button>
              </Cell>
            </Grid>
          </div>

          {/* Begin of the dialog Body*/}
          <CeDialogView
            ledgerList={ledgerList}
            onModalClose={this.handleModalClose}
            modalOpen={createModalOpen}
            editModalOpen={editModalOpen}
            deleteModalOpen={deleteModalOpen}
            createCE={createCE}
            handleInputChange={this.onChange}
            onModalSubmit={this.handleModalOk}
            formEmptyField={formValidation.flag}
            handleDateChange={this.handleDateChange}
            transactionDisabled={transactionDisabled}
          />
        </div>
      </ContraEntryStyled>
    );
  }
}

CreateContraEntry.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
    bu_id: state.billing.bu_id || null,
  };
};

const createContraEntry = connect(mapStateToProps)(CreateContraEntry);

export default createContraEntry;
