import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FilterMenuStyled from './MenuStyled';

/**
 * @type {{onDialogCancel: shim, onDialogOkay: shim, name: shim, presentationType: shim}}
 */
const propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  header: PropTypes.object,
  body: PropTypes.object,
  onClose: PropTypes.bool,
  disabled: PropTypes.bool,
  disableBody: PropTypes.bool,
  hideOnClick: PropTypes.bool,
  hideOnClickOutside: PropTypes.bool,
};

const defaultProps = {
  label: '',
  value: 0,
  onClose: false,
  disabled: false,
  disableBody: false,
  hideOnClick: false,
  hideOnClickOutside: true,
};

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBody: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  setWrapperRef = node => {
    if (node) {
      this.wrapperRef = node;
    }
  };

  handleHeaderClick = e => {
    const { openMenu } = this.props;
    e.preventDefault();
    this.setState({ showBody: !this.state.showBody });
    openMenu();
  };

  handleClickOutside = event => {
    const { hideOnClickOutside, hideOnClick } = this.props;
    const dropDownEvent = event.target.id.includes('react-select');
    if (hideOnClick) {
      // this.setState({ showBody: false })
    }

    if (hideOnClickOutside && this.wrapperRef && !this.wrapperRef.contains(event.target) && !dropDownEvent) {
      this.setState({ showBody: false });
    }
  };

  close = () => {
    this.setState({ showBody: false });
  };

  render() {
    const { showBody } = this.state;
    const { label, value, body, children, header, disabled, disableBody, onClose } = this.props;

    return (
      <FilterMenuStyled>
        <div className={`filter-item ${disabled ? 'disabled-btn' : ''}`}>
          <span className={showBody ? 'filter-background' : ''}>
            <span
                onClick={e => this.handleHeaderClick(e)}
                onFocus={e => this.handleHeaderClick(e)}
            >{header}</span>
          </span>
        </div>
        {showBody && !disableBody && !onClose &&(
          <div className={`filter-dropdown-card ${label}`} ref={node => this.setWrapperRef(node)}>
            {body}
            {children}
          </div>
        )}
      </FilterMenuStyled>
    );
  }
}

Menu.propTypes = propTypes;

Menu.defaultProps = defaultProps;

export default Menu;
