import React from 'react';
import { NavLink } from 'react-router-dom';
import { Collapsible } from '../../components/Collapsible/index';
import {
  AGEING,
  STOCK_AGEING,
  BUSINESS_GROUPS,
  CUSTOMER_LEDGER_BASE,
  DATABASE_LOG,
  ENTRY_LOG,
  INVENTORY_BASE,
  JOURNAL_VOUCHER_BASE,
  LEDGERS,
  OPENING_BALANCE,
  OUTSTANDING,
  PURCHASE_RETURN_BASE,
  PURCHASE_RETURN_VAT,
  PURCHASE_VAT,
  RECEIPT,
  PAYMENT,
  SALES_INVOICE_BASE,
  SERVICE_INVOICE_BASE,
  SALES_MATERIALIZED_VIEW,
  SALES_RETURN_BASE,
  SALES_RETURN_VAT,
  SALES_VAT,
  SKU,
  STOCK_JOURNAL_BASE,
  STOCK_LEDGER_BASE,
  STOCK_VALUATION_REPORT,
  DAMAGE_EXPIRY_VALUATION,
  USERS,
  USER_GROUP,
  VAT,
  AGGREGATE_CUSTOMER_LEDGER_BASE,
  DAY_BOOK_SUMMARY,
  AGENT,
  ACCOUNT_GROUP,
  TRIAL_BALANCE,
  PROFIT_LOSS,
  BALANCE_SHEET,
  FISCAL_YEAR_MIGRATION,
  ABBREVIATED_INVOICE_BASE,
  CONTRA_ENTRY_DETAILS,
  CONTRA_ENTRY_BASE,
  TAG,
  SETTINGS,
  DEBIT_NOTE,
  CREDIT_NOTE,
  PICK_LIST,
  PDC,
  PROMOTION,
  NEW_SALES_VAT,
  NEW_PURCHASE_VAT,
  NEW_PURCHASE_RETURN_VAT,
  NEW_SALES_RETURN_VAT,
  SALES_PERSON_OUTSTANDING,
  SALES_REPORT,
  SRN_REPORT,
  GRN_REPORT,
  PRN_REPORT,
  VAT_SUMMARY_REPORT, LEDGER_WISE, TAG_WISE, SERVICE,
  NETSALES,
  ARCHIVED_EMAIL,
  EMAIL_FREQUENCY,
} from '../../data/enums/Route';

import PROGRAM from '../../data/enums/program';

const MENUS = [
  {
    url: SERVICE_INVOICE_BASE,
    acronym: 'SR I',
    title: 'Service Invoice',
    program: PROGRAM.SI,
    children: [],
  },
  {
    url: SALES_RETURN_BASE,
    acronym: 'SRN',
    title: 'Sales Return',
    program: PROGRAM.SRN,
    children: [],
  },
  {
    url: JOURNAL_VOUCHER_BASE,
    acronym: 'JV',
    title: 'Journal Voucher',
    program: PROGRAM.JOURNAL_VOUCHER,
    children: [],
  },
  {
    url: ARCHIVED_EMAIL,
    acronym: 'AE',
    title: 'Archived Email',
    program: PROGRAM.SI,
    children: [],
  },
  {
    url: EMAIL_FREQUENCY,
    acronym: 'EFC',
    title: 'Email Frequency Category',
    program: PROGRAM.SI,
    children: [],
  },
  // {
  //   url: CONTRA_ENTRY_BASE,
  //   acronym: 'CE',
  //   title: 'Contra Entry',
  //   program: PROGRAM.CONTRA_ENTRY,
  //   children: [],
  // },
  {
    url: DEBIT_NOTE,
    acronym: 'DN',
    title: 'Debit Note',
    program: PROGRAM.DEBIT_NOTE,
    children: [],
  },
  {
    url: CREDIT_NOTE,
    acronym: 'CN',
    title: 'Credit Note',
    program: PROGRAM.CREDIT_NOTE,
    children: [],
  },
  // {
  //   url: PICK_LIST,
  //   acronym: 'PL',
  //   title: 'Pick List',
  //   program: PROGRAM.PICK_LIST,
  //   children: [],
  // },
  {
    url: '',
    acronym: 'CO',
    title: 'Collection',
    program: PROGRAM.PAYMENT,
    children: [
      {
        url: RECEIPT,
        acronym: 'RC',
        title: 'Receipt',
        program: PROGRAM.PAYMENT,
        children: [],
      },
      {
        url: PAYMENT,
        acronym: 'PY',
        title: 'Payment',
        program: PROGRAM.PAYMENT,
        children: [],
      },
      
      // {
      //   url: PDC,
      //   acronym: 'PDR',
      //   title: 'PDC Report',
      //   program: PROGRAM.PAYMENT,
      //   children: [],
      // },
    ],
  },
  // {
  //   url: '',
  //   acronym: 'TR',
  //   title: 'Tag Report',
  //   program: PROGRAM.LEDGER_WISE,
  //   children: [
  //     {
  //       url: LEDGER_WISE,
  //       acronym: 'LW',
  //       title: 'Ledger Wise Tag Report',
  //       program: PROGRAM.LEDGER_WISE,
  //       children: [],
  //     },
  //     {
  //       url: TAG_WISE,
  //       acronym: 'TW',
  //       title: 'Tag Wise Ledger Report',
  //       program: PROGRAM.TAG_WISE,
  //       children: [],
  //     },
  //   ],
  // },
  // {
  //   url: '',
  //   acronym: 'I',
  //   title: 'Inventory',
  //   program: PROGRAM.SI,
  //   children: [
  //     {
  //       url: STOCK_LEDGER_BASE,
  //       acronym: 'SL',
  //       title: 'Stock Ledger',
  //       program: PROGRAM.STOCK_LEDGER,
  //       children: [],
  //     },
  //     {
  //       url: OPENING_BALANCE,
  //       acronym: 'OB',
  //       title: 'Opening Balance',
  //       program: PROGRAM.OPENING_BALANCE,
  //       children: [],
  //     },
  //     {
  //       url: STOCK_JOURNAL_BASE,
  //       acronym: 'SJ',
  //       title: 'Stock Journal',
  //       program: PROGRAM.STOCK_JOURNAL,
  //       children: [],
  //     },
  //     {
  //       url: STOCK_VALUATION_REPORT,
  //       acronym: 'SV',
  //       title: 'Stock Valuation Report',
  //       program: PROGRAM.SVR,
  //       children: [],
  //     },
  //     {
  //       url: DAMAGE_EXPIRY_VALUATION,
  //       acronym: 'DEV',
  //       title: 'Damage Expiry Valuation',
  //       program: PROGRAM.DAMAGE_EXPIRY_VALUATION_REPORT,
  //       children: [],
  //     },
  //     {
  //       url: STOCK_AGEING,
  //       acronym: 'SAR',
  //       title: 'Stock Ageing Report',
  //       program: PROGRAM.STOCK_AGEING,
  //       children: [],
  //     },
  //   ],
  // },
  {
    url: '',
    acronym: 'C',
    title: 'Configuration',
    program: PROGRAM.VENDOR_CUSTOMER,
    children: [
      // {
      //   url: FISCAL_YEAR_MIGRATION,
      //   acronym: 'FYM',
      //   title: 'Fiscal Year Migration',
      //   program: PROGRAM.FISCAL_YEAR_MIGRATION,
      //   children: [],
      // },
       {
        url: LEDGERS,
        acronym: 'L',
        title: 'Ledgers',
        program: PROGRAM.VENDOR_CUSTOMER,
        children: [],
      },
      // {
      //   url: SKU,
      //   acronym: 'SKU',
      //   title: 'SKU',
      //   program: PROGRAM.SKU,
      //   children: [],
      // },
      {
        url: SERVICE,
        acronym: 'SR',
        title: 'Service',
        program: PROGRAM.SKU,
        children: [],
      },
      {
        url: USERS,
        acronym: 'US',
        title: 'Users',
        program: PROGRAM.USER,
        children: [],
      },
      {
        url: USER_GROUP,
        acronym: 'UG',
        title: 'User Group',
        program: PROGRAM.USER,
        children: [],
      },
      // {
      //   url: AGENT,
      //   acronym: 'A',
      //   title: 'Salesperson',
      //   program: PROGRAM.AGENT,
      //   children: [],
      // },
      {
        url: ACCOUNT_GROUP,
        acronym: 'AG',
        title: 'Account Group',
        program: PROGRAM.ACCOUNT_GROUP,
        children: [],
      },
      // {
      //   url: TAG,
      //   acronym: 'T',
      //   title: 'Tags',
      //   program: PROGRAM.TAG,
      //   children: [],
      // },
      {
        url: SETTINGS,
        acronym: 'S',
        title: 'Settings',
        program: PROGRAM.SETTINGS,
        children: [],
      },
      // {
      //   url: PROMOTION,
      //   acronym: 'PRM',
      //   title: 'Promotion',
      //   program: PROGRAM.PROMOTION,
      //   children: [],
      // },
    ],
  },
  {
    url: '',
    acronym: 'FR',
    title: 'Fundamental Reports',
    program: PROGRAM.LEDGER_REPORT,
    children: [
      {
        url: CUSTOMER_LEDGER_BASE,
        acronym: 'LR',
        title: 'Ledger Report',
        program: PROGRAM.LEDGER_REPORT,
        children: [],
      },
      {
        url: NEW_SALES_VAT,
        acronym: 'SVR',
        title: 'Sales VAT Report',
        program: PROGRAM.SVR,
        children: [],
      },
      {
        url: SALES_VAT,
        acronym: 'OSVR',
        title: 'Old Sales VAT Report',
        program: PROGRAM.SVR,
        children: [],
      },
      {
        url: VAT,
        acronym: 'VR',
        title: 'VAT Report',
        program: PROGRAM.VAT_REPORT,
        children: [],
      },
      {
        url: VAT_SUMMARY_REPORT,
        acronym: 'VSR',
        title: 'VAT Summary Report',
        program: PROGRAM.VAT_SUMMARY_REPORT,
        children: [],
      },
      {
        url: NEW_SALES_RETURN_VAT,
        acronym: 'SRVR',
        title: 'Sales Return VAT Report',
        program: PROGRAM.SRN_VR,
        children: [],
      },
      // {
      //   url: AGGREGATE_CUSTOMER_LEDGER_BASE,
      //   acronym: 'ALR',
      //   title: 'Aggregated Ledger Report',
      //   program: PROGRAM.AGGREGATE_CUSTOMER_LEDGER_BASE,
      //   children: [],
      // },
      // {
      //   url: AGEING,
      //   acronym: 'AR',
      //   title: 'Ageing Report',
      //   program: PROGRAM.AGEING_REPORT,
      //   children: [],
      // },
      {
        url: SALES_MATERIALIZED_VIEW,
        acronym: 'SMV',
        title: 'Sales Materialized View',
        program: PROGRAM.SMV,
        children: [],
      },
      // {
      //   url: SALES_PERSON_OUTSTANDING,
      //   acronym: 'SPOR',
      //   title: 'Sales Person Outstanding Report',
      //   program: PROGRAM.AGENT_WISE_OUTSTANDING_REPORT,
      //   children: [],
      // },
      // {
      //   url: OUTSTANDING,
      //   acronym: 'OR',
      //   title: 'Outstanding Report',
      //   program: PROGRAM.OUTSTANDING_REPORT,
      //   children: [],
      // },
      // {
      //   url: NETSALES,
      //   acronym: 'NSR',
      //   title: 'Net Sales Report',
      //   program: PROGRAM.NET_SALES_REPORT,
      //   children: [],
      // },
      {
        url: ENTRY_LOG,
        acronym: 'EL',
        title: 'Entry Log',
        program: PROGRAM.ENTRY_LOG,
        children: [],
      },
      {
        url: DATABASE_LOG,
        acronym: 'DL',
        title: 'Database Log',
        program: PROGRAM.DATABASE_LOG,
        children: [],
      },
    ],
  },
  {
    url: '',
    acronym: 'A/C R',
    title: 'Accounting Reports',
    program: PROGRAM.PROFIT_LOSS,
    children: [
      {
        url: TRIAL_BALANCE,
        acronym: 'TB',
        title: 'Trial Balance',
        program: PROGRAM.TRIAL_BALANCE,
        children: [],
      },
      {
        url: PROFIT_LOSS,
        acronym: 'PLA',
        title: 'Profit and Loss A/C',
        program: PROGRAM.PROFIT_LOSS,
        children: [],
      },
      {
        url: DAY_BOOK_SUMMARY,
        acronym: 'DB',
        title: 'Day Book',
        program: PROGRAM.DAY_BOOK_SUMMARY,
        children: [],
      },
      {
        url: BALANCE_SHEET,
        acronym: 'BS',
        title: 'Balance Sheet',
        program: PROGRAM.BALANCE_SHEET,
        children: [],
      },
    ],
  },
  // {
  //   url: '',
  //   acronym: 'AR',
  //   title: 'Archive Report',
  //   program: PROGRAM.SALES_REPORT,
  //   children: [
  //     {
  //       url: SALES_REPORT,
  //       acronym: 'SI R',
  //       title: 'Sales Invoice Report',
  //       program: PROGRAM.SALES_REPORT,
  //       children: [],
  //     },
  //     {
  //       url: SRN_REPORT,
  //       acronym: 'SRN R',
  //       title: 'Sales Return Report',
  //       program: PROGRAM.SRN_REPORT,
  //       children: [],
  //     },
  //     {
  //       url: GRN_REPORT,
  //       acronym: 'GRN R',
  //       title: 'Good Received Report',
  //       program: PROGRAM.GRN_REPORT,
  //       children: [],
  //     },
  //     {
  //       url: PRN_REPORT,
  //       acronym: 'PRN R',
  //       title: 'Purchase Return Report',
  //       program: PROGRAM.PRN_REPORT,
  //       children: [],
  //     },
  //   ],
  // },
  // { url: PROMOTION, acronym: 'PR', title: 'Promotion' },
  // {
  // { url: SKU_PRICE, acronym: 'SP', title: 'SKU Price' },

  // { url: JOURNAL_VOUCHER_BASE, acronym: 'JV', title: 'Journal Voucher' },
];
const NavTitle = ({ link, drawerMiniState, onDrawerStateChange }) => (
  <>
    {drawerMiniState ? (
      <p>
        {link.acronym}
        <img src='' alt='' onClick={onDrawerStateChange} />
      </p>
    ) : (
      <p>
        <span onClick={onDrawerStateChange}>{link.acronym}</span>
        <strong>{link.title}</strong>
      </p>
    )}
  </>
);
const LinkTag = ({ link, drawerMiniState, path }) => (
  <NavLink to={`/${link.url}`} activeClassName={'bg-accent-color'}>
    <NavTitle link={link} drawerMiniState={drawerMiniState} />
  </NavLink>
);

const CollapsibleTag = ({ link, drawerMiniState, onDrawerStateChange, path }) => {
  const active = link.children.filter((a) => a.url == path);
  const activeTab = active.length > 0;
  return (
    <Collapsible
      trigger={<NavTitle link={link} drawerMiniState={drawerMiniState} onDrawerStateChange={onDrawerStateChange} />}
      activeTab={activeTab}
    >
      {link.children.map((element) => (
        <LinkTag link={element} drawerMiniState={drawerMiniState} path={path} />
      ))}
    </Collapsible>
  );
};

export {
  CollapsibleTag, MENUS, LinkTag
};
