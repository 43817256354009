import Create from './Create';

import { compose } from 'recompose';

import apiInterceptor from '../../../../api/interceptor';

import { emailFrequencyCustomerList } from '../../../common/base.api';

const composedCreate = compose(apiInterceptor({ ...emailFrequencyCustomerList }))(Create);

export default composedCreate;
