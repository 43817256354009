import React, { Component } from 'react';
import DetailView from '../../common/detail/DetailView';
import PageButtonWrapper from '../../common/PageButtonWrapper';
import config from './config';
import * as filterUtil from '../../../utils/filterUtil';
import { checkIfApiCallSuccess, jvApi ,ledgerApi,tagApi} from '../../common/base.api';
import withBaseState from '../../common/withBaseState';
import { JOURNAL_VOUCHER_BASE } from '../../../data/enums/Route';
import { Button,Icon } from '../../../components/BillingMDC';
import history from '../../../utils/history';
import { voucherDecoder } from '../../../utils/miscellaneous';
import { clone } from '../../../utils/arrayProcessor';
import Header from '../../../components/PrintComponent/Header';
import TableHeader from '../../../components/TableHeader/TableHeader';
import VoucherFooter from '../../../components/PrintComponent/voucherFooter';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import numberToWords from '../../../utils/numberToTextConverter';
import {flattenDataPrintAttributeWise, groupPrintDataSet} from "../../common/print.service";
import JVStyled from '../JVStyled';
import JvDialogView  from './DialogView';
import { validateForm } from '../../common/validation';
import { BillingSnackBar } from '../../../components/BillingMDC';
import * as snackService from '../../common/snack.service';

const propTypes = {};
const defaultProps = {};

class JournalVoucherDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        summary: {},
      },
      editInput:false,
      snack: { ...snackService.snackParameters },
      createJV:config.formMapper({}),
      createJVBackUp:{},
      summary:{
        totalDrAmount:0,
        totalCrAmount:0,
        mainNarration:'',
        documentDate: filterUtil.getCurrentDay(),
      },
      showUpdateBtn:false,
      dataBackup:[],
      readModalOpen: false,
      transactionDisabled: {
        credit: false,
        debit: false,
      },
      validation: {
        flag: false,
        fieldList: config.formValidationFieldList,
      },
      idJournalVoucherDetail:0,
      editModalOpen:false,
      userDetail: { id: 'userId', title: '' },
      preparedBy: { title: 'Prepared By', value: '' },
      dataList: [],
      print: false,
      documentDate: filterUtil.getCurrentDay(),
      ledgerList: [],
      tagList:[],
    };

    this.dataListLength = 0;
  }

  componentDidMount() {
    this.getData();
    this.getLedgerList();
    this.getTagList();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }
  getTagList = () => {
    tagApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ tagList: response.data.list });
      }
    });
  };

   // get the customer list for the selection.
   getLedgerList = () => {
    ledgerApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        const responseDataList = response.data.list;
        responseDataList.map(ledger => {
          ledger.label = `${ledger.customerId}. ${ledger.title}`;
        });
        this.setState({ ledgerList: responseDataList });
      }
    });
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  getData = () => {
    const { match } = this.props;
    const { preparedBy,summary } = this.state;
    const voucher_number = voucherDecoder(match.params.id);

    jvApi.getDetails({ voucher_number, user_id: match.params.userId || '' }).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const { journalVoucherDetail, journalVoucher } = response.data;
        const {
          narration,
          drAmount,
          crAmount,
          date = journalVoucher.transactionDate,
          mitiTitle,
          voucherNumber,
          idJournalVoucher,
          documentDate,
          transactionType,
        } = journalVoucher;

        const formattedList = flattenDataPrintAttributeWise(journalVoucherDetail, config.lengthyAttributes, config.getData);
        const data = {
          list: formattedList,
          summary: {
            narration,
            drAmount,
            crAmount,
            date,
            mitiTitle,
            voucherNumber,
            idJournalVoucher,
            transactionType,
            totalInWords: numberToWords(drAmount || 0),
          },
        };
        const backUpData = clone([...formattedList]);
        const userDetail = config.userDetailMapper(response.data);
        const documentDateUpdated =filterUtil.formatToDateMonthYear(documentDate);
        preparedBy.value = userDetail.title;
        summary.mainNarration = narration;
        summary.documentDate = documentDateUpdated;
        this.setState({ data, dataBackup:backUpData,userDetail, preparedBy, documentDate: documentDateUpdated ,summary});
      }
    });
  };
  handleUpdate = () => {
  this.setState({readModalOpen:true});
  }
  handlePrintClick = () => {
    const self = this;
    this.groupPrintDataSetNew();
    self.setState({ print: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.setState({ print: false });
    };
  };

  btnWrapper = () =>{
    const { showUpdateBtn,editInput} = this.state;
     return (
    <>
      <Button accent className="cancel-btn modal-btn" onClick={() => history.push(`/${JOURNAL_VOUCHER_BASE}`)}>
        Cancel
      </Button>
      <Button
        accent
        className="margin-right-0 save-btn modal-btn"
        onClick={() => {
          this.handlePrintClick();
        }}
      >
        Print
      </Button>
      { (editInput || showUpdateBtn) && 
      <Button
        accent
        className="margin-right-0 save-btn modal-btn"
        onClick={() => {
          this.handleUpdate();
        }}
      >
        Update
      </Button>
  }
    </>
  )};

  groupPrintDataSetNew() {
    const { data} = this.state;
    const { company  } = this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(data.list);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  }

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  }
   handleEditIconClick = list => {   
    this.disableInput();
    this.setState({
      createJV:config.formMapper({...list}),
      createJVBackUp:clone({list}),
      editModalOpen:true,
      idJournalVoucherDetail:list?.idJournalVoucherDetail,
    },()=>{
      this.disableInput();
    }
     )
  };
  disableInput = () => {
    const { createJV, transactionDisabled } = this.state;
    if (Number(createJV.drAmount) !== 0) {
      createJV.crAmount = 0;
      transactionDisabled.credit = true;
      transactionDisabled.debit = false;
    } else if (Number(createJV.crAmount) !== 0) {
      createJV.drAmount = 0;
      transactionDisabled.debit = true;
      transactionDisabled.credit = false;
    } else if (Number(createJV.drAmount) === 0 || Number(createJV.crAmount) === 0) {
      transactionDisabled.credit = false;
      transactionDisabled.debit = false;
    }
    this.setState({ transactionDisabled, createJV });
  };
  handleDateChange = (field, value) => {
    const {summary } = this.state
      summary.documentDate =filterUtil.formatToDateMonthYear(value);
      this.setState({ documentDate: summary.documentDate,summary});
  };
  onChange = (field, value, all = {}) => {
    const { createJV ,data,summary} = this.state;
    if(field ==="mainNarration"){
      summary[field] = value;
      data.summary.narration = summary.mainNarration;
      }
      else if(field === 'customerId'){
        createJV.customerId = value;
      }
      else if(field === 'tagid'){
        createJV.tagsId = value;
        
      }
      else{
      createJV[field] = value;
      }
    this.disableInput();
    this.setState({ createJV: createJV ,data,summary});
  };
  handleModalClose = () => {
    this.resetJVDialog();
    this.resetJVData();
    this.getData();
  };
  resetJVDialog = () => {
    this.setState({ editModalOpen: false,readModalOpen: false,formEmptyField: false});
  }
  resetJVData = () => {
    this.getData();
    this.setState({showUpdateBtn:false,editInput:false});
  };
  updateDataSummary= () =>{
    const {data,summary} =this.state;
    this.checkBalance();
    data.summary.crAmount = summary.totalCrAmount;
    data.summary.drAmount = summary.totalDrAmount;
    this.setState({data})
  }
    handleModalOk = () => {
      const { editModalOpen, createJV ,data,validation,idJournalVoucherDetail,tagList,ledgerList} = this.state;
      if ( editModalOpen) {
        const formValid = validateForm(createJV, validation, valid => this.setState({ validation: valid }));
        if (formValid) {
          const objIndex = data.list.findIndex((obj => obj.idJournalVoucherDetail === idJournalVoucherDetail));
            data.list[objIndex].drAmount = createJV.drAmount;
            data.list[objIndex].crAmount = createJV.crAmount;
            data.list[objIndex].narration = createJV.narration;
            data.list[objIndex].customer.customerId = createJV?.customerId;
            data.list[objIndex].customerTitle =ledgerList.filter((a)=>a?.customerId === createJV.customerId)[0]?.title;
            data.list[objIndex].partyLedgerId = createJV?.partyLedgerId;
            data.list[objIndex].partyLedgerTitle =ledgerList.filter((a)=>a?.customerId === createJV.partyLedgerId)[0]?.title;
            data.list[objIndex].partyTaxableAmount =createJV?.partyTaxableAmount;
            data.list[objIndex].tagTitle =tagList.filter((a)=>a?.idTags === createJV.tagsId)[0]?.title;
            data.list[objIndex].tag.id_tags=createJV.tagsId;
          this.setState({data},()=>{
            this.getChangedDataList();
            this.updateDataSummary();
          })
          this.resetJVDialog();
      } else {
        this.setState({data})
        // this.resetJVDialog();
      }
    }
    else{
      this.getUpdateApi();
      this.resetJVDialog();
    }
    };
    checkBalance =() =>{
      const {data,summary} =this.state;
      const totalCrAmount = data.list.reduce((a, b) => ({crAmount: a.crAmount + b.crAmount}));
      const totalDrAmount = data.list.reduce((a, b) => ({drAmount: a.drAmount + b.drAmount}));
      const compare =( totalCrAmount.crAmount === totalDrAmount.drAmount) ? true : false;
      summary.totalCrAmount =totalCrAmount.crAmount;
      summary.totalDrAmount =totalDrAmount.drAmount;
      summary.narration =data.summary.narration;
      this.setState({summary});
      return compare;
    }
    compareDataList =(otherArray) =>{
      return function(c){
        return otherArray.filter(function(o){
          return o.drAmount === c?.drAmount && o?.crAmount === c?.crAmount && o?.narration === c?.narration && o?.tagTitle ===c?.tagTitle &&o?.ledgerTitle ===c?.ledgerTitle  &&  o?.customerId === c?.customerId && o?.partyLedgerId === c?.partyLedgerId  && o?.partyTaxableAmount === c?.partyTaxableAmount 
        }).length === 0;
      }
    }
    getChangedDataList =() =>{
      const {data,dataBackup}= this.state;
      const filteredData = data.list.filter(this.compareDataList(dataBackup))
      this.setState({showUpdateBtn: !!filteredData.length})
      return filteredData;
    }
    getUpdateApi = () => {
      const {data,summary} =this.state;
      const {update} =this.props;
      const checkBalanceStatus =this.checkBalance(); 
      const filteredDataList =this.getChangedDataList();
      const dataList =filteredDataList.length === 0 ?data.list:filteredDataList;
      const apiTransformedData =config.updateApiTransformedData(dataList,summary)
      if(checkBalanceStatus){
        update({
          id: data.summary.idJournalVoucher,
          query: apiTransformedData,
        })
          .then(response => {
            const snack = snackService.generateUpdateMessage();
            this.setState({ snack });
           this.resetJVData();
          })
          .catch(err => {
            const snack = snackService.generateFailureMessage('Error while updating JV Details');
            this.handleModalClose();
            this.setState({ snack });
          });
      }
      else{
        const errorMsg = "Debit Credit Not Balanced";
        const snack = snackService.generateFailureMessage(errorMsg);
        this.setState({ snack });
      this.handleModalClose();
      }
    }
  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };
  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };


  render() {
    const { serverResponseWaiting, company } = this.props;
    const { data, print, dataMiscellaneousList, dataList, preparedBy, documentDate,editModalOpen ,createJV,transactionDisabled,readModalOpen,validation,editInput,snack,summary,ledgerList,tagList} = this.state;
    const transactionTypeStatus= config.TRANSACTION_TYPE_ALLOWED.includes(data.summary.transactionType );
    return (
      <JVStyled className={`j-v-detail ${serverResponseWaiting ? 'clickable-false' : ''}`}>
        <div className={print ? 'no-print' : 'display-block'}>
         {transactionTypeStatus  &&
        <div className="edit-cancel print_display_none">
        <Icon name='edit' className ={`${editInput && 'active'}`} onClick={() =>{ this.setState({editInput:true})}} />
        <Icon name='close' onClick={() =>{ this.setState({editInput:false})}}/>
        </div>
          }
          <DetailView
            display={{
              header: true,
              footer: true,
              summary: true,
            }}
            serverResponseWaiting={serverResponseWaiting}
            headerConfig={{
              company,
              title: config.title,
              miti: data.summary.mitiTitle,
              date: documentDate,
            }}
            pageConfig={config}
            changeTheadStatus ={transactionTypeStatus}
            data={data}
            onTableBodyClick={this.handleEditIconClick}
            editInput ={editInput}
            handleInputChange ={this.onChange}
            documentDate={summary.documentDate}
            mainNarration={summary.mainNarration}
            handleDateChange={this.handleDateChange}
            ledgerList ={ledgerList}
          />
            <JvDialogView
            onModalClose={this.handleModalClose}
            createJV ={createJV}
            editModalOpen={editModalOpen}
            readModalOpen={readModalOpen}
            transactionDisabled={transactionDisabled}
            handleInputChange={this.onChange}
            onModalSubmit={this.handleModalOk}
            formEmptyField={validation.flag}
            handleDateChange={this.handleDateChange}
            ledgerList={ledgerList}
            tagList={tagList}
          />
          <PageButtonWrapper renderBtn={this.btnWrapper} />
        </div>
        <div className={print ? 'display-block portrait-type jvd' : 'no-print'}>
          {dataList.map((jvItem, key) => (
            <div className="print-body zoom-reduce voucher-print">
              <div className="jv card-body">
                <Header
                  company={company}
                  divClassName={key === 0 ? 'first-header-bar header-border-bottom' : 'header-bar header-border-bottom'}
                  date={documentDate}
                  miti={data.summary.mitiTitle}
                  pageTitle={<h2>{config.title}</h2>}
                  dataMiscellaneousList={dataMiscellaneousList}
                />
                <div
                  className={`overflow-scrollable fixed-table-wrapper ${
                    !jvItem.footer ? 'fixed-table-height ' : 'payment-total-footer'
                  }`}
                >
                  <table className="voucher-table">
                    <TableHeader headerDetails={config.header} filterHeaderLabel={false} />

                    {config.getTableBody({ dataList: jvItem.list ,remainingLines:jvItem.remainingLines,dataSummary:data.summary, footer: jvItem.footer,print})}
                  </table>
                </div>
                {jvItem.footer && (
                  <table className="left-footer print-active total-section-wrapper footer clearfix">
                    <>
                      <VoucherFooter
                        preparedBy={preparedBy}
                        totalInWords={data.summary.totalInWords}
                        signatureList={config.signatureTitleList}
                        lineMargin="padding-r-24"
                        remarksStatus
                        remarks={data.summary.narration}
                      />
                    </>
                  </table>
                )}
              </div>
              <div className="footer-block">
                <PageNumber value={jvItem.page} totalPage={dataList.length} />
              </div>
            </div>
          ))}
        </div>
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </JVStyled>
    );
  }
}

JournalVoucherDetail.propTypes = propTypes;
JournalVoucherDetail.defaultProps = defaultProps;

const JournalVoucherDetailWithState = withBaseState(JournalVoucherDetail);

export default JournalVoucherDetailWithState;
