import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import LoginView from './View';
import history from '../../utils/history';
import { JWT } from '../../environment';
import { setDetails } from '../../actions';
import * as projectInit from '../../utils/projectInit';
import {ACTION, BUSINESS_ID} from '../../data/enums/enums';
import { validateForm } from '../common/validation';
import * as snackService from '../common/snack.service';
import { defaultFiscalYear, fiscalYear } from '../../data/enums/enums';
import { SALES_INVOICE_BASE, COMPANY } from '../../data/enums/Route';
import BillingSnackBar from '../../components/BillingMDC/BillingSnackbar';
import BILLING_PROGRAM from '../../data/enums/program';
import { MENUS } from '../../components/LeftDrawer/config';
import {buApi, checkIfApiCallSuccess} from "../common/base.api";

const propTypes = {
  serverResponseWaiting: PropTypes.bool.isRequired,
  serverResponseError: PropTypes.string.isRequired,
  userLogin: PropTypes.func.isRequired,
  onLoginSuccess: PropTypes.func.isRequired,
  initProject: PropTypes.func.isRequired,
};

class Login extends Component {
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  state = {
    form: {
      resetEmail: '',
      email: '',
      password: '',
    },
    snack: { ...snackService.snackParameters },
    forgetPW: {
      email: '',
    },
    validation: {
      flag: false,
      fieldList: [{ title: 'resetEmail' }],
    },
    dialogType: ACTION.NULL,
    showHidePw: false,
  };

  handleInputChange = (field, value) => {
    const { form } = this.state;
    form[field] = value;
    this.setState({ form });
  };
  handleFabButtonClick = () => {
    this.setState({ dialogType: ACTION.ADD });
  };

  getAndSetBUList = () => {
    buApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        const businessCompany = response.data.list.find(b => b.idBusinessCompany === 6) || {};
        const businessId = businessCompany.businessId || 1;
        localStorage.setItem(BUSINESS_ID, businessId);
      }
    });
  };

  handleFormSubmit = field => {
    const { userLogin, onLoginSuccess, initProject } = this.props;
    const { form, forgetPW, validation } = this.state;
    if (field === 'forgot_pw') {
      const formValid = validateForm(forgetPW, validation, valid => this.setState({ validation: valid }));
      if (formValid) {
        this.sendEmail();
        this.handleModalClose();
      }
    }
    if (field === 'login') {
      const { resetEmail, ...loginPayload } = form;
      localStorage.clear();
      userLogin(loginPayload).then(async response => {
        localStorage.setItem(JWT.LOCAL_STORAGE.APP_STORAGE.NAME, JSON.stringify(response.user));
        localStorage.setItem(JWT.LOCAL_STORAGE.TOKEN.NAME, response.accessToken);
        localStorage.setItem(JWT.LOCAL_STORAGE.REFRESH_TOKEN.NAME, response.refreshToken);
        localStorage.setItem(JWT.LOCAL_STORAGE.SETTINGS.NAME, response.settings);
        const billingDetails = setDetails({
          user: response.user,
          company: response.company,
          permission: response.permission || [],
          fiscalYearRange: response.fiscalYearRange || defaultFiscalYear,
        });
        await localStorage.setItem(fiscalYear, JSON.stringify(response.fiscalYearRange || defaultFiscalYear));
        await this.getAndSetBUList();
        projectInit.loadFilterData(initProject);
        onLoginSuccess(billingDetails);
        const companyConfigured = Object.keys(response.company).length > 0;

        if (!companyConfigured) {
          history.push(`/${COMPANY}`);
        } else {
          const defaultLandingPageUrl = this.getDefaultLandingPage(response.permission);
          history.push(`/${defaultLandingPageUrl}`);
        }
      },).catch(err => {
        const snack = snackService.generateFailureMessage('Incorrect username or password.');
        this.setState({ snack });
      });
    }
    else{
      const snack = snackService.generateFailureMessage('Error while Login');
      this.setState({ snack });
    }
  };

  getDefaultLandingPage = permissionList => {
    if (permissionList.length > 0) {
      if (permissionList.includes(BILLING_PROGRAM.SI)) {
        const menu = MENUS.find(m => m.program === BILLING_PROGRAM.SI) || {};
        return menu.url;
      }
      let url = '';
      MENUS.some(m => {
        if (m.children.length > 0) {
          m.children.some(c => {
            if (c.program === permissionList[0]) {
              url = c.url;
              return true;
            }
            return false;
          });
        }
        if (m.program === permissionList[0]) {
          url = m.url;
          return true;
        }
      });

      return url;
    }

    return '';
  };
  sendEmail = () => {
    const { form } = this.state;
    const { forgetPassword } = this.props;
    const data = form.resetEmail;
    forgetPassword({ email: data })
      .then(response => {
        const snack = snackService.generateSuccessMessage('Email is sucessfully send');
        this.setState({
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage('Error while sending email');
        this.setState({ snack });
      });
  };
  handleModalClose = () => {
    const { validation, form } = this.state;
    validation.flag = false;
    this.setState({
      dialogType: ACTION.NULL,
      validation,
      form: {
        ...form,
        resetEmail: '',
      },
    });
  };
  showHidePwFunc = () => {
    const { showHidePw } = this.state;
    this.setState({ showHidePw: !showHidePw });
  };
  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { form, validation, dialogType, snack, showHidePw } = this.state;
    const { serverResponseWaiting, serverResponseError } = this.props;
    return (
      <>
        <LoginView
          loading={serverResponseWaiting}
          onSubmit={this.handleFormSubmit}
          errorMessage={serverResponseError}
          data={form}
          onInputChange={this.handleInputChange}
          validationFlag={validation.flag}
          dialogType={dialogType}
          onModalClose={this.handleModalClose}
          handleFabButtonClick={this.handleFabButtonClick}
          showHidePwFunc={this.showHidePwFunc}
          showHidePw={showHidePw}
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </>
    );
  }
}

Login.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  onLoginSuccess: action => dispatch(action),
  initProject: action => dispatch(action),
});

const LoginDetails = connect(null, mapDispatchToProps)(Login);

export default LoginDetails;
