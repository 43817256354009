/**
 * Created by kAy on 8/15/17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _range from 'lodash/range';
import CustomSelect from './../Select/CustomSelect';
import { Icon, Grid, Cell } from 'react-mdc-web';
import PaginationStyled from './PaginationStyled';
// ...required at end if the page value cannot find max page by 3
const pagesInEachHalf = 3;
const defaultProps = {
  pageLimit: 50,
  orientation: 'bottom',
  currentPage: 1,
};
const propTypes = {
  pageSize: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onPageSelect: PropTypes.func.isRequired,
  orientation: PropTypes.string,
  currentPage: PropTypes.number,
};

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: props.pageSize,
      currentPage: 1,
      totalItems: props.totalItems,
      numberOfPages: 1,
      isLastActive: true,
      isNextActive: true,
      isFirstActive: true,
      isPreviousActive: true,
    };
  }

  componentDidMount() {
    this._setPropsToState();
  }

  componentWillReceiveProps(nextProps) {
    let totalNumberOfPages = Math.ceil(nextProps.totalItems / nextProps.pageSize);
    this.setState(
      {
        numberOfPages: totalNumberOfPages,
        currentPage: nextProps.currentPage,
      },
      () => {
        this._setActiveButtons();
      },
    );
  }

  handlePageChange = pageNumber => {
    this.setState({ currentPage: pageNumber }, () => {
      this.props.onPageSelect(pageNumber, this.state.pageSize);
    });
  };
  /** return if lower limit can be found */
  couldFindLowerLimit = (currentPage, totalPage) => {
    return !(currentPage > pagesInEachHalf + 1);
  };
  /** return if upper limit can be found */
  couldFindUpperLimit = (currentPage, totalPage) => {
    return totalPage - currentPage <= pagesInEachHalf;
  };
  /**
   * update the pageSize change
   */
  handlePageSizeChange = event => {
    this.setState(
      {
        pageSize: event.target.value,
        currentPage: 1,
      },
      () => {
        this.props.onPageSelect(this.state.currentPage, this.state.pageSize);
      },
    );
  };
  returnArrayForPages = (lowerLimitReached, upperLimitReached, totalNumberOfPages, currentPage) => {
    let startIndex = 1;
    let endIndex = totalNumberOfPages;
    let array = [];
    if (!lowerLimitReached) {
      startIndex = currentPage - pagesInEachHalf;
    }
    if (!upperLimitReached) {
      endIndex = currentPage + pagesInEachHalf;
    }
    for (let count = startIndex; count <= endIndex; count++) {
      array.push(count);
    }
    return array;
  };

  render() {
    const { currentPage, numberOfPages, pageSize } = this.state;
    const { orientation, totalItems } = this.props;
    const lowerLimitReached = this.couldFindLowerLimit(currentPage, numberOfPages);
    const upperLimitReached = this.couldFindUpperLimit(currentPage, numberOfPages);
    const arrayForPages = this.returnArrayForPages(lowerLimitReached, upperLimitReached, numberOfPages, currentPage);
    const start = (currentPage - 1) * pageSize + 1;
    const end = start + pageSize - 1;
    return (
      <PaginationStyled>
        <div className="pagination-wrapper">
          {
            //<Grid>
            //<Cell col={2} className="left-align">
          }
          <Grid>
            <Cell col={2} tablet={2} className="page-leftside-wrapper">
              <span className="page-leftside-wrapper-show">Show</span>

              <CustomSelect
                list={[
                  { label: '50', value: 50 },
                  { label: '100', value: 100 },
                  { label: '150', value: 150 },
                ]}
                name="pagination-select"
                className="pagination-dropdown"
                defaultValue="50"
                orientation={orientation}
                onChange={this.handlePageSizeChange}
              />
              {
                // <span className="Select-arrow-zone">
                //   <span className="Select-arrow"></span>
                // </span>
              }
            </Cell>
            <Cell col={8} tablet={4}>
              <span className="nv-margin">
                <span onClick={this.state.isFirstActive ? () => this._onClickFirst() : null}>
                  <Icon name="skip_previous" />
                </span>
                <span onClick={this.state.isPreviousActive ? () => this._onClickPrevious() : null}>
                  <Icon name="keyboard_arrow_left" />
                </span>
                {!lowerLimitReached && <span>...</span>}
                <span>
                  {arrayForPages.map(page => (
                    <span
                      className={
                        page !== this.state.currentPage ? 'pagination-page' : 'pagination-page pagination-page-active'
                      }
                      onClick={page !== this.state.currentPage ? () => this.handlePageChange(page) : null}
                    >
                      {page}
                    </span>
                  ))}
                </span>
                {!upperLimitReached && <span>...</span>}
                <span onClick={this.state.isNextActive ? () => this._onClickNext() : null}>
                  <Icon name="keyboard_arrow_right" />
                </span>
                <span onClick={this.state.isLastActive ? () => this._onClickLast() : null}>
                  <Icon name="skip_next" />
                </span>
              </span>
            </Cell>
            <Cell col={2} tablet={2}>
              <span className="float-right pagination-display-label">
                Showing {start?.toLocaleString()}-{end < totalItems ? end?.toLocaleString() : totalItems?.toLocaleString()}{' '}
                of {totalItems?.toLocaleString()}
              </span>
            </Cell>
          </Grid>

          {
            //</Cell>
            //</Grid>
          }

          {/*<select className="pagination-total-pages" value={pageSize} onChange={this.handlePageSizeChange}>*/}
          {/*<option value="50">50</option>*/}
          {/*<option value="100">100</option>*/}
          {/*<option value="150">150</option>*/}
          {/*</select>*/}
        </div>
      </PaginationStyled>
    );
  }

  /**
   * @private
   * get total number of pages.
   */
  _setPropsToState = () => {
    let totalNumberOfPages = Math.ceil(this.props.totalItems / this.props.pageSize);
    this.setState(
      {
        numberOfPages: totalNumberOfPages,
        currentPage: this.props.currentPage,
      },
      () => {
        this._setActiveButtons();
      },
    );
  };
  _setActiveButtons = () => {
    const { currentPage, numberOfPages } = this.state;
    let isFirstActive = true,
      isPreviousActive = true,
      isLastActive = true,
      isNextActive = true;
    if (currentPage === 1) {
      isFirstActive = false;
      isPreviousActive = false;
    }
    if (currentPage == numberOfPages) {
      isNextActive = false;
      isLastActive = false;
    }
    this.setState({
      isLastActive: isLastActive,
      isNextActive: isNextActive,
      isPreviousActive: isPreviousActive,
      isFirstActive: isFirstActive,
    });
  };
  _onClickPage = page => {
    this.setState({ currentPage: page }, () => {
      this._setActiveButtons();
      this.props.onPageSelect(page, this.state.pageSize);
    });
  };
  _onClickNext = () => {
    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this._setActiveButtons();
      this.props.onPageSelect(this.state.currentPage, this.state.pageSize);
    });
  };
  _onClickPrevious = () => {
    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this._setActiveButtons();
      this.props.onPageSelect(this.state.currentPage, this.state.pageSize);
    });
  };
  _onClickFirst = () => {
    this.setState({ currentPage: 1 }, () => {
      this._setActiveButtons();
      this.props.onPageSelect(this.state.currentPage, this.state.pageSize);
    });
  };
  _onClickLast = () => {
    this.setState({ currentPage: this.state.numberOfPages }, () => {
      this._setActiveButtons();
      this.props.onPageSelect(this.state.currentPage, this.state.pageSize);
    });
  };
}

Pagination.defaultProps = defaultProps;
Pagination.propTypes = propTypes;

export default Pagination;
