import React from 'react';
import PropTypes from 'prop-types';

import { fixedFloatAndCommas } from '../../../utils/conversion';

const SalesVatReportTableBody = ({ dataSet = [],remainingLines,columnTotal ={},footer =false, onTableBodyClick }) => {
  return (
    <>
      {
        dataSet.map((data) => (
          <tr className='cursor-pointer'>
            <td className='left-align'>{data.miti_title}</td>
            <td className='left-align bill-no link-voucher' onClick={() => onTableBodyClick(data)}>{data.bill_no}</td>
            <td className='left-align ledger-name'>{data.ledger_name}</td>
            <td className='left-align'>{data.pan_no}</td>
            <td className='right-align'>{data.business_alias || 'Miscellaneous'}</td>
            <td className='right-align'>{data.quantity || 0}</td>
            <td className='right-align'>{data.tax_exempted_amount ? fixedFloatAndCommas(data.tax_exempted_amount) : 0}</td>
            <td className='right-align'>{data.export_sales ? fixedFloatAndCommas(data.export_sales) : data.export_sales}</td>
            <td className='right-align'>{data.taxable_sales_amount ? fixedFloatAndCommas(data.taxable_sales_amount) : 0}</td>
            <td className='right-align'>
              {data.taxable_sales_vat ? fixedFloatAndCommas(data.taxable_sales_vat) : data.taxable_sales_vat}
            </td>
          </tr>
        ))
      }
      { footer &&
  <Total columnTotal ={columnTotal}/>
      }
      {remainingLines && remainingLines.length
        ? remainingLines.map((i) => (
          <tr className='blank-tr'>
            <td></td>
            <td colSpan ='11' className=''></td>
          </tr>
        ))
        : null}
    </>
  )
};

SalesVatReportTableBody.propTypes = {
  dataSet: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func,
};

const SVRTableFooter = ({ columnTotal = {} }) => (
  <tfoot className=''>
    <Total columnTotal ={columnTotal}/>
  </tfoot>
);
const Total = ({ columnTotal = {} }) => (
  <tr>
    <td colSpan='2' />
    <td className='left-align font-bold pad-l-10' colSpan={3}>
    Total
    </td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.totalQuantity)}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.taxExemptedSales)}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.exportSales)}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.totalTaxableAmount)}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.vat)}</td>
  </tr>
);

const salesVatReportConfig = {
  headerDetails: [
    {
      id: 2,
      label: 'miti_title',
      title: 'Miti',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    {
      id: 3,
      label: 'bill_no',
      title: 'Bill Number',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    {
      id: 4,
      label: 'ledger_name',
      title: 'Buyers Name',
      className: 'cursor-pointer left-align ledger-name',
      sortable: true,
    },
    {
      id: 5,
      label: 'pan_no',
      title: 'Buyers PAN No.',
      className: 'cursor-pointer left-align',
      sortable: true,
    },

    {
      id: 12,
      label: 'business_alias',
      title: 'Item name',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 13,
      label: 'quantity',
      title: 'Quantity',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 7,
      label: 'tax_exempted_sales',
      title: 'Tax Exempted Sale',
      className: 'cursor-pointer right-align',
      sortable: false,
    },
    {
      id: 8,
      label: 'export_sales',
      title: ' Export (Goods/Service) Amount',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 10,
      label: 'taxable_sales_amount',
      title: 'Total Sales',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 11,
      label: 'taxable_sales_vat',
      title: 'VAT',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
  ],
  upperHeaderDetails: [
    {
      id: 1,
      label: 'invoice',
      title: 'Invoice',
      className: 'table-cell center-align',
      sortable: false,
    },
    {
      id: 2,
      label: 'taxableSales',
      title: 'Taxable Sales',
      className: 'table-2-cell center-align',
      sortable: false,
    },
  ],
};

const downloadFormat = [
  { id: 1, value: 'Download Sales Book PDF' },
  { id: 2, value: 'Download Sales Book CSV' },
  { id: 3, value: 'Download Sales Book XLS' },
  { id: 4, value: 'Download Sales Book XML' },
  { id: 5, value: 'Download Net Sales Vat Report CSV' },
];
const lengthyAttributes = ['ledger_name'];
const printEnums = {
  withoutFooterLimit: 52,
  withFooterLimit: 51,
  ledger_name: 56 ,
  lengthyAttributes,
};

const config = {
  header: salesVatReportConfig,
  getTableBody: SalesVatReportTableBody,
  getTableFooter: SVRTableFooter,
  printEnums,
  uniqueIdGetter: (i) => i.id
};
export {
  downloadFormat, salesVatReportConfig, SalesVatReportTableBody, config
};
