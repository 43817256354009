import React from 'react';
import PropTypes from 'prop-types';
import * as filterUtil from '../../../utils/filterUtil';
import Icon from 'react-mdc-web/lib/Icon/Icon';

const ArchivedEmailDetailsTableBody = ({ dataList ,onSpanClick}) => (
  <tbody>
    {dataList?.map((data, index) => (
      <tr>
        <td className="left-align">{data?.invoice_number}</td>
        <td className="left-align">{filterUtil.formatToReadableDate(data?.date)} / {data?.time}</td>
        <td className="right-align" onClick={(e) => e.stopPropagation()} className='download-icon'>
        <Icon name='download' onClick={() => onSpanClick(data)} />
        </td>
      </tr>
    ))}
  </tbody>
);
ArchivedEmailDetailsTableBody.propTypes = { data: PropTypes.object.isRequired };

const ArchivedEmailDetailsConfig = [
  {
    id: 1, label: 'month', title: 'File Name', className: 'cursor-pointer left-align', sortable: false,
  },
  {
    id: 1, label: 'emailDate', title: 'Emailed Date', className: 'cursor-pointer left-align', sortable: false,
  },
  {
    id: 1, label: 'options', title: '', className: 'cursor-pointer right-align download-icon', sortable: false,
  },
];

const config = {
  header: ArchivedEmailDetailsConfig,
  getTableBody: ArchivedEmailDetailsTableBody,
};
export default config;
