import React, { Component } from 'react';
import { Icon, Grid, Cell } from 'react-mdc-web';
import Dialog from './Dialog';
import { loginApi } from '../../views/common/base.api';
import history from '../../utils/history';
import NavigationStyled from './NavigationStyled';
import { DBS, ACTION, PASSWORD_RULE } from '../../data/enums/enums';
import * as snackService from '../../views/common/snack.service';
import BillingSnackBar from '../BillingMDC/BillingSnackbar';

const propTypes = {};

const defaultProps = {};
class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      form: {
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
      },
      validationArr: ['oldPassword', 'newPassword', 'repeatPassword'],
      formEmptyField: false,
      dialogType: ACTION.NULL,
      snack: { ...snackService.snackParameters },
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  setWrapperRef = node => {
    if (node) {
      this.wrapperRef = node;
    }
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        showMenu: false,
      });
    }
  };

  handleShowMenu = event => {
    event.preventDefault();
    this.setState({ showMenu: true });
  };

  validateForm = (formObject, config, flag) => {
    const data = Object.keys(formObject).some(k => {
      if (config.indexOf(k) > -1) {
        const value = formObject[k];
        if (!value) {
          this.setState({ [flag]: true });
          return true;
        }
        if (k === 'newPassword' && value && flag === 'formEmptyField') {
          const valid = value.match(PASSWORD_RULE);
          if (!valid) {
            this.setState({ [flag]: true });
            return true;
          }
          return false;
        }
        if (k === 'repeatPassword' && value && flag === 'formEmptyField') {
          const { form } = this.state;
          const valid = value !== form.newPassword;
          if (valid) {
            this.setState({ [flag]: true });
            return true;
          }
          return false;
        }
        return false;
      }
      return false;
    });

    return !data;
  };

  handleInputChange = (field, value) => {
    const { form } = this.state;
    form[field] = value;
    this.setState({ form });
  };

  handleModalSubmit = () => {
    const { form, validationArr } = this.state;
    const formValid = this.validateForm(form, validationArr, 'formEmptyField');
    if (formValid) {
      this.changePasswordFunc();
      this.handleModalClose();
    }
  };

  changePasswordFunc = () => {
    const { user } = this.props;
    const { form } = this.state;
    const payload = {
      old_password: form.oldPassword,
      new_password: form.newPassword,
    };
    loginApi
      .changePassword({
        id: user.idUsers,
        query: payload,
      })
      .then(response => {
        const snack = snackService.generateSuccessMessage('Password Changed Sucessfully');
        this.setState({
          snack,
        });
        setTimeout(() => {
          history.push('/login');
        }, 1000);
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage(err.message);
        this.setState({
          snack,
        });
      });
  };

  handleFabButtonClick = () => {
    this.setState({ dialogType: ACTION.ADD });
  };

  handleModalClose = () => {
    this.setState({
      dialogType: ACTION.NULL,
      formEmptyField: false,
      form: {
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
      },
    });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { drawerState, onDrawerStateChange, onLogOutClick, onInfoClick, onDownloadClick, company, user } = this.props;
    const { showMenu, form, formEmptyField, dialogType, snack } = this.state;

    const fullName = user?.name?.split(' ');
    const firstName = fullName?fullName[0]:undefined;
    const lastName = fullName?fullName[fullName.length - 1]:undefined;

    return (
      <NavigationStyled>
        <div className="nav-bar">
          <Grid>
            <Cell tablet={1} phone={1}>
              <Icon name={`${drawerState ? 'menu' : 'close'}`} className="menu-icon" onClick={onDrawerStateChange} />
            </Cell>
            <Cell className="nav-bar-center-item" tablet={1} phone={1}>
              <h1>{"Evolve Billing System"}</h1>
              <h5>v1.0.0</h5>
            </Cell>
            <Cell className="nav-bar-right-item " tablet={6} phone={6}>
              <div className="flex">
                <>
                  <span className="grep" />
                  <span className="info-menu-icon" onClick={onInfoClick}>
                    <Icon name="info" />
                  </span>

                  <span className="logout-menu-icon" onClick={onDownloadClick}>
                    <Icon name="get_app" />
                  </span>
                </>
                <div className="logout-menu-userinfo">
                  <ul>
                    <li className="logout-username">{user.name}</li>
                    <li className="logout-distributor">{company.title}</li>
                  </ul>
                </div>
                <div className="user">
                  <span className="user__info" onClick={this.handleShowMenu}>
                  {`${firstName?.split('')[0]?.toUpperCase()}${lastName?.split('')[0]?.toUpperCase()}`}
                  </span>
                  {showMenu && (
                    <ul ref={node => this.setWrapperRef(node)}>
                      <div className="arrow-up" />
                      <li
                        onClick={e => {
                          this.handleFabButtonClick();
                        }}
                      >
                        Change Password
                      </li>
                      <li onClick={onLogOutClick}>Logout</li>
                    </ul>
                  )}
                  <Dialog
                    form={form}
                    actionType={dialogType}
                    onInputChange={this.handleInputChange}
                    onModalClose={this.handleModalClose}
                    onModalSubmit={this.handleModalSubmit}
                    formEmptyField={formEmptyField}
                  />
                </div>
              </div>
            </Cell>
          </Grid>
        </div>
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </NavigationStyled>
    );
  }
}

NavigationBar.propTypes = propTypes;

NavigationBar.defaultProps = defaultProps;

export default NavigationBar;
