import React, { Component } from 'react';
import tableConfig from './config';
import { ACTION } from '../../../data/enums/enums';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { CUSTOMER_VENDOR_CONFIG, PRN_CONFIG } from '../../common/domain.config';
import { AddFab, BillingSnackBar } from '../../../components/BillingMDC';
import * as tableLayoutService from '../../common/tableLayout';
import { PRN_CREATE, PRN_DETAILS } from '../../../data/enums/Route';
import { voucherEncoder } from '../../../utils/miscellaneous';
import history from '../../../utils/history';
import CreateBtn from '../../common/pagination/CreateBtn/CreateBtn';
import VatReportStyle from '../../irdReports/NewSRNVatReport/VatReportStyle';
import {storeInLocalStorage ,fetchFromLocalStorage} from "../../common/common";

class PurchaseReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          business_id: [],
        },
      },
      data: {
        list: [],
        total: 0,
      },
    };
    this.reference = {
      tableHeader: React.createRef(),
      tableFooter: React.createRef(),
      tableBody: React.createRef(),
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.getFilterStateFromStorage();
    /* tableLayoutService.adjustToScreen(
     this.refs.tableReference,
     this.refs.fixedTableFooter,
     this.refs.fixedTableBody,
     );*/
  }
  setFilterStateInStorage = () => {
    const { queryParameters,display} = this.state;
    storeInLocalStorage(
      'DISPLAY',
      display,
      'PRN'
    );
    storeInLocalStorage(
      'FILTER',
      {
       queryParameters,
      },
      "PRN"
    );
  };
  getFilterStateFromStorage = () => {
    const {queryParameters,display} =this.state;
    const localData = fetchFromLocalStorage('FILTER', "PRN");
    const localDisplay = fetchFromLocalStorage('DISPLAY', 'PRN');
    this.setState(
      { queryParameters:localData?.queryParameters || queryParameters ,
        display:{...localDisplay,filter:false} || display},
      () => {
        this.loadTableData();
      },
    );
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getList } = this.props;
    const { queryParameters } = this.state;
    this.setFilterStateInStorage();
    getList(queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.total;
      this.setState({ data });
    });
  };

  handleFabButtonClick = () => {
    history.push(`/${PRN_CREATE}`);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  handleTableBodyClick = data => {
    const encodedVoucherNo = voucherEncoder(data.purchaseReturnInvoiceNumber);
    history.push(`/${PRN_DETAILS}/${encodedVoucherNo}`);
  };
  handleClearSearch =() =>{
    const {display}=this.state;
    this.setState({display:{...display,searchBox:false,search:false}});
    this.basePaginationService.clearSearchText();
  }
  handleResetFilter =()=>{
    this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        business_id: [],
      },
    });
  }

  render() {
    const { queryParameters, data, display, snack } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <VatReportStyle>
      <div className="prn">
        <PageView
          domainConfig={PRN_CONFIG}
          headerConfig={{
            search: true,
            date: true,
            upload: false,
            download: true,
            filter: true,
            create: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.handleClearSearch}
          resetFilter={this.handleResetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleDownloadClick={this.basePaginationService.handleDownloadClick}
          handleFilterChange={this.basePaginationService.handleFilterChange}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onTableBodyClick={this.handleTableBodyClick}
          onPageSelect={this.basePaginationService.onPageSelect}
          createHandler={this.handleFabButtonClick}
        />
        {/* <AddFab handleFabClick={this.handleFabButtonClick}/> */}

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
      </VatReportStyle>
    );
  }
}

const PurchaseReturnWithState = withBaseState(PurchaseReturn);

export default PurchaseReturnWithState;
