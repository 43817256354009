import React from 'react'
import PageView from '../../../common/pagination/PageView'
import DialogView from './DialogView';
import { tableConfig } from './config';


const Customers =(props)=> {
  const { data, modalOpen, handleModalClose, editModalOpen, deleteModalOpen, handleCustomerChange, handleFabIconClick, customers, customerList, createCustomer, handleModalSubmit, handleDeleteIconClick,formValidation } =props;

  return (
    <div>
      <PageView
        domainConfig={{  title:`${customers?.length||''} Customers` }}
        headerConfig={{
          fabIcon: true,
          create: false
        }}
        data={ { list: customers }}
        createHandler={handleFabIconClick}
        tableConfig={tableConfig}
        footerConfig={{ pagination:false }}
        onIconClick={handleDeleteIconClick}
        endList={false}
      />
      <DialogView
        handleModalClose={handleModalClose}
        handleModalSubmit={handleModalSubmit}
        modalOpen={modalOpen}
        editModalOpen={editModalOpen}
        deleteModalOpen={deleteModalOpen}
        handleCustomerChange={handleCustomerChange}
        customers={customers}
        customerList={customerList}
        createCustomer={createCustomer}
        handleDeleteIconClick={handleDeleteIconClick}
        validation={formValidation.flag}
      />
    </div>
  )
}

export default Customers;
