import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import CreateSrnDialog from './DialogView';
import history from '../../../../utils/history';
import { clone } from '../../../../utils/arrayProcessor';
import * as filterUtil from '../../../../utils/filterUtil';
import { SALES_RETURN_BASE, SRN_DETAILS } from '../../../../data/enums/Route';
import { isObjectEmpty } from '../../../../utils/objectProcessor';
import { fixedFloatAndCommas, decimalRounding } from '../../../../utils/conversion';
import { voucherEncoder } from '../../../../utils/miscellaneous';
import BillingSelect from '../../../../components/Select/BillingSelect';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import { debouncer, handleBatchSelect } from '../../../../utils/handlers';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import RadioButtonList from '../../../../components/RadioButtonList/RadioButtonList';
import {
  CreateSalesReturnTableBody,
  createSkuMapper,
  customerMapper,
  agentMapper,
  editFormValidateFieldArray,
  formValidateFieldArray,
  message,
  pageValidateFieldArray, cashLedger,
} from './config';
import {
  buApi,
  catalogsApi,
  checkIfApiCallSuccess,
  ledgerApi,
  salesInvoiceApi,
  srnApi,
} from '../../../common/base.api';
import { Button, Cell, Checkbox, Fab, Grid, Icon, LinearProgress, Snackbar } from '../../../../components/BillingMDC';
import { validateForm } from '../../../common/validation';
import { getPercentage } from '../../../../utils/miscellaneous';
import { LEDGERS, VAT_PERCENTAGE } from '../../../../data/enums/enums';
import GRNStyled from '../../grn/GRNStyled';
import { checkBUId, getIntegratedBUList } from '../../../common/common';
import { JWT } from '../../../../environment';
import { updateDiscountValueOfInvoiceList } from '../../common/helpers';
import AutoComplete from "../../../../components/AutoComplete";
import CustomAutoComplete from "../../../../components/CustomAutoComplete";
import SRNStyled from '../SRNStyled';

const propTypes = {
  fiscalYearRange: PropTypes.shape({
    start_date: PropTypes.number,
    end_date: PropTypes.number,
    previous_fiscal_year_closed: PropTypes.bool,
  }),
};
const defaultProps = {
  fiscalYearRange: {
    start_date: filterUtil.getCurrentDate(),
    end_date: filterUtil.getCurrentDate(),
    previous_fiscal_year_closed: false,
  },
};

class CreateSalesReturn extends Component {
  // get the customer list for the selection.

  getCustomerList = () => {
    ledgerApi.getCustomerList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        const responseDataList = response.data.list;

        const updatedList = responseDataList.map(customer => {
          customer.label = `${customer.customerId}. ${customer.title}`;
          return customer;
        });

        // push cash ledger
        updatedList.push({...cashLedger});

        this.setState({ customerList: updatedList });
      }
    });
  };

  // get the brand list
  getBrandList = () => {
    const { businessUnitId, buList } = this.state;
    const integratedBUIds = getIntegratedBUList(buList).map(list => list.businessId);
    catalogsApi.getBrandList({ business_id: checkBUId(businessUnitId) }).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const filteredBrandList = response.data.list.filter(list => !integratedBUIds.includes(list.businessId));
        this.setState({ brandList: filteredBrandList });
      }
    });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = (brandId, skuId = null, businessId) => {
    const { batchList } = this.state;
    let batches = [...batchList];
    this.setState({ skuList: [] });
    catalogsApi.getSkuUnderBrand({ business_id: businessId, brand_id: brandId }).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const skus = response.data.list || [];
        if (skuId) {
          const sku = skus.find(sku => sku.businessSkuId === skuId) || {};
          batches = sku.batchList || [];
        }
        this.setState({ skuList: skus, batchList: batches, brandSKUList: skus });
      }
    });
  };

  // get sku list
  getSkuList = async inputValue => {
    const { businessUnitId, buList } = this.state;
    const integratedBUIds = getIntegratedBUList(buList).map(list => list.businessId);
    const response = await catalogsApi.getSkuList({ business_id: checkBUId(businessUnitId) });
    const filteredBrandList = response.data.list.filter(list => !integratedBUIds.includes(list.businessId));
    const filteredSKUList = filteredBrandList.filter(list =>
      list.title?.toLowerCase().includes(inputValue?.toLowerCase()),
    );
    this.setState({ skuList: filteredSKUList });
    return filteredSKUList;
  };

  getSkuLists =  () => {
    const { businessId } = this.state;
    catalogsApi.getSkuList({ business_id: businessId,  }).then((response) => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ skuList: response.data.list });
      }
    });
  };

  getBrandSkuList = async inputValue => {
    const { brandSKUList } = this.state;
    const filteredSKUList = brandSKUList.filter(list => list.title.toLowerCase().includes(inputValue.toLowerCase()));
    this.setState({ skuList: filteredSKUList });
    return filteredSKUList;
  };

  // get BU list
  getBUList = () => {
    buApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  getSelectedInvoiceList = checkedList => {
    const { invoiceList } = this.state;
    const selectedInvoiceList = invoiceList.filter(item => checkedList.indexOf(item.sales_detail_id) > -1);

    return selectedInvoiceList;
  };

  onInvoiceNumberSelection = (formattedValue, name) => {
    this.onInvoiceIdChange({ target: { name, value: formattedValue } }, true);
  };

  resetData = (invoiceNumber, callBack) => {
    this.resetBillSummaryAmounts();
    this.mapCustomerDetails({});
    this.mapAgentDetails({});
    this.mapBillName('');
    this.resetDiscountCalculationReferenceField();
    this.setState({
      invoiceId: invoiceNumber,
      invoiceList: [],
      skuList: this.state.skuList,
      invoiceIdPresent: false
    }, () =>
     callBack(invoiceNumber)
   );
  };

  getInvoiceNumberList = (salesInvoiceNumber) => {
    const { createSKU, invoiceNumberList, businessUnitId } = this.state;
    if (salesInvoiceNumber.length >= 3) {
      let exactlyMatched = invoiceNumberList.indexOf(salesInvoiceNumber) > -1;
      if (!exactlyMatched) {
        this.setState({ loading: true });
        salesInvoiceApi
            .getInvoiceNumberList({
                  invoiceString: salesInvoiceNumber,
                  businessId: businessUnitId,
        }).then((response) => {
            exactlyMatched = response.data.matched;
            const  { list = [] } = response.data;
            this.setState({
                  invoiceNumberList: list && list.map(i => i.invoiceNumber ) || [],
                  loading: false,
                  invoiceId: salesInvoiceNumber
                },
                () => this.getInvoiceIdDetails(salesInvoiceNumber, exactlyMatched));
          }).catch(e => {
          this.setState({ invoiceNumberList: [], loading: false },
              () => this.getInvoiceIdDetails(salesInvoiceNumber, exactlyMatched));
          /**todo: display error here  **/
        });
      } else {
        this.getInvoiceIdDetails(salesInvoiceNumber, exactlyMatched);
      }
    } else {
      this.setState({ invoiceNumberList: [], loading: false });
    }
  };

  getInvoiceIdDetails = (id, exactlyMatched) => {
    const { buList, businessId } = this.state;
    if(exactlyMatched) {
      const integratedBUIds = getIntegratedBUList(buList).map(list => list.businessId);
      this.setState({ loading: true });
      srnApi
          .getAvailableSales(id)
          .then(response => {
            this.setState({ loading: false });
            if (response.success && !isObjectEmpty(response.data)) {
              if (!integratedBUIds.includes(response.data.businessId) && businessId === response.data.businessId ) {
                const customerDetails = response.data.customerDetail;
                const agentDetails = response.data.agentDetail;
                const cashStatus = response.data.paymentMode;
                this.setState({ invoiceIdPresent: true, invoiceIdTaken: false },()=>{
                  this.getAgentList(exactlyMatched);
                });
                this.mapCustomerDetails(customerDetails);
                this.mapAgentDetails(agentDetails);
                this.mapPaymentMode(cashStatus);
                this.mapBillName(response.data.customerName);
                this.createListObject(response.data.salesInvoice);
                this.referenceData.subTotal = response.data.subTotalAmount || 1;
                this.referenceData.billDiscount = response.data.cashDiscount || 0;
                this.referenceData.tradeDiscount = response.data.tradeDiscount || 0;
              } else {
                this.setState({
                  showMessage: true,
                  success: false,
                  message: message.misMatchBusinessId,
                  invoiceIdPresent: false,
                  invoiceList: [],
                  invoiceIdTaken: true,
                });
              }
            } else {
              const userMessage = response.message
                  ? `!${response.message}`
                  : '!SRN already done.';
              this.setState({
                showMessage: true,
                success: false,
                message: userMessage,
                invoiceIdPresent: false,
                invoiceList: [],
                invoiceIdTaken: false,
              });
            }
          })
          .catch(() => {
            this.setState({
              loading: false,
              showMessage: true,
              success: false,
              message: message.invoiceNumberNotFound,
              invoiceIdPresent: false,
              invoiceList: [],
              invoiceIdTaken: false,
            });
          });
    }

  };

  onInvoiceIdChange = (event) => {
    const value = event.target.value;
    const invoiceId = value.trim();
    this.resetData(invoiceId, this.getInvoiceNumberList)
    /*this.setState({ invoiceId: value.trim() }, () => {
      debouncer(this.getInvoiceIdDetails, 1000)(this.state.invoiceId);
    });*/
    this.handleSecondaryCheckBoxClick();
  };

  checkQuantity = () => {
    const { createSKU } = this.state;

    if (
      createSKU.normal + createSKU.damage + createSKU.expiry >
      createSKU.compare_quantity
    ) {
      this.setState({
        qtyValidation: true,
      });
      return false;
    } else {
      this.setState({
        qtyValidation: false,
      });
      return true;
    }
  };

  // handle change of create sku events.
  onChange = (field, value, detail = {}) => {
    const { invoiceIdPresent, editModalOpen } = this.state;
    let { createSKU } = this.state;
    createSKU[field] = value;
    if (field === 'brand') {
      createSKU.batch_id = '';
      createSKU.rate = '';
      createSKU.promotion_discount = 0;
      createSKU.business_id = detail ? detail.businessId : '';
      createSKU.skuTitle = '';
      createSKU.sku_id = null;
      this.setState({ batchList: [] }, () =>
      detail && this.getSkuLists()
      );
      if(value === null){
        createSKU.brand='';
        this.setState({brandSKUList:[]})
      }
    }
    if (field === 'sku_id') {
      createSKU.skuTitle = detail.title || '';
      createSKU.rate = detail.rlpWithExcise;
      createSKU.business_id = detail.businessId;
      createSKU = {
        ...(handleBatchSelect(
          createSKU,
          (detail.batchList.length > 0 && detail.batchList[0]) || {},
          'sales'
        ) || createSKU),
      };
      createSKU.vat_percent = detail.vatPercent;
      this.setState({ batchList: detail.batchList || [] });
    }
    if (field === 'batch_id') {
      createSKU = {
        ...(handleBatchSelect(createSKU, detail, 'sales') || createSKU),
      };
    }

    if(field === 'discount_label') {
      createSKU.discount_input = null;
    }
    createSKU.quantity = createSKU.normal + createSKU.damage + createSKU.expiry;
    createSKU.amount = Number(
      Number(createSKU.quantity * createSKU.rate).toFixed(2)
    );
    const discount = createSKU.discount_label === 'rs'
        ? Number(createSKU.discount_input || 0)
        : Number(Number((createSKU.discount_input || 0) * createSKU.amount * 0.01).toFixed(2));
    createSKU.promotion_discount = (invoiceIdPresent ? createSKU.promotion_discount : discount) || 0;
    if (field === 'normal' || field === 'damage' || field === 'expiry') {
      if (editModalOpen) {
        this.checkQuantity();
      }
      createSKU.promotion_discount =( invoiceIdPresent
        ? this.calculateRelativeDiscount(
            createSKU.actual_quantity * createSKU.rate,
            createSKU.actual_promotion_discount,
            createSKU.amount,
          )
        : createSKU.promotion_discount) || 0;
    }
    if (
      createSKU.promotion_discount < 0 ||
      createSKU.promotion_discount > createSKU.quantity * createSKU.rate
    ) {
      createSKU.promotion_discount = invoiceIdPresent
        ? createSKU.promotion_discount
        : 0;
    }
    createSKU.net_amount = Number(
      (
        createSKU.quantity * createSKU.rate -
        createSKU.promotion_discount
      ).toFixed(2)
    );
    createSKU.vat = Number(((createSKU.vat_percent / 100) * createSKU.net_amount).toFixed(2)) || 0;
    this.setState({ createSKU });
  };

  onPageInputFieldChange = (field, value) => {
    const { billSummary } = this.state;
    billSummary[field] = value;
    this.setState({ billSummary });
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (data, index) => {
    const { invoiceIdPresent } = this.state;
    this.setState({ editModalOpen: true, indexInInvoiceList: index });
    if (!invoiceIdPresent) this.getSkuLists();
    this.setState({ createSKU: clone(data), backUpCreateSku: clone(data) });
  };

  // create the sales Return, update the discount value
  createSalesReturn = () => {
    const { invoiceList, createSKU } = this.state;
    this.getSkuLists();
    invoiceList.push(createSKU);
    this.setState({ invoiceList }, () => {
      this.handleModalClose();
      // handle SRN item update
      this.handleSRNItemUpdate(invoiceList);
    });
  };

  editSalesReturn = (data, index) => {
    const { invoiceList, createSKU, invoiceIdPresent } = this.state;
    if (invoiceIdPresent) {
      createSKU.bill_discount =
          this.calculateRelativeDiscount(
              this.referenceData.subTotal,this.referenceData.billDiscount,
          createSKU.net_amount,
        ) || 0;
      createSKU.trade_discount =
        this.calculateRelativeDiscount(
          this.referenceData.subTotal,
          this.referenceData.tradeDiscount,
          createSKU.net_amount,
        ) || 0;
      createSKU.line = Number(createSKU.net_amount - createSKU.bill_discount - createSKU.trade_discount);
      createSKU.vat = Number((createSKU.line * (createSKU.vat_percent / 100)).toFixed(4)) || 0;
      createSKU.tax_exempted_amount = createSKU.vat === 0 ? createSKU.line : 0;
    }

    invoiceList[index] = createSKU;
    this.setState({ invoiceList }, () => {
      this.handleModalClose();
    });
    if (invoiceIdPresent) {
      this.handleSecondaryCheckBoxClick(data.sales_detail_id, true, createSKU);
    } else {
      this.handleSRNItemUpdate(invoiceList);
    }
  };

  // delete the sales Return for particular sku, and update the total value.
  deleteSalesReturnItem = index => {
    const { invoiceList, indexInInvoiceList } = this.state;
    if (invoiceList.length >= indexInInvoiceList) {
      invoiceList.splice(indexInInvoiceList, 1);
      this.setState({ invoiceList }, () => {
        this.handleModalClose();
        this.handleSRNItemUpdate(invoiceList);
      });
    }
  };

  calculateRelativeDiscount = (
    referenceAmount = 1,
    referenceDiscount,
    amount
  ) => (amount * referenceDiscount) / referenceAmount;

  // when SRN item number changes, update the bill total value
  handleSRNItemUpdate = invoiceList => {
    // set the invoice total.
    const { billSummary } = this.state;

    let amountInfo = {
      salesValue: 0,
      billDiscount: 0,
      tradeDiscount: 0,
      promotionDiscount: 0,
      amount: 0,
    };
    invoiceList.map(element => {
      amountInfo = {
        amount:
          amountInfo.amount +
          Number(element.quantity || 0) * Number(element.rate || 0),
        salesValue: amountInfo.salesValue + Number(element.net_amount || 0),
        billDiscount:
          amountInfo.billDiscount + Number(element.bill_discount || 0),
        tradeDiscount:
          amountInfo.tradeDiscount + Number(element.trade_discount || 0),
        promotionDiscount:
          (amountInfo.promotionDiscount +
          Number(element.promotion_discount || 0)) || 0,
      };

      return amountInfo;
    });
    const taxableAmount = Number(
      amountInfo.salesValue - amountInfo.billDiscount - amountInfo.tradeDiscount
    );
    invoiceList = updateDiscountValueOfInvoiceList(invoiceList, amountInfo.billDiscount, amountInfo.tradeDiscount);
    const vat = invoiceList.reduce((acc, item) => acc + (item.vat || 0), 0);
    billSummary.taxExemptedAmount = invoiceList.reduce((acc, item) => acc + item.tax_exempted_amount , 0)
    billSummary.actualTaxableAmount = taxableAmount - (billSummary.taxExemptedAmount || 0)

    billSummary.vat = vat || 0;
    billSummary.bill_discount = amountInfo.billDiscount;
    billSummary.trade_discount = amountInfo.tradeDiscount;
    billSummary.sub_total = Number(amountInfo.salesValue.toFixed(2));
    billSummary.net_amount = Number(taxableAmount.toFixed(2));
    billSummary.gross_amount = Number((taxableAmount + vat).toFixed(2));
    billSummary.rounded_gross_amount = billSummary.gross_amount;
    const settings = localStorage.getItem(JWT.LOCAL_STORAGE.SETTINGS.NAME);

    if (settings) {
      let parsedSettings = JSON.parse(settings);
      if (parsedSettings) {
        let decimalRoundingSetting = parsedSettings.decimal_rounding;
        if (decimalRoundingSetting && decimalRoundingSetting.status) {
          billSummary.rounded_gross_amount = decimalRounding(
            Number(billSummary.gross_amount),
            decimalRoundingSetting.type
          );
        }
      }
    }
    billSummary.amount = amountInfo.amount;
    billSummary.promotion_discount = (amountInfo.promotionDiscount) || 0;
    this.setState({ billSummary });
  };

  // on okay click for the modal
  handleModalOk = () => {
    const { createModalOpen, editModalOpen } = this.state;
    if (editModalOpen || createModalOpen) {
      const valid = this.validateModal();
      const qtyValidation = this.checkQuantity();
      if (valid) {
        if (createModalOpen) {
          this.createSalesReturn();
        } else {
          if (qtyValidation) {
            this.editSalesReturn(
              this.state.createSKU,
              this.state.indexInInvoiceList
            );
          }
        }
      }
    } else {
      this.deleteSalesReturnItem();
    }
  };

  /** handle the primary check box click. */
  handlePrimaryCheckBoxClick = flag => {
    const { checkBoxForSalesReturn, invoiceList } = this.state;
    checkBoxForSalesReturn.primary = flag;
    if (!flag) {
      checkBoxForSalesReturn.secondary = [];
      this.resetBillSummaryAmounts();
      this.setState({ checkBoxForSalesReturn });
    } else {
      /** run through all the loop within the invoice list */
      const allCheckBox = invoiceList.map(element => element.sales_detail_id);
      checkBoxForSalesReturn.secondary = allCheckBox;
      const selectedInvoiceList = this.getSelectedInvoiceList(
        checkBoxForSalesReturn.secondary
      );
      this.setState({ checkBoxForSalesReturn }, () =>
        this.handleSRNItemUpdate(selectedInvoiceList)
      );
    }
  };

  /** handle the secondary checkBox click */
  handleSecondaryCheckBoxClick = (id, flag) => {
    const { checkBoxForSalesReturn, invoiceList } = this.state;
    let selectedInvoiceList = [];
    const indexInList = checkBoxForSalesReturn.secondary.indexOf(id);
    if (!flag) {
      // reset the primary checkbox
      checkBoxForSalesReturn.secondary.splice(indexInList, 1);
      checkBoxForSalesReturn.primary = false;
    } else {
      // check the condition if primary is to be set.
      if (indexInList === -1) checkBoxForSalesReturn.secondary.push(id);
      if (checkBoxForSalesReturn.secondary.length === invoiceList.length) {
        checkBoxForSalesReturn.primary = true;
      }
    }
    selectedInvoiceList = this.getSelectedInvoiceList(checkBoxForSalesReturn.secondary);
    this.setState({ checkBoxForSalesReturn }, () => this.handleSRNItemUpdate(selectedInvoiceList));
  };

  // close the modal resetting  all the value
  handleModalClose = () => {
    this.setState({
      createModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      batchList: [],
      brandSKUList: [],
    });
    this.resetSKUDialog();
  };

  directToMainPage = () => {
    const { showMessage, success, srnNumber } = this.state;
    if (!showMessage && success) {
      const encodedVoucherNo = voucherEncoder(srnNumber);
      history.push(`/${SRN_DETAILS}/${encodedVoucherNo}`,{
        printEnable: true,
      });
    }
  };

  handleBUClick = id => {
    this.setState({ businessUnitId: id }, () => {
      this.getBrandList();
      this.getSkuLists();
    });
  };

  handleSaveClick = () => {
    // generate the object to send to the server
    const {
      customerDetails,
      billSummary,
      invoiceList,
      businessUnitId,
      invoiceId,
      checkBoxForSalesReturn,
      invoiceIdPresent,
      pageValidation,
      date,
      cashStatus,
      agentDetails,
    } = this.state;
    const toValidObject = { ...customerDetails };
    const document_date = date;
    toValidObject.invoiceId = invoiceId;
    toValidObject.remarks = billSummary.remarks;
    const invalidIdFLag = this.state.invoiceIdTaken;
    const valid = validateForm(toValidObject, pageValidation, (valid) =>
      this.setState({ pageValidation: valid })
    );
    const invoiceListCheck = invoiceIdPresent
      ? checkBoxForSalesReturn.secondary.length > 0
      : invoiceList.length > 0;
    if (!invoiceListCheck) {
      this.setState({
        showMessage: true,
        success: false,
        message: message.invoiceListNotSelected,
      });

      return false;
    }
    if (valid && invoiceListCheck) {
      let invoiceArray = [];
      if (
        checkBoxForSalesReturn.secondary.length > 0 &&
        checkBoxForSalesReturn.primary === false
      ) {
        invoiceArray = invoiceList.filter(
          (item, key) =>
            checkBoxForSalesReturn.secondary.indexOf(item.sales_detail_id) > -1
        );
      } else {
        invoiceArray = invoiceList;
      }
      const invoiceArrayList = invoiceArray.map(({batch_id, ...rest}) => {
        return rest;
      });
      const object = {
        customer_id: customerDetails.customerId,
        business_id: checkBUId(businessUnitId),
        entered_by: billSummary.entered_by,
        net_amount: billSummary.gross_amount,
        vat: billSummary.vat || 0,
        amount: billSummary.amount,
        skuList: invoiceArrayList,
        invoice_number: invoiceId,
        promotion_discount: billSummary.promotion_discount || 0,
        bill_discount: billSummary.bill_discount,
        trade_discount: billSummary.trade_discount,
        taxable_amount: billSummary.taxable_amount,
        remarks: billSummary.remarks,
        document_date,
        payment_mode: cashStatus === true ? LEDGERS.CASH.title : LEDGERS.CUSTOMER.title,
        customer_name: billSummary.billName,
        tax_exempted_amount: billSummary.taxExemptedAmount || 0,
        isOldBill: !invoiceIdPresent,
       // agent_id: agentDetails.idAgent,

      };
      this.setState({ loading: true, saveDisable: true });
      srnApi
        .create(object)
        .then(response => {
          this.setState({ loading: false });
          if (response.success) {
            // alert('Sales Return Created');
            const salesReturnInvoiceNumber = response.data.detail.sales_return_invoice_number;
            this.setState({
              showMessage: true,
              success: true,
              message: message.success,
              saveDisable: true,
              srnNumber: salesReturnInvoiceNumber,
            });
            this.setState({ invoiceList: [] });
          }
        })
        .catch(error => {
          {
            this.setState({
              loading: false,
              showMessage: true,
              success: false,
              message: message.error,
              saveDisable: false,
            });
          }
        });
    }
  };

  handleCancelClick = () => {
    history.push(`/${SALES_RETURN_BASE}`);
  };

  handleDeleteIconClick = index => {
    this.setState({ deleteModalOpen: true, indexInInvoiceList: index });
  };

  validateModal = () => {
    const { createSKU, invoiceIdPresent, formValidation } = this.state;
    const fieldList = invoiceIdPresent
      ? editFormValidateFieldArray
      : formValidateFieldArray;
    const checkIfValid = validateForm(
      createSKU,
      { fieldList, flag: formValidation.flag },
      (valid) => this.setState({ formValidation: valid })
    );

    return checkIfValid;
  };

  createListObject = salesArray => {
    const { invoiceIdPresent } = this.state;
    const srnInvoicedDate = salesArray.length > 0 && salesArray[0].date;
    const invoiceList = salesArray.map((data, key) => {
      const createSkuObj = createSkuMapper(data);
      if (invoiceIdPresent) {
        createSkuObj.maxValue = data.quantity;
        createSkuObj.disabled = true;
      }
      return createSkuObj;
    });
    this.setState({ invoiceList, invoicedDate: srnInvoicedDate });
  };

  mapCustomerDetails = (customer = {}) => {
    const { invoiceIdPresent, billSummary, customerList } = this.state;
    const exist = customerList.some(c => c.customerId === customer.customerId);
    const customerDetails = customerMapper(customer);
    if(!exist)customerList.push(customerDetails);
    this.setState({ customerDetails, customerList });
    if (!invoiceIdPresent) {
      billSummary.billName = customer.title;
      this.setState({ billSummary });
    }
  };

  mapAgentDetails = (agent = {}) => {
    this.setState({ agentDetails: agent });
  };

  mapPaymentMode = cashStatus => {
    const paymentMode = cashStatus === LEDGERS.CASH.title;
    this.setState({ cashStatus: paymentMode });
  };

  mapBillName = customerName => {
    const { billSummary } = this.state;
    billSummary.billName = customerName;
    this.setState({ billSummary });
  };
  resetSKUDialog = () => {
    const { formValidation } = this.state;
    formValidation.flag = false;
    this.setState({
      createSKU: createSkuMapper({}),
      skuList: this.state.skuList,
      formValidation,
    });
  };

  resetBillSummaryAmounts = () => {
    const { billSummary } = this.state;
    billSummary.net_amount = 0;
    billSummary.vat = 0;
    billSummary.bill_discount = 0;
    billSummary.trade_discount = 0;
    billSummary.gross_amount = 0;
    billSummary.rounded_gross_amount = 0;
    billSummary.sub_total = 0;
    billSummary.amount = 0;

    this.setState({ billSummary });
  };

  resetDiscountCalculationReferenceField = () => {
    this.referenceData.subTotal = 1;
    this.referenceData.billDiscount = 0;
    this.referenceData.tradeDiscount = 0;
  };

  getAgentList = (invoiceIdPresent=false) => {
    salesInvoiceApi.getAgentList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        const agentList = ( invoiceIdPresent ?  response.data.list : response.data.list.filter(a=>a.status)) || [];
        this.setState({ agentList });
      }
    });
  };
  handleDateChange = date => {
    const formattedDate = moment(date).format('ll');
    this.setState({ date: formattedDate });
  };

  onBatchFabClick = () => {
    this.resetBatchFormDetail();
    this.setState({
      batchFormOpen: true,
      batchDialogValidation: true,
    });
  };

  onBatchInputChange = (field, value, detail) => {
    const { batchDetails } = this.state;
    batchDetails[field] = value;
    this.setState({ batchDetails });
  };

  resetBatchFormDetail = () => {
    const batchDetails = {
      title: '',
      dlp: 0,
      mfgDate: filterUtil.getCurrentDay(),
      expiryDate: filterUtil.getCurrentDay(),
    };

    this.setState({
      batchDetails,
    });
  };

  onBatchFormSubmit = () => {
    const { batchDetails, createSKU, businessUnitId, batchList, createSku, skuList } = this.state;
    const sku = skuList.find(s => s.businessSkuId === createSKU.sku_id) || {};
    const valid =
        batchDetails.title !== '' &&
        batchDetails.dlp >= 0;

    if (!valid) {
      this.setState({ batchDialogValidation: false });
    } else {
      const batchData = {
        business_id: sku.businessId || 0,
        business_sku_id: createSKU.sku_id,
        title: batchDetails.title,
        dlp: batchDetails.dlp,
        mfg_date: batchDetails.mfgDate,
        expiry_date: batchDetails.expiryDate,
      };

      catalogsApi
          .createSkuBatch(batchData)
          .then(response => {
            const batchResponse = response.data;

            const batch = {
              id: batchResponse.business_batch_id,
              title: batchResponse.title,
              // rlp: batchResponse.rlp,
              dlp: batchResponse.dlp,
              businessBatchId: batchResponse.business_batch_id,
            };
            batchList.push(batch);

            this.setState(
                {
                  batchList,
                  batchFormOpen: false,
                },
                () => {
                  this.onChange('batch_id', batch.businessBatchId, batch);
                },
            );
          })
          .catch(err => {
            throw err;
          });
    }
  };

  onBatchFormCancel = () => {
    this.resetBatchFormDetail();
    this.setState({ batchFormOpen: false });
  };

  constructor(props) {
    super(props);
    const { user, bu_id } = props;
    this.state = {
      invoiceId: '',
      businessUnitId: null,
      cashStatus: false,
      indexInInvoiceList: 0,
      message: message.success,
      success: true,
      loading: false,
      showMessage: false,
      saveDisable: false,
      qtyValidation: false,
      editModalOpen: false,
      invoiceIdTaken: false,
      createModalOpen: false,
      deleteModalOpen: false,
      invoiceIdPresent: false,
      batchFormOpen: false,
      batchDialogValidation: true,
      invoicedDate: filterUtil.getCurrentDay(),
      buList: [],
      skuList: [],
      batchList: [],
      brandList: [],
      invoiceList: [],
      customerList: [],
      brandSKUList: [],
      invoiceNumberList: [],
      backUpCreateSku: {},
      batchDetails: {
        title: '',
        dlp: 0,
        mfgDate: filterUtil.getCurrentDay(),
        expiryDate: filterUtil.getCurrentDay(),
        business_id: 0,
      },
      billSummary: {
        net_amount: 0,
        entered_by: user.idUsers,
        amount: 0,
        vat: 0,
        sub_total: 0,
        bill_discount: 0,
        trade_discount: 0,
        gross_amount: 0,
        remarks: '',
        billName: '',
        taxExemptedAmount: 0,
        actualTaxableAmount: 0,
      },
      srnNumber: '',
      checkBoxForSalesReturn: {
        primary: false,
        secondary: [],
      },
      createSKU: createSkuMapper({}),
      customerDetails: customerMapper({}),
      agentDetails: agentMapper({}),
      date: filterUtil.getCurrentDay(),
      formValidation: {
        flag: false,
        fieldList: formValidateFieldArray,
      },
      pageValidation: {
        flag: false,
        fieldList: pageValidateFieldArray,
      },
      businessId:null,

    };
    this.promotionError = '!Violates Promotion';
    this.referenceData = {
      subTotal: 1,
      billDiscount: 0,
      tradeDiscount: 0,
    };

  }
  getMinimumDate = () => {
    const { invoiceIdPresent, invoicedDate } = this.state;
    const { fiscalYearRange } = this.props;
    const fyCloseStatus = fiscalYearRange.previous_fiscal_year_closed;
    if (invoiceIdPresent) {
      if (fyCloseStatus) {
        if (invoicedDate >= fiscalYearRange.start_date) return invoicedDate;
        return fiscalYearRange.start_date;
      }
      return invoicedDate;
    }
    return fiscalYearRange.start_date;
  };

  handleCashCheckClick = flag => {
    this.setState({
      cashStatus: flag,
    });
  };

  componentDidMount() {
    this.getBUList();
    this.getCustomerList();
    this.getAgentList();
  }

  handleBUClick = id => {
    this.setState({ businessUnitId: id ,businessId:id}, () => {
      this.getSkuLists();
    });
  };

  render() {
    const {
      createSKU,
      saveDisable,
      customerDetails,
      agentDetails,
      success,
      message,
      date,
      buList,
      businessUnitId,
      invoiceId,
      invoiceIdTaken,
      customerList,
      invoiceIdPresent,
      billSummary,
      checkBoxForSalesReturn,
      invoiceList,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      skuList,
      brandList,
      batchList,
      formValidation,
      pageValidation,
      loading,
      agentList,
      showMessage,
      qtyValidation,
      cashStatus,
      brandSKUList,
      batchDialogValidation,
      batchDetails,
      batchFormOpen,
      invoiceNumberList,
    } = this.state;
    const billDiscountPer = getPercentage(
      billSummary.bill_discount,
      billSummary.sub_total
    );
    const tradeDiscountPer = getPercentage(
      billSummary.trade_discount,
      billSummary.sub_total - billSummary.bill_discount || 0
    );
    const minDate = this.getMinimumDate();

    return (
      <SRNStyled>
        {loading && (
          <div className='linear-progress-wrapper temp-progress-wrapper'>
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={`srn-create ${loading ? 'clickable-false' : ''}`}>
          <div className='message-snackbar'>
            {
              <Snackbar
                className={success ? 'success-message' : ''}
                timeout={success ? 1000 : 6000}
                onTimeout={() => {
                  this.setState({ showMessage: false }, () => {
                    this.directToMainPage();
                  });
                }}
                open={showMessage}
              >
                {message}
              </Snackbar>
            }
          </div>
          <div className='card-header-bar clearfix' ref='tableReference'>
            <div className='header-left'>
              <h2>New SRN</h2>
            </div>
            <GRNStyled>
              <div className='header-right document_date'>
                <span className='date-picker-wrapper'>
                  <label>Document Date:</label>
                  <DatePicker
                    date={moment(date)}
                    onChange={this.handleDateChange}
                    maxDate={filterUtil.getCurrentDate()}
                    minDate={filterUtil.getMomentDate(minDate)}
                  />
                </span>
              </div>
            </GRNStyled>
          </div>
          <div className='card-body'>
            <div className='radio-list-wrapper'>
            <div className="float-left">
              {buList.length && (
                <RadioButtonList
                  data={buList}
                  disableRosiaBu
                  handleBuClick={this.handleBUClick}
                />
              )}
              </div>
              <div className="float-right cash-checkbox">
                    <Checkbox
                      checked={cashStatus}
                      onChange={({ target: { checked } }) => {
                        this.handleCashCheckClick(checked);
                      }}
                    />
                    <label>Cash</label>
              </div>
            </div>

            <div className={businessUnitId !== null ? '' : 'block-overlay-wrapper'}>
            <div className={businessUnitId !== null ? '' : 'block-overlay'}>
              <div className='three-input-wrapper'>
                <Grid>
                  <Cell col={3} className="input-select">
                    <div className="grn-label-text sales-invoice-number">
                      <CustomAutoComplete
                          name='invoiceId'
                          onChange={this.onInvoiceIdChange}
                          dropDownList={invoiceNumberList}
                          labelContent="Sales Invoice Number"
                          dropDownValueKey={null}
                          dropDownDisplayKey={null}
                          required={true}
                          emptyField={pageValidation.flag}
                          /*disabled={loadingInvoiceStatus}*/
                          onDropDownSelection={this.onInvoiceNumberSelection}
                          errorMessage={
                            invoiceIdTaken
                                ? 'Invoice No. belongs to other Business Unit'
                                : 'Should not be empty'
                          }
                          invalidError={invoiceIdTaken}
                      />
                      { invoiceIdPresent && (
                          <div className="success-label">
                            <Icon name="check" />
                            <span>Valid Number</span>
                          </div>)
                      }
                    </div>
                  </Cell>
                  {/*<Cell col={3} className='input-field'>
                    <BillingTextField
                      value={invoiceId}
                      floatingLabel='Sales Invoice Number'
                      required
                      className='billing-required'
                      emptyField={pageValidation.flag}
                      errorMessage={
                        invoiceIdTaken
                          ? 'Invoice No. b  elongs to other Business Unit'
                          : 'Should not be empty'
                      }
                      handleChange={this.onInvoiceIdChange}
                      invalidError={invoiceIdTaken}
                    />
                  </Cell>*/}
                  <Cell col={3} className='input-select'>
                    <label>Customer Name</label>
                    <BillingSelect
                      param='title'
                      clearable={false}
                      required
                      multipleParam
                      valueKey='customerId'
                      labelKey='title'
                      options={customerList}
                      name='form-field-name'
                      emptyField={pageValidation.flag}
                      value={customerDetails.customerId}
                      className={
                        invoiceIdPresent ? 'no-pointer-events inp-disabled' : ''
                      }
                      handleChange={(field = '', id = '', customer = {}) =>
                        this.mapCustomerDetails(customer)
                      }
                    />
                  </Cell>
                  <Cell col={3} className='input-field'>
                    <BillingTextField
                      value={customerDetails.address}
                      floatingLabel='Address'
                      className='billing-required inp-disabled'
                    />
                  </Cell>
                  <Cell col={3} className='input-field'>
                    <BillingTextField
                      value={customerDetails.panNo}
                      floatingLabel='PAN Number'
                      required
                      type='number'
                      rule='isInt'
                      className='billing-required inp-disabled'
                    />
                  </Cell>
                  <Cell col={3} className="input-field">
                    <BillingTextField
                      value={this.state.billSummary.billName}
                      param="billName"
                      floatingLabel="Bill Name"
                      handleChange={(param, value) => {
                        const { billSummary } = this.state;
                        billSummary[param] = value;
                        this.setState({ billSummary });
                      }}
                      className={customerDetails.title ? 'no-pointer-events' : ''}
                    />
                  </Cell>
                  <Cell col={3} className="input-field">
                    <BillingTextField
                      value={billSummary.remarks}
                      param='remarks'
                      floatingLabel='Remarks'
                      required
                      className='billing-required'
                      emptyField={pageValidation.flag}
                      handleChange={this.onPageInputFieldChange}
                    />
                  </Cell>
                </Grid>
              </div>
              <div className='table-wrapper'>
                <div ref='fixedTableBody' className='fixed-table-wrapper'>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          {invoiceIdPresent && (
                            <Checkbox
                              checked={checkBoxForSalesReturn.primary}
                              onChange={({ target: { checked } }) => {
                                this.handlePrimaryCheckBoxClick(checked);
                              }}
                            />
                          )}
                          {!invoiceIdPresent && <span>SN</span>}
                        </th>
                        <th>Service</th>
                        <th>Description</th>
                        <th className='right-align'>Quantity</th>
                        <th className='right-align'>Rate</th>
                        <th className='right-align'>Amount</th>
                        <th/>
                      </tr>
                    </thead>
                    {invoiceList.map((data, key) => (
                      <tbody className='common-checkbox'>
                        <CreateSalesReturnTableBody
                          data={data}
                          index={key}
                          deleteSalesInvoice={this.deleteSalesInvoice}
                          handleEditIconClick={this.handleEditIconClick}
                          handleDeleteIconClick={this.handleDeleteIconClick}
                          handleCheckBoxClick={
                            this.handleSecondaryCheckBoxClick
                          }
                          selectedCheckBox={checkBoxForSalesReturn.secondary}
                          showCheckBox={invoiceIdPresent}
                        />
                      </tbody>
                    ))}
                  </table>
                </div>

                {/* Fab Icon */}
                {!invoiceIdPresent && (
                  <div className='mini-fab-button'>
                    <Fab
                      mini
                      onClick={() => this.setState({ createModalOpen: true })}
                    >
                      <Icon name='add' />
                    </Fab>
                  </div>
                )}
              </div>
              {/* Sales invoice table summary goes over here. */}
              <div className='total-section-wrapper'>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className='right-align active-opacity-text'>
                    Sub Total
                  </Cell>
                  <Cell col={1} className='right-align'>
                    {fixedFloatAndCommas(billSummary.sub_total)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className='right-align active-opacity-text'>
                    Discount{' '}
                    {billDiscountPer ? `(${billDiscountPer}%)` : ''}
                  </Cell>
                  <Cell col={1} className='right-align'>
                    {fixedFloatAndCommas(billSummary.bill_discount)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className='right-align active-opacity-text'>
                    Taxable Amount
                  </Cell>
                  <Cell col={1} className='right-align'>
                    {fixedFloatAndCommas(billSummary.actualTaxableAmount)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className='right-align active-opacity-text'>
                    VAT
                  </Cell>
                  <Cell col={1} className='right-align'>
                    {fixedFloatAndCommas(billSummary.vat)}
                  </Cell>
                </Grid>

                <div className='hr' />
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className='right-align active-opacity-text'>
                    Total
                  </Cell>
                  <Cell col={1} className='right-align font-bold'>
                    {fixedFloatAndCommas(billSummary.rounded_gross_amount)}
                  </Cell>
                </Grid>
              </div>
            </div>
          </div>
          </div>
          <div className='newinvoice-btn-wrapper'>
            <Grid>
              <Cell col={8} />
              <Cell col={4} className='right-align'>
                <Button
                  accent
                  className='cancel-btn modal-btn'
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  accent
                  className={
                    saveDisable
                      ? 'save-btn modal-btn btn-disabled'
                      : 'save-btn modal-btn'
                  }
                  disabled={saveDisable}
                  onClick={() => {
                    this.handleSaveClick();
                  }}
                >
                  Save
                </Button>
              </Cell>
            </Grid>
          </div>

          {/* Begin of the dialog Body */}
          <CreateSrnDialog
            onModalClose={this.handleModalClose}
            modalOpen={createModalOpen}
            editModalOpen={editModalOpen}
            deleteModalOpen={deleteModalOpen}
            createSKU={createSKU}
            handleInputChange={this.onChange}
            onModalSubmit={this.handleModalOk}
            skuList={skuList}
            brandList={brandList}
            batchList={batchList}
            formEmptyField={formValidation.flag}
            invoiceIdStatus={invoiceIdPresent}
            qtyValidation={qtyValidation}
            brandSKUList={brandSKUList}
            onBatchInputChange={this.onBatchInputChange}
            batchDetails={batchDetails}
            onBatchFormCancel={this.onBatchFormCancel}
            onBatchFormSubmit={this.onBatchFormSubmit}
            batchFormOpen={batchFormOpen}
            onBatchFabClick={this.onBatchFabClick}
            batchDialogValidation={batchDialogValidation}
            loadOptions={this.getSkuLists }
          />
        </div>
      </SRNStyled>
    );
  }
}

CreateSalesReturn.defaultProps = defaultProps;
CreateSalesReturn.propTypes = propTypes;
CreateSalesReturn.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
  bu_id: state.billing.bu_id || null,
  fiscalYearRange: state.billing.fiscalYearRange || null,
});

const createSalesReturn = connect(mapStateToProps)(CreateSalesReturn);

export default createSalesReturn;
