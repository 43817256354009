import * as filterUtil from '../../utils/filterUtil';

const getEnumFromObject = (object) => Object.freeze({ ...object });

const FIXED_DECIMAL_DIGIT = 2;
const FOLLOWS_PRN_WEIGHTED_COMPUTATION = true;

const SORTING = {
  ASC: 'asc',
  DESC: 'desc',
  NO_SORT: 'no_sort',
};

const PAGINATION = {
  PAGE: 1,
  LIMIT: 50,
};

const ACTION = {
  UPLOAD: 'Upload',
  ADD: 'Create',
  ADD_AGGREGATE: 'Create Aggregated Ledger',
  UPDATE: 'Update',
  DELETE: 'Delete',
  NULL: 'NoAction',
  READ: 'Read',
  UPLOADOB: 'Upload Opening Balance',
};

const STOCK_TYPE = {
  INWARD: 0,
  OUTWARD: 1,
};
const PRINT_INFO_DEFAULT = {
  count: 3,
  batchEnabled: true,
};
const GET_BATCH_TH = {
  id: 3,
  label: 'batch',
  title: 'Batch',
  className: 'batch',
  sortable: false,
  classNames: 'batch',
};

const DOMAIN = {
  AGEING_REPORT: 'ageing_report',
  STOCK_AGEING_REPORT: 'stock_aging_report',
  CUSTOMER_VENDOR: 'customer_vendor',
  DATABASE: 'database',
  ENTRY_LOG: 'entry_log',
  JOURNAL_VOUCHER: 'journal_voucher',
  LEDGER_LIST: 'ledgers-list',
  LEDGER_REPORT: 'ledger_report',
  LEDGERS: 'ledger',
  MATERIALIZED_VIEW: 'materialized_view',
  NET_PURCHASE_VAT: 'net_purchase_vat',
  NET_SALES_VAT: 'net_sales_vat',
  OPENING_BALANCE: 'opening_balance',
  OUTSTANDING_REPORT: 'outstanding_report',
  SALES_PERSON_OUTSTANDING_REPORT: 'agent_outstanding_report',
  PURCHASE: 'purchase',
  PURCHASE_RETURN: 'purchase_return',
  PURCHASE_RETURN_VAT: 'purchase_return_vat',
  PURCHASE_VAT: 'purchase_vat',
  NEW_PURCHASE_VAT: 'new_purchase_vat',
  NEW_PURCHASE_RETURN_VAT: 'new_purchase_return_vat',
  VAT_REPORT: 'vat_report',
  RECEIPT: 'RECEIPT',
  PAYMENT: 'payment',
  SALES: 'sales',
  SERVICE_INVOICE:'service_invoice',
  SALES_MV: 'sales_mv',
  SALES_RETURN: 'sales_return',
  ABBREVIATED_INVOICE: 'abbreviated_invoice',
  SALES_VAT: 'sales_vat',
  NEW_SALES_VAT: 'new_sales_vat',
  SKU: 'sku',
  SRN_VAT: 'srn_vat',
  NEW_SRN_VAT_REPORT_CONFIG: 'new_srn_vat',
  STOCK_JOURNAL: 'stock_journal',
  STOCK_LEDGER: 'stock_ledger',
  STOCK_LEDGER_DETAIL: 'stock_ledger_detail',
  STOCK_LEDGER_BATCH_DETAILS: 'stock_ledger_batch_details',
  USERS: 'users',
  AGENT: 'agent',
  VAT_LEDGER: 'vat_ledger',
  STOCK_VALUATION_REPORT: 'stock_valuation_report',
  AGGREGATED_LEDGER_REPORT: 'aggregated_ledger_report',
  DAY_BOOK_SUMMARY: 'day_book',
  AGGREGATED_LEGER_JV_DETAILS: 'ledger_group_jv_details',
  PICK_LIST: 'picklist',
  ACCOUNT_GROUP: 'account_group',
  FYM_JV_ENTRY: 'fym_jv_entry',
  TRIAL_BALANCE: 'trial_balance',
  PROFIT_LOSS: 'profit_loss',
  BALANCE_SHEET: 'balance_sheet',
  LEDGER_DETAILS: 'ledger_details',
  CONTRA_ENTRY: 'contra-entry',
  SETTINGS: 'settings',
  SAMPLE_OPENING_BALANCE: 'sample_opening_balance',
  DEBIT_NOTE: 'debit-note',
  CREDIT_NOTE: 'credit-note',
  PSR: 'psr',
  PDC: 'pdc',
  SRN_DETAILS_CONFIG: 'sales_return_detail',
  USER_GROUP: 'user_group',
  SALES_REPORT: 'archive_sales_vat',
  SRN_REPORT: 'archive_srn_vat',
  GRN_REPORT: 'archive_purchase_vat',
  PRN_REPORT: 'archive_purchase_return_vat',
  VAT_SUMMARY: 'vat_summary',
  DAMAGE_EXPIRY_VALUATION_REPORT: 'stock_damage_expiry_report',
  TAG_WISE_CONFIG: 'tag_wise',
  LEDGER_WISE_CONFIG: 'ledger_wise',
  TAG_WISE_DETAILS:'tag_report_detail',
  NET_SALES: 'net_sales_report',
  SALES_SUMMARY: 'sales_summary',
  ARCHIVED_EMAIL:'archive_email',
  EMAIL_FREQUENCY:'email_frequency',
};

const REPORT_TYPE = {
  CSV: 'csv',
  PDF: 'pdf',
  XLS: 'xls',
  XML: 'xml',
};

const RESPONSE = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  CONFLICT: 'conflict',
};

const USER = {
  SUPER_USER_GROUP: 1,
};

const SCHEMA = 'https://';

const HELP_FILE = 'DBS-HelpFile.pdf';

const STRING_TYPE = {
  query: 'query',
  general: 'general',
};
// Sample Configuration File for each Domain
const config = {
  domain: DOMAIN.SALES,
  title: 'Sales Invoice',
  downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF, REPORT_TYPE.XLS],
  url: 'sales-invoice',
};

const BALANCE_TYPE = [
  { title: 'Credit', value: 'Cr', id: 1 },
  { title: 'Debit', value: 'Dr', id: 2 },
];

const LEDGER_TYPE = [
  { title: 'Customer', value: 4 },
  { title: 'Vendor', value: 6 },
  { title: 'Others', value: 7 },
  // { title: 'Customer & Vendor', value: 7 },
];

const LEDGERS = {
  SALES: {
    name: 'SALES A/C',
    ledger_id: 102,
    narration: 'Sales A/C entry',
    type: 1,
    title: 'SALES',
  },
  PURCHASE: {
    name: 'PURCHASE A/C',
    ledger_id: 104,
    narration: 'Purchase A/C entry',
    type: 2,
    title: 'PURCHASE',
  },
  VAT: {
    name: 'VAT A/C',
    ledger_id: 103,
    narration: 'Vat',
    type: 3,
    title: 'TAX',
  },
  CUSTOMER: {
    name: 'CUSTOMER A/C',
    ledger_id: 'customer_reference_id',
    narration: 'Credit entry',
    type: 4,
    title: 'CUSTOMER',
  },
  VENDOR: {
    name: 'VENDOR A/C',
    ledger_id: 101,
    narration: 'Vendor entry',
    type: 6,
    title: 'VENDOR',
  },
  CASH: {
    name: 'CASH A/C',
    ledger_id: 105,
    narration: 'Cash entry',
    type: 8,
    title: 'CASH',
    validInvoiceAmount: 50000,
    validAbiAmount: 10000,
  },
};

const JV_MANUAL_TYPE = 5;
const PAN_NO_VALID_DIGIT = 9;
const MOBILE_NO_VALID_DIGIT = 10;
const CLIENT = {
  ROSIA: 'ROSIA',
  DBS: 'DBS',
};

const MESSAGE_TYPE = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS',
};

const TRANSACTION_NATURE = {
  PARTIAL: 'PARTIAL',
  PAID: 'PAID',
};

const STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

const USER_GROUPS = [
  {
    id: 2,
    title: 'Admin',
    group_id: '2',
  },
  {
    id: 3,
    title: 'General',
    group_id: '3',
  },
];

const VAT_ZERO_PERCENTAGE = 0;
const VAT_PERCENTAGE = 13;
const VAT_PER_DERIVE=0.13;
const RATE_WITH_VAT_FACTOR = 1.13; // factor to convert the rate amount to rate with vat

const decimalRoundingType = {
  ROUND_UP: 'round_up',
  ROUND_DOWN: 'round_down',
  ROUND_TO_NEAREST_WHOLE_NUMBER: 'round_to_nearest_whole_number',
};

const DISCOUNT_OPTIONS = [
  { value: 'per', label: 'in %' },
  { value: 'rs', label: 'in Rs' },
];

const DBS = 'DBS';
const BUSINESS_ID = 'businessId';
const LEDGER_GROUP_TYPE = [
  { id: 1, title: 'Income' },
  { id: 2, title: 'Expenses' },
  { id: 3, title: 'Assets' },
  { id: 4, title: 'Liability' },
];

const defaultFiscalYear = {
  start_date: filterUtil.getCurrentDay(),
  end_date: filterUtil.getCurrentDay(),
  previous_fiscal_year_closed: false,
};

const fiscalYear = 'fiscalYear';

const SYSTEM_LEDGER_IDS = [101, 102, 103, 104, 105, 107, 110, 111];

const TRANSACTION_TYPE = {
  SI: 1,
  SRN: 2,
  GRN: 3,
  PR: 4,
  SJ: 5,
  OB: 6,
  PI: 7,
  JV: 8,
  JV_INWARD: 9,
  JV_OUTWARD: 10,
  CREDIT_NOTE: 11,
  DEBIT_NOTE: 12,
  CONTRA_ENTRY: 13,
};

const paymentTypes = [
  {
    id: 1,
    title: 'CASH',
    value: 'CASH',
  },
  {
    id: 2,
    title: 'CHEQUE',
    value: 'CHEQUE',
  },
  {
    id: 3,
    title: 'POST DATED CHEQUE',
    value: 'POST_DATED_CHEQUE',
  },
];

const PASSWORD_RULE = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{8,64}/;
const EMAIL_RULE = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g;
const PASSWORD_CRITERIA_MSG =
  'Password must be between 8-64 character and must contain one of each number, alphabet and special character';

const promotionType = [
  {
    id: 1,
    title: 'Normal',
    value: 'NORMAL',
  },
];

const promotionCriteriaDimension = [
  {
    id: 1,
    title: 'Quantity',
    value: 'QUANTITY',
  },
  {
    id: 2,
    title: 'Amount',
    value: 'AMOUNT',
  },
];

const promotionCriteriaOperation = [
  {
    id: 1,
    title: '> GREATER_THAN',
    value: 'GREATER_THAN',
  },
  {
    id: 2,
    title: '>= GREATER_THAN_EQUALS',
    value: 'GREATER_THAN_EQUALS',
  },
  {
    id: 3,
    title: '< LESS_THAN',
    value: 'LESS_THAN',
  },
  {
    id: 4,
    title: '<= LESS_THAN_EQUALS',
    value: 'LESS_THAN_EQUALS',
  },
  {
    id: 5,
    title: '= EQUALS',
    value: 'EQUALS',
  },
];

const disbursementTypeList = [
  {
    id: 1,
    title: 'Discount (%)',
    value: 'PERCENTAGE',
  }, {
    id: 2,
    title: 'Amount',
    value: 'AMOUNT',
  }
];

const ROLES = getEnumFromObject({
  SUPER_ADMIN: 1,
  ADMIN: 2,
  GENERAL: 3,
});

const DOWNLOAD_SAMPLE_FILE_URL = 'https://rosia-dbs-sample-files.s3.ap-south-1.amazonaws.com';

export {
  USER,
  SORTING,
  PAGINATION,
  DOMAIN,
  REPORT_TYPE,
  config,
  HELP_FILE,
  SCHEMA,
  STRING_TYPE,
  LEDGERS,
  ACTION,
  BALANCE_TYPE,
  RESPONSE,
  LEDGER_TYPE,
  JV_MANUAL_TYPE,
  PAN_NO_VALID_DIGIT,
  MOBILE_NO_VALID_DIGIT,
  STOCK_TYPE,
  CLIENT,
  MESSAGE_TYPE,
  TRANSACTION_NATURE,
  VAT_PERCENTAGE,
  VAT_PER_DERIVE,
  USER_GROUPS,
  STATUS,
  DISCOUNT_OPTIONS,
  FIXED_DECIMAL_DIGIT,
  FOLLOWS_PRN_WEIGHTED_COMPUTATION,
  DBS,
  LEDGER_GROUP_TYPE,
  PRINT_INFO_DEFAULT,
  GET_BATCH_TH,
  defaultFiscalYear,
  fiscalYear,
  RATE_WITH_VAT_FACTOR,
  SYSTEM_LEDGER_IDS,
  TRANSACTION_TYPE,
  decimalRoundingType,
  paymentTypes,
  PASSWORD_RULE,
  PASSWORD_CRITERIA_MSG,
  EMAIL_RULE,
  promotionType,
  promotionCriteriaDimension,
  promotionCriteriaOperation,
  disbursementTypeList,
  ROLES,
  VAT_ZERO_PERCENTAGE,
  BUSINESS_ID,
  DOWNLOAD_SAMPLE_FILE_URL
};
